.colorRed {
    color: red;
  }
  
  .colorGreen {
    color: green;
  }

  /* .edit-profile-icon-active i:before {content: "\f00d";} */
.edit-profile-icon-active .edit-icon .btn-danger i:before {content: "\f00d" !important;} 
/* done by above line  SP-209  */

.edit-profile-icon-active .edit-profile-icon{
  background: #eef1f5;
  color: #252423;
}

.subject-info-list > li {
  width: 50%;
  line-height: 1.2;
  padding: 0 5px;
  word-break: normal !important;
}


.card-table-custom .rdt_TableHeader {
  order: -5;
  padding: 0;
  margin-bottom: 15px;
  min-height: inherit;
}

.card-table-custom .data-table-extensions {
  order: 0;
  padding: 0;
  margin-bottom: 15px;
}