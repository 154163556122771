body {
  --topbar-color: Green;
  --highlight-color: Purple;
  --submenu-hover-color: #ef8700b3;
  --subsubmenu-hover-color: #f9cf99;
  --sidebar-color: Brown;
  --active-color: pink;
  --hover-color: Red;
  --tab-hover-color: #f4ac4f;
  --login-header-color: rgba(121, 179, 255, 0.61);
  --active-text-color: #ffff;
  --form-tab-hover-color: #e6ecf2;
  --lightGray: #f5f3f3;
  --black: #000;
  --red: #f00;
  --green: #5cb85c;
  --button-color: Black;
  --button-hover-color: Yellow;
  --topbar-opacity-color: Orange;
  --sidebar-opacity-color: Blue;
  --gradebook-collapse-color: #467FCF;
  --gradebook-expand-color: #EF8700;
  --gradebook-collapse-bg-color: #ECF2FA;
  --gradebook-expand-bg-color:rgba(239, 135, 0, 0.1);;
}

/* Pseudo element css  start */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.015);
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1);
  width: 5px !important;
}

.not-refresh:after {
  content: "";
  width: 1px;
  height: 125%;
  position: absolute;
  background: #000;
  transform: rotate(-45deg);
  transform-origin: center;
  left: 6px;
  top: -2px;
}
.not-refresh {
  position: relative;
}
/* Pseudo element css  end */
a,
button {
  outline: none !important;
  text-decoration: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUIBlack.eot");
  src: url("../fonts/SegoeUIBlack.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SegoeUIBlack.woff2") format("woff2"),
    url("../fonts/SegoeUIBlack.woff") format("woff"),
    url("../fonts/SegoeUIBlack.ttf") format("truetype"),
    url("../fonts/SegoeUIBlack.svg#SegoeUIBlack") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI.eot");
  src: url("../fonts/SegoeUI.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SegoeUI.woff2") format("woff2"),
    url("../fonts/SegoeUI.woff") format("woff"),
    url("../fonts/SegoeUI.ttf") format("truetype"),
    url("../fonts/SegoeUI.svg#SegoeUI") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI-Bold.eot");
  src: url("../fonts/SegoeUI-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SegoeUI-Bold.woff2") format("woff2"),
    url("../fonts/SegoeUI-Bold.woff") format("woff"),
    url("../fonts/SegoeUI-Bold.ttf") format("truetype"),
    url("../fonts/SegoeUI-Bold.svg#SegoeUI-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI-Light.eot");
  src: url("../fonts/SegoeUI-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SegoeUI-Light.woff2") format("woff2"),
    url("../fonts/SegoeUI-Light.woff") format("woff"),
    url("../fonts/SegoeUI-Light.ttf") format("truetype"),
    url("../fonts/SegoeUI-Light.svg#SegoeUI-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI-Semibold.eot");
  src: url("../fonts/SegoeUI-Semibold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SegoeUI-Semibold.woff2") format("woff2"),
    url("../fonts/SegoeUI-Semibold.woff") format("woff"),
    url("../fonts/SegoeUI-Semibold.ttf") format("truetype"),
    url("../fonts/SegoeUI-Semibold.svg#SegoeUI-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI Semilight";
  src: url("../fonts/SegoeUI-Semilight.eot");
  src: url("../fonts/SegoeUI-Semilight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SegoeUI-Semilight.woff2") format("woff2"),
    url("../fonts/SegoeUI-Semilight.woff") format("woff"),
    url("../fonts/SegoeUI-Semilight.ttf") format("truetype"),
    url("../fonts/SegoeUI-Semilight.svg#SegoeUI-Semilight") format("svg");
  font-weight: 300;
  font-style: normal;
}

body {
  background: #f3f6f9;
  line-height: 1.2;
  font-family: "Segoe UI";
  font-size: 14px;
  color: #484644;
  overflow: hidden;
}

img {
  max-width: 100%;
}

.bg-white {
  background: #fff;
}

main {
  height: 100vh;
  padding-top: 48px;
}

.content-wrapper {
  max-height: -webkit-calc(100vh - 48px);
  max-height: -moz-calc(100vh - 48px);
  max-height: calc(100vh - 48px);
  height: -webkit-calc(100vh - 48px);
  height: -moz-calc(100vh - 48px);
  height: calc(100vh - 48px);
  overflow: auto;
  overflow-x: hidden;
}

.btn-white-bordered {
  border: 1px solid #d7dee8;
  color: #484644;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 10px;
  line-height: 1.2;
  background: #fff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13) !important;
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13) !important;
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13) !important;
}

.btn-white-bordered:hover {
  background: #d7dee8;
}

.btn-white-bordered:focus,
.btn-white-bordered:active {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-white-bordered i {
  margin-right: 5px;
}

select.form-control {
  background-image: url(../images/arrow-down.svg) !important;
  background-repeat: no-repeat !important;
  background-position: -webkit-calc(100% - 10px) center !important;
  background-position: -moz-calc(100% - 10px) center !important;
  background-position: calc(100% - 10px) center !important;
  -webkit-background-size: 10px !important;
  -moz-background-size: 10px !important;
  -o-background-size: 10px !important;
  background-size: 10px !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;

  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  border: none;
  padding: 8px;
  min-height: 34px;
  max-height: 34px;
}

.p-30px {
  padding: 30px !important;
}

.p-17px {
  padding: 17px !important;
}
/* .p-15px{padding:15px !important;} */

/***Header Css Start***/
header {
  background: var(--topbar-color);
  line-height: 1.2;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  max-height: 48px;
  min-height: 48px;
}

.logo {
  /* max-width: 260px; */
}

.logo img {
  max-width: 26px;
}

.logo a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.logotext {
  font-size: 18px;
  color: #fff;
  font-weight: 300;
  margin-left: 10px;
  line-height: 1.2;
}

.logotext strong {
  font-weight: 600;
}

.nav-ul > li {
  position: relative;
  padding: 8px 5px;
}

.nav-ul > li > a {
  font-size: 18px;
  line-height: 1;
}

.nav-ul > li > a {
  color: #fff;
}

.nav-ul > li + li {
  margin-left: 10px;
}

.nav-ul > li.user-profile:hover {
  background: #9ac3fa;
}

.user-profile .dropdown-toggle:after {
  display: none;
}

.user-profile .user-drop-down-img {
  width: 32px;
  height: 32px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #1899d2;
  color: #fff;
  display: inline-block;
  cursor: pointer;
  overflow: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-profile .user-drop-down-img .portal-status-icon {
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.user-profile .user-drop-down-img img {
  border-radius: 50%;
  object-fit: cover;
  height: 100%;
  min-width: 100%;
  object-position: center;
  width: 100%;
  position: static;
  /* background-color: #fff; */
}

.user-profile .dropdown-menu {
  padding: 10px 0px;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
  top: 100% !important;
  right: 0 !important;
  left: auto !important;
  border: 1px solid #d7dee8;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  min-width: 360px;
}

.user-profile .dropdown-menu li a,
.user-profile .dropdown-menu li button {
  padding: 8px 20px;
  display: block;
  font-size: 14px;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  color: #484644;
  line-height: 1.2;
  cursor: pointer;
}

.user-profile .dropdown-menu li > a > i,
.user-profile .dropdown-menu li > button > i {
  font-size: 14px;
  min-width: 20px;
  display: inline-block;
}

.user-profile .dropdown-menu li a:hover,
.user-profile .dropdown-menu li button:hover {
  background: #e6ecf2;
}

.user-profile .dropdown-menu li a:focus,
.user-profile .dropdown-menu li button:focus {
  background: #d3dee9;
}

.profile-img-child {
  width: 50px;
  height: 50px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #d7dee8;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
}

.profile-img-child img {
  height: 100%;
  width: auto;
  min-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile-name {
  font-size: 12px;
}

.profile-img-parent {
  margin-right: 10px;
}

.change-picture button {
  font-size: 12px !important;
  padding: 0 !important;
  margin: 0;
  background: none !important;
}

.login-user-profile {
  margin: 10px 20px;
}

.profile-name-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
}

.divider {
  margin: 9px 0;
  overflow: hidden;
  background-color: #d7dee8;
  height: 1px;
}

.user-profile .li-has-menu {
  position: relative;
}

.user-profile .li-has-menu .sub-menu {
  position: absolute;
  padding: 10px 0px;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
  border: 1px solid #d7dee8;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  min-width: 240px;
  right: 98%;
  background: #fff;
  top: 0;
  display: none;
}

.status-container .portal-status-icon {
  font-size: 12px;
  min-width: 18px;
  display: inline-block;
}

.portal-status-icon .fa-check-circle {
  color: #92c353;
}

.portal-status-icon .fa-circle {
  color: #c4314b;
}

.portal-status-icon .fa-minus-circle {
  color: #c4314b;
}

.portal-status-icon .fa-clock {
  color: rgb(253, 185, 19);
}

.user-profile .dropdown-menu .li-has-menu > a > .toogle-menu-icon,
.user-profile .dropdown-menu .li-has-menu > button > .toogle-menu-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  top: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/***Header Css End***/

.sidebar-left {
  width: 80px;
  background: var(--sidebar-color);
  position: fixed;
  height: 100%;
  z-index: 99;
}

.side-bar-nav > li > a {
  display: block;
  font-size: 10px;
  color: #fff;
  text-align: center;
  padding: 10px 0px;
  line-height: 1.2;
  border-left: 4px solid var(--sidebar-color);
  background: var(--sidebar-color);
  text-decoration: none;
}

.side-bar-nav > li > a .nav-icon {
  display: block;
  margin-bottom: 5px;
  font-size: 18px;
  position: relative;
}

.side-bar-nav > li > a .nav-icon .notify-count,
.side-bar-nav > li > a .nav-icon .Chat-count {
  position: absolute;
  right: 8px;
  top: -9px;
  width: 22px;
  height: 22px;
  background: #ff0202;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid var(--sidebar-color) !important;
  font-size: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: "Segoe UI";
  font-weight: bold;
  z-index: 1;
}

.side-bar-nav > li > a .nav-icon i {
  position: relative;
}

.side-bar-nav > li.active a,
.side-bar-nav > li a.active,
.side-bar-nav > li:hover > a {
  border-left: 4px solid #fff;
  background: var(--highlight-color);
}

.side-bar-nav {
  height: calc(100% - 30px);
  overflow: auto;
  padding: 20px 0;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
  background: var(--sidebar-color);
}

.side-bar-nav::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.side-bar-nav > li a.active {
  border-left: 4px solid #fff;
  background: var(--topbar-color);
}

.side-bar-nav > li.active a .nav-icon .notify-count,
.side-bar-nav > li.active a .Chat-count,
.side-bar-nav > li:hover a .nav-icon .notify-count,
.side-bar-nav > li:hover a .Chat-count {
  border: 2px solid var(--highlight-color) !important;
}

.count {
  position: absolute;
  right: -10px;
  top: -4px;
  width: 24px;
  height: 24px;
  background: #ff0202;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid var(--topbar-color);
  font-size: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: "Segoe UI";
  font-weight: bold;
}

/****/
.sidebar-right {
  width: -webkit-calc(100% - 80px);
  width: -moz-calc(100% - 80px);
  width: calc(100% - 80px);
  /* overflow: auto; */
  margin-left: auto;
}

.mycourses-list-wrap {
  padding: 20px;
}

.mycourses-list-wrap > p {
  margin-bottom: 15px;
}

.couses-bx-card {
  border: 1px solid #d7dee8;
  padding: 20px 5px 15px;
  text-align: center;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  cursor: pointer;
  min-height: 100%;
}

.couses-bx-card .course-link-redirect {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.couses-bx-card:hover {
  background: #e6ecf2;
}

.couses-bx-card:focus,
.couses-bx-card:active {
  background: var(--hover-color);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.couses-bx-card .icon {
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  background: #467fcf;
  color: #f3f6f9;
  font-size: 24px;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.couses-bx-card h3 {
  line-height: 1.2;
  font-size: 16px;
  color: #252423;
  font-weight: bold;
  margin: 13px 0;
}

.couses-bx-card p {
  margin-bottom: 10px;
}

.mycourses-ul-list li {
  width: -webkit-calc(100% / 5 - 10px);
  width: -moz-calc(100% / 5 - 10px);
  width: calc(100% / 5 - 10px);
  margin: 5px;
}

.mycourses-ul-list {
  margin: 0 -5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.couses-bx-card .btn-white-bordered {
  max-width: 220px;
  margin: 0 auto;
  z-index: 1;
  padding: 8px 10px;
}

/****/
.toggle-menu {
  width: 20px;
  height: 16px;
  position: relative;
  display: none;
}

.toggle-menu i {
  position: absolute;
  display: block;
  height: 2px;
  background: #ffffff;
  width: 20px;
  left: 0px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.toggle-menu i:nth-child(1) {
  top: 0px;
}

.toggle-menu i:nth-child(2) {
  top: 8px;
}

.toggle-menu i:nth-child(3) {
  top: 16px;
}

.toggle-menu.active i:nth-child(1) {
  top: 12px;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.toggle-menu.active i:nth-child(2) {
  background: transparent;
}

.toggle-menu.active i:nth-child(3) {
  top: 12px;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

/****/

.sub-detail-lft {
  /* padding: 20px; */
  top: 48px;
  max-width: 320px;
  position: absolute;
  min-height: -webkit-calc(100% - 48px);
  min-height: -moz-calc(100% - 48px);
  min-height: calc(100% - 48px);
  z-index: 2;
  width: 100%;
}

.sub-detail-lft .sub-lft-head,
.sub-detail-lft .sub-lft-body {
  margin: 20px;
  line-height: 1.2;
}

.sub-detail-lft .sub-lft-body {
  max-height: -webkit-calc(100vh - 270px);
  max-height: -moz-calc(100vh - 270px);
  max-height: calc(100vh - 270px);
  overflow: auto;
  height: 100vh;
  margin-right: 0 !important;
}

.sub-detail-lft .sub-lft-body.mCustomScrollbar {
  margin-right: 10px !important;
}

/* .ScrollbarsCustom .ScrollbarsCustom-Wrapper {
  margin-right: 20px;
} */

.ScrollbarsCustom.trackYVisible .ScrollbarsCustom-Wrapper {
  margin-right: 10px;
}

.ScrollbarsCustom.trackYVisible
  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  right: 7px !important;
  width: 5px !important;
}

.ScrollbarsCustom .ScrollbarsCustom-content {
  padding-bottom: 150px !important;
}

.couses-bx-info-top {
  margin: 20px 0;
}

.couses-bx-info-top .icon {
  width: 70px;
  height: 70px;
  min-width: 70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 20px 0 0;
  background: #467fcf;
  color: #f3f6f9;
  font-size: 24px;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.couses-bx-info-top h3 {
  line-height: 1.2;
  font-size: 18px;
  color: #252423;
  font-weight: bold;
  margin: 0 0 5px;
}

.couses-bx-info-top p {
  margin: 0;
}

.all-courses i {
  margin-right: 10px;
  font-size: 20px;
  vertical-align: text-bottom;
}

.all-courses {
  font-size: 16px;
}

.hide-completed {
  margin: 15px 0;
  padding: 0;
  border: none;
  background: none;
  color: #06172a;
  font-weight: 600;
}

.hide-completed:before {
  content: "Hide Completed";
}

.hide-completed:after {
  content: "\f106";
  font-family: "Font Awesome 5 Pro";
  margin-left: 5px;
}

.hide-completed.collapsed:before {
  content: "Show Completed";
}

.hide-completed.collapsed:after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
}

.sub-lft-head {
  /* margin-bottom: 20px; */
  position: relative;
}

.subject-list-card {
  margin-right: 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #d7dee8;
  border-left: 4px solid #467fcf;
  padding: 8px;
  cursor: pointer;
}

.ScrollbarsCustom.trackYVisible
  .ScrollbarsCustom-Wrapper
  .subject-list-card.card {
  margin-right: 2px;
}

.subject-list-card-link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.subject-list-card.active,
.subject-list-card:hover {
  border-left: 4px solid var(--active-color);
  background: var(--active-color);
}

.subject-list-card:hover {
  background: var(--hover-color);
}

.subject-list-card:active,
.subject-list-card:focus {
  background: var(--active-color);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.subject-list-card + .subject-list-card {
  margin-top: 15px;
}

.subject-list-card h3 {
  position: relative;
  z-index: 3;
  font-size: 14px;
  margin: 0 0 10px 0;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.subject-info-list {
  margin: 0 -5px;
}

.subject-info-list > li {
  width: 50%;
  line-height: 1.2;
  padding: 0 5px;
  word-break: break-all;
}

.status-detail-li .sub-info-lst-row {
  /*height: 100%;*/
}

.subject-info-list > li + li {
  border-left: 1px solid #f3f6f9;
}

.sub-info-lst-row .sub-info-lst-lft {
  margin-right: 5px;
}

.sub-info-lst-row .sub-info-lst-lft .sub-icon img {
  max-width: 25px;
}

.sub-info-lst-row .sub-info-lst-rgt small {
  color: rgba(37, 36, 35, 0.6);
  font-size: 10px;
  line-height: 1.2;
  display: block;
  margin: 0 0 2px 0;
}

.sub-info-lst-row .sub-info-lst-rgt p {
  font-size: 11px;
  margin: 0;
  color: #252423;
  font-weight: 600;
}

/******/
.sub-detail-rgt {
  max-width: -webkit-calc(100% - 320px);
  max-width: -moz-calc(100% - 320px);
  max-width: calc(100% - 320px);
  width: 100%;
  padding: 20px;
  position: relative;
  left: 320px;
}

.lecture-bx-info .icon {
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 10px 0 0;
  background: #467fcf;
  color: #f3f6f9;
  font-size: 16px;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.lecture-bx-info-rgt h3 {
  font-size: 17px;
  font-weight: bold;
  margin: 0 0 3px 0;
  line-height: 1.2;
  color: #252423;
}

.lecture-bx-info-rgt p {
  margin: 0;
  font-size: 11px;
  color: #484644;
}

.lecture-bx-info-rgt p i {
  margin-right: 5px;
}

.lec-dtl-rgt-ul li {
  height: 100%;
}

.lec-dtl-rgt-ul li + li {
  margin-left: 10px;
}

.lec-dtl-rgt-ul li > div {
  padding: 3px 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  line-height: 1.2;
  min-height: 35px;
}

.lec-dtl-rgt-ul li > div.msg-icon {
  font-size: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
  display: none;
}

.your-complete-date .yr-com-lft {
  margin-right: 10px;
}

.your-complete-date .yr-com-rgt small {
  color: rgba(37, 36, 35, 0.6);
  font-size: 10px;
  line-height: 1.2;
  display: block;
  margin: 0 0 2px 0;
}

.your-complete-date .yr-com-rgt p {
  font-size: 11px;
  margin: 0;
  color: #252423;
  font-weight: 600;
}

.sign-off-pending {
  position: relative;
}

.sign-off-pending .sign-off-lft {
  max-width: 25px;
  margin-right: 10px;
}

.sign-off-pending .sign-off-rgt p {
  font-size: 11px;
}

.sign-off-pending .sign-off-rgt .alert-explain {
  position: absolute;
  background: #c4324c;
  font-size: 8px;
  color: #fff;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 2px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 2px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 2px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  right: -10px;
  top: -10px;
}

.lecture-detail-top {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.lecture-detail-top-left {
  margin-bottom: 15px;
}

.lecture-detail-top-right {
  margin-bottom: 15px;
}

.lecture-tabs-wrap .nav-pills {
  margin: 0 -20px;
  border-bottom: 1px solid #e6ebf1;
  padding: 0 60px;
}

.lecture-tabs-wrap .nav-pills.no-border {
  border-bottom: 0px !important;
}

.lecture-tabs-wrap .nav-pills .nav-link {
  padding: 10px 0;
  background: none !important;
  color: #484644;
  position: relative;
}

.lecture-tabs-wrap .nav-pills .nav-item + .nav-item {
  margin-left: 20px;
}

.lecture-tabs-wrap .nav-pills .nav-link.active {
  color: #467fcf;
  font-weight: bold;
}

.lecture-tabs-wrap .nav-pills .nav-link i {
  margin-right: 5px;
  font-weight: 300;
  font-family: "Font Awesome 5 Pro";
}

.lecture-tabs-wrap .nav-pills .nav-link:after {
  content: "";
  height: 4px;
  background: transparent;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.lecture-tabs-wrap .nav-pills .nav-link.active:after,
.lecture-tabs-wrap .nav-pills .nav-link:hover:after {
  background: #06172a;
}

.lecture-tabs-wrap .tab-content {
  padding: 20px;
}

.lecture-tabs-wrap > .tab-content {
  padding: 20px 0;
}

/******************/

.post-wrap,
.post-wrap > .post-wrap-header,
.post-wrap > .post-wrap-body {
  background: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: none;
}

.post-wrap > .post-wrap-header .media-body.card {
  padding: 20px 35px 20px 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 1.98px 0.02px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 1.98px 0.02px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 1.98px 0.02px rgba(125, 125, 125, 0.13);
  border: 1px solid rgb(215, 222, 232);
}

.media-icon.rounded-circle .user-profile-pic {
  display: block;
  overflow: hidden;
  width: 45px;
  height: 45px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.media-icon.rounded-circle .user-profile-pic img {
  width: 100%;
}

.media-icon.rounded-circle {
  margin-right: 14px;
  position: relative;
}

.media-icon.rounded-circle .online-status {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #f8fafc;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  padding: 3px;
  font-size: 7px;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.media-icon.rounded-circle .online-status i {
  width: 15px;
  height: 15px;
  font-size: 7px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
}

.media-icon.rounded-circle .online-status i.fa-check {
  background: #94c456;
}

.post-wrap-header .media-icon.rounded-circle .user-profile-pic,
.post-wrap
  > .post-wrap-body
  > .user-post-main
  > .media-icon.rounded-circle
  .user-profile-pic {
  width: 60px;
  height: 60px;
}

.comment-submit-bx {
  position: relative;
}

.comment-submit-bx textarea.form-control {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #d8dfe9;
  resize: none;
  padding: 8px 35px 8px 8px;
  font-size: 16px;
}

.comment-submit-bx textarea.form-control::-webkit-input-placeholder {
  color: #cbced0;
}

.comment-submit-bx textarea.form-control::-moz-placeholder {
  color: #cbced0;
}

.comment-submit-bx textarea.form-control:-ms-input-placeholder {
  color: #cbced0;
}

.comment-submit-bx textarea.form-control:-moz-placeholder {
  color: #cbced0;
}

.comment-submit-bx textarea.form-control:focus {
  -webkit-box-shadow: 0 0 0 1px rgba(68, 126, 207, 0.25);
  -moz-box-shadow: 0 0 0 1px rgba(68, 126, 207, 0.25);
  box-shadow: 0 0 0 1px rgba(68, 126, 207, 0.25);
}

.comment-submit-bx .comment-submit {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 1px solid #494745;
  color: #494745;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: none;
  cursor: pointer;
  min-width: 20px;
  min-height: 20px;
  padding: 0;
}

.top_search_sd .filter-scroll {
  top: 0;
}

span.sing_ard_badge {
  margin-bottom: 2px;
  display: inline-block;
}

span.sing_ard_badge img {
  margin-right: 5px;
}

.sing_ard_badge .btn {
  padding: 5px 5px;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
  height: 24px;
  width: auto;
  min-width: auto;
}

.comment-submit-bx .comment-submit:hover {
  background: #494745;
  color: #fff;
}

.comment-submit-bx .comment-submit[disabled="disabled"] {
  opacity: 0.3;
}

.user-post-main {
  margin-top: 15px;
}

.user-post-main > .media-body.card {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 1.98px 0.02px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 1.98px 0.02px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 1.98px 0.02px rgba(125, 125, 125, 0.13);
  border: 1px solid rgb(215, 222, 232);
}

.user-post-main > .media-body.card > .card-header,
.user-post-main > .media-body.card > .card-footer {
  background: #fff;
}

.user-post-main > .media-body.card > .card-header,
.user-post-main > .media-body.card > .card-footer {
  border: none;
  padding: 15px 35px 15px 15px;
  line-height: 1;
}

.user-post-main > .media-body.card > .card-body {
  padding: 0;
  border-top: 0.5px solid rgb(215, 222, 232);
  border-bottom: 0.5px solid rgb(215, 222, 232);
}

.user-post-main > .media-body.card > .card-body > .user-post-reply {
  padding: 15px;
}

.user-post-main > .media-body.card > .card-header h6 {
  font-weight: 600;
  margin: 0 0 5px 0;
  font-size: 14px;
}

.user-post-main > .media-body.card > .card-header h6 small {
  margin-left: 8px;
}

.user-post-main > .media-body.card > .card-header p {
  font-size: 16px;
}

.user-post-main > .media-body.card > .card-body > .user-post-reply h6 {
  font-weight: 600;
  margin: 0 0 5px 0;
  font-size: 14px;
}

.user-post-main > .media-body.card > .card-body > .user-post-reply h6 small {
  margin-left: 8px;
}

.user-post-main > .media-body.card > .card-body > .user-post-reply p {
  font-size: 16px;
}

.dropdown-dots {
  position: absolute;
  right: 5px;
  top: 5px;
}

.dropdown-dots .btn:after {
  display: none;
}

.dropdown-dots .btn {
  padding: 0;
  font-size: 26px;
  line-height: 1;
  width: 25px;
  height: 25px;
  border: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.dropdown-dots .dropdown-menu {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
  right: 10px !important;
  left: auto !important;
  top: 100% !important;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 1.98px 0.02px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 1.98px 0.02px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 1.98px 0.02px rgba(125, 125, 125, 0.13);
  border: 1px solid rgb(215, 222, 232);
}

/**Close Side bar Css Start**/
.close-btn-sidebar {
  position: absolute;
  right: 0px;
  top: 15px;
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 30px;
  height: 30px;
  border: none;
  font-size: 22px;
  color: #06172a;
  opacity: 1;
  line-height: 1;
  padding-left: 0;
  /* background: #06172a; */
  padding: 0 5px;
  /* border: 1px solid; */
  z-index: 9;
}

.close-btn-sidebar:before {
  content: "\f00d";
  font-family: "Font Awesome 5 Pro";
}

body.hide-subject-sidebar .close-btn-sidebar:before {
  content: "\f0c9";
}

.close-btn-sidebar .close-bar {
  width: 100%;
  display: block;
  margin: 5px auto;
  height: 3px;
  background: #467fcf;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: none;
}

.close-btn-sidebar .close-bar-2 {
  /* width: 80%; */
  height: 3px;
}

.close-btn-sidebar:hover {
  opacity: 1;
}

body.hide-subject-sidebar .sub-detail-lft {
  left: -252px;
}

body.hide-subject-sidebar .sub-detail-rgt {
  max-width: 100%;
  left: 0;
  padding-left: 40px;
}

body.hide-subject-sidebar .close-btn-sidebar {
  right: -30px;
}

/**Close Side bar Css End**/

/****Home Page Css Start****/
.home-list-wrap {
  padding: 20px;
}

.home-ul-list {
  margin: -5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.home-ul-list > li {
  width: -webkit-calc(100% / 5 - 10px);
  width: -moz-calc(100% / 5 - 10px);
  width: calc(100% / 5 - 10px);
  margin: 5px;
}

.home-list-wrap > p {
  margin-bottom: 15px;
}

.home-bx-card {
  border: 1px solid #d7dee8;
  padding: 25px 5px 35px;
  text-align: center;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  /* -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13); */
  /* box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13); */
  cursor: pointer;
  height: 100%;
}

.home-bx-card:hover {
  background: var(--active-color);
}

.home-bx-card:active,
.home-bx-card:focus {
  background: #d3dee9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.home-bx-card h3 {
  line-height: 1.2;
  font-size: 16px;
  color: #252423;
  font-weight: bold;
  margin: 13px 0;
}

.home-bx-card p {
  margin-bottom: 10px;
  color: #252423;
}

.home-bx-card .icon {
  height: 60px;
  width: 60px;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.home-slider .owl-dots {
  margin: 10px 0 20px;
  text-align: center;
}

.home-slider .owl-dots .owl-dot {
  width: 30px;
  height: 6px;
  background: #467fcf;
  margin: 0 5px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  opacity: 0.18;
}

.home-slider .owl-dots .owl-dot.active,
.home-slider .owl-dots .owl-dot:hover {
  opacity: 1;
}

.home-slider-item img {
  max-width: 100%;
  height: auto;
}

.home-slider-item {
  border: 2px solid #e4e4e4;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  max-width: 100%;
  display: block;
}

.home-slider .owl-stage {
  padding-left: 0 !important;
}

/****Home Page Css End****/

/****Profile Css Start****/
.profile-wrapper {
  max-height: -webkit-calc(100vh - 48px);
  max-height: -moz-calc(100vh - 48px);
  max-height: calc(100vh - 48px);
  height: -webkit-calc(100vh - 48px);
  height: -moz-calc(100vh - 48px);
  height: calc(100vh - 48px);
}

.profile-left {
  max-width: 300px;
  width: 100%;
  position: fixed;
  height: 100%;
  max-height: -webkit-calc(100vh - 48px);
  max-height: -moz-calc(100vh - 48px);
  max-height: calc(100vh - 48px);
}

.profile-right {
  max-width: -webkit-calc(100% - 300px);
  max-width: -moz-calc(100% - 300px);
  max-width: calc(100% - 300px);
  width: 100%;
  margin-left: 300px;
  padding: 20px;
}

.profile-tab-pills .nav-link {
  padding: 20px;
  line-height: 1;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 4px solid #fff;
  background: #fff;
  color: #2d2a29;
}

.profile-tab-pills .nav-link i {
  width: 40px;
  text-align: center;
  font-size: 26px;
  margin-right: 15px;
  font-weight: 300;
}

.profile-tab-pills .nav-link.active {
  background: var(--active-color);
  color: #2d2a29;
  border-right: 4px solid #2d2a29;
}

.profile-tab-pills .nav-link:hover {
  background: var(--hover-color);
  border-right-color: #06172a;
}

/*.profile-tab-pills .nav-link:focus {
  background: #d3dee9;
  border-right-color: #06172a;
}*/

.profile-tab-pills li.nav-item {
  border-bottom: 1px solid #eff2f5;
}

.profile-tab-pills {
  border-top: 1px solid #eff2f5;
}

.profile-left p i {
  margin-right: 5px;
}

.profile-cover-top {
  background: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  padding: 4px;
}

.profile-cover-top figure {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: block;
  overflow: hidden;
  min-height: 120px;
  position: relative;
}

.profile-cover-top img {
  width: 100%;
}

.profile-cover-top figure:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(6 23 42 / 60%);
  border-radius: 5px;
}

.avtar-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -95px;
  position: relative;
  padding: 0 30px;
}

.avtar-bot-lft {
  max-width: 110px;
  margin-right: 15px;
  position: relative;
}

.avtar-bot-rgt {
  width: 100%;
  color: #fff;
  line-height: 1;
}

.avtar-bot-lft .profile-img {
  width: 110px;
  height: 110px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
}

.avtar-bot-rgt h4 {
  font-size: 16px;
  margin: 0 0 6px 0;
  font-weight: 600;
}

.avtar-bot-rgt label {
  margin: 0 0 6px 0;
}

.avtar-bot-rgt label .green {
  color: #7cb337;
}

.avtar-bot-rgt p {
  margin: 0;
  font-size: 12px;
}

.profile-cover-top:hover .upload-icon {
  opacity: 1;
}

.avtar-bot-lft:hover .upload-icon {
  opacity: 1;
}

.upload-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  cursor: pointer;
  overflow: hidden;
  opacity: 0;
  transition: all 300ms;
}

.upload-icon input {
  position: absolute;
  /* width: 100%; */
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.upload-icon i {
  position: relative;
  z-index: 0;
  font-size: 16px;
}

.upload-icon:hover {
  background: #e6ecf2;
}

.upload-icon:focus,
.upload-icon:active {
  background: #d3dee9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.upload-icon.upload-icon-avtar {
  right: -10px;
  bottom: 30px;
}

.upload-icon.upload-icon-cover {
  right: 15px;
  top: 15px;
}

.card-body-inr.card-body-edit {
  display: none;
}

.card.card-profile-info-card.card-profile-info-edit
  .card-body-inr.card-body-edit {
  display: block;
}

.card.card-profile-info-card.card-profile-info-edit
  .card-body-inr.card-body-info {
  display: none;
}

.card.card-profile-info-card .card-header {
  border: none;
  background: none;
  font-weight: bold;
  padding: 15px 0;
  text-transform: capitalize;
  font-size: 16px;
  color: #252423;
}

/* .card.card-profile-info-card {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: none;
} */

.card-profile-info-card + .card-profile-info-card {
  margin-top: 20px;
}

.card.card-profile-info-card .card-body {
  background: #ffff;
  /* padding: 30px; */
  position: relative;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  /* -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #d7dee8; */
}

.edit-icon {
  min-height: 35px;
  position: relative;
  padding-right: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.edit-profile-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  height: 35px;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  cursor: pointer !important;
  overflow: hidden;
  border: none;
  font-size: 18px;
}

.edit-profile-icon:hover {
  background: #e6ecf2;
}

.edit-profile-icon:focus {
  background: #d3dee9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.card.card-profile-info-card .form-group > label {
  color: #484644;
  line-height: 1.2;
  margin: 0 0 10px 0;
}

.card.card-profile-info-card .form-control {
  background-color: #eef1f5;
  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  border: none;
  padding: 10px 15px;
  min-height: 42px;
}

.card.card-profile-info-card .select {
  line-height: 1;
}

/* .card.card-profile-info-card select.form-control { background-image: url(../images/arrow-select.png); background-repeat: no-repeat; background-position: -webkit-calc(100% - 10px) center; background-position: -moz-calc(100% - 10px) center; background-position: calc(100% - 10px) center; -webkit-background-size: 15px 15px; -moz-background-size: 15px; -o-background-size: 15px; background-size: 15px; -webkit-appearance: none; -moz-appearance: none; appearance: none; } */
.date-form-group .row {
  margin: 0 -1px;
}

.date-form-group .row div {
  padding: 0 2px !important;
}

.atttach-file label {
  position: relative;
  display: inline-block;
  margin: 0 !important;
  font-weight: 600;
  background: #eef1f5;
  font-size: 14px !important;
  line-height: 1.2 !important;
  height: inherit;
  border: 1px solid #cbced0;
  padding: 7px 7px;
  min-height: inherit;
  -webkit-border-radius: 1px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  cursor: pointer;
  color: #484644;
  height: 34px;
  vertical-align: top;
  box-shadow: 0 2px 2.97px 0.03px hsla(0, 0%, 49%, 0.13);
}

.atttach-file label i {
  font-size: 14px;
  vertical-align: middle;
  margin-right: 5px;
}

.atttach-file label input.form-control-aatch-file {
  position: absolute;
  padding: 0 !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
  min-height: inherit !important;
}

.attached-file-ul li + li {
  border-top: 1px solid #e5e5e5;
}

.attached-file-ul li {
  padding: 15px 35px 15px 0px;
  position: relative;
  line-height: 1.4;
}

.attached-file-ul li a,
.attached-file-ul li .download-document {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.attached-file-ul li .btn-danger {
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: 0;
  font-size: 14px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.attached-file-ul li .btn-danger i {
  margin: 0;
}

.btn.rounded-circle {
  min-width: 30px;
  width: 30px;
  height: 30px;
  /* background: var(--topbar-color); */
  padding: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  font-size: 14px;
  box-shadow: 0px 1.5px 2px 0px rgb(0 0 0 / 30%) !important;
}

.btn.rounded-circle i {
  margin-right: 0;
}

.btn.rounded-circle:hover {
  /* background: #3163a9; */
}

.btn.rounded-circle:focus {
  /* background: #234b84; */
}

.table-responsive {
  border-radius: 0.25rem !important;
}

table.table thead th {
  background: #06172a !important;
  border-color: #06172a !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

table.table tbody td {
  border-right-color: white;
}

table.table tbody td:last-child {
  border-right-color: #eff2f5;
}

table.table tbody td {
  border-color: #eff2f5;
  vertical-align: middle;
}

table.table .fa-toggle-off {
  font-size: 20px;
}

table.table .fa-toggle-off.active {
  color: #467fcf;
}

.profile-right .custom-control.custom-checkbox {
  font-size: 16px;
  font-weight: 600;
  color: #e54d2c;
  line-height: 1.2;
  z-index: 0;
}

.profile-right
  .custom-control.custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--topbar-color);
  background-color: var(--topbar-color);
}

/****Profile Css End****/

/****Login Page Css Start***/
.portal-login {
  background-repeat: no-repeat;
  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: auto;
}

.portal-login .container {
  max-width: 880px;
  padding: 15px;
}

.portal-login-card {
  background: none;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.portal-login-card .card-header {
  background: var(--login-header-color);
  padding: 0;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.card-header-img {
  max-width: 640px;
  margin: -12px auto 0;
  padding: 0 15px;
}

.portal-login-card .card-body {
  background: #fff;
  padding: 0 30px 30px 30px;
}

.portal-login-card .card-body-inr {
  max-width: 360px;
  margin: 0 auto;
  line-height: 1.2;
}

.portal-login-info h1 {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 2px;
}

.portal-login-info h3 {
  font-size: 32px;
  margin: 30px 0 0 0;
  font-weight: bold;
  letter-spacing: 1px;
  color: #4a7dbf;
  text-transform: capitalize;
}

.portal-login-info p {
  font-size: 16px;
  letter-spacing: 1px;
  margin: 10px 0 40px;
}

.portal-login-form .input-group {
  margin-bottom: 20px;
}

.portal-login-form .input-group .form-control {
  background-color: #eef1f5;
  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  border: 1px solid #eef1f5;
  padding: 12px 15px;
  min-height: 40px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.portal-login-form .input-group .form-control.is-invalid {
  border-color: #dc3545 !important;
}

.portal-login-form .input-group .input-group-prepend .input-group-text {
  background: none;
  border: none;
  color: #4a7dbf;
  font-size: 18px;
  padding: 0;
  width: 30px;
}

.portal-login-form .custom-control.custom-checkbox {
  margin: 20px 0 28px;
  margin-left: 30px;
}

.portal-login-form .custom-control-label {
  line-height: 1.2;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding-left: 5px;
}

.custom-control-input:focus ~ .custom-control-label::before {
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.custom-checkbox .custom-control-label::before,
.custom-checkbox .custom-control-label::after {
  top: 0;
  width: 20px !important;
  height: 20px !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  border-color: var(--topbar-color);
  -webkit-background-size: 10px 10px;
  -moz-background-size: 10px;
  -o-background-size: 10px;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
  cursor:pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background: #4a7dbf;
  border-color: #4a7dbf;
}

.custom-control-input:checked ~ .custom-control-label {
  color: var(--topbar-color);
}

button.btn.btn-white-bordered.btn-submit-info {
  padding: 0px 20px;
  font-size: 18px;
  text-transform: uppercase;
}

.portal-login-card .card-footer {
  padding: 30px;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  justify-content: center;
}

.portal-login-card .card-footer a {
  color: var(--topbar-color);
  font-size: 16px;
}

.portal-login-card .card-footer a:hover {
  color: var(--hover-color);
}

/****Login Page Css End***/

/***Notification Css Start***/
.notfication-left-wrap {
  max-width: 350px;
  width: 100%;
  position: absolute;
  min-height: -webkit-calc(100% - 48px);
  min-height: -moz-calc(100% - 48px);
  min-height: calc(100% - 48px);
  z-index: 1;
}

.notfication-right-wrap {
  max-width: -webkit-calc(100% - 350px);
  max-width: -moz-calc(100% - 350px);
  max-width: calc(100% - 350px);
  width: 100%;
  padding: 30px;
  position: relative;
  left: 350px;
  min-height: 100%;
}

.btn-day {
  padding: 8px;
  line-height: 1;
  font-size: 14px;
  background: #e6edf2;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-top: 1px solid #dfe0e2;
  border-bottom: 1px solid #dfe0e2;
}

.btn-day:hover {
  background: #e6ecf2;
}

.btn-day:focus {
  background: #d3dee9;
}

.btn-day:before {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  margin-right: 5px;
  width: 15px;
  display: inline-block;
}

/* .btn-day[aria-expanded="false"]:before { content: "\f054"; } */

.btn-day[aria-expanded="true"]:before {
  content: "\f078";
}

.notification-list-ul > li + li {
  border-top: 1px solid #f3f7fa;
}

.sort-by .form-inline label {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  margin-bottom: 0;
}

.notfication-panel-head .sort-by .form-control {
  border: none;
  padding: 0;
  font-size: 14px;
  height: inherit;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: inline-block;
  width: auto;
  vertical-align: middle;
  padding-right: 12px;
  background-position: 100% !important;
  padding-left: 5px;
}

.notfication-panel-head {
  margin: 15px;
}

.notfication-body {
  max-height: -webkit-calc(100vh - 99px);
  max-height: -moz-calc(100vh - 99px);
  max-height: calc(100vh - 99px);
  overflow: auto;
  height: 100vh;
}

.read-unread-tab-pill li + li {
  margin-left: 15px;
}

.read-unread-tab-pill li a {
  color: #484644;
  font-weight: 600;
  position: relative;
  padding-bottom: 3px;
}

.read-unread-tab-pill li a:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: 0;
}

.read-unread-tab-pill li.active a:after {
  background: #467fcf;
}

.read-unread-tab-pill li a.active:after {
  background: var(--topbar-color);
}

.notify-card .card-left {
  max-width: -webkit-calc(100% - 90px) !important;
  max-width: -moz-calc(100% - 90px) !important;
  max-width: calc(100% - 90px) !important;
  line-height: 1.2;
  width: 100%;
}

.notify-card .card-right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 90px;
  font-size: 11px;
  padding-left: 5px;
}

.notify-card .card-right .notify-include-items li + li {
  margin-left: 5px;
}

.notify-card .card-left h4,
.notify-card .card-left p {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.notify-card .card-left h4 {
  font-size: 14px;
  margin: 0 0 5px 0;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.notify-card .card-left p {
  margin: 0;
  font-size: 11px;
}

.notify-card .card-left h4,
.notify-card .card-left p,
.notify-card .card-right .date-time {
  font-weight: 600;
}

.notify-card .card-left p,
.notify-card .card-right .date-time {
  color: var(--topbar-color);
}

.card.notify-card {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
  -moz-box-orient: horizontal !important;
  -moz-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  border: none;
  border-right: 4px solid #467fcf;
  padding: 10px 6px 10px 15px;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  cursor: pointer;
}

.card.notify-card:after {
  content: "";
  height: 1px;
  width: -webkit-calc(100% - 15px);
  width: -moz-calc(100% - 15px);
  width: calc(100% - 15px);
  position: absolute;
  background: #dfe0e2;
  bottom: 0;
  right: 0;
}

.card.notify-card.notify-active {
  background: var(--active-color);
}

.card.notify-card:hover {
  background: var(--hover-color);
}

.card.notify-card:active,
.card.notify-card:focus {
  background: var(--active-color) !important;
}

.card.notify-card.notify-bulk {
  border-right: 4px solid #ff0202;
}

.card.notify-card.notify-read {
  border-right: 4px solid #fff;
}

.card.notify-card.notify-active,
.card.notify-card:hover {
  border-right: 4px solid #06172b !important;
}

.card.notify-card.notify-read .card-left h4,
.card.notify-card.notify-read .card-left p,
.card.notify-card.notify-read .card-right .date-time {
  font-weight: normal;
}

.card.notify-card.notify-read .card-left p,
.card.notify-card.notify-read .card-right .date-time {
  color: #484644;
}

.card.notify-bulk .card-left h4,
.card.notify-bulk .card-left p,
.card.notify-bulk .card-right .date-time {
  color: #ff0202 !important;
}

.notfication-body.mCustomScrollbar .mCSB_inside > .mCSB_container {
  margin: 0;
}

.notfication-body.mCustomScrollbar .mCustomScrollBox {
  padding-right: 15px;
}

.notfication-body.mCS_no_scrollbar .mCustomScrollBox {
  padding-right: 0px;
}

/**Right**/
.notfication-head-lft .icon {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 10px 0 0;
  background: var(--topbar-color);
  color: #f3f6f9;
  font-size: 16px;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.notfication-head-rgt h3 {
  font-size: 17px;
  font-weight: bold;
  margin: 0 0 3px 0;
  line-height: 1.2;
  color: #252423;
  position: relative;
  padding-right: 100px;
}

.notfication-head-rgt p {
  margin: 0;
  font-size: 11px;
  color: #484644;
}

.notfication-head-rgt .blue-text {
  color: var(--topbar-color);
}

.notfication-head-rgt p + p {
  margin-top: 3px;
}

.notfication-head-rgt {
  max-width: -webkit-calc(100% - 60px);
  max-width: -moz-calc(100% - 60px);
  max-width: calc(100% - 60px);
  width: 100%;
}

.notfication-head-rgt h3 .attachment-detail {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
}

.notfication-head-rgt h3 .attachment-detail a + a {
  margin-left: 5px;
  display: inline-block;
}

.attachment-detail .attachment {
  color: #252423;
}

.attachment-detail .alert-icon {
  color: #ff0202;
}

.notfication-detail-head {
  margin-bottom: 20px;
}

.notfication-detail-body .card {
  padding: 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #d7dee8;
  min-height: -webkit-calc(100vh - 200px);
  min-height: -moz-calc(100vh - 200px);
  min-height: calc(100vh - 200px);
}

.notfication-detail-body .card-header {
  background: #fff;
  padding: 0 0 15px;
  border-bottom: 1px solid #eeeeee;
}

.notfication-detail-body .card-header p {
  margin: 0;
}

.notfication-detail-body .card-header p + p {
  margin-top: 5px;
}

.notfication-detail-body .card-body {
  padding: 20px 0;
}

.notfication-detail-body .card-body hr {
  border-top-color: #eeeeee;
  margin: 20px 0;
}

.notfication-detail-body .card-body .notify-sub-info li + li {
  margin-top: 10px;
}

.notify-sub-info li a {
  color: #467fcf;
}

.notfication-detail-body .card-body .aie-regards p {
  margin: 0;
  font-size: 12px;
}

.notfication-detail-body .card-body .aie-regards p + p {
  margin-top: 10px;
}

/***Notification Css End***/

.no-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  min-height: calc(100vh - 108px);
}

.no-img {
  max-width: 160px;
  width: 100%;
  margin: 0 0 20px 0;
}

.no-content {
  text-align: center;
  width: 100%;
  color: #484644;
}

.no-content h4 {
  font-weight: 600;
  font-size: 14px;
  margin: 0 0 5px 0;
}

.no-content p {
  font-size: 11px;
}

.see-more-li {
  padding: 10px 6px 10px 15px;
  line-height: 1;
}

.see-more-btn {
  font-size: 14px;
  padding: 0;
  line-height: 1.2;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
}

.see-more-btn:hover {
  opacity: 0.8;
}

.see-more-btn:focus {
  opacity: 0.6;
}

.notfication-body .ScrollbarsCustom-Wrapper {
  margin-right: 0;
}

.notfication-body .ScrollbarsCustom.trackYVisible .ScrollbarsCustom-Wrapper {
  margin-right: 5px;
}

.notfication-body
  .ScrollbarsCustom.trackYVisible
  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  right: 5px !important;
}

/***Attachement Box Css Start***/
.attachment-wrap strong {
  display: block;
  margin: 0 0 5px 0;
}

.attach-bx {
  display: flex;
}

.attach-icon {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 10px 0 0;
  background: var(--topbar-color);
  color: #f3f6f9;
  font-size: 22px;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.attach-detail p {
  color: #484644;
  font-weight: 600;
  margin: 2px 0 5px;
}

.attachment-col {
  margin: 15px;
}

.attachment-row {
  margin: 0 -15px;
}

.attach-detail .btn-white-bordered {
  padding: 5px;
  font-size: 12px;
}

/***Attachement Box Css End***/

/****Exam Center Page Css Start****/
.exam-list-wrap {
  padding: 20px;
}

.exam-list-wrap > p {
  margin-bottom: 15px;
}

.exam-ul-list {
  margin: 0 -5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.exam-ul-list > li {
  width: -webkit-calc(100% / 5 - 10px);
  width: -moz-calc(100% / 5 - 10px);
  width: calc(100% / 5 - 10px);
  margin: 5px;
}

.exam-bx-card {
  border: 1px solid #d7dee8;
  padding: 20px 5px 15px;
  text-align: center;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  cursor: pointer;
  min-height: 100%;
}

.exam-bx-card:hover {
  background: var(--hover-color);
}

.exam-bx-card:focus,
.exam-bx-card:active {
  background: #d3dee9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.exam-bx-card .exam-link-redirect {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.exam-bx-card .icon {
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  background: #467fcf;
  color: #f3f6f9;
  font-size: 24px;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.exam-bx-card h3 {
  line-height: 1.2;
  font-size: 16px;
  color: #252423;
  font-weight: bold;
  margin: 13px 0;
}

.exam-bx-card p {
  margin-bottom: 10px;
}

.exam-bx-card p i {
  margin-right: 5px;
}

.exam-bx-card p i img {
  max-width: 20px;
}

/****Exam Center Page Css End****/

.exam-detail-lft {
  /* padding: 20px; */
  max-width: 320px;
  width: 100%;
  position: absolute;
  min-height: -webkit-calc(100% - 48px);
  min-height: -moz-calc(100% - 48px);
  min-height: calc(100% - 48px);
  z-index: 1;
}

.exam-detail-rgt {
  max-width: -webkit-calc(100% - 320px);
  max-width: -moz-calc(100% - 320px);
  max-width: calc(100% - 320px);
  width: 100%;
  padding: 20px;
  position: relative;
  left: 320px;
}

.hide-subject-sidebar .exam-detail-rgt {
  max-width: 100%;
  left: 0;
  padding-left: 40px;
}

body.hide-subject-sidebar .exam-detail-lft {
  left: -252px;
}

.exam-detail-lft .exam-lft-head,
.exam-detail-lft .exam-lft-body {
  margin: 20px;
  line-height: 1.2;
}

.all-exam {
  font-size: 16px;
}

.all-exam i {
  margin-right: 10px;
  font-size: 20px;
  vertical-align: text-bottom;
}

.exam-bx-info-top {
  margin: 20px 0;
}

.exam-bx-info-top .icon {
  width: 70px;
  height: 70px;
  min-width: 70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 20px 0 0;
  background: #467fcf;
  color: #f3f6f9;
  font-size: 24px;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.exam-bx-info-top h3 {
  line-height: 1.2;
  font-size: 18px;
  color: #252423;
  font-weight: bold;
  margin: 0 0 5px;
}

.exam-bx-info-top p {
  margin: 0;
}

.exam-list-opt {
  margin-bottom: 15px;
}

.exam-list-opt-card {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #d7dee8;
  /* border-left: 4px solid #467fcf; */
  padding: 8px;
  cursor: pointer;
}

.exam-list-opt-card + .exam-list-opt-card {
  margin-top: 15px;
}

.exam-list-opt-card h3 {
  font-size: 14px;
  margin: 0 0 10px 0;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.exam-detail-top {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.exam-bx-info .icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 10px 0 0;
  background: #467fcf;
  color: #f3f6f9;
  font-size: 16px;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.exam-bx-info-rgt h3 {
  font-size: 17px;
  font-weight: bold;
  margin: 0 0 3px 0;
  line-height: 1.2;
  color: #252423;
}

.exam-bx-info-rgt p {
  margin: 0;
  font-size: 11px;
  color: #484644;
}

.exam-list-opt-card > ul.fa-ul {
  margin: 0;
  padding-right: 110px;
}

.exam-list-opt-card .btn {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  min-width: 115px;
  background: #467fcf;
  color: #fff;
  border-color: #467fcf;
}

.exam-list-opt-card .btn:hover {
  background: #376cb5 !important;
  border-color: #376cb5 !important;
}

.exam-list-opt-card .btn:focus {
  background: #06172a !important;
  border-color: #06172a !important;
}

.exam-list-opt-card .btn.btn-active {
  background: #06172a;
  border-color: #06172a;
  box-shadow: 0 2px 2.97px 0.03px rgb(0 105 217 / 0.13) !important;
}

.exam-list-opt-card .btn:focus,
.exam-list-opt-card .btn:active {
  background: #06172a !important;
  border-color: #06172a !important;
}

.exam-list-opt-card .btn-white-bordered.btn-success {
  background: #28a745 !important;
  border-color: #28a745 !important;
  cursor: context-menu;
}

.exam-list-opt-card > ul.fa-ul > li {
  line-height: 1.2;
  word-break: break-all;
  display: -ms-flexbox !important;
  display: flex !important;
  padding-left: 25px;
  align-items: center;
}

.exam-list-opt-card > ul.fa-ul > li > i {
  margin-right: 5px;
  left: 0;
  width: 20px;
  text-align: left;
}

.exam-list-opt-card > ul.fa-ul > li > i > img {
  max-width: 20px;
}

.exam-register-bx .card-header {
  border: none;
  background: none;
  font-weight: bold;
  padding: 15px 0;
  text-transform: capitalize;
  font-size: 16px;
  color: #252423;
}

.exam-register-bx .card-body {
  background: #ffff;
  padding: 15px;
  position: relative;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #d7dee8;
}

.exam-register-bx .card-form-bx {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: none;
}

.exam-register-bx {
  margin: 15px 0;
}

.terms-condition-wrap {
  border: 1px solid #d7dee8;
  padding: 15px;
  margin: 15px 0;
}

.terms-condition-wrap h5 {
  font-size: 14px;
  font-weight: 600;
}

.terms-condition-wrap p {
  margin: 0 0 30px 0;
}

.terms-condition-wrap .custom-checkbox {
  color: #252423;
  font-weight: 500;
  padding-left: 30px;
}

.terms-condition-wrap .custom-checkbox .custom-control-label::before,
.terms-condition-wrap .custom-checkbox .custom-control-label::after {
  left: -30px;
  top: 0;
}

.terms-condition-wrap .custom-checkbox .custom-control-label {
  line-height: 1.4;
}

.heading-blue {
  background: #467fcf;
  padding: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  margin: 10px 0;
}

.btn-additional {
  font-size: 14px;
  color: #467fcf;
}

.btn-additional:hover,
.btn-additional:focus {
  color: #06172a;
}

.btn-primary,
.btn-save {
  /* background: #0069d9;*/
  min-width: 86px;
  color: #fff;
  font-size: 14px;
  background: var(--button-color) !important;
  border-color: var(--button-color) !important;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  box-shadow: 0px 1.5px 2px 0px rgb(0 0 0 / 30%) !important;
  font-weight: 600;
  height: 34px;
  padding-top: 0;
  padding-bottom: 0;
  /* line-height: 34px; */
}

.btn-primary i,
.btn i {
  margin-right: 5px;
}

.btn-primary:hover,
.btn-save:hover {
  background: var(--button-hover-color) !important;
  border-color: var(--button-hover-color) !important;
}

.btn-primary:active,
.btn-save:active {
  background: var(--button-active-color) !important;
  border-color: var(--button-active-color) !important;
}

.btn-primary:focus,
.btn-save:focus {
  background: var(--button-active-color) !important;
  border-color: var(--button-active-color) !important;
}

.exam-register-bx .form-control {
  background: #eef1f5;
  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  border: none;
  padding: 8px;
  min-height: 34px;
  max-height: 34px;
}

.exam-register-bx .add-row-repeat .form-control {
  color: #252423;
  background: #fff;
  border: 1px solid #d7dee8;
  padding: 7.4px 15px;
}

.exam-register-bx .input-group-text {
  background: #eef1f5;
  border: none;
}

.info-circle {
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  max-width: 30px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.add-row-repeat .btn-danger {
  /* background: #ef0303; */
  /* border-color: #ef0303; */
  width: 34px;
  padding: 0;
  border-radius: 50%;
  height: 34px;
  min-width: 34px;
}

.add-row-repeat .btn-danger i {
  margin-right: 5px;
}

.add-row-repeat .btn-danger:hover,
.add-row-repeat .btn-danger:focus {
  /* background-color: #c82333; */
  /* border-color: #bd2130; */
}

/*       20-10-2020
.view-exam-center-details {
  border: 1px solid #d7dee8;
  padding: 15px;
  margin: 15px 0;
}

.small-ttl {
  font-size: 16px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  font-weight: bold;
  color: #252423;
}

.exam-centerList {
  padding-left: 0px;
}

.exam-detail-item .control-label {
  font-weight: 600;
  margin-bottom: 10px;
}

.exam-detail-item p {
  margin-bottom: 10px;
}

.exam-detail-item {
  margin-bottom: 5px;
}

.exam-center-print {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.add-row-repeat .btn-danger i {
  margin-right: 0px;
}

.theme-form .form-control {
  background: #eef1f5;
  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  border: none;
  padding: 8px 15px;
  min-height: 34px;
}

.theme-form .form-group>label {
  color: #484644;
  line-height: 1.2;
  margin: 0 0 10px 0;
}

.instructions-table {
  margin: 0px 15px;
  margin-bottom: 10px;
}

.cust_tbl_col_1 {
  padding-left: 0px;
}

.cust_tbl_col_4 {
  padding-right: 0;
}

.add-row-head {
  margin-bottom: 10px;
}

.exam-bx-info-top .fa-ul {
  margin-left: 20px;
}

.color-red {
  color: #ef0303 !important;
}

/**Chat Css Start**/

@media only screen and (min-width: 992px) {
  .Chat-left-wrap .close-btn-sidebar {
    display: none;
  }
}

/***Notification Css Start***/
.Chat-left-wrap {
  max-width: 320px;
  width: 100%;
  position: absolute;
  min-height: -webkit-calc(100% - 48px);
  min-height: -moz-calc(100% - 48px);
  min-height: calc(100% - 48px);
  z-index: 1;
}

.Chat-right-wrap {
  max-width: -webkit-calc(100% - 320px);
  max-width: -moz-calc(100% - 320px);
  max-width: calc(100% - 320px);
  width: 100%;
  padding: 30px;
  position: relative;
  left: 320px;
  min-height: 100%;
}

.btn-day {
  padding: 8px;
  line-height: 1;
  font-size: 14px;
  background: #e6edf2;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-top: 1px solid #dfe0e2;
  border-bottom: 1px solid #dfe0e2;
}

.btn-day:hover {
  background: #e6ecf2;
}

.btn-day:focus {
  background: #d3dee9;
}

.btn-day:before {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  margin-right: 5px;
  width: 15px;
  display: inline-block;
}

/* .btn-day[aria-expanded="false"]:before { content: "\f054"; } */

.btn-day[aria-expanded="true"]:before {
  content: "\f078";
}

.notification-list-ul > li + li {
  border-top: 1px solid #f3f7fa;
}

.sort-by .form-inline label {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  margin-bottom: 0;
}

.Chat-panel-head .sort-by .form-control {
  border: none;
  padding: 0;
  font-size: 14px;
  height: inherit;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.Chat-panel-head {
  margin: 15px;
  position: relative;
}

.search-chat {
  position: absolute;
  z-index: 1;
  width: 100%;
  display: none;
}

.search-chat .form-control {
  font-size: 13px;
  border-radius: 2px;
  padding: 10px;
  line-height: 1.2;
  padding-right: 30px;
}

.close-search-chat {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  height: 100%;
  width: 30px;
  text-align: center;
  line-height: 100%;
  right: 0;
}

.Chat-panel-head.search-chat-open .search-chat {
  display: block;
}

.Chat-body {
  max-height: -webkit-calc(100vh - 99px);
  max-height: -moz-calc(100vh - 99px);
  max-height: calc(100vh - 99px);
  overflow: auto;
  height: 100vh;
}

.read-unread-tab-pill li + li {
  margin-left: 15px;
}

.read-unread-tab-pill li a {
  color: #484644;
  font-weight: 600;
  position: relative;
  padding-bottom: 3px;
}

.read-unread-tab-pill li a:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: 0;
}

.read-unread-tab-pill li.active a:after {
  background: #467fcf;
}

.read-unread-tab-pill li a.active:after {
  background: var(--topbar-color);
}

.Chat-card .card-left {
  max-width: -webkit-calc(100% - 90px) !important;
  max-width: -moz-calc(100% - 90px) !important;
  max-width: calc(100% - 90px) !important;
  line-height: 1.2;
  width: 100%;
  display: flex;
}

.card-user-img-round {
  width: 32px;
  height: 32px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-flex;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  background-color: #467fcf;
  color: #ffffff;
}

.card-group-img {
  width: 32px;
  height: 32px;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.card-user-img-round img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  min-width: 100%;
  display: block;
}

.Chat-card .portal-status-icon {
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.card-user-img {
  position: relative;
  max-width: 32px;
  margin-right: 8px;
}

.card-user-detail {
  width: calc(100% - 40px);
}

.Chat-card .card-right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 90px;
  font-size: 11px;
  padding-left: 5px;
}

.Chat-card .card-right .Chat-include-items li + li {
  margin-left: 5px;
}

.Chat-card .card-left h4,
.Chat-card .card-left p {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.Chat-card .card-left h4 {
  font-size: 14px;
  margin: 0 0 5px 0;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.Chat-card .card-left p {
  margin: 0;
  font-size: 11px;
}

.Chat-card .card-left h4,
.Chat-card .card-left p,
.Chat-card .card-right .date-time {
  font-weight: 600;
}

.Chat-card .card-left p,
.Chat-card .card-right .date-time {
  color: #467fcf;
}

.card.Chat-card {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
  -moz-box-orient: horizontal !important;
  -moz-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  border: none;
  border-right: 4px solid #467fcf;
  padding: 10px 6px 10px 15px;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  cursor: pointer;
}

.card.Chat-card:after {
  content: "";
  height: 1px;
  width: -webkit-calc(100% - 15px);
  width: -moz-calc(100% - 15px);
  width: calc(100% - 15px);
  position: absolute;
  background: #dfe0e2;
  bottom: 0;
  right: 0;
}

.card.Chat-card.Chat-active,
.card.Chat-card:hover {
  background: #e6edf2;
}

.card.Chat-card:active,
.card.Chat-card:focus {
  background: #d3e0e9 !important;
}

.card.Chat-card.Chat-bulk {
  border-right: 4px solid #ff0202;
}

.card.Chat-card.Chat-read {
  border-right: 4px solid #fff;
}

.card.Chat-card.Chat-active,
.card.Chat-card:hover {
  border-right: 4px solid #06172b !important;
}

.card.Chat-card.Chat-read .card-left h4,
.card.Chat-card.Chat-read .card-left p,
.card.Chat-card.Chat-read .card-right .date-time {
  font-weight: normal;
}

.card.Chat-card.Chat-read .card-left p,
.card.Chat-card.Chat-read .card-right .date-time {
  color: #484644;
}

.card.Chat-bulk .card-left h4,
.card.Chat-bulk .card-left p,
.card.Chat-bulk .card-right .date-time,
.card.Chat-bulk .card-right .alert-icon,
.tag-important {
  color: #ff0202 !important;
}

.Chat-body.mCustomScrollbar .mCSB_inside > .mCSB_container {
  margin: 0;
}

.Chat-body.mCustomScrollbar .mCustomScrollBox {
  padding-right: 15px;
}

.Chat-body.mCS_no_scrollbar .mCustomScrollBox {
  padding-right: 0px;
}

/**Right**/
.Chat-head-lft .icon {
  position: relative;
  margin: 0 10px 0 0;
}

.Chat-head-lft .icon .icon-img {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* margin: 0 10px 0 0; */
  background: #467fcf;
  color: #f3f6f9;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 50%;
  overflow: hidden;
}

.Chat-head-rgt h3 {
  font-size: 17px;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
  color: #252423;
  position: relative;
}

.Chat-detail-head {
  margin-bottom: 20px;
  align-items: center;
}

.Chat-head-lft .icon .portal-status-icon {
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 0px;
  font-size: 14px;
}

.Chat-head-lft .icon .icon-img > img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  min-width: 100%;
  display: block;
}

@media only screen and (max-width: 991px) {
  .Chat-left-wrap {
    max-width: 290px;
    left: -225px;
    z-index: 1;
    -webkit-box-shadow: 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
    -moz-box-shadow: 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
    box-shadow: 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  }

  body.hide-subject-sidebar .Chat-left-wrap {
    left: 68px;
  }

  .Chat-right-wrap {
    max-width: 100%;
    left: 0;
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .Chat-left-wrap {
    left: -295px;
  }

  body.hide-subject-sidebar .Chat-left-wrap {
    left: 0;
  }

  .flexible-modal {
    width: auto !important;
    display: flex !important;
    left: 0px !important;
    /* top: 60px !important; */
  }
}

/***Notification Css End***/

.chat-editbtn {
  color: #252423;
}

.Chat-panel-head h5 {
  font-weight: 600;
}

/* Addded By Raju */

/* tabs css start 6nov */
.Actabs .tab-content {
  background: #fff;
}

.custom-nav-tabs .nav-link {
  background-color: #1a6dbd;
  color: #fff;
  border-radius: 0px;
  text-align: center;
  font-size: 18px;
  border: 1px solid transparent !important;
  border-top: 3px solid #1a6dbd !important;
}

.custom-nav-tabs .nav-item.show .nav-link,
.custom-nav-tabs .nav-link.active {
  color: #000;
  background-color: #fff;
  border-color: #1a6dbd #dee2e6 #fff !important;
}

.custom-nav-tabs .nav-item .nav-link.Orientation {
  color: #fff;
  background-color: #7ac33d;
  border-color: #7ac33d #dee2e6 #fff !important;
}

.custom-nav-tabs .nav-item .nav-link.Orientation:focus,
.custom-nav-tabs .nav-item .nav-link.Orientation.active {
  color: #000;
  background-color: #fff;
  border-color: #7ac33d #dee2e6 #fff;
}

.ave-title {
  color: var(--topbar-color);
}

.Actabs .nav-tabs {
  display: none;
}

.custom-nav-tabs li a {
  font-weight: 600;
  color: #161618;
  text-transform: uppercase;
}

.Actabs .card.cmpCard .card-header {
  display: block;
}

.clsList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.clsList li {
  margin-bottom: 5px;
  display: inline-block;
  width: 100%;
}

.clsList li label {
  font-weight: bold;
  margin: 0;
  padding-right: 10px;
  margin-bottom: 5px;
}

.Actabs .card.cmpCard .card-header {
  font-size: 20px;
  color: #fff;
  border: none;
}

.Actabs .card.cmpCard .card-header i {
  margin-right: 10px;
}

.card.cmpCard {
  min-height: 100%;
  border: none;
  background-clip: inherit;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 38%);
}

.card.cmpCard .btn {
  font-size: 14px;
}

.sub-ttl {
  padding: 30px 0px;
}

.sub-ttl h2 {
  color: #467fcf;
  font-weight: bold;
}

table.tbGeneral.table thead th {
  background: #467fcf !important;
  border-color: #467fcf !important;
  color: #fff;
}

.cmpCard.cd-1 .card-header {
  background: #ff0006;
}

.cmpCard.cd-2 .card-header {
  background: #ff8814;
}

.cmpCard.cd-3 .card-header {
  background: #0066cc;
}

.cmpCard.cd-4 .card-header {
  background: #0066cc;
}

.cmpCard.cd-5 .card-header {
  background: #ff8814;
}

.qhead p {
  font-size: 25px;
}

.qhead strong,
.qhead b {
  color: #ff8814;
}

.ac-button:after {
  content: "";
  position: absolute;
  right: 15px;
  border-bottom: 7px solid #000000;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 300ms all;
  transition: 300ms all;
}

.custom-nav-tabs li + li {
  margin-left: 10px;
}

.Actabs .tab-content > .tab-pane .card-body > div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.Actabs .tab-content > .tab-pane .card-body > div .col-sm-4 > div {
  height: 100%;
  border-radius: 6px 6px 0 0 !important;
  overflow: hidden;
}

.Actabs .tab-content > .tab-pane .card-body > div .col-sm-4 .nso-div {
  background: #bcdae7;
  padding: 0;
}

.nso-div.Problems-bg {
  background: #bcdae7;
}

.nso-div h2 {
  background: #0066cc;
  color: #fff;
  font-size: 19px;
  padding: 15px;
  margin: 0 0 15px 0;
  text-align: center;
}

.Actabs .tab-content > .tab-pane .card-body > div .col-sm-4 div h2 i {
  margin-right: 5px;
}

.nso-div.Problems-bg h2 {
  background: #ff0006;
}

.Actabs .tab-content > .tab-pane .card-body > div .col-sm-4 .nso-div ul {
  min-height: inherit;
  padding: 0 !important;
  margin: 0px;
  list-style: none;
  margin-bottom: 10px;
}

.Actabs .tab-content > .tab-pane .card-body > div .col-sm-4 .nso-div ul li {
  margin: 0 !important;
  padding: 0px 15px 5px 15px;
  list-style: none !important;
  font-size: 15px;
}

.Actabs
  .tab-content
  > .tab-pane
  .card-body
  .col-sm-4
  > div.nso-div.Problems-bg
  > ul
  > li {
  position: relative;
  padding-left: 25px;
}

.Actabs .tab-content > .tab-pane .card-body .col-sm-4 .btn-info {
  color: #fff;
  font-size: 14px;
  background: #467fcf !important;
  border-color: #467fcf !important;
  border-radius: 2px;
  box-shadow: 0 2px 2.97px 0.03px hsla(0, 0%, 49%, 0.13) !important;
  font-weight: 600;
  height: 34px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 34px;
}

.Actabs .tab-content > .tab-pane .card-body .col-sm-4 .btn-info:hover,
.Actabs .tab-content > .tab-pane .card-body .col-sm-4 .btn-info:active {
  background: #376cb5 !important;
  border-color: #376cb5 !important;
}

.Actabs .tab-content > .tab-pane .card-body .col-sm-4 .btn-info:focus {
  background: #06172a !important;
  border-color: #06172a !important;
}

.Actabs .tab-content > .tab-pane .card-body > div .col-sm-4:nth-child(2) h2 {
  background: #ff8814;
}

.Actabs .tab-content > .tab-pane .card-body table * {
  font-size: 14px;
}

.Actabs .tab-content > .tab-pane .card-body table tbody td {
  border-top: 1px solid #e6e6e6 !important;
}

.Actabs .tab-content > .tab-pane .card-body table tbody td p {
  line-height: 26px;
}

.custom-nav-tabs .nav-link strong {
  display: block;
  margin-bottom: 5px;
}

.tab-content.cnt-border {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.tab-content.cnt-border .card.tab-pane {
  border: 0px;
}

.tab-content.cnt-border .card.tab-pane .card-body {
  padding: 0px;
}

@media (min-width: 768px) {
  .Actabs .nav-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .Actabs .card .card-header {
    display: none;
  }

  .Actabs .card .collapse {
    display: block;
  }
}

@media (max-width: 1280px) {
  .custom-nav-tabs .nav-link {
    font-size: 14px;
  }
}

@media (max-width: 1199px) {
  .Actabs .tab-content > .tab-pane .card-body > div {
    display: block;
  }

  .Actabs .tab-content > .tab-pane .card-body > div .col-sm-4 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .Actabs .tab-content > .tab-pane {
    display: block !important;
    opacity: 1;
    margin-bottom: 7px;
  }

  .Actabs .card-header.m-head {
    background-color: #fff;
    padding: 0px;
    margin-bottom: -1px;
  }

  .Actabs .card-header a {
    font-size: 15px;
    color: #000000;
    display: block;
    width: 100%;
    position: relative;
    padding: 15px;
    padding-right: 36px;
  }

  .Actabs .card-header a:after {
    content: "";
    position: absolute;
    right: 15px;
    border-bottom: 7px solid #000000;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 300ms all;
    transition: 300ms all;
  }

  .Actabs .card-header a.collapsed:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .Actabs .tab-content {
    padding: 0px !important;
    background: transparent;
  }

  .Actabs .card.cmpCard .card-header {
    font-size: 15px;
  }

  .cl-cmp {
    margin-bottom: 20px;
  }

  .sub-ttl {
    padding: 0px 0px 20px 0px;
  }

  .sub-ttl h2 {
    font-size: 18px;
    margin: 0px;
  }

  .qhead p {
    font-size: 18px;
  }

  table.tbGeneral.table thead th {
    padding: 10px;
    font-size: 14px;
    white-space: nowrap;
  }

  .Actabs p {
    line-height: 20px;
  }

  .tab-content.cnt-border .card.tab-pane .card-body {
    padding: 15px;
  }

  .tab-content.cnt-border {
    border: none;
  }

  .Actabs .tab-content > .tab-pane .card-body > div .col-sm-4,
  .Actabs .tab-content > .tab-pane .card-body > .col-sm-12 {
    padding: 0px;
  }
}

/* tabs css end 6nov */

.auto-width-custom-carousel .owl-dots {
  margin: 10px 0 20px;
  text-align: center;
}

.auto-width-custom-carousel .owl-dots .owl-dot {
  width: 30px;
  height: 6px;
  background: #467fcf;
  margin: 0 5px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  opacity: 0.18;
}

.auto-width-custom-carousel .owl-dots .owl-dot.active,
.home-slider .owl-dots .owl-dot:hover {
  opacity: 1;
}

.auto-width-custom-carousel .owl-nav {
  margin: 10px 0 20px;
  text-align: center;
}

.auto-width-custom-carousel button {
  width: 30px;
  height: 6px;
  background: #467fcf;
  margin: 0 5px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  opacity: 0.18;
  font-size: 0;
}

.auto-width-custom-carousel button.active {
  opacity: 1;
}

.swiper-pagination {
  margin: 10px 0 20px;
  text-align: center;
  position: static;
}

.swiper-pagination-bullet {
  width: 30px;
  height: 6px;
  background: #467fcf;
  margin: 0 5px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  opacity: 0.18;
  font-size: 0;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-pagination-bullet:hover {
  opacity: 1;
}

.swiper-pagination-bullet:focus,
.swiper-pagination-bullet:active {
  background: #06172a;
  opacity: 1;
}

.swiper-home .swiper-slide {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #d7dee8;
}

.swiper-home .swiper-slide .home-slider-inr {
  padding: 30px;
}

.swiper-home .swiper-slide p {
  color: #fff;
  margin: 0 0 15px 0;
}

.swiper-home .swiper-slide p.text-max-80 {
  width: auto;
  max-width: 80% !important;
}

.swiper-home .swiper-slide h4 {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  margin: 0 0 15px 0;
  color: #000000;
}

.swiper-home .swiper-slide .btn-learn-more {
  background: rgb(255 255 255 / 70%);
  color: #000;
  text-transform: uppercase;
  padding: 8px 18px;
  line-height: 1.2;
  font-size: 14px;
  position: relative;
}

.swiper-home .swiper-slide .btn-learn-more:hover {
  background: #fff;
  color: #000;
}

.swiper-home .swiper-slide .btn-learn-more:focus,
.swiper-home .swiper-slide .btn-learn-more:active {
  box-shadow: none;
  background-color: #d7dee8;
}

.home-slider-wraper {
  overflow: hidden;
}

.swiper-home {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.swiper-home .swiper-slide.w-25 {
  width: calc(25% - 10px) !important;
}

.swiper-home .swiper-slide.w-50 {
  width: calc(50% - 10px) !important;
}

.home-slider-content {
  position: relative;
  z-index: 1;
}

.home-slider-image {
  position: absolute;
  max-width: 250px;
  right: 0;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
}

.home-slider-image img {
  max-height: 180px;
}

@media only screen and (max-width: 1199px) {
  .swiper-home .swiper-slide.w-50,
  .swiper-home .swiper-slide.w-25 {
    width: calc(100% - 10px) !important;
  }

  .swiper-home .swiper-slide .home-slider-inr {
    padding: 20px;
    min-height: 203px;
    display: flex;
    align-items: center;
  }
  .table-item-width {
    max-width: 180px !important;
  }
}

/* Assessment tab css start */
.ac-card h3 {
  font-size: 16px;
  color: #252423;
  margin-bottom: 15px;
  font-weight: 700;
}

.ac-white-card {
  background: #fff;
  padding: 25px;
  border: 1px solid #d7dee8;
}

.assMentbox > div {
  font-size: 14px;
  color: #252423;
  flex: 1;
}

.assMentbox > div:first-child {
  width: 260px;
  flex: 0 0 260px;
  max-width: 260px;
}

.assMentbox p {
  margin: 0px;
  font-weight: 600;
}

.as-red {
  color: #ff0000;
}

.as-blue {
  color: #467fcf;
}

.as-orange {
  color: #ffab2d;
}

.as-green {
  color: #00c915;
}

.as-red i {
  margin-right: 10px;
}

.as-blue i {
  margin-right: 10px;
}

.as-orange i {
  margin-right: 10px;
}

.as-green i {
  margin-right: 10px;
}

.row.rowAssessment + .row.rowAssessment {
  margin-top: 20px;
}

.flex-shrink {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 776px;
}

.flex-shrink .form-control {
  background: #eef1f5;
}

.flex-shrink .atttach-file label {
  min-width: 120px;
  text-align: center;
}

.flex-shrink ul {
  padding: 0px;
  margin: 0px;
}

.flex-shrink ul {
  list-style: none;
}

.flex-shrink em {
  font-weight: 600;
  color: #484644;
}

.assMentbox > div i {
  margin-right: 10px;
}

.descriptiontext h3 {
  font-size: 14px;
  font-weight: bold;
  color: #252423;
}

.descriptiontext p {
  color: #484644;
  font-size: 14px;
}

.descriptiontext {
  margin-bottom: -18px;
}

.flex-shrink > b {
  font-weight: 600;
}

/* learning-material page css start */
.learning-material-listing {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.learning-material-listing > li {
  margin-bottom: 10px;
}

.learning-material-head {
  background: #fff;
  border: 1px solid #d7dee8;
  border-radius: 2px;
  display: flex;
  padding: 8px;
  border-left: 3px solid;
  cursor: pointer;
  /* margin-bottom:15px; */
  align-items: center;
  outline: none;
  transition: 300ms all;
  -webkit-transition: 300ms all;
  flex: 1;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  overflow: hidden;
}

.learning-material-head:hover {
  background: #e6ecf2;
  border-left-color: #06172a !important;
}

.learning-material-head:focus,
.learning-material-head:active {
  background: #d3dee9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  border-left-color: #06172a !important;
}

@media screen and (min-width: 768px) {
  .hd-main {
    align-items: center;
  }
}

.learning-material-head:hover,
.learning-material-head:focus {
  background: #e6edf2;
}

.learning-material-head[aria-expanded="true"] {
  background-color: #e6edf2 !important;
  border-left-color: #06172a !important;
}

.hd-main[aria-expanded="true"] .learning-material-head {
  background-color: #e6edf2 !important;
  border-left-color: #06172a !important;
}

.hd-main[aria-expanded="true"] .progress {
  background: #fff;
}

.learning-material-head.borderprimary {
  border-left-color: #467fcf;
}

.learning-material-head p {
  margin: 0px;
}

.learning-left {
  padding-right: 25px;
}

.learning-left h3 {
  font-size: 15px;
  font-weight: bold;
  margin: 0px;
  color: #252423;
}

.learning-left .txt-smll {
  margin-top: 5px;
  display: block;
  color: rgba(72, 70, 68, 55%);
  font-size: 12px;
  line-height: 1.1;
}

.learningright-inner .progress {
  width: 200px;
  background: #d7dee8;
  border-radius: 2px;
}

.learningright-inner .progress-bar {
  background-color: #467fcf;
}

.learningright-inner {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.header-status {
  margin-left: 10px;
  text-align: center;
}

.header-status .status-gol {
  height: 50px;
  display: flex;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  width: 50px;
}

.header-status i {
  -webkit-transition: 300ms all;
  -o-transition: 300ms all;
  transition: 300ms all;
}

.hd-main[aria-expanded="true"] .header-status i {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.status-gol.bg-success {
  background: #429d4c !important;
}

.status-gol.bg-danger {
  background: #d4145a !important;
}

.learning-material-card {
  margin-bottom: 15px;
}

.learning-material-body {
  border: 1px solid #d7dee8;
  padding: 15px;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  margin-top: 10px;
}

.sub-header {
  color: #467fcf;
}

.sub-header h3 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.sub-header label {
  font-weight: 700;
}

.sub-header p {
  margin: 0px;
  color: #94b4e2;
  font-size: 12px;
}

.learning-material-head.bordersecondary {
  border-left-color: #06172a;
}

.row.rowlec {
  width: 100%;
}

.card-media {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 2px;
  margin-right: 10px;
  color: #fff;
  flex: 0 0 40px;
  font-size: 16px;
}

.card-media-text p {
  color: #252423;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
}

.card-media-text span {
  font-size: 11px;
}

.card-media-text span i {
  margin-right: 5px;
}

.card-blue-1 {
  background: var(--topbar-color);
}

.card-blue-2 {
  background: #05305b;
}

.card-blue-3 {
  background: #0696f9;
}

.learningright-inner .btn-white-bordered {
  font-size: 12px;
  min-width: 120px;
}

.learningright-inner .header-status {
  margin: 0px;
}

.learningright-inner .header-status .status-gol {
  height: 26px;
  width: 26px;
  font-size: 14px;
}

.media-gp {
  display: flex;
  justify-content: flex-end;
}

.card-list-inner {
  border-bottom: 1px solid #d7dee8;
  align-items: center;
}

.card-list-inner + .card-list-inner {
  margin-top: 10px;
}

.card-list-inner:last-child {
  border: none;
}

.sub-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.learning-material-listing > li.active .learning-material-head {
  background: #e6edf2;
}

.lec-sec-left img {
  width: 100%;
}

.architectural-sec {
  border-bottom: 1px solid #d7dee8;
  margin-bottom: 15px;
}

.lec-sec-text p {
  font-size: 16px;
  color: #777572;
  margin-bottom: 15px;
}

.lec-sec-text p b {
  color: #252423;
}

.lec-sec-footer span {
  padding-right: 10px;
}

.lec-sec-footer {
  margin-top: 25px;
}

.lec-sec-footer .btn-primary {
  background: #337ab7;
  min-width: 115px;
  height: 40px;
  line-height: 26px;
  transition: 300ms all;
  -webkit-transition: 300ms all;
}

.lec-sec-footer .btn-primary:hover {
  opacity: 0.8;
}

.card-red-1 {
  background: #c1272d;
}

.status-gol.bg-black {
  background: #06172a;
}

.progress.progress-blank {
  background: #fff;
  border: 1px solid #d7dee8 !important;
  overflow: hidden;
}

.learning-material-head.bordersuccess {
  border-left-color: #39b54a;
}

.learning-material-head.borderwarning {
  border-left-color: #fbb03b;
}

.learning-material-head.borderblue {
  border-left-color: #3fa9f5;
}

.learning-material-head.borderparoule {
  border-left-color: #9e005d;
}

.card-media-text {
  padding-right: 10px;
}

.Lmtime {
  white-space: nowrap;
}

.lec-sec-footer .btn-white-bordered {
  min-width: 123px;
  margin-right: 10px;
}

.deep-text {
  color: #a3a8af;
  width: 205px;
}

.barImg i {
  display: block;
  line-height: 5px;
}

.barImg i:nth-child(2) {
  margin-left: 5px;
}

ul#pills-tab {
  white-space: nowrap !important;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
}

.learningright-inner li + li {
  padding-left: 15px;
}

.learningright-inner li {
  border: none !important;
}

/* .sub-ul>li:nth-child(2) {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d7dee8;
} */

.subject-header {
  margin-bottom: 15px;
}

.subject-header .lecture-bx-info {
  margin-bottom: 10px;
}

.subject-sub-header {
  position: relative;
  padding-left: 30px;
}

.subject-sub-header h3 {
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #467fcf;
  /* display: flex; */
  /* align-items: center; */
  margin-bottom: 5px;
}

.subject-sub-header i {
  margin-right: 10px;
  flex: 0 0 25px;
  max-width: 25px;
  position: absolute;
  left: 0;
  font-size: 20px;
}

.subject-sub-header img {
  width: 100%;
  max-width: 100%;
}

ul.sub-ul > li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d7dee8;
}

ul.sub-ul > li:last-child {
  margin-bottom: 0px;
  padding-bottom: 0;
  border-bottom: 0;
}

.lead-text-sm {
  font-size: 14px;
}

.lead-text-sm i {
  font-size: 18px;
  color: #467fcf;
  min-width: 20px;
}

/* .vidbox .react-player .react-player__preview { min-height: 200px; max-height: 400px !important; } */
.vidbox {
  position: relative;
  padding-top: 56.25%;
  /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1650px) {
  .lec-sec-text p {
    font-size: 15px;
  }

  .learningright-inner .progress {
    width: 140px;
  }

  .card-media-text p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1199px) {
  .learning-material-head {
    display: block;
  }

  .learningright-inner li:first-child {
    padding-left: 0px !important;
    border-left: 0px !important;
  }

  .learning-left {
    margin-bottom: 10px;
  }

  .card-list-inner {
    display: block !important;
  }

  .vidbox {
    margin-bottom: 30px;
  }

  .learningright-inner {
    justify-content: space-between;
  }

  .header-status .status-gol {
    font-size: 20px;
  }

  .lead-text-sm {
    font-size: 14px;
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .header-status {
    width: 30px;
  }

  .header-status .status-gol {
    width: 30px;
    height: 30px;
  }

  .media-gp {
    min-width: inherit;
  }

  .learningright-inner li {
    border: none !important;
  }

  filter-scroll .learningright-inner li {
    padding: 0px;
  }

  .learning-material-head {
    padding: 10px;
  }

  .learningright-inner .progress {
    width: 100%;
  }

  .learning-material-body {
    padding: 10px;
  }

  .learningright-inner .btn-white-bordered {
    min-width: inherit;
  }

  .header-status {
    margin-left: 5px;
  }

  .btn-white-bordered.mr-2 {
    margin-right: 2px !important;
  }

  .btn-white-bordered.ml-2 {
    margin-left: 2px !important;
  }

  .innerlead {
    font-size: 10px;
  }

  .card-media {
    margin-right: 5px;
  }

  .card-list-ic {
    margin-bottom: 10px;
  }

  .item-progress,
  .bar-line,
  .deep-text {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .learningright-inner {
    flex-wrap: wrap;
  }

  .learningright-inner li + li {
    margin-top: 10px;
  }

  .lec-sec-text p {
    font-size: 14px;
  }

  .lec-sec-footer {
    margin-top: 0;
  }

  .learning-left {
    padding-right: 0;
  }

  .card-media-text {
    padding-right: 0px;
  }

  .learningright-inner li + li {
    padding-left: 0;
  }

  .subject-sub-header {
    padding-left: 30px;
  }
}

/* Re-registration page css start */
.exam-bx-info-rgt p i {
  margin-right: 5px;
}

.textdanger {
  color: #bf1e2e;
}

.heading-5 {
  font-weight: bold;
  font-size: 16px;
}

.inputfield .form-control {
  margin-bottom: 10px;
}

.box-tm .custom-control-label::before,
.box-tm .custom-control-label::after {
  top: -1px;
}

.btn.btn-main {
  font-size: 14px;
}

.unstyledlist-custom {
  position: relative;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.unstyledlist-custom li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
}

.unstyledlist-custom li::before {
  position: absolute;
  left: 0;
  top: -6px;
  content: "-";
  color: #252423;
  font-size: 20px;
  width: 16px;
}

.inputfield label {
  max-width: 1224px;
}

.innerlead {
  text-transform: capitalize;
}

/***Tickets Css Start***/
.Tickets-left-wrap {
  max-width: 360px;
  width: 100%;
  position: absolute;
  min-height: -webkit-calc(100% - 48px);
  min-height: -moz-calc(100% - 48px);
  min-height: calc(100% - 48px);
  z-index: 1;
}

.Tickets-right-wrap {
  max-width: -webkit-calc(100% - 360px);
  max-width: -moz-calc(100% - 360px);
  max-width: calc(100% - 360px);
  width: 100%;
  padding: 30px;
  position: relative;
  left: 360px;
  min-height: 100%;
  padding-bottom: 90px;
}

.btn-day {
  padding: 8px;
  line-height: 1;
  font-size: 14px;
  background: #efefef;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-top: 1px solid #dfe0e2;
  border-bottom: 1px solid #dfe0e2;
}

.btn-day:hover {
  background: #e6ecf2;
}

.btn-day:focus {
  background: #d3dee9;
}

.btn-day:before {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  margin-right: 5px;
  width: 15px;
  display: inline-block;
}

/* .btn-day[aria-expanded="false"]:before { content: "\f054"; } */

.btn-day[aria-expanded="true"]:before {
  content: "\f078";
}

.notification-list-ul > li + li {
  border-top: 1px solid #f3f7fa;
}

.sort-by .form-inline label {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  margin-bottom: 0;
}

.Tickets-panel-head .sort-by .form-control {
  border: none;
  padding: 0;
  font-size: 14px;
  height: inherit;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.Tickets-panel-head {
  margin: 15px;
}

.Tickets-body {
  /* max-height: -webkit-calc(100vh - 99px);
  max-height: -moz-calc(100vh - 99px);
  max-height: calc(100vh - 99px); */
  overflow: auto;
  height: 365px;
}

.read-unread-tab-pill li + li {
  margin-left: 15px;
}

.read-unread-tab-pill li a {
  color: #484644;
  font-weight: 600;
  position: relative;
  padding-bottom: 3px;
}

.read-unread-tab-pill li a:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: 0;
}

.read-unread-tab-pill li.active a:after {
  background: #467fcf;
}

.read-unread-tab-pill li a.active:after {
  background: var(--topbar-color);
}

.Tickets-card .card-left {
  max-width: -webkit-calc(100% - 115px) !important;
  max-width: -moz-calc(100% - 115px) !important;
  max-width: calc(100% - 115px) !important;
  line-height: 1.2;
  width: 100%;
}

.Tickets-card .card-right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 90px;
  font-size: 11px;
  padding-left: 5px;
}

.Tickets-card .card-right .Tickets-include-items li + li {
  margin-left: 5px;
}

.Tickets-card .card-left h4,
.Tickets-card .card-left p {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.Tickets-card .card-left h4 {
  font-size: 14px;
  margin: 0 0 5px 0;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-weight: 600 !important;
}

.Tickets-card .card-left p {
  margin: 0;
  font-size: 11px;
}

.Tickets-card .card-left h4,
.Tickets-card .card-left p,
.Tickets-card .card-right .date-time {
  font-weight: 600;
}

.Tickets-card .card-left p,
.Tickets-card .card-right .date-time {
  color: var(--topbar-color);
}

.card.Tickets-card {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
  -moz-box-orient: horizontal !important;
  -moz-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  border: none;
  border-right: 4px solid #467fcf;
  padding: 10px 6px 10px 15px;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  cursor: pointer;
  flex-wrap: wrap;
}

.card.Tickets-card:after {
  content: "";
  height: 1px;
  width: -webkit-calc(100% - 15px);
  width: -moz-calc(100% - 15px);
  width: calc(100% - 15px);
  position: absolute;
  background: #dfe0e2;
  bottom: 0;
  right: 0;
}

.card.Tickets-card:hover {
  background: var(--hover-color);
}

.card.Tickets-card:active,
.card.Tickets-card:focus {
  background: var(--active-color) !important;
}

.card.Tickets-card.Tickets-active {
  background: var(--active-color);
}

.card.Tickets-card.Tickets-bulk {
  border-right: 4px solid #ff0202;
}

.card.Tickets-card.Tickets-read {
  border-right: 4px solid #fff;
}

.card.Tickets-card.Tickets-active {
  border-right: 4px solid #06172b !important;
  background: var(--active-color);
}

.card.Tickets-card:hover {
  border-right: 4px solid #06172b !important;
  background: var(--hover-color);
}

.card.Tickets-card.Tickets-read .card-left h4,
.card.Tickets-card.Tickets-read .card-left p,
.card.Tickets-card.Tickets-read .card-right .date-time {
  font-weight: normal;
}

.card.Tickets-card.Tickets-read .card-left p,
.card.Tickets-card.Tickets-read .card-right .date-time {
  color: #484644;
}

.card.Tickets-bulk .card-left h4,
.card.Tickets-bulk .card-left p,
.card.Tickets-bulk .card-right .date-time {
  color: #ff0202 !important;
}

.tickets-view-link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.Tickets-body.mCustomScrollbar .mCSB_inside > .mCSB_container {
  margin: 0;
}

.Tickets-body.mCustomScrollbar .mCustomScrollBox {
  padding-right: 15px;
}

.Tickets-body.mCS_no_scrollbar .mCustomScrollBox {
  padding-right: 0px;
}

/**Right**/
.Tickets-head-lft .icon {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 10px 0 0;
  background: var(--topbar-color);
  color: #f3f6f9;
  font-size: 16px;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.Tickets-head-rgt h3 {
  font-size: 17px;
  font-weight: bold;
  margin: 0 0 3px 0;
  line-height: 1.2;
  color: #252423;
  position: relative;
  padding-right: 100px;
}

.Tickets-head-rgt p {
  margin: 0;
  font-size: 11px;
  color: #484644;
}

.Tickets-head-rgt .blue-text {
  color: #467fcf;
}

.Tickets-head-rgt p + p {
  margin-top: 3px;
}

.Tickets-head-rgt {
  max-width: -webkit-calc(100% - 60px);
  max-width: -moz-calc(100% - 60px);
  max-width: calc(100% - 60px);
  width: 100%;
}

.Tickets-head-rgt h3 .attachment-detail {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
}

.attachment-detail .attachment {
  color: #252423;
}

.attachment-detail .alert-icon {
  color: #ff0202;
}

.Tickets-detail-head {
  margin-bottom: 20px;
}

.Tickets-detail-body .card {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: none;
}

.Tickets-detail-body .card .card-header {
  border: none;
  background: none;
  font-weight: bold;
  padding: 15px 0;
  text-transform: capitalize;
  font-size: 16px;
  color: #252423;
}

.Tickets-detail-body .card .card-body {
  background: #ffff;
  padding: 15px;
  position: relative;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #d7dee8;
}

.card.card-add-new-ticket .form-control {
  background-color: #eef1f5;
  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  border: none;
  padding: 12px 15px;
  min-height: 40px;
}

.card.card-add-new-ticket textarea.form-control {
  min-height: 120px;
  resize: none;
}

.card-add-new-ticket .form-group > label {
  color: #484644;
  line-height: 1.2;
  margin: 0 0 10px 0;
}

.ticket-detail-col-right {
  flex: 1;
}

.ticket-box > div {
  font-size: 14px;
  color: #252423;
  flex: 1;
}

.ticket-box > div:first-child {
  width: 260px;
  flex: 0 0 260px;
}

.ticket-box p {
  margin: 0px;
  font-weight: 600;
  padding-left: 40px;
}

.ticket-box > div i {
  margin-right: 10px;
}

/* .row.row-ticket-detail { margin-bottom: 20px; } */
.row.row-ticket-detail + .row.row-ticket-detail {
  margin-top: 20px;
}

.Tickets-detail-body hr {
  border-top-color: #eeeeee;
  margin: 20px 0;
}

li.ticket-number,
li.ticket-status,
li.ticket-Category {
  width: calc(25% - 6px);
}

li.last-update,
li.ticket-Department {
  width: calc(50% - 6px);
}

li.last-update {
  max-width: 120px;
}

.list-unstyled.ticket-card-extra-info-list {
  margin: 5px -3px 0;
}

.list-unstyled.ticket-card-extra-info-list > li {
  margin: 3px;
}

.TCAI-bx {
  display: flex;
  line-height: 1.2;
}

.TCAI-bx-lft {
  margin-right: 5px;
}

.TCAI-bx-right small {
  color: rgba(37, 36, 35, 0.6);
  font-size: 10px;
  line-height: 1.2;
  display: block;
  margin: 0 0 2px 0;
}

.TCAI-bx-right p {
  font-size: 11px;
  margin: 0;
  color: #252423;
  font-weight: 600;
}

.TCAI-bx-lft i img {
  max-width: 15px;
}

.add-new-ticket-bx .btn-white-bordered {
  min-width: 60px;
}

.add-new-ticket-bx {
  margin-left: auto;
}

@media only screen and (min-width: 992px) {
  .Tickets-left-wrap .close-btn-sidebar {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  body.hide-subject-sidebar .Tickets-left-wrap {
    left: 68px;
  }

  .Tickets-left-wrap .close-btn-sidebar {
    display: block;
    right: -40px;
  }

  .Tickets-left-wrap {
    max-width: 360px;
    left: -302px;
    z-index: 1;
    -webkit-box-shadow: 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
    -moz-box-shadow: 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
    box-shadow: 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  }

  .Tickets-right-wrap {
    max-width: 100%;
    left: 0;
    padding: 20px;
    padding-left: 40px;
  }
  .table-item-width {
    max-width: 100px !important;
  }
}

@media only screen and (max-width: 767px) {
  .Tickets-left-wrap {
    left: -351px;
    max-width: 340px;
  }

  body.hide-subject-sidebar .Tickets-left-wrap {
    left: 0;
  }

  .Tickets-detail-head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .Tickets-head-rgt {
    max-width: 100%;
  }

  .Tickets-head-rgt h3 {
    padding: 0;
    font-size: 15px;
    margin: 0 0 10px 0;
  }

  .Tickets-head-rgt h3 .attachment-detail {
    position: static;
    display: block;
    margin: 10px 0 0;
  }

  .Tickets-head-lft .icon {
    margin: 0 0 10px 0;
  }

  .Tickets-detail-body .card-body .Tickets-sub-info li strong {
    display: block;
    margin: 0 0 5px 0;
  }

  /* .Tickets-body {
    padding-bottom: 100px;
  } */
  .table-item-width {
    max-width: 50px !important;
  }
}

@media only screen and (max-width: 567px) {
  .Tickets-left-wrap {
    left: -310px;
    max-width: 300px;
  }
  .table-item-width {
    max-width: 20px !important;
  }
}

.card-add-new-ticket .btn-primary {
  max-height: 34px;
}

.Tickets-card .card-right .date-time {
  min-width: 105px;
  margin-left: -35px;
}

.Tickets-card .card-left h4 {
  max-width: calc(100% - 35px);
}

/***Tickets Css End***/

.comment-user-bx {
  display: flex;
}

.comment-user-img {
  width: inherit;
  height: inherit;
  overflow: inherit;
  border-radius: inherit;
  /* background: #f3f6f9; */
}

.comment-user-other .assigned-title-info {
  left: 43px;
}

.comment-box-pro-item {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  /* background: #467fcf; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

small.date {
  text-align: right;
  display: block;
  color: #212529;
  font-size: 12px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  bottom: -18px;
  white-space: nowrap;
}

.comment-user-detail {
  /* max-width: calc(100% - 60px); */
  max-width: calc(100% - 80px);
  /* background: #f6f7f8; */
  position: relative;
  min-width: 45px;
}

.posted-comment {
  border-radius: 3px;
  background: #5b5b5b;
  padding: 9px;
  color: #fff;
  font-size: 12px;
  /* max-width: 400px; */
  word-break: break-word;
}

.posted-comment a {
  color: rgb(37 36 35 / 0.6);
}

.posted-comment a:hover {
  color: #467fcf;
}

.comment-conversation-list > li {
  margin: 30px 0;
}

.comment-user-current .comment-user-bx {
  flex-direction: row-reverse;
}

li.comment-user-other .comment-user-img {
  margin-right: 15px;
}

li.comment-user-current .comment-user-img {
  margin-left: 15px;
}

.comments-card-footer {
  background: #fff;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.comment-area-actions .btn {
  color: #484644;
  font-size: 22px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.comment-user-other small.date {
  left: 0;
  right: auto;
}

.comment-area-actions .btn:hover {
  background: #467fcf;
  color: #fff;
}

.comment-user-other .posted-comment {
  background-color: var(--sidebar-color);
}

.comment-area-actions .btn:focus {
  background: #06172a;
  color: #fff;
}

.comment-area-actions {
  display: flex;
  padding-left: 5px;
  margin-left: 5px;
  align-self: center;
}

.comment-area-actions .btn + btn {
  margin-left: 5px;
}

.comment-area-control {
  width: calc(100% - 100px);
}

.comment-form-control {
  /* resize: none; */
  border: none;
  box-shadow: none !important;
  border-radius: 0;
  padding: 12px;
  height: 50px;
}

.attached-media-comment {
  max-width: 140px;
  margin-top: 10px;
}

/****re-registration Center Page Css Start****/
.re-registration-list-wrap {
  padding: 20px;
}

.re-registration-list-wrap > p {
  margin-bottom: 15px;
}

.re-registration-ul-list {
  margin: 0 -5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.re-registration-ul-list > li {
  width: -webkit-calc(100% / 5 - 10px);
  width: -moz-calc(100% / 5 - 10px);
  width: calc(100% / 5 - 10px);
  margin: 5px;
}

.re-registration-bx-card {
  border: 1px solid #d7dee8;
  padding: 20px 5px 15px;
  text-align: center;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  cursor: pointer;
  min-height: 100%;
}

.re-registration-bx-card:hover {
  background: #e6ecf2;
}

.re-registration-bx-card:focus,
.re-registration-bx-card:active {
  background: #d3dee9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.re-registration-bx-card .re-registration-link-redirect {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.re-registration-bx-card .icon {
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  background: #467fcf;
  color: #f3f6f9;
  font-size: 24px;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.re-registration-bx-card h3 {
  line-height: 1.2;
  font-size: 16px;
  color: #252423;
  font-weight: bold;
  margin: 13px 0;
}

.re-registration-bx-card p {
  margin-bottom: 10px;
}

.re-registration-bx-card p i {
  margin-right: 5px;
}

.re-registration-bx-card p i img {
  max-width: 20px;
}

/****re-registration Center Page Css End****/

/* Registration-detail */

.re-registration-detail-lft {
  /* padding: 20px; */
  max-width: 320px;
  width: 100%;
  position: absolute;
  min-height: -webkit-calc(100% - 48px);
  min-height: -moz-calc(100% - 48px);
  min-height: calc(100% - 48px);
  z-index: 1;
}

.re-registration-detail-rgt {
  max-width: -webkit-calc(100% - 320px);
  max-width: -moz-calc(100% - 320px);
  max-width: calc(100% - 320px);
  width: 100%;
  padding: 20px;
  position: relative;
  left: 320px;
}

.hide-subject-sidebar .re-registration-detail-rgt {
  max-width: 100%;
  left: 0;
  padding-left: 40px;
}

body.hide-subject-sidebar .re-registration-detail-lft {
  left: -252px;
}

.re-registration-detail-lft .re-registration-lft-head,
.re-registration-detail-lft .re-registration-lft-body {
  margin: 20px;
  line-height: 1.2;
}

.all-exam {
  font-size: 16px;
}

.all-exam i {
  margin-right: 10px;
  font-size: 20px;
  vertical-align: text-bottom;
}

.re-registration-bx-info-top {
  margin: 20px 0;
}

.re-registration-bx-info-top .icon {
  width: 70px;
  height: 70px;
  min-width: 70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 20px 0 0;
  background: #467fcf;
  color: #f3f6f9;
  font-size: 24px;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.re-registration-bx-info-top h3 {
  line-height: 1.2;
  font-size: 18px;
  color: #252423;
  font-weight: bold;
  margin: 0 0 5px;
}

.re-registration-bx-info-top p {
  margin: 0;
}

.re-registration-bx-info-top .re-registration-bx-info-right .fa-ul {
  margin-left: 20px;
}

.re-registration-list-opt {
  margin-bottom: 15px;
}

.re-registration-list-opt-card {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #d7dee8;
  /* border-left: 4px solid #467fcf; */
  padding: 8px;
  cursor: pointer;
}

.re-registration-list-opt-card + .re-registration-list-opt-card {
  margin-top: 15px;
}

.re-registration-list-opt-card h3 {
  font-size: 14px;
  margin: 0 0 10px 0;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.re-registration-detail-top {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.re-registration-bx-info .icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 10px 0 0;
  background: #467fcf;
  color: #f3f6f9;
  font-size: 16px;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.re-registration-bx-info-rgt h3 {
  font-size: 17px;
  font-weight: bold;
  margin: 0 0 3px 0;
  line-height: 1.2;
  color: #252423;
}

.re-registration-bx-info-rgt p {
  margin: 0;
  font-size: 11px;
  color: #484644;
}

.re-registration-list-opt-card > ul.fa-ul {
  margin: 0;
  /*padding-right: 110px;*/
}

.re-registration-list-opt-card .btn {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  min-width: 115px;
  background: #0069d9;
  color: #fff;
  border-color: #0069d9;
}

.re-registration-list-opt-card .btn:hover {
  background: #467fcf;
  border-color: #467fcf;
}

.re-registration-list-opt-card .btn:focus {
  background: #06172a;
  border-color: #06172a;
}

.re-registration-list-opt-card .btn-white-bordered.btn-success {
  background: #28a745 !important;
  border-color: #28a745 !important;
  cursor: context-menu;
}

.re-registration-list-opt-card > ul.fa-ul > li {
  line-height: 1.2;
  word-break: break-all;
  display: -ms-flexbox !important;
  display: flex !important;
  padding-left: 25px;
  align-items: center;
}

.re-registration-list-opt-card > ul.fa-ul > li > i {
  margin-right: 5px;
  left: 0;
  width: 20px;
  text-align: left;
}

.re-registration-list-opt-card > ul.fa-ul > li > i > img {
  max-width: 20px;
}

.re-registration-register-bx .card-header {
  border: none;
  background: none;
  font-weight: bold;
  padding: 15px 0;
  text-transform: capitalize;
  font-size: 16px;
  color: #252423;
}

.re-registration-register-bx .card-body {
  background: #ffff;
  padding: 15px;
  position: relative;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #d7dee8;
}

.re-registration-register-bx .card-form-bx {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: none;
}

.re-registration-register-bx {
  margin: 15px 0;
}

.re-registration-register-bx .form-control {
  background: #eef1f5;
  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  border: none;
  padding: 8px;
  min-height: 34px;
  max-height: 34px;
}

.re-registration-register-bx .add-row-repeat .form-control {
  color: #252423;
  background: #fff;
  border: 1px solid #d7dee8;
  padding: 7.4px 15px;
}

.re-registration-register-bx .input-group-text {
  background: #eef1f5;
  border: none;
}

@media only screen and (max-width: 1699px) {
  .table-item-width {
    max-width: 580px !important;
  }
}
/* Registration-detail */

@media only screen and (max-width: 1599px) {
  .re-registration-ul-list > li {
    width: -webkit-calc(100% / 5 - 10px);
    width: -moz-calc(100% / 5 - 10px);
    width: calc(100% / 5 - 10px);
  }
  .table-item-width {
    max-width: 480px !important;
  }
}

@media only screen and (max-width: 1299px) {
  .re-registration-ul-list > li {
    width: -webkit-calc(100% / 4 - 10px);
    width: -moz-calc(100% / 4 - 10px);
    width: calc(100% / 4 - 10px);
  }
  .table-item-width {
    max-width: 380px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .re-registration-ul-list > li {
    width: -webkit-calc(100% / 3 - 10px);
    width: -moz-calc(100% / 3 - 10px);
    width: calc(100% / 3 - 10px);
  }
  .table-item-width {
    max-width: 180px !important;
  }
}

@media only screen and (max-width: 991px) {
  .re-registration-detail-lft {
    left: -252px;
  }

  .re-registration-detail-rgt {
    max-width: 100%;
    left: 0;
    padding-left: 40px;
  }

  body.hide-subject-sidebar .re-registration-detail-lft {
    left: 68px;
  }
  .table-item-width {
    max-width: 100px !important;
  }
}

@media only screen and (max-width: 767px) {
  .re-registration-ul-list > li {
    width: -webkit-calc(100% / 2 - 10px);
    width: -moz-calc(100% / 2 - 10px);
    width: calc(100% / 2 - 10px);
  }

  .re-registration-detail-lft {
    left: -320px;
  }

  body.hide-subject-sidebar .re-registration-detail-lft {
    left: 0px;
  }
  .table-item-width {
    max-width: 100px !important;
  }
}

@media only screen and (max-width: 467px) {
  .re-registration-ul-list > li {
    width: -webkit-calc(100% / 1 - 10px);
    width: -moz-calc(100% / 1 - 10px);
    width: calc(100% / 1 - 10px);
  }
}

/****Support team Css Start****/
.support-team-col {
  max-width: calc(100% / 5);
  flex: 0 0 calc(100% / 5);
  margin: 15px 0;
}

.support-team-box {
  text-align: center;
  line-height: 1.2;
  background: #fff;
  padding: 50px 15px 15px 15px;
  height: 100%;
  border: 1px solid #cbced0;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}

.support-team-icon {
  width: 70px;
  height: 70px;
  border: 1px solid #cbced0;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 15px;
  position: relative;
  z-index: 1;
  background: #fff;
}

.support-team-name {
  font-size: 14px;
  color: #484644;
  font-weight: 600;
  margin: 0;
}

.support-team-detail p {
  margin: 5px 0;
}

.support-team-detail .support-team-detail {
  margin-top: 15px;
  line-height: 15px;
}

.support-team-detail {
  font-size: 12px;
  line-height: 1.2;
}

p.support-team-subject {
  color: #467fcf;
}

p.support-team-email {
  color: rgb(37 36 35 / 0.6);
}

.support-team-box:before {
  width: 120%;
  height: 260px;
  background: #467fcf;
  position: absolute;
  display: block;
  top: -170px;
  content: "";
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  opacity: 0.25;
}

@media only screen and (max-width: 1599px) {
  .support-team-col {
    max-width: calc(100% / 4);
    flex: 0 0 calc(100% / 4);
    margin: 15px 0;
  }
}

@media only screen and (max-width: 1399px) {
  .support-team-col {
    max-width: calc(100% / 3);
    flex: 0 0 calc(100% / 3);
    margin: 15px 0;
  }
}

@media only screen and (max-width: 1199px) {
  .support-team-col {
    max-width: calc(100% / 2);
    flex: 0 0 calc(100% / 2);
    margin: 15px 0;
  }
}

@media only screen and (max-width: 991px) {
  .support-team-col {
    max-width: calc(100% / 3);
    flex: 0 0 calc(100% / 3);
    margin: 15px 0;
  }
}

@media only screen and (max-width: 767px) {
  .support-team-col {
    max-width: calc(100% / 2);
    flex: 0 0 calc(100% / 2);
    margin: 15px 0;
  }
}

@media only screen and (max-width: 467px) {
  .support-team-col {
    max-width: calc(100% / 1);
    flex: 0 0 calc(100% / 1);
    margin: 15px 0;
  }
}

/****Support team Css End****/

/****Assesment Table css changes Raju****/

.card-table-custom {
  /* padding: 30px; */
  display: flex;
  flex-direction: column;
}

.card-table-custom .rdt_TableHeader {
  order: 1;
  padding: 0;
  margin-bottom: 0px;
  min-height: inherit;
}

.card-table-custom .data-table-extensions {
  /*order: 2;
  padding: 0;
  margin-bottom: 0 !important;*/
}

.card-table-custom .jMvRrf,
.card-table-custom .sc-hHftDr {
  order: 3;
  border: 1px solid #d7dee8;
}

.card-table-custom > div:last-child {
  order: 4;
}

.card-table-custom .rdt_TableHeader .fNiiPs,
.card-table-custom .rdt_TableHeader .sc-fFubgz {
  font-size: 18px;
  font-weight: bold;
}

.card-table-custom .eliEPG,
.card-table-custom .rdt_TableHeadRow {
  background: #fff !important;
}

.card-table-custom .dqLrnR,
.card-table-custom .rdt_TableCol {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

/* .card-table-custom .dqLrnR > div:hover *,
.card-table-custom .rdt_TableCol > div:hover * {
  color: #06172A !important;
} */
.card-table-custom .dqLrnR div,
.card-table-custom .rdt_TableCol div {
  color: #06172a;
}

.card-table-custom .rdt_TableRow:not(:last-of-type),
.card-table-custom .rdt_TableRow:not(:last-of-type) {
  border: none;
}

.card-table-custom .rdt_TableRow:nth-child(even) {
  background: #f3f6f9;
}

.card-table-custom .rdt_TableRow p {
  margin: 0;
}

.assessment_buttons {
  display: block;
  flex-direction: column;
  padding: 14px 0;
  text-align: right;
}

.assessment_buttons .btn {
  font-size: 17px;
  font-weight: normal;
  border-radius: 2px;
  line-height: 1.2;
  height: 34px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 34px;
}

.assessment_buttons .btn + .btn {
  margin-top: 5px;
}

.assessment_buttons .btn i {
  font-weight: 300;
  margin-right: 5px;
}

.assessment_btn_grey {
  background: #acacac;
  color: #ffffff !important;
}

.assessment_btn_grey:disabled {
  cursor: not-allowed;
}

.assessment_btn_yellow {
  background: #ffa500;
  color: #ffffff !important;
}

/* .card-assessments-table.card-table-custom .rdt_TableCol:first-child,
 .card-assessments-table.card-table-custom .rdt_TableRow .rdt_TableCell:first-child {
  width: 119px;
  max-width: 119px;
} */

/* .card-assessments-table.card-table-custom .rdt_TableRow .rdt_TableCell:nth-child(2),
.card-assessments-table.card-table-custom .rdt_TableCol:nth-child(2) {
  max-width: 180px;
 
} */

.card-assessments-table.card-table-custom
  .rdt_TableRow
  .rdt_TableCell:nth-child(3),
.card-assessments-table.card-table-custom .rdt_TableCol:nth-child(3) {
  /* max-width: 180px; */
  min-width: 180px;
  justify-content: left;
}

/* .card-assessments-table.card-table-custom .rdt_TableRow .rdt_TableCell:nth-child(4),
.card-assessments-table.card-table-custom .rdt_TableCol:nth-child(4) {
  max-width: 180px;
} */

/* .card-assessments-table.card-table-custom .rdt_TableRow .rdt_TableCell:nth-child(5),
.card-assessments-table.card-table-custom .rdt_TableCol:nth-child(5) {
  max-width: 110px;
  text-align: left;
  padding: 0;
} */

/* .card-assessments-table.card-table-custom .rdt_TableRow .rdt_TableCell:nth-child(6),
.card-assessments-table.card-table-custom .rdt_TableCol:nth-child(6) {
  max-width: 175px !important;
  width: 175px !important;
} */
.card-assessments-table.card-table-custom
  .rdt_TableRow
  .rdt_TableCell:nth-child(7),
.card-assessments-table.card-table-custom .rdt_TableCol:nth-child(7) {
  /* max-width: 200px; */
  text-align: left !important;
  justify-content: flex-start;
}

.card-assessments-table.card-table-custom
  .rdt_TableRow
  .rdt_TableCell:nth-child(8),
.card-assessments-table.card-table-custom .rdt_TableCol:nth-child(8) {
  min-width: 160px;
  justify-content: end;
}

.card-assessments-table.card-table-custom .rdt_TableCol,
.card-assessments-table.card-table-custom .rdt_TableRow .rdt_TableCell {
  padding: 0 10px;
  color: #5b5b5b;
  font-size: 13px;
}

/****Assesment Table css changes Raju****/

.List-of-files {
  margin: 15px 0;
  padding: 0;
  border: none;
  background: none;
  color: #06172a;
  font-weight: 600;
}

.List-of-files:before {
  content: "List of files";
}

.List-of-files:after {
  content: "\f106";
  font-family: "Font Awesome 5 Pro";
  margin-left: 5px;
}

.List-of-files.collapsed:before {
  content: "List of files";
}

.List-of-files.collapsed:after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
}

.attached-file-ul li {
  display: flex;
  align-items: center;
  padding-right: 0;
}

.attached-file-ul li .download-list-action-btns {
  display: flex;
  margin-left: auto;
}

.attached-file-ul .download-list-action-btns .btn + .btn {
  margin-left: 5px !important;
}

.attached-file-ul li .download-list-action-btns .btn {
  position: static;
  transform: none;
  text-align: center !important;
}

.attached-file-ul li .download-list-action-btns .btn i {
  margin: 0;
}

/****Home Changes Start****/
.wlcome-user-info {
  margin-bottom: 15px;
}

.wlcome-user-info h4 {
  line-height: 1.2;
  font-size: 18px;
  color: #252423;
  font-weight: 700;
  margin: 13px 0;
}

.wlcome-user-info h4 span {
  color: var(--topbar-color);
}

@media only screen and (min-width: 1499px) {
  .home-list-wrap,
  .compliment-wrpe-main,
  .mycourses-list-wrap,
  .exam-list-wrap,
  .re-registration-list-wrap {
    max-width: 1399px;
    margin: 0 auto;
  }
}

/****Home Changes End****/

/* compliment page css */
.compliment-wrpe-main {
  padding: 20px;
}

.nav-pills-custom .nav-item a {
  text-align: center;
  color: #252423;
  position: relative;
  display: block;
}

.nav-pills-custom .nav-item a img {
  width: 80px;
}

.nav-pills-custom .nav-item p {
  text-transform: uppercase;
  padding: 5px 0px;
  margin: 0;
  font-size: 12px;
}

.nav-pills-custom .nav-item a.active p {
  font-weight: bold;
}

.tbs-main {
  border-radius: 2px;
  overflow: hidden;
  padding: 15px;
  background: #fff;
}

.compliment-header h3 {
  line-height: 1.2;
  font-size: 18px;
  color: #252423;
  font-weight: 700;
  margin-bottom: 10px;
}

.reviw-col .btn {
  min-width: 120px;
}

.compliment-header p {
  color: #484644;
  font-size: 14px;
}

.card-group label {
  color: #484644;
  line-height: 1.2;
  margin: 0 0 10px 0;
}

.card-group .form-control {
  background-color: #eef1f5;
  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  border: none;
  padding: 12px 15px;
  min-height: 40px;
}

.tab-pane-head {
  margin-bottom: 20px;
}

.tab-pane-head h3 {
  font-size: 16px;
  color: #252423;
  margin-bottom: 10px;
  font-weight: 600;
}

.tab-pane-head h3 span {
  font-weight: bold;
}

.nav-pills-custom {
  overflow: inherit !important;
  padding-bottom: 21px;
}

.card-group textarea.form-control {
  resize: none;
  height: 130px;
}

.compliment-header {
  margin-bottom: 30px;
}

.reviw-col.or-text {
  padding: 0px 10px;
}

.Attachment-area {
  padding: 10px 9px;
}

.form-group-save-cancel .btn + .btn {
  margin-left: 5px;
}

.form-group.atttach-file.m-0.col-12 label ~ div {
  margin-top: 10px;
}

.comments-card-body {
  height: 100%;
  overflow: auto;
  max-height: inherit;
  /* padding-right: 30px;
  padding-left: 30px; */
}

.as-red img {
  width: 20px;
  margin-right: 5px;
}

.form-group.atttach-file.m-0.col-12.d-flex {
  flex-wrap: wrap;
}

.form-group.atttach-file.m-0.col-12.d-flex .m-2:first-child {
  margin-left: 0 !important;
}

.ticketAttachments {
  display: block;
  width: 100%;
  flex: 0 0 100%;
}

.ticketAttachments > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  max-width: 259px;
}

.ticketAttachments > div + div {
  margin-top: 10px;
}

.ticketAttachments .btn {
  width: 30px;
  height: 30px;
  min-width: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin-left: 10px;
}

.ticketAttachments .btn i {
  margin: 0px;
}

.Tickets-panel .nav-tabs li:first-child {
  margin: 0;
}

.Tickets-panel .nav-tabs li {
  color: #484644;
  font-weight: 400;
  position: relative;
  padding-bottom: 3px;
}

.Tickets-panel .nav-tabs li::before {
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: 0;
}

.Tickets-panel .nav-tabs li {
  border-bottom: none !important;
}

.Tickets-panel .nav-tabs li.selected {
  font-weight: 600;
}

.Tickets-panel .nav-tabs li.selected::before {
  background: var(--topbar-color);
}

.rowAssessment .list-file-wrap .List-of-files {
  /*margin: 10px 0px;*/
  margin: 0;
}

.rowAssessment .attached-file-ul li {
  padding: 5px 5px 5px 0;
}

.rowAssessment .attached-file-ul li .download-list-action-btns {
  margin-left: auto;
}

.notfication-detail-body .card p strong {
  margin-bottom: 10px;
  display: inline-block;
  padding-right: 5px;
}

.notfication-detail-body .card p span {
  margin-bottom: 5px;
  display: inline-block;
}

.dzu-previewContainer {
  padding: 10px 0 !important;
}

.dzu-dropzone {
  border: 1px solid #d7dee8 !important;
  border-radius: 2px;
  overflow: hidden;
}

.rowAssessment .list-file-wrap {
  /*margin-top: 10px;*/
}

.btn-back {
  margin-bottom: 10px;
}

.card-body-inr table.table tbody td .btn.btn-danger {
  min-width: 30px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  font-size: 14px;
  border-radius: 50%;
}

.card-body-inr table.table tbody td .btn.btn-danger i {
  margin: 0 !important;
}

/* .card-profile-info-card .btn.rounded-circle {
  margin: 0 5px;
}

.Chat-panel-head a {
  color: #252423;
}

.exam-list-opt-card .btn.btn-disabled {
  cursor: not-allowed;
  opacity: 0.6;
  background: #0069d9;
  color: #fff;
  border-color: #0069d9;
}

@media screen and (max-width: 1650px) {
  .assMentbox>div:first-child {
    width: 150px;
    flex: 0 0 150px;
    max-width: 150px;
  }

  .assMentbox>div {
    font-size: 12px;
  }

  .ticket-box>div:first-child {
    width: 150px;
    flex: 0 0 150px;
    max-width: 150px;
  }

  .ticket-box>div {
    font-size: 12px;
  }
}

@media screen and (max-width: 1300px) {
  .row.rowAssessment {
    display: block;
  }

  .assessment-col-left {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .ticket-detail-col-left {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media screen and (max-width: 767px) {
  .assMentbox>div:first-child {
    width: 100%;
    margin-bottom: 5px;
  }

  .ticket-box>div:first-child {
    margin-bottom: 5px;
    width: 100%;
    flex: 0 0 100%;
  }

  .d-flex.ticket-box {
    flex-wrap: wrap;
  }

  .row.rowAssessment>div+div {
    margin-top: 10px;
  }

  .ticket-box>div {
    font-size: 12px;
  }

  .ac-white-card {
    padding: 10px;
  }

  .assMentbox>div,
  .ticket-box>div {
    flex: 0 0 100%;
  }

  .ticket-box>div:first-child {
    margin-bottom: 10px;
  }

  .ticket-box p {
    padding-left: 0px;
  }

  .tbs-main {
    padding: 15px;
  }

  .nav-pills-custom .nav-item a img {
    width: 38px;
  }

  .nav-pills-custom .nav-item p {
    font-size: 10px;
    padding: 0px 5px;
  }

  .row.reviw-content {
    display: block;
  }

  .reviw-col {
    text-align: center !important;
  }

  .reviw-col {
    margin: 5px 0px;
  }

  .card-table-custom {
    padding: 0 15px;
  }

  html body #lhc_status_container {
    bottom: 78px;
    right: 10px !important;
  }
}

.red-color {
  color: red;
}

.green-color {
  color: green;
}

.card-progress .progress.progress-blank {
  background: #fff;
  border: 1px solid #d7dee8 !important;
  overflow: hidden;
}

.card-progress .progress {
  background: #d7dee8;
  border-radius: 2px;
}

.card-progress .progress-bar {
  background-color: #467fcf;
}

/***online classes css start****/
.tui-full-calendar-popup-detail .tui-full-calendar-section-header {
  display: none;
}

.tui-full-calendar-section-button {
  display: none;
}

.tui-full-calendar-time-schedule {
  background: none !important;
  border: none !important;
}

.tui-full-calendar-time-schedule-content {
  border: none !important;
}

.tui-full-calendar-weekday-grid-line {
  background: white !important;
}

.tui-full-calendar-weekday-schedule.tui-full-calendar-weekday-schedule-time
  .tui-full-calendar-weekday-schedule-title {
  padding: 0;
}

.tui-full-calendar-weekday-schedule-bullet {
  display: none;
}

.tui-full-calendar-timegrid-hour span {
  text-align: left !important;
}

.meeting-custom-calendar .button-grp {
  display: flex;
}

.meeting-custom-calendar .button-grp select.form-control {
  display: none;
  max-width: 100px;
  height: inherit;
  padding: 5px 10px;
  border: 1px solid #d7dee8;
  color: #484644;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  border-radius: 2px;
  box-shadow: 0 2px 2.97px 0.03px hsla(0, 0%, 49%, 0.13) !important;
}

.meeting-custom-calendar .button-grp .btn-white-bordered {
  min-width: 80px;
  padding: 8px 10px;
}

.meeting-custom-calendar .button-grp #prev-button,
.meeting-custom-calendar .button-grp #next-button {
  max-width: 38px;
  min-width: 38px;
}

.meeting-custom-calendar .button-grp #prev-button i,
.meeting-custom-calendar .button-grp #next-button i {
  margin: 0;
}

.meeting-custom-calendar .button-grp .btn-white-bordered + .btn-white-bordered {
  margin-left: 5px;
}

.meeting-custom-calendar .custom-control {
  min-height: inherit;
  margin-left: 5px;
  align-self: center;
}

.meeting-custom-calendar .custom-control-label:after,
.meeting-custom-calendar .custom-control-label:before {
  top: -2px;
}

.meeting-custom-calendar .button-grp select.form-control:hover {
  background: #d7dee8;
}

.meeting-custom-calendar .button-grp select.form-control:focus {
  box-shadow: none !important;
}

.current-date {
  line-height: 1.2;
  font-size: 14px;
  color: #252423;
  align-self: center;
  margin-right: auto;
  margin-left: 10px;
  align-self: center;
}

.meeting-title {
  line-height: 1.2;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.meeting-title i {
  position: static;
  width: auto;
  text-align: inherit;
}

.meeting-title .fa-house-signal {
  color: #8ece8e;
}

.meeting-title .fa-university {
  color: #8e00a7;
}

.tui-full-calendar-popup-container {
  background: #f9f8f7;
}

.meeting-title-box,
.past-meeting-title-box,
.canceled-meeting-title-box,
.upcoming-meeting-title-box,
.live-meeting-title-box {
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 2px 2.97px 0.03px hsl(0deg 0% 49% / 13%);
  border: 1px solid #d7dee8;
  border-left: 3px solid #06172a;
  padding: 5px;
  cursor: pointer;
  background: #e6ecf2;
  min-height: 100%;
  word-break: break-word;
  white-space: initial;
}

.meeting-title-box:hover,
.past-meeting-title-box:hover,
.upcoming-meeting-title-box:hover {
  background: #d3dee9;
}

.live-meeting-title-box {
  background: #dcecdc;
  border-left-color: #5db85d;
  padding-right: 60px;
}

.live-meeting-title-box:hover {
  background: #b6dab6;
}

.live-meeting-title-box .meeting-title-reload {
  min-width: calc(100% + 50px);
}

.btn-join-title-box {
  padding: 5px !important;
  height: inherit !important;
  line-height: 1 !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  position: absolute;
  right: 5px;
  top: 8px;
}

.btn-join-title-box i {
  transform: rotate(45deg);
  margin-right: 2px;
  transform-origin: 6px 2px;
  font-size: 12px;
}

.meeting-title-reload {
  text-align: right;
  margin-top: -20px;
}

.canceled-meeting-title-box {
  border-left-color: #ff0202;
  color: #ff0202;
  text-decoration: line-through;
  background: #ffe3e3;
}

.canceled-meeting-title-box:hover {
  background: #ecc7c7;
}

.canceled-meeting-title-box * {
  text-decoration: line-through;
  color: #ff0202 !important;
}

.meeting-title-box p {
  font-weight: normal;
  max-height: 30px;
  min-height: 28px;
  overflow: hidden;
  color: #6f6e6d;
  line-height: 1.2;
  margin: 0 0 5px 0;
}

/* Popup Css Start */
.meeting-detail-box {
  line-height: 1.2;
  font-family: "Segoe UI";
}

.label-orange {
  background: #ffa500;
  color: #fff;
  padding: 0.25em 0.4em;
  font-weight: 700;
  border-radius: 3px;
  margin: 0 0 10px 0;
  line-height: 1.2;
}

.label-green {
  background: #5db85d;
  color: #fff;
  padding: 0.25em 0.4em;
  font-weight: 700;
  border-radius: 3px;
  margin: 0 0 10px 0;
  line-height: 1.2;
}

.label-purple {
  background: #8e00a7;
  color: #fff;
  padding: 0.25em 0.4em;
  font-weight: 700;
  border-radius: 3px;
  margin: 0 0 10px 0;
  line-height: 1.2;
}

.label-blue {
  background: #467fcf;
  color: #fff;
  padding: 0.25em 0.4em;
  font-weight: 700;
  border-radius: 3px;
  margin: 0 0 10px 0;
  line-height: 1.2;
}

.label-red {
  background: red;
  color: #fff;
  padding: 0.25em 0.4em;
  font-weight: 700;
  border-radius: 3px;
  margin: 0 0 10px 0;
  line-height: 1.2;
}

.meeting-detail-box h6 {
  line-height: 1.2;
  font-size: 16px;
  color: #06172a;
  font-weight: 700;
  margin: 0 0 10px;
}

.meeting-detail-list {
  margin-left: 20px;
  font-size: 12px;
  line-height: 1.2;
}

.meeting-detail-list li + li {
  margin-top: 5px;
}

.meeting-detail-box p {
  margin: 0;
}

.meeting-detail-box p + p {
  margin-top: 5px;
}

.stars {
  margin: 5px 0;
  color: #467fcf;
}

.meeting-detail-box .btn {
  display: block;
  line-height: 1.2;
  width: 100%;
  padding: 0.375rem 5px;
}

.meeting-detail-box .btn + .btn {
  margin-top: 10px;
}

.instructor-name {
  font-size: 16px;
  color: #06172a;
  font-weight: 600;
}

.instructor-name i {
  margin-right: 8px;
}

.btn-square {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-square .btn {
  line-height: 1.2;
  display: inline-block;
  width: 100px;
  padding: 12px;
  font-size: 14px;
  max-height: inherit !important;
  height: auto;
}

.btn-square .btn + .btn {
  margin-left: 10px;
  margin-top: 0;
}

.btn-square .btn i {
  margin: 0 0 5px 0;
  font-size: 22px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-square .exit-campus-class {
  background: #fa921d;
  color: #fff;
}

.btn-square .exit-campus-class:focus,
.btn-square .exit-campus-class:hover {
  color: #fff;
  background: #ce7410;
  border-color: #ce7410;
}

.btn-square .exit-campus-class:active {
  color: #fff !important;
  background: #b3640b;
  border-color: #b3640b;
}

.meeting-detail-box .red-color,
.meeting-detail-box .green-color {
  font-weight: bold;
}

.tui-full-calendar-popup-top-line {
  display: none;
}

.tui-full-calendar-layout {
  background: #f3f2f1 !important;
}

.tui-full-calendar-timegrid-timezone {
  background: #f3f2f1 !important;
}

.tui-full-calendar-timegrid-left {
  font-size: 16px !important;
}

.tui-full-calendar-timegrid-gridline {
  background: #fff;
}

.tui-full-calendar-dayname-container {
  height: 60px !important;
  overflow-y: auto !important;
  padding-right: 17px !important;
}

.tui-full-calendar-dayname-name {
  display: block;
  line-height: 1;
  margin-top: -10px;
  font-weight: normal;
}

.tui-full-calendar-dayname + .tui-full-calendar-dayname {
  border-left: 1px solid #e5e5e5 !important;
}

.tui-full-calendar-dayname {
  padding: 0px 10px !important;
}

.tui-full-calendar-popup-detail .tui-full-calendar-popup-container {
  width: 370px !important;
  min-width: 370px !important;
  padding: 15px;
}

.tui-full-calendar-timegrid-todaymarker {
  background: #467fcf !important;
}

.tui-full-calendar-timegrid-hourmarker-line-today {
  border-top-color: #467fcf !important;
}

.tui-full-calendar-timegrid-hourmarker-line-left {
  border-top-color: #467fcf !important;
}

.tui-full-calendar-timegrid-hourmarker-time {
  color: #467fcf !important;
}

/* Popup Css Start */

@media only screen and (max-width: 1399px) {
  .tui-full-calendar-timegrid-left {
    font-size: 14px !important;
    width: 50px !important;
  }

  .tui-full-calendar-timegrid-right {
    margin-left: 50px !important;
  }

  .tui-full-calendar-dayname-leftmargin {
    margin-left: 50px !important;
  }

  .calendar-box {
    overflow: auto;
  }

  /* .calendar-box > div { height: calc(100vh - 220px) !important; }
.calendar-box > div > div { max-width: 880px; min-width: 880px; } */
}

@media only screen and (max-width: 1299px) {
  .calendar-view-buttons {
    display: none;
  }

  .meeting-custom-calendar .button-grp select.form-control {
    display: block;
  }

  .meeting-custom-calendar .button-grp select.form-control {
    min-width: 90px;
  }

  .meeting-custom-calendar .button-grp .btn-white-bordered {
    min-width: inherit;
  }

  .calendar-box > div > div {
    max-width: 1180px;
    min-width: 1180px;
  }
}

@media only screen and (max-width: 991px) {
  .current-date {
    font-size: 14px;
    width: 100%;
    order: 100;
    margin: 10px 0 0 0;
  }

  .meeting-custom-calendar .button-grp {
    flex-wrap: wrap;
  }

  .meeting-custom-calendar .button-grp select.form-control {
    margin-left: auto;
  }
}

@media only screen and (max-width: 567px) {
  .meeting-custom-calendar .custom-control {
    order: 999;
    margin: 15px 0 0;
    width: 100%;
  }
}

/***online classes css End****/

.card.card-profile-info-card .card-body .card-table-custom {
  padding: 0px 0px;
  border: none;
}

.card.card-profile-info-card .card-body .card-table-custom .rdt_TableHeader {
  display: none;
}

.btn-danger {
  min-width: 80px;
  background-color: #ff0102;
  border-color: #ff0102;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  box-shadow: 0px 1.5px 2px 0px rgb(0 0 0 / 30%) !important;
  font-weight: 600;
  font-size: 14px;
  height: 34px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 34px;
}

.btn-danger:hover,
.btn-danger:active {
  color: #fff;
  background-color: #c90103;
  border-color: #c90103;
}

.btn-danger:focus {
  background-color: #ce0000;
  border-color: #ce0000;
}

.row.row-repeat a.btn.btn-danger,
.re-registration-detail-rgt .row a.btn.btn-danger {
  border-radius: 50%;
  width: 34px;
  min-width: 34px;
  padding: 0;
}

.row.row-repeat a.btn.btn-danger i,
.re-registration-detail-rgt .row a.btn.btn-danger i {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .row.row-repeat {
    flex-wrap: nowrap;
  }

  .row.row-repeat .col-sm-6 {
    width: calc(100% - 53px);
  }
}

.btn-white-bordered.btn-back {
  background: #06172a;
  color: #fff;
  border-color: #06172a;
}

.rowAssessment .btn-submit.btn-danger {
  font-size: 14px;
}

.dzu-submitButton {
  background: #0069d9;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
}

.dzu-submitButton:active,
.dzu-submitButton:hover {
  background: #467fcf;
  border-color: #467fcf;
}

.dzu-submitButton:focus {
  background: #06172a;
  border-color: #06172a;
}

.flex-shrink ul.list-number {
  list-style-type: decimal;
  margin: 0 0 10px;
}

.flex-shrink ul.list-number ul.list-bullet {
  list-style-type: disc;
  margin-top: 10px !important;
  margin-left: 20px;
}

.flex-shrink ul.list-number,
.flex-shrink ul.list-bullet {
  list-style-position: inside;
  color: #484644;
  font-size: 14px;
}

.flex-shrink ul.list-number li + li {
  margin-top: 10px;
}

.description strong {
  display: block;
  margin: 0 0 10px 0;
}

.appeal_assessment_btn {
  font-size: 12px;
  border-radius: 3px;
  line-height: 1.2;
  padding: 7px 10px;
}

.ac-ass-col-adjust .assessment-col {
  margin: 10px 0;
}

.ac-ass-col-adjust .row.rowAssessment + .row.rowAssessment {
  margin-top: 0;
}

.landing-heading {
  line-height: 1.2;
  font-size: 18px;
  color: #252423;
  font-weight: 700;
  /* margin: 13px 0;*/
  /* margin-bottom: 30px !important; */
}

.landing-heading.no-margin {
  margin-bottom: 0px !important;
}

.landing-heading span {
  color: var(--topbar-color);
}

@media screen and (max-width: 1650px) {
  .ac-ass-col-adjust .assessment-col .assMentbox > div:last-child {
    width: calc(100% - 150px);
  }
}

@media screen and (max-width: 1299px) {
  .ac-ass-col-adjust .assessment-col {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.tui-full-calendar-layout,
.tui-full-calendar-timegrid-timezone {
  background: #f3f6f9 !important;
}

.btn-success {
  font-weight: 600;
  height: 34px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 34px;
  font-size: 14px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 30%) !important;
  background: #5db85d;
  border-color: #5db85d;
}

.btn-primary.disabled,
.btn-success:disabled,
.btn-primary:disabled {
  cursor: not-allowed;
  background: #acacac !important;
  border-color: #acacac !important;
  color: #fff !important;
}

/* Ticket v-2 */

.Tickets-card-v-2 {
  font-size: 14px;
}

.Tickets-card-v-2 .card-right {
  font-size: 14px;
  max-width: 70px;
  padding: 0;
  display: block;
  text-align: right;
}

.Tickets-card-v-2 .card-right li.ticket-number {
  width: 100%;
  text-align: right;
}

.Tickets-card-v-2 .card-left {
  max-width: -webkit-calc(100% - 70px) !important;
  max-width: -moz-calc(100% - 70px) !important;
  max-width: calc(100% - 70px) !important;
}

.Tickets-card-v-2 .card-right li.ticket-number .TCAI-bx {
  justify-content: flex-end;
}

.Tickets-card-v-2 .card-left h4 {
  max-width: 100%;
  margin: 0 0 10px 0;
}

.Tickets-card-v-2 .card-left > p {
  margin-bottom: 10px !important;
  min-width: calc(100% + 75px);
  font-size: 12px;
}

.Tickets-card-v-2 .ticket-card-extra-info > div + div {
  margin-left: 15px;
}

.Tickets-card-v-2 .card-left .last-update p {
  line-height: 1.4;
  margin-bottom: 10px !important;
}

.Tickets-card-v-2 .TCAI-bx-right p {
  font-size: 12px;
  line-height: 1.5;
}

.header-status-li button {
  pointer-events: none;
  cursor: initial;
}

.portal-status-icon .fad.fa-check-circle:before {
  color: #fff;
  opacity: 1;
}

.portal-status-icon .fad.fa-check-circle:after {
  color: #92c353;
  opacity: 1;
}

/* Meeting poup chnages */
.meeting-btns-list {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0 -2.5px;
  flex-wrap: wrap;
}

.meeting-btns-list li {
  margin: 2.5px;
}

.meeting-btns-list .btn {
  line-height: 34px;
  padding-top: 0;
  padding-bottom: 0;
}

.meeting-leave-comment .form-control {
  resize: none;
  border: none;
  background: #e6ecf2;
}

.meeting-leave-comment .btn {
  display: inline-block;
  margin-left: auto !important;
  float: right;
  margin-top: 10px;
}

.meeting-info-status-col,
.meeting-btns-col,
.meeting-rating-start-col,
.meeting-leave-comment-col {
  margin: 4px 0;
}

/* Meeting poup chnages */

.meeting-desc ul {
  padding: 0;
  list-style-position: inside;
  margin: 5px 0;
}

/* Cource Detail page updations v2 */

.subject-labels label {
  margin: 5px 0 0;
  font-size: 12px;
  width: 100%;
  text-align: center;
}

.subject-status-card {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%);
  border: 1px solid #d7dee8;
  margin-bottom: 15px;
}

.subject-status-ul > li + li {
  border-left: 1px solid #d7dee8;
}

.subject-status-ul > li {
  padding: 10px;
  display: flex;
  align-items: center;
}

.subject-status-ul > li > div {
  display: flex;
  align-items: center;
}

.subject-status-icon img {
  max-width: 25px;
}

.custom-switch.custom-switch-text {
  min-width: 54px;
  padding: 0;
}

.custom-switch.custom-switch-text .custom-control-label {
  width: 100%;
}

.custom-switch.custom-switch-text .text-on-off:before {
  content: "No";
}

.custom-switch.custom-switch-text
  .custom-control-input:checked
  ~ .custom-control-label
  .text-on-off:before {
  content: "Yes";
}

.custom-switch.custom-switch-text .custom-control-label::before {
  width: 100%;
  height: 26px;
  border-radius: 40px;
  top: 0;
  background: #ef0303;
  border-color: #ef0303;
  left: 0;
}

.custom-switch.custom-switch-text .custom-control-label::after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  background: #fff;
}

.custom-switch.custom-switch-text
  .custom-control-input:checked
  ~ .custom-control-label:after {
  -webkit-transform: translateX(27px);
  transform: translateX(27px);
}

.custom-switch.custom-switch-text
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background: #5db85d;
  border-color: #5db85d;
}

.custom-switch.custom-switch-text .text-on-off {
  position: absolute;
  right: 8px;
  top: 5px;
  color: #fff;
  font-weight: 600;
}

.custom-switch.custom-switch-text
  .custom-control-input:checked
  ~ .custom-control-label
  .text-on-off {
  left: 5px;
  right: auto;
}

.card.card-header-transparent {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: none;
}

.card.card-header-transparent .card-header {
  border: none;
  background: none;
  font-weight: bold;
  padding: 15px 0;
  text-transform: capitalize;
  font-size: 16px;
  color: #252423;
  display: block !important;
}

.card.card-header-transparent .card-body {
  background: #ffff;
  padding: 15px;
  position: relative;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #d7dee8;
}

.form-group-date-picker .react-datepicker-wrapper {
  width: 100%;
  display: block;
}

.subject-status-ul .btn-white-bordered.active {
  background: #06172a !important;
  border-color: #06172a !important;
  color: #fff !important;
}

/* Cource Detail page updations v2 */

.edit-profile-icon-active .edit-icon i:before {
  content: "\f00d";
}

.edit-profile-icon-active .edit-profile-icon {
  background: #eef1f5;
  color: #252423;
}

/****Quiz Page Css Start***/

.label-square-icon {
  width: 30px;
  height: 30px;
  display: inline-flex;
  margin-right: 10px;
  border-radius: 3px;
}

.label-quiz-red {
}

.label-quiz-red .label-square-icon {
  background: #ef0303;
}

.label-quiz-green .label-square-icon {
  background: #5cb85c;
}

.label-quiz-orange .label-square-icon {
  background: #ffa500;
}

.quiz-labels .label {
  display: inline-flex;
  align-items: center;
  margin: 10px 0;
}

.quiz-labels .label + .label {
  margin-left: 20px;
}

.card.card-header-dark > .card-header {
  background: #06172a;
  color: #fff;
  padding: 15px;
  line-height: 1.2;
  margin: -1px -1px 0 -1px;
  padding: 11px 25px;
}

.card.card-header-dark > .card-body {
  border: none;
  padding: 20px 25px;
}

.radio-group-custom {
  line-height: 1.6;
  font-size: 14px;
  color: #252423;
}

.radio-group-custom
  .custom-control.custom-radio
  + .custom-control.custom-radio {
  margin-top: 12px;
}

.checkbox-group-custom {
  line-height: 1.6;
  font-size: 14px;
  color: #252423;
}

.checkbox-group-custom
  .custom-control.custom-checkbox
  + .custom-control.custom-checkbox {
  margin-top: 12px;
}

.quiz-pagination .btn {
  width: 30px;
  height: 30px;
  margin: 4px;
}

.quiz-pagination {
  margin-left: -4px;
  margin-right: -4px;
}

.btn-white-bordered.btn-white-bordered-green {
  background: #5cb85c;
  border-color: #5cb85c;
  color: #fff;
}

.btn-white-bordered.btn-white-bordered-red {
  background: #ef0303;
  border-color: #ef0303;
  color: #fff;
}

.btn-white-bordered.btn-white-bordered-orange {
  background: orange;
  border-color: orange;
  color: #fff;
}

.btn-white-bordered.btn-secondary {
  min-width: 120px;
  color: #fff;
  font-size: 14px;
  background: #06172a !important;
  border-color: #06172a !important;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%) !important;
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13) !important;
  box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%) !important;
  font-weight: 600;
  height: 34px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 34px;
}

.card-header-transparent.card-header-transparent-no-padding > .card-body {
  padding: 0;
}

.card-header-transparent.card-header-transparent-no-padding
  > .card-body
  .card-header-dark,
.card-header-transparent.card-header-transparent-no-padding
  > .card-body
  .card-header-dark
  .card-body {
  border: none;
  box-shadow: none;
  padding-bottom: 0;
}

.card-header-transparent.card-header-transparent-no-padding
  > .card-body
  .card-header-dark
  .card-body {
}

.card-header-transparent.card-header-transparent-no-padding .quiz-footer-btns {
  padding: 20px 25px;
  padding-top: 0;
  margin-top: 0 !important;
}

.card.card-quiz-answer {
  margin-bottom: 20px;
}

.card.card-quiz-answer > .card-header {
  color: #fff;
  padding: 15px;
  line-height: 1.2;
  margin: -1px -1px 0 -1px;
  padding: 11px 25px;
}

.card.card-quiz-answer > .card-body {
  border: none;
  padding: 20px 25px;
}

.card.card-quiz-answer.card-header-red > .card-header {
  background: #ef0303;
}

.card.card-quiz-answer.card-header-green > .card-header {
  background: #5cb85c;
}

.table-custom-info > tbody > tr > th,
.table-custom-info > tbody > tr > td {
  border: 1px solid rgb(112 112 112 / 22%);
}

.table-custom-info tbody td:last-child {
  border-right-color: rgb(112 112 112 / 22%) !important;
}

.table-custom-info tr:nth-of-type(odd) th,
.table-custom-info tr:nth-of-type(odd) td {
  background: #eef1f5;
}

.card.card-quiz-answer th {
  width: 30%;
}

.card.card-quiz-answer td {
  width: 70%;
}

.learning-quiz-wrap .table-custom-info th,
.learning-quiz-wrap .table-custom-info td {
  width: 50%;
}

.main-action-btns.text-right {
  margin-bottom: -40px;
  position: relative;
  z-index: 1;
}

.main-action-btns .btn + .btn {
  margin-left: 5px;
}

/****Quiz Page Css End***/

/***Subject Responisve Css***/
@media only screen and (max-width: 1399px) {
  .subject-status-ul > li + li {
    border: none;
  }

  .subject-status-ul > li {
    border: 1px solid #d7dee8 !important;
    margin: -1px 0 0 -1px;
    flex: auto;
  }

  .start-subject-module-li,
  li.mark-complete-subject-li,
  li.notes-subject-li,
  li.require-sign-on-off-li {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  li.subject-date-li,
  li.subject-status-li {
    width: calc(100% + 2px);
    min-width: calc(100% + 2px);
  }
}

/***Subject Responisve End***/

.subject-no-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  min-height: calc(100vh - 108px);
}

.table-striped.table-custom-info.table-column-3 td,
.table-striped.table-custom-info.table-column-3 th {
  width: 33.333% !important;
}

.select-list-inline select.form-control {
  width: auto;
  min-width: 150px;
  margin: 0 10px;
  background: #eef1f5;
  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  border: none;
  padding: 10px 15px;
  min-height: 30px;
}

.select-list-inline li {
  display: flex;
  align-items: center;
}

.select-list-inline li span {
  min-width: 150px;
}

.select-list-inline li + li {
  margin-top: 12px;
}

.learning-quiz-wrap .card.card-header-dark > .card-body textarea.form-control {
  background: #eef1f5;
  font-size: 14px;
  line-height: 1.2;
  border: none;
  padding: 10px 15px;
  min-height: 80px;
  resize: auto;
}

.quiz-footer-btns .btn + .btn {
  margin-left: 15px;
}

@media only screen and (max-width: 767px) {
  .select-list-inline li span,
  .select-list-inline select.form-control {
    min-width: 100%;
    margin: 5px 0;
  }

  .select-list-inline li {
    flex-direction: column;
  }

  .checkbox-group-custom {
    line-height: 1.2;
  }

  .card.card-header-dark > .card-body {
    padding: 10px 15px;
  }

  .main-action-btns.text-right {
    margin: 0;
  }
}

.rating-input i {
  color: #467fcf;
  font-size: 16px;
}

.rating-input i + i {
  margin-left: 5px;
}

.tui-full-calendar-time-schedule,
.tui-full-calendar-weekday-schedule-title {
  font-weight: normal !important;
}

.text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Class Shedule List View Start*/
.Class-Shedule-main-table {
  margin-bottom: 0;
}

.Class-Shedule-main-table .table {
  margin: 0;
  border: none;
}

.Class-Shedule-main-table tbody tr > td {
  border: none;
  padding: 0;
}

.Class-Shedule-main-table .class-list-row td {
  padding: 0.75rem !important;
  position: relative;
}

.Class-Shedule-main-table .thead-dark th {
  text-transform: capitalize;
  border: none !important;
}

.Class-Shedule-main-table .thead-dark th:nth-child(1) {
  /*border-left: 3px solid #06172a !important;*/
}

tr.class-list-row.class-upcoming-row td,
tr.class-list-row.class-past-row td,
tr.class-list-row.class-canceled-row td,
tr.class-list-row.class-live-row td,
.class-list-row td {
  border-bottom: 1px solid #d7dee8 !important;
}

.class-list-row td:nth-child(1) {
  width: 150px;
  background: #fff !important;
  text-align: center;
}

.class-list-row .meeting-title {
  margin: 0;
}

.class-list-row .fa-house-signal {
  color: #8ece8e;
}

.class-list-row .fa-university {
  color: #8e00a7;
}

.class-upcoming-row td {
  background: #e6ecf2 !important;
  border-color: #e6ecf2 !important;
}

.class-upcoming-row:hover td {
  background: #d3dee9 !important;
}

.class-upcoming-row td:nth-child(2) {
  border-left: 3px solid #06172a !important;
}

.class-past-row td {
  background: #e6ecf2 !important;
  border-color: #e6ecf2 !important;
}

.class-past-row:hover td {
  background: #d3dee9 !important;
}

.class-past-row td:nth-child(2) {
  border-left: 3px solid #06172a !important;
}

.class-live-row td {
  background: #dcecdc !important;
  border-color: #dcecdc !important;
}

.class-live-row:hover td {
  background: #b6dab6 !important;
}

.class-live-row td:nth-child(2) {
  border-left: 3px solid #5db85d !important;
}

.class-canceled-row td {
  background: #ffe3e3 !important;
  border-color: #ffe3e3 !important;
  text-decoration: line-through;
  color: #ff0202;
}

.class-canceled-row:hover td {
  background: #ecc7c7 !important;
}

.class-canceled-row td:nth-child(2) {
  border-left: 3px solid #ff0202 !important;
}

.class-list-row td .btn-join-title-box {
  top: 50%;
  transform: translateY(-50%);
}

.class-list-row td .btn-join-title-box .btn-success {
  height: 28px;
  line-height: 28px;
}

.class-list-row .meeting-title i {
  margin-left: 5px;
}

.Class-Shedule-List-View tr.class-list-row.class-upcoming-row td:nth-child(1),
.Class-Shedule-List-View tr.class-list-row.class-past-row td:nth-child(1),
.Class-Shedule-List-View tr.class-list-row.class-canceled-row td:nth-child(1),
.Class-Shedule-List-View tr.class-list-row.class-live-row td:nth-child(1),
.Class-Shedule-List-View .class-list-row td:nth-child(1) {
  font-size: 12px;
}

.Class-Shedule-List-View tr.class-list-row.class-upcoming-row td:nth-child(2),
.Class-Shedule-List-View tr.class-list-row.class-past-row td:nth-child(2),
.Class-Shedule-List-View tr.class-list-row.class-canceled-row td:nth-child(2),
.Class-Shedule-List-View tr.class-list-row.class-live-row td:nth-child(2),
.Class-Shedule-List-View .class-list-row td:nth-child(2) {
  padding-left: 20px !important;
}

.Class-Shedule-List-View tr.class-list-row.class-upcoming-row:last-child td,
.Class-Shedule-List-View tr.class-list-row.class-past-row:last-child td,
.Class-Shedule-List-View tr.class-list-row.class-canceled-row:last-child td,
.Class-Shedule-List-View tr.class-list-row.class-live-row:last-child td,
.class-list-row:last-child td {
  border-bottom: none !important;
}

.class-list-row {
  cursor: pointer;
}

/* Class Shedule List View End */

.class-list-view-poup-row,
.class-list-view-poup-row td {
  display: block;
  width: 100%;
}

.class-list-view-poup-row {
  position: absolute;
  z-index: 1;
  width: 370px !important;
  min-width: 370px !important;
  padding: 15px;
  max-width: 100%;
  left: 250px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  clear: both;
  background: #f9f8f7;
  border-radius: 2px;
  border: 1px solid #d5d5d5;
  margin-top: -10px;
}

.class-list-view-poup-row:after {
  content: "";
  border: 7px solid transparent;
  border-right-color: #fff;
  border-left: none;
  top: -7px;
  left: 20px;
  display: inline-block;
  position: absolute;
  top: -10px;
  transform: rotate(95deg);
}

.Class-Shedule-List-View {
  /* max-height: 630px;
  overflow: auto; */
}

.Class-Shedule-List-View {
  border: 1px solid #d7dee8;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%) !important;
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13) !important;
  box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%) !important;
  overflow: hidden;
}

.Class-Shedule-List-View .table-responsive {
  border-radius: 0 !important;
}

.popup-content,
[role="tooltip"].popup-content {
  width: 370px !important;
  min-width: 370px !important;
  padding: 15px;
  background: #f9f8f7;
  font-size: 12px;
}

.popup-arrow {
  color: #f9f8f7;
}

.class-list-view-pagination .pagination li {
  margin: 4px;
}

.class-list-view-pagination .pagination {
  margin-left: -4px;
}

.class-list-view-pagination .pagination .page-item .page-link {
  width: 30px;
  height: 30px;
  border: 1px solid #d7dee8;
  color: #484644;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  line-height: 1.2;
  background: #fff;
  text-align: center;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%) !important;
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13) !important;
  box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%) !important;
}

.class-list-view-pagination .pagination .page-item.active .page-link,
.class-list-view-pagination .pagination .page-item.active .page-link:hover {
  background: #467fcf;
  color: #fff;
  border-color: #467fcf;
}

.class-list-view-pagination .pagination .page-item.active .page-link:focus,
.class-list-view-pagination .pagination .page-item.active .page-link:focus {
  background: #06172a;
  color: #fff;
  border-color: #06172a;
}

.meeting-custom-calendar .button-grp .btn-white-bordered.active {
  background: #06172a !important;
  border-color: #06172a !important;
  color: #fff !important;
}

/*  */

.upload-drop-down-wrap {
  position: absolute;
  opacity: 0;
  transition: all 300ms;
}

.avtar-bot-lft .upload-drop-down-wrap {
  right: -10px;
  bottom: 30px;
}

.upload-drop-down-wrap .dropdown-toggle:after {
  display: none;
}

.upload-drop-down-wrap .dropdown-toggle {
  width: 30px;
  height: 30px;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%);
  cursor: pointer;
  overflow: hidden;
  font-size: 22px;
  padding: 0 !important;
}

.upload-drop-down-wrap .dropdown-toggle:hover {
  background: #e6ecf2;
}

.upload-drop-down-wrap .dropdown-toggle:focus {
  background: #d3dee9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.upload-drop-down-wrap .dropdown-toggle i {
  margin: 0;
}

.profile-cover-top .upload-drop-down-wrap {
  right: 15px;
  top: 15px;
}

.profile-cover-top:hover .upload-drop-down-wrap {
  opacity: 1;
}

.avtar-bottom:hover .upload-drop-down-wrap {
  opacity: 1;
}

.upload-drop-down-wrap .dropdown-menu {
  padding: 10px 0px;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
  top: 100% !important;
  right: 0 !important;
  left: auto !important;
  border: 1px solid #d7dee8;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%);
  font-size: 14px;
  color: #484644;
  line-height: 1.2;
  min-width: 250px;
  text-align: left;
}

.upload-drop-down-wrap .dropdown-menu ul {
  margin: 0;
}

.upload-drop-down-wrap .dropdown-menu ul li {
}

.upload-drop-down-wrap .dropdown-menu ul li i {
  font-size: 14px;
  min-width: 20px;
  display: inline-block;
}

.upload-image-option input {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  top: 0;
  left: 0;
  text-indent: -999999px;
}

.upload-image-option {
  position: relative;
  padding: 8px 20px;
}

.upload-drop-down-wrap .dropdown-menu ul li button {
  border: none;
  background: none;
  padding: 8px 20px;
  display: block;
  width: 100%;
  text-align: left;
}

.upload-image-option:hover,
.upload-drop-down-wrap .dropdown-menu ul li button:hover {
  background: #e6ecf2;
}

.upload-image-option:focus,
.upload-drop-down-wrap .dropdown-menu ul li button:focus {
  background: #d3dee9;
}

.avtar-bot-lft .upload-drop-down-wrap .dropdown-menu {
  left: 0 !important;
  right: auto !important;
}

.subject-list-card.subject-list-card-orange {
  background: #fff3e4;
  border-left: 4px solid #ffa500;
}

.subject-list-card.subject-list-card-orange:hover,
.subject-list-card.subject-list-card-orange.active {
  background: #f5e5d1;
  border-left: 4px solid #ffa500;
}

.subject-list-card.subject-list-card-orange:active,
.subject-list-card.subject-list-card-orange:focus {
  background: #eaceaa;
  border-left: 4px solid #ffa500;
}

.barImg i {
  font-size: 22px;
  color: #61605f;
}

.notfication-detail-body a {
  cursor: pointer !important;
}

.Tickets-card-v-2 .ticket-card-extra-info {
  z-index: 2;
}

.theme-form select.form-control[disabled] {
  background-image: none !important;
}

.tui-full-calendar-floating-layer {
  margin-left: 5px;
}

.react-date-picker__wrapper {
  border: none !important;
}

a.morelink {
  text-decoration: none;
  outline: none;
}

.morecontent span {
  display: none;
}

.ui.popup {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  z-index: 1900;
  border: 1px solid #d4d4d5;
  line-height: 1.4285em;
  max-width: 250px;
  background: #fff;
  padding: 0.833em 1em;
  font-weight: 400;
  font-style: normal;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
}

.ui.popup > .header {
  padding: 0;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1.14285714em;
  line-height: 1.2;
  font-weight: 700;
}

.ui.popup > .header + .content {
  padding-top: 0.5em;
}

.ui.popup:before {
  position: absolute;
  content: "";
  width: 0.71428571em;
  height: 0.71428571em;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 2;
  -webkit-box-shadow: 1px 1px 0 0 #bababc;
  box-shadow: 1px 1px 0 0 #bababc;
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]:before {
  pointer-events: none;
  position: absolute;
  content: "";
  font-size: 1rem;
  width: 0.71428571em;
  height: 0.71428571em;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 2;
  -webkit-box-shadow: 1px 1px 0 0 #bababc;
  box-shadow: 1px 1px 0 0 #bababc;
}

[data-tooltip]:after {
  pointer-events: none;
  content: attr(data-tooltip);
  position: absolute;
  text-transform: none;
  text-align: left;
  white-space: nowrap;
  font-size: 1rem;
  border: 1px solid #d4d4d5;
  line-height: 1.4285em;
  max-width: none;
  background: #fff;
  padding: 0.833em 1em;
  font-weight: 400;
  font-style: normal;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
  z-index: 1;
}

[data-tooltip]:not([data-position]):before {
  top: auto;
  right: auto;
  bottom: 100%;
  left: 50%;
  background: #fff;
  margin-left: -0.07142857rem;
  margin-bottom: 0.14285714rem;
}

[data-tooltip]:not([data-position]):after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 100%;
  margin-bottom: 0.5em;
}

[data-tooltip]:after,
[data-tooltip]:before {
  pointer-events: none;
  visibility: hidden;
}

[data-tooltip]:before {
  opacity: 0;
  -webkit-transform: rotate(45deg) scale(0) !important;
  transform: rotate(45deg) scale(0) !important;
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

[data-tooltip]:after {
  opacity: 1;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

[data-tooltip]:hover:after,
[data-tooltip]:hover:before {
  visibility: visible;
  pointer-events: auto;
}

[data-tooltip]:hover:before {
  -webkit-transform: rotate(45deg) scale(1) !important;
  transform: rotate(45deg) scale(1) !important;
  opacity: 1;
}

[data-tooltip]:after,
[data-tooltip][data-position="bottom center"]:after,
[data-tooltip][data-position="top center"]:after {
  -webkit-transform: translateX(-50%) scale(0) !important;
  transform: translateX(-50%) scale(0) !important;
}

[data-tooltip]:hover:after,
[data-tooltip][data-position="bottom center"]:hover:after {
  -webkit-transform: translateX(-50%) scale(1) !important;
  transform: translateX(-50%) scale(1) !important;
}

[data-tooltip][data-position="left center"]:after,
[data-tooltip][data-position="right center"]:after {
  -webkit-transform: translateY(-50%) scale(0) !important;
  transform: translateY(-50%) scale(0) !important;
}

[data-tooltip][data-position="left center"]:hover:after,
[data-tooltip][data-position="right center"]:hover:after {
  -webkit-transform: translateY(-50%) scale(1) !important;
  transform: translateY(-50%) scale(1) !important;
}

[data-tooltip][data-position="bottom left"]:after,
[data-tooltip][data-position="bottom right"]:after,
[data-tooltip][data-position="top left"]:after,
[data-tooltip][data-position="top right"]:after {
  -webkit-transform: scale(0) !important;
  transform: scale(0) !important;
}

[data-tooltip][data-position="bottom left"]:hover:after,
[data-tooltip][data-position="bottom right"]:hover:after,
[data-tooltip][data-position="top left"]:hover:after,
[data-tooltip][data-position="top right"]:hover:after {
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
}

[data-tooltip][data-inverted]:before {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

[data-tooltip][data-inverted]:before {
  background: #1b1c1d;
}

[data-tooltip][data-inverted]:after {
  background: #1b1c1d;
  color: #fff;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

[data-tooltip][data-inverted]:after .header {
  background-color: none;
  color: #fff;
}

[data-position="top center"][data-tooltip]:after {
  top: auto;
  right: auto;
  left: 50%;
  bottom: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-bottom: 0.5em;
}

[data-position="top center"][data-tooltip]:before {
  top: auto;
  right: auto;
  bottom: 100%;
  left: 50%;
  background: #fff;
  margin-left: -0.07142857rem;
  margin-bottom: 0.14285714rem;
}

[data-position="top left"][data-tooltip]:after {
  top: auto;
  right: auto;
  left: 0;
  bottom: 100%;
  margin-bottom: 0.5em;
}

[data-position="top left"][data-tooltip]:before {
  top: auto;
  right: auto;
  bottom: 100%;
  left: 1em;
  margin-left: -0.07142857rem;
  margin-bottom: 0.14285714rem;
}

[data-position="top right"][data-tooltip]:after {
  top: auto;
  left: auto;
  right: 0;
  bottom: 100%;
  margin-bottom: 0.5em;
}

[data-position="top right"][data-tooltip]:before {
  top: auto;
  left: auto;
  bottom: 100%;
  right: 1em;
  margin-left: -0.07142857rem;
  margin-bottom: 0.14285714rem;
}

[data-position="bottom center"][data-tooltip]:after {
  bottom: auto;
  right: auto;
  left: 50%;
  top: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 0.5em;
}

[data-position="bottom center"][data-tooltip]:before {
  bottom: auto;
  right: auto;
  top: 100%;
  left: 50%;
  margin-left: -0.07142857rem;
  margin-top: 0.14285714rem;
}

[data-position="bottom left"][data-tooltip]:after {
  left: 0;
  top: 100%;
  margin-top: 0.5em;
}

[data-position="bottom left"][data-tooltip]:before {
  bottom: auto;
  right: auto;
  top: 100%;
  left: 1em;
  margin-left: -0.07142857rem;
  margin-top: 0.14285714rem;
}

[data-position="bottom right"][data-tooltip]:after {
  right: 0;
  top: 100%;
  margin-top: 0.5em;
}

[data-position="bottom right"][data-tooltip]:before {
  bottom: auto;
  left: auto;
  top: 100%;
  right: 1em;
  margin-left: -0.14285714rem;
  margin-top: 0.07142857rem;
}

[data-position="left center"][data-tooltip]:after {
  right: 100%;
  top: 50%;
  margin-right: 0.5em;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

[data-position="left center"][data-tooltip]:before {
  right: 100%;
  top: 50%;
  margin-top: -0.14285714rem;
  margin-right: -0.07142857rem;
}

[data-position="right center"][data-tooltip]:after {
  left: 100%;
  top: 50%;
  margin-left: 0.5em;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

[data-position="right center"][data-tooltip]:before {
  left: 100%;
  top: 50%;
  margin-top: -0.07142857rem;
  margin-left: 0.14285714rem;
}

[data-position~="bottom"][data-tooltip]:before {
  background: #fff;
  -webkit-box-shadow: -1px -1px 0 0 #bababc;
  box-shadow: -1px -1px 0 0 #bababc;
}

[data-position="left center"][data-tooltip]:before {
  background: #fff;
  -webkit-box-shadow: 1px -1px 0 0 #bababc;
  box-shadow: 1px -1px 0 0 #bababc;
}

[data-position="right center"][data-tooltip]:before {
  background: #fff;
  -webkit-box-shadow: -1px 1px 0 0 #bababc;
  box-shadow: -1px 1px 0 0 #bababc;
}

[data-position~="top"][data-tooltip]:before {
  background: #fff;
}

[data-inverted][data-position~="bottom"][data-tooltip]:before {
  background: #1b1c1d;
  -webkit-box-shadow: -1px -1px 0 0 #bababc;
  box-shadow: -1px -1px 0 0 #bababc;
}

[data-inverted][data-position="left center"][data-tooltip]:before {
  background: #1b1c1d;
  -webkit-box-shadow: 1px -1px 0 0 #bababc;
  box-shadow: 1px -1px 0 0 #bababc;
}

[data-inverted][data-position="right center"][data-tooltip]:before {
  background: #1b1c1d;
  -webkit-box-shadow: -1px 1px 0 0 #bababc;
  box-shadow: -1px 1px 0 0 #bababc;
}

[data-inverted][data-position~="top"][data-tooltip]:before {
  background: #1b1c1d;
}

[data-position~="bottom"][data-tooltip]:before {
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

[data-position~="bottom"][data-tooltip]:after {
  -webkit-transform-origin: center top;
  transform-origin: center top;
}

[data-position="left center"][data-tooltip]:before {
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

[data-position="left center"][data-tooltip]:after {
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

[data-position="right center"][data-tooltip]:before {
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

[data-position="right center"][data-tooltip]:after {
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.ui.popup {
  margin: 0;
}

.ui.top.popup {
  margin: 0 0 0.71428571em;
}

.ui.top.left.popup {
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}

.ui.top.center.popup {
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

.ui.top.right.popup {
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
}

.ui.left.center.popup {
  margin: 0 0.71428571em 0 0;
  -webkit-transform-origin: right 50%;
  transform-origin: right 50%;
}

.ui.right.center.popup {
  margin: 0 0 0 0.71428571em;
  -webkit-transform-origin: left 50%;
  transform-origin: left 50%;
}

.ui.bottom.popup {
  margin: 0.71428571em 0 0;
}

.ui.bottom.left.popup {
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.ui.bottom.center.popup {
  -webkit-transform-origin: center top;
  transform-origin: center top;
}

.ui.bottom.right.popup {
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.ui.bottom.center.popup:before {
  margin-left: -0.30714286em;
  top: -0.30714286em;
  left: 50%;
  right: auto;
  bottom: auto;
  -webkit-box-shadow: -1px -1px 0 0 #bababc;
  box-shadow: -1px -1px 0 0 #bababc;
}

.ui.bottom.left.popup {
  margin-left: 0;
}

.ui.bottom.left.popup:before {
  top: -0.30714286em;
  left: 1em;
  right: auto;
  bottom: auto;
  margin-left: 0;
  -webkit-box-shadow: -1px -1px 0 0 #bababc;
  box-shadow: -1px -1px 0 0 #bababc;
}

.ui.bottom.right.popup {
  margin-right: 0;
}

.ui.bottom.right.popup:before {
  top: -0.30714286em;
  right: 1em;
  bottom: auto;
  left: auto;
  margin-left: 0;
  -webkit-box-shadow: -1px -1px 0 0 #bababc;
  box-shadow: -1px -1px 0 0 #bababc;
}

.ui.top.center.popup:before {
  top: auto;
  right: auto;
  bottom: -0.30714286em;
  left: 50%;
  margin-left: -0.30714286em;
}

.ui.top.left.popup {
  margin-left: 0;
}

.ui.top.left.popup:before {
  bottom: -0.30714286em;
  left: 1em;
  top: auto;
  right: auto;
  margin-left: 0;
}

.ui.top.right.popup {
  margin-right: 0;
}

.ui.top.right.popup:before {
  bottom: -0.30714286em;
  right: 1em;
  top: auto;
  left: auto;
  margin-left: 0;
}

.ui.left.center.popup:before {
  top: 50%;
  right: -0.30714286em;
  bottom: auto;
  left: auto;
  margin-top: -0.30714286em;
  -webkit-box-shadow: 1px -1px 0 0 #bababc;
  box-shadow: 1px -1px 0 0 #bababc;
}

.ui.right.center.popup:before {
  top: 50%;
  left: -0.30714286em;
  bottom: auto;
  right: auto;
  margin-top: -0.30714286em;
  -webkit-box-shadow: -1px 1px 0 0 #bababc;
  box-shadow: -1px 1px 0 0 #bababc;
}

.ui.bottom.popup:before {
  background: #fff;
}

.ui.left.center.popup:before,
.ui.right.center.popup:before {
  background: #fff;
}

.ui.top.popup:before {
  background: #fff;
}

.ui.inverted.bottom.popup:before {
  background: #1b1c1d;
}

.ui.inverted.left.center.popup:before,
.ui.inverted.right.center.popup:before {
  background: #1b1c1d;
}

.ui.inverted.top.popup:before {
  background: #1b1c1d;
}

.ui.popup > .ui.grid:not(.padded) {
  width: calc(100% + 1.75rem);
  margin: -0.7rem -0.875rem;
}

.ui.loading.popup {
  display: block;
  visibility: hidden;
  z-index: -1;
}

.ui.animating.popup,
.ui.visible.popup {
  display: block;
}

.ui.visible.popup {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.ui.basic.popup:before {
  display: none;
}

.ui.wide.popup {
  max-width: 350px;
}

.ui[class*="very wide"].popup {
  max-width: 550px;
}

@media only screen and (max-width: 767px) {
  .ui.wide.popup,
  .ui[class*="very wide"].popup {
    max-width: 250px;
  }
}

.ui.fluid.popup {
  width: 100%;
  max-width: none;
}

.ui.inverted.popup {
  background: #1b1c1d;
  color: #fff;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui.inverted.popup .header {
  background-color: none;
  color: #fff;
}

.ui.inverted.popup:before {
  background-color: #1b1c1d;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ui.flowing.popup {
  max-width: none;
}

.ui.mini.popup {
  font-size: 0.78571429rem;
}

.ui.tiny.popup {
  font-size: 0.85714286rem;
}

.ui.small.popup {
  font-size: 0.92857143rem;
}

.ui.popup {
  font-size: 1rem;
}

.ui.large.popup {
  font-size: 1.14285714rem;
}

.ui.huge.popup {
  font-size: 1.42857143rem;
}

/* css added by 18-5-2021 */
.form-group-date-picker > div {
  display: block;
  width: 100%;
  border: none !important;
  box-shadow: none;
}

.form-group-date-picker > div > div {
  display: flex;
  border: 1px solid #eef1f5;
  padding: 10px;
  box-shadow: none !important;
  outline: none !important;
  height: 34px;
  padding: 8px 0px 8px 15px !important;
  background: #eef1f5;
  border-radius: 0.25rem;
  font-size: 14px;
  color: #495057;
}

.form-group-date-picker > div input {
  padding: 0px !important;
  height: inherit !important;
}

.form-group-date-picker button {
  height: 32px;
  width: 40px;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0px;
  margin: 0px;
  position: relative;
}

.form-group-date-picker button svg {
  opacity: 0;
}

.form-group-date-picker button:before {
  position: absolute;
  content: "\f073";
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-weight: 300;
}

.form-group-date-picker fieldset {
  border: none !important;
}

.ui.popup {
  width: 370px;
  max-width: 100%;
  background: #f9f8f7;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  border-radius: 2px;
  font-size: 12px;
  font-weight: 2.5;
  border: 1px solid #d5d5d5;
}

.meeting-btns-list {
  margin-bottom: -4px;
}

.meeting-btns-list {
  margin: 0 -2px;
}

.meeting-btns-list li {
  margin: 0;
  padding: 0px 2px;
  margin-bottom: 4px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  min-width: 32%;
}

.meeting-btns-list li .btn-primary {
  min-width: inherit;
}

#spacer {
  width: 100%;
  height: auto;
  min-height: 100px;
  max-height: 150px;
}

.fullscreen {
  display: none !important;
}

.show-cls-custom:hover {
  cursor: pointer;
}

/* custom player css start */
.custom-vi-player .progress {
  background: #d7dee8;
  border-radius: 2px;
}

.custom-vi-player .progress-bar {
  background-color: #467fcf;
}

.video-item-group {
  display: flex;
  justify-content: space-between;
  margin-left: -4px;
  margin-right: -4px;
}

.video-item-group .btn {
  min-width: 68px;
  width: 100%;
  margin-right: 0;
  margin-bottom: 10px;
  padding: 10px 5px;
  font-size: 16px;
}

.custom-vi-player {
  margin-bottom: 20px;
}

.video-item-group p {
  margin-bottom: 0px;
  color: #484644;
  font-weight: 600;
  font-size: 14px;
}

.video-item {
  text-align: center;
  padding: 0px 4px;
}

.video-item.b-play {
  flex-grow: 1;
}

.b-play .btn.btn-white-bordered {
  background: #467fcf;
  color: #fff;
  border-color: #467fcf;
}

.lec-sec-text {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.lec-sec-footer {
  width: 100%;
  margin-top: auto;
}

.video-sec .card-list-inner:first-child {
  padding-bottom: 10px;
}

.video-item-group i {
  margin-right: 0px;
}

/* custom player css end */
@media screen and (max-width: 1650px) {
  .video-item-group p {
    font-size: 11px;
  }

  .video-item-group .btn {
    min-width: 40px;
  }
}

/* calendar css start page*/
.class-list-view-pagination {
  position: relative;
  z-index: 0;
}

.calendar-panel-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.calendar-panel-left {
  color: #467fcf;
  font-weight: 600;
}

.calendar-panel-left a {
  color: #484644;
}

.calendar-panel-body {
  padding: 0 0px 20px 0px;
  margin-bottom: 70px;
  max-height: calc(100vh - 99px);
  overflow: auto;
  height: 100vh;
}

.calendar-cart-item {
  position: relative;
  padding: 2px 15px;
  overflow-x: hidden;
  /* display: flex; */
  /* align-items: center; */
}

.calendar-cart-item button {
  color: #484644;
  background: transparent;
  border: none;
  padding: 0px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 210px;
  overflow: hidden;
}

.calendar-cart-item > i {
  margin-right: 5px;
  position: absolute;
  left: 15px;
  top: 12px;
}

.text-status-green i {
  color: #53b962;
}

.text-status-blue i {
  color: #1365f6;
}

.text-status-orange i {
  color: #ffa834;
}

.text-status-purple i {
  color: #b8285b;
}

.text-status-red i {
  color: #ff0202;
}

.calendar-cart-item.active i {
  font-weight: bold;
}

.event-calendar-left {
  max-width: 320px;
  width: 100%;
  position: absolute;
  min-height: -webkit-calc(100% - 48px);
  min-height: -moz-calc(100% - 48px);
  min-height: calc(100% - 48px);
  z-index: 2;
}

.event-calendar-right {
  max-width: -webkit-calc(100% - 320px);
  max-width: -moz-calc(100% - 320px);
  max-width: calc(100% - 320px);
  width: 100%;
  padding: 20px;
  position: relative;
  left: 320px;
}

body.hide-subject-sidebar .event-calendar-left {
  left: -252px;
}

body.hide-subject-sidebar .close-btn-sidebar {
  right: -30px;
}

.hide-subject-sidebar .event-calendar-right {
  max-width: 100%;
  left: 0;
  padding-left: 40px;
}

.filter label {
  margin: 0px;
  padding: 0px 10px;
}

.filter select {
  border: 1px solid #d7dee8;
  padding: 5px 5px;
  line-height: 1.2;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 2.97px 0.03px hsla(0, 0%, 49%, 0.13) !important;
  width: 98px;
  outline: none;
}

.status {
  text-align: right;
}

.event-calendar-right .tui-full-calendar-weekday-schedule .status {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
}

.event-calendar-right .tui-full-calendar-weekday-schedule {
  background: #ffecc9 !important;
  border: 1px solid #cfcfcf !important;
}

.event-calendar-right .tui-full-calendar-weekday-schedule-title {
  font-weight: 600 !important;
  font-size: 14px;
  font-weight: 600;
}

.event-calendar-right .assessment-title {
  font-size: 14px;
  font-weight: 600;
  margin: 3px 0px;
}

.event-calendar-right .meeting-title-box .status {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 4px;
}

.event-calendar-right .tui-full-calendar-weekday-collapse-btn,
.event-calendar-right .tui-full-calendar-weekday-exceed-in-week {
  z-index: 2 !important;
}

@media screen and (max-width: 1450px) {
  .event-calendar-right .filter select {
    width: 88px;
    font-size: 12px;
  }

  .meeting-custom-calendar .button-grp .btn-white-bordered {
    font-size: 12px;
  }

  .meeting-custom-calendar .button-grp #next-button,
  .meeting-custom-calendar .button-grp #prev-button {
    max-width: 32px;
    min-width: 32px;
  }

  .event-calendar-right .calendar-view-buttons .btn {
    font-size: 12px;
  }

  .event-calendar-right .meeting-custom-calendar .custom-control {
    font-size: 12px;
  }

  .event-calendar-right .current-date {
    font-size: 11px;
  }

  .event-calendar-right
    .meeting-custom-calendar
    .button-grp
    .btn-white-bordered {
    min-width: 60px;
  }
}

@media screen and (max-width: 1290px) {
  .event-calendar-right .meeting-custom-calendar .button-grp {
    flex-wrap: wrap;
  }

  .event-calendar-right .calendar-view-buttons {
    flex: 0 0 100%;
    padding: 5px 0px;
  }

  .event-calendar-right .calendar-view-buttons .btn {
    margin: 10px 0px;
  }

  .event-calendar-right .filter {
    margin-left: auto;
  }
}

@media screen and (max-width: 1299px) {
  .event-calendar-right
    .meeting-custom-calendar
    .button-grp
    select.form-control {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .event-calendar-left {
    left: -252px;
  }

  body.hide-subject-sidebar .event-calendar-left {
    left: 68px;
  }

  .event-calendar-right {
    max-width: 100%;
    left: 0;
    padding-left: 40px;
  }

  .meeting-custom-calendar .custom-control {
    flex: 0 0 70%;
    max-width: 70%;
  }
}

@media screen and (max-width: 767px) {
  .event-calendar-left {
    left: -320px;
  }

  body.hide-subject-sidebar .event-calendar-left {
    left: 0px;
  }

  .event-calendar-right .meeting-custom-calendar .filter {
    flex: 0 0 100%;
  }

  .filter label {
    padding: 0px 10px 0px 0px;
  }

  .event-calendar-right .calendar-view-buttons {
    justify-content: space-between;
  }

  .event-calendar-right
    .meeting-custom-calendar
    .button-grp
    .btn-white-bordered {
    font-size: 10px;
    min-width: 50px;
    padding: 5px 5px;
  }

  .event-calendar-right .calendar-view-buttons .btn {
    font-size: 10px;
  }
}

.assessment-list-row td {
  background: #ffecc9 !important;
  border-color: #ffecc9 !important;
}

.assessment-list-row td .meeting-title {
  display: flex;
}

.class-list-row td .meeting-title {
  display: flex;
}

.assessment-list-row:hover td {
  background: #f3cf8c !important;
}

.class-list-row .status {
  margin-left: auto;
}

.assessments-result-box {
  display: flex;
  align-items: self-start;
  line-height: 1.1;
  white-space: nowrap;
}

.assessments-result-box i {
  margin-right: 4px !important;
  line-height: 1.1;
  font-size: 10px;
  padding-top: 2px;
}

.event-calendar-right .calendar-view-buttons {
  display: flex;
  flex-wrap: wrap;
}

.assessments-result-box i.red {
  color: #f11c1c;
}

.assessments-result-box i.green {
  color: #5cb85c;
}

.assessments-result-box i.yellow {
  color: #ffa500;
}

.card-assessments-table.card-table-custom .rdt_TableRow .btn {
  padding: 0px 5px;
}

@media screen and (max-width: 1798px) {
  /* .card-assessments-table.card-table-custom .rdt_TableCol:nth-child(8),
  .card-assessments-table.card-table-custom
    .rdt_TableRow
    .rdt_TableCell:nth-child(8) {
    max-width: 130px;
    min-width: 130px;
  } */
  .card-assessments-table.card-table-custom .assessment_buttons .btn {
    min-width: 57px;
    padding: 0px 5px;
  }

  .assessment_buttons .btn i {
    margin-right: 2px;
  }

  .card-assessments-table.card-table-custom .rdt_TableCol,
  .card-table-custom .dqLrnR,
  .card-table-custom .rdt_TableCol {
    /*font-size: 13px !important;*/
    padding: 0px 5px;
  }

  .card-assessments-table.card-table-custom .rdt_TableRow .rdt_TableCell {
    padding: 0 5px;
    /*font-size: 12px;*/
  }

  /* .card-assessments-table.card-table-custom .rdt_TableRow .rdt_TableCell:nth-child(2),
  .card-assessments-table.card-table-custom .rdt_TableCol:nth-child(2) {
    max-width: 90px;
    width: 90px;
  } */

  /* .card-assessments-table.card-table-custom .rdt_TableRow .rdt_TableCell:nth-child(3),
  .card-assessments-table.card-table-custom .rdt_TableCol:nth-child(3) {
    max-width: 90px;
    width: 90px;
  } */

  /* .card-assessments-table.card-table-custom .rdt_TableRow .rdt_TableCell:nth-child(4),
  .card-assessments-table.card-table-custom .rdt_TableCol:nth-child(4) {
    max-width: 90px;
    width: 90px;
  } */

  /* .card-assessments-table.card-table-custom .rdt_TableRow .rdt_TableCell:nth-child(6),
  .card-assessments-table.card-table-custom .rdt_TableCol:nth-child(6) {
    max-width: 152px !important;
    width: 152px !important;
  } */

  /* .card-assessments-table.card-table-custom .rdt_TableRow .rdt_TableCell:nth-child(5),
  .card-assessments-table.card-table-custom .rdt_TableCol:nth-child(5) {
    max-width: 50px;
    width: 50px;
  } */
}

@media only screen and (max-width: 1470px) {
  /* .card-assessments-table.card-table-custom .rdt_TableCol:first-child,
   .card-assessments-table.card-table-custom .rdt_TableRow .rdt_TableCell:first-child {
    min-width: 100px;
    max-width: 100px;
  } */
}

.without-header .sidebar-right {
  margin-left: 0px !important;
}

.without-header .sub-detail-lft {
  top: 0px !important;
}

/* setting page css */
@media only screen and (min-width: 1499px) {
  .settings-section {
    max-width: 1399px;
    margin: 0 auto;
  }
}

.settings-section {
  padding: 20px;
}

.settings-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 13px;
}

.settings-body {
  padding: 20px;
  background: #fff;
  border: 1px solid #d7dee8;
  border-radius: 3px;
  box-shadow: 0px 2px 3px rgba(125, 125, 125, 13%);
}

.item-label {
  font-weight: bold;
  margin-bottom: 13px;
}

select.selectcontrol {
  border: 1px solid #eef1f5;
  background-color: #eef1f5;
  width: 404px;
  box-shadow: none;
  outline: none;
}

select.selectcontrol:focus {
  box-shadow: none;
  border-color: #eef1f5;
}

.form-item + .form-item {
  margin-top: 15px;
}

/*  Toggle Switch  */
.toggleSwitch span span {
  display: none;
}

.toggleSwitch {
  display: inline-block;
  height: 22px;
  position: relative;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  width: 40px;
  margin-bottom: 0px;
}

.toggleSwitch * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.toggleSwitch label,
.toggleSwitch > span {
  line-height: 20px;
  height: 20px;
  vertical-align: middle;
}

.toggleSwitch input:focus ~ a,
.toggleSwitch input:focus + label {
  outline: none;
}

.toggleSwitch label {
  position: relative;
  z-index: 3;
  display: block;
  width: 100%;
}

.toggleSwitch input {
  position: absolute;
  opacity: 0;
  z-index: 5;
}

.toggleSwitch > span {
  position: absolute;
  left: -40px;
  width: 100%;
  margin: 0;
  padding-right: 40px;
  text-align: left;
  white-space: nowrap;
}

.toggleSwitch > span span {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: block;
  width: 50%;
  margin-left: 50px;
  text-align: left;
  font-size: 13px;
  width: 100%;
  left: 15%;
  top: -2px;
  opacity: 0;
}

.toggleSwitch a {
  position: absolute;
  right: 50%;
  z-index: 4;
  display: block;
  height: 14px;
  padding: 0;
  left: 5px;
  width: 14px;
  top: 2px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.toggleSwitch > span span:first-of-type {
  color: #ccc;
  opacity: 1;
  left: 27%;
}

.toggleSwitch > span:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 40px;
  top: -2px;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 30px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.toggleSwitch input:checked ~ a {
  border-color: #fff;
  left: 100%;
  margin-left: -18px;
  top: 2px;
}

.toggleSwitch input:checked ~ span:before {
  border-color: #467fcf;
  box-shadow: inset 0 0 0 30px #467fcf;
  background: #467fcf;
}

.toggleSwitch input:checked ~ span span:first-of-type {
  opacity: 0;
}

.toggleSwitch input:checked ~ span span:last-of-type {
  opacity: 1;
  color: #fff;
}

.save-setting {
  border-top: 1px solid #d7dee8;
  padding-top: 20px;
}

.save-setting .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1199px) {
  select.selectcontrol {
    width: 204px;
  }
}

@media only screen and (max-width: 767px) {
  .form-item {
    display: block !important;
  }

  .secondary-label {
    margin-bottom: 10px;
  }
}

.calendar-cart-item:hover {
  background: var(--hover-color);
}

.f-bold {
  font-weight: bold;
}

/* Help Center page css */
@media only screen and (min-width: 1499px) {
  .sec-help-center {
    max-width: 1399px;
    margin: 0 auto;
  }
}

.card-simple {
  padding: 20px;
  background: #fff;
  border: 1px solid #d7dee8;
  border-radius: 3px;
  box-shadow: 0px 2px 3px rgb(125 125 125 / 13%);
}

.card-simple-header {
  font-weight: bold;
  margin-bottom: 15px;
  align-items: center;
}

.card-simple-header .ttl-left {
  line-height: 1.2;
  font-size: 18px;
  color: #252423;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.card-simple-header.border-bottom {
  border-bottom: 1px solid #e6ebf1;
}

.sec-help-center {
  padding: 20px;
}

.vido-box {
  position: relative;
}

.vido-box:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgb(35 31 32 / 43%);
}

.video-column .ply-video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  z-index: 1;
  font-size: 24px;
  -webkit-transition: 300ms all;
  transition: 300ms all;
}

.video-column:hover .ply-video {
  background: #467fcf;
}

.card-list-item .lead-text {
  max-width: 310px;
}

.card-list-simple .secondary-label {
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: 600;
  padding-top: 5px;
}

.card-list-simple + .card-list-simple {
  border-top: 1px solid #d7dee8;
  padding-top: 15px;
}

.card-media-text a {
  display: block;
  color: #252423;
}

.card-list-ic {
  position: relative;
}

.card-video-inner .card-list-ic {
  border-radius: 3px;
}

.card-video-inner:hover .card-list-ic {
  background: #f1f1f1;
}

.card-list-ic .ab-link {
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.card-list-item:hover .card-media-text p {
  color: #467fcf;
}

.list-group-video .card-list-item + .card-list-item {
  margin-top: 15px;
}

.card-simple-header .ml-auto {
  font-weight: normal;
}

.card-list-simple.mb-3 {
  margin-bottom: 0px !important;
}

.pa6 {
  margin-bottom: 20px;
}

.sec-watch-video .card-list-item:hover .card-media-text p {
  color: #252423;
}

@media only screen and (max-width: 991px) {
  .video-column {
    margin-bottom: 30px;
  }

  .card-simple {
    padding: 10px;
  }

  .pa6 {
    margin-bottom: 10px;
  }

  .card-video-inner .card-list-ic {
    margin-bottom: 0px;
  }
}

label.toggleSwitch.Switchcalendar {
  display: flex;
  align-items: center;
}

.show-all {
  display: flex;
  white-space: nowrap;
  align-items: center;
  margin-left: -64px;
  margin-top: -2px;
  color: #4a7dbf;
}

@media only screen and (min-width: 991px) {
  .event-calendar-left .close-btn-sidebar {
    display: none;
  }
}

.toggleSwitch.toggleSwitch-list {
  height: 17px;
}

.event-calendar-left .fal.fa-eye {
  opacity: 0;
}

.calendar-cart-item-inner {
  width: 100%;
  border-left: 3px solid;
  display: flex;
  padding: 8px 0px 8px 15px;
  align-items: center;
}

.calendar-cart-item.border-darkred .calendar-cart-item-inner {
  border-color: #891822;
}

.calendar-cart-item.border-darkred .toggleSwitch input:checked ~ span:before {
  border-color: #891822;
  box-shadow: inset 0 0 0 30px #891822;
}

@media only screen and (max-width: 991px) {
  .calendar-panel-title {
    padding: 23px 38px 15px 15px;
  }
}

.flexible-modal {
  height: auto !important;
  z-index: 99 !important;
}

.flexible-modal .close-icon {
  background-color: #ffffff;
  border-radius: 0;
  right: 10px;
  top: 10px;
  color: #06172a;
}

.flexible-modal .react-player {
  height: 100% !important;
  background: #fff !important;
}

@media only screen and (max-width: 767px) {
  .flexible-modal {
    width: 100% !important;
    display: flex !important;
    left: 0px !important;
    top: 60px !important;
  }

  .card-simple-header {
    display: block !important;
  }

  .card-simple-header span {
    display: block;
  }
}

.flexible-modal-mask {
  z-index: 2 !important;
}

.flexible-modal {
  z-index: 99;
}

calendar-cart-item-inner {
  position: relative;
  padding-right: 30px;
}

.calendar-checkbox {
  position: absolute;
  right: 3px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: 10px;
  padding: 2px;
  top: 10px;
}

.calendar-checkbox input[type="checkbox"] {
  width: 0;
  height: 0;
  cursor: pointer;
}

.calendar-checkbox input[type="checkbox"]:after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  background: #fff;
}

.calendar-checkbox input[type="checkbox"]:checked:after {
  background: none;
}

.calendar-checkbox input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 6px;
  width: 6px;
  height: 11px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.flexible-modal .close-icon {
  background-color: #ffffffa8;
  border-radius: 0;
  right: 10px;
  top: 10px;
  color: #06172a;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
9627
.calendar-checkbox {
 border-radius: 50%;
 right: 5px;
 top: 10px;
}

9641
.calendar-checkbox input[type='checkbox']:after {
  border-radius: 50%;
}
9330
.toggleSwitch a {
  top: 2px;
}
9368
.toggleSwitch input:checked ~ a {
  top: 2px;
}
9373
.toggleSwitch input:checked~span:before {
  background: #467fcf;
}
*/

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

.ticket-view-title h3 {
  padding-right: 24px;
}

.ticket-view-title {
  border-right: 1px solid #cbced0;
  margin-right: 24px;
}

@media only screen and (max-width: 767px) {
  .ticket-view-title {
    margin-bottom: 15px;
    border-right: 0px;
  }

  .ticket-view-btn .add-new-ticket-bx {
    margin-left: 0;
  }

  .ticket-view-title .Tickets-head-lft {
    margin-right: 10px;
  }

  .ticket-view-btn .add-new-ticket-bx {
    margin-left: 0;
  }
}

.block-heading {
  margin-bottom: 30px;
}

.block-heading h2 {
  font-size: 32px;
  color: #537fc9;
  font-weight: bold;
  max-width: 290px;
}

.orientation-master-lead {
  margin-bottom: 30px;
}

.bluetext {
  font-weight: bold;
  color: #537fc9;
}

.courses-card-simple-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.courses-card-simple-header h3 {
  margin: 0;
}

.courses-card-simple h3 {
  font-size: 26px;
}

.icon-bx {
  margin-left: auto;
  width: 38px;
  height: 38px;
  flex: 0 0 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgb(255 255 255 / 33%);
}

.courses-card-simple {
  color: #fff;
  border-radius: 39px;
  padding: 30px;
  height: 100%;
}

.card-red {
  background: #ed2617;
}

.card-orange {
  background: #ef8d39;
}

.card-blue {
  background: #537fc9;
}

.courses-card-simple-body h3 {
  font-weight: bold;
  margin-bottom: 5px;
}

.courses-card-simple-body p {
  margin-bottom: 5px;
}

.courses-card-simple-footer p {
  margin: 0px;
}

.courses-card-simple-footer {
  display: flex;
}

.courses-card-simple-footer .btn {
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-color: #fff;
  margin-left: 15px;
  height: 32px;
}

.card-orange .courses-card-simple-footer .btn {
  color: #ef8d39;
}

.card-blue .courses-card-simple-footer .btn {
  color: #537fc9;
}

.courses-card-simple .courses-card-simple-footer .btn:hover {
  color: #fff;
  background: transparent;
}

.card-campus {
  margin-bottom: 30px;
}

.text-para-blue {
  color: #537fc9;
}

.class-shedule-date p {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: bold;
}

.class-shedule-date {
  text-transform: uppercase;
  flex: 0 0 80px;
  max-width: 80px;
  margin-right: 20px;
  position: relative;
}

.class-shedule-date h3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}

.class-shedule-date small {
  font-size: 13px;
}

.card-agenda {
  display: flex;
}

.context-agenda-right p {
  margin: 0px;
}

.m-guter {
  background: #537fc9;
  color: #fff;
  margin: 0px -20px;
  padding: 30px;
}

.card-shedule-row {
  margin-bottom: 30px;
}

.class-shedule-date:before {
  position: absolute;
  right: 0;
  top: 0;
  width: 2px;
  height: 50px;
  background: #fff;
  content: "";
}

.address-box p {
  margin-bottom: 5px;
}

.home-bx-card .icon {
  position: relative;
}

.notification-bg {
  border-radius: 100%;
  color: #fff;
  font-size: 10px;
  height: 28px;
  position: absolute;
  right: -1px;
  text-align: center;
  top: -2px;
  background: #ff0202;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #fff;
  line-height: 1.1;
  font-family: "Segoe UI";
  font-weight: 700;
}

.home-bx-card:hover .notification-bg {
  border-color: #e6ecf2;
}

.home-bx-card:active .notification-bg,
.home-bx-card:focus .notification-bg {
  border-color: #d3dee9;
}

.home-bx-card:active,
.home-bx-card:focus {
  background: #d3dee9;
  box-shadow: none;
  outline: none;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
}

@media only screen and (max-width: 991px) {
  .block-heading {
    margin-bottom: 15px;
  }

  .block-heading h2 {
    font-size: 24px;
    max-width: 100%;
  }

  .card-campus [class*="col-"] + [class*="col-"] {
    margin-top: 30px;
  }

  .m-guter [class*="col-"] + [class*="col-"] {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .courses-card-simple {
    border-radius: 5px;
    padding: 15px;
  }

  .courses-card-simple h3 {
    font-size: 18px;
  }

  .context-agenda-right p {
    margin-bottom: 0px;
  }

  .nav-right .nav-ul > li .count {
    right: -9px;
  }

  .nav-right .nav-ul > li + li {
    padding-left: 5px;
  }

  .sec-help-center .landing-heading.no-margin {
    display: flex;
  }

  .sec-help-center .lecture-detail-bottom {
    margin-bottom: 15px;
  }

  .sec-help-center .card-simple-header {
    padding-bottom: 15px;
  }

  .sec-help-center {
    margin-bottom: 50px;
  }
}

.sec-help-center .lecture-tabs-wrap .nav-pills .nav-link {
  font-weight: 500;
}

.sec-help-center .lecture-tabs-wrap .nav-pills .nav-link.active {
  font-weight: bold;
}

.card-profile-info-card .form-group {
  position: relative;
}

.exclamation {
  position: absolute;
  right: 10px;
  top: 36px;
  display: none;
  color: #dc3545;
}

.form-group.date-form-group .exclamation {
  top: 10px;
  right: 10px;
}

.form-group.date-form-group .exclamation i {
  font-weight: 400;
}

.form-group select.form-control.is-invalid ~ .exclamation {
  display: block;
}

.form-group select.form-control.is-invalid ~ .exclamation i {
  font-weight: 400;
}

.Tickets-detail-body .exclamation {
  right: 22px;
  top: 39px;
}

.atttach-file label:hover {
  background: #d7dee8;
}

select.form-control.is-invalid {
  background-position: calc(100% - 30px) 50% !important;
}

.nav-ul > li > a:hover .count {
  color: #fff;
}

.form-group-save-cancel .btn + .btn {
  margin-left: 10px;
}

.side-bar-nav > li > a.active .notify-count {
  border: 2px solid var(--highlight-color) !important;
}

/*.side-bar-nav > li > a.active .notify-count { border: 2px solid #467fcf !important;}*/

@media only screen and (max-width: 1399px) {
  .card.card-profile-info-card .form-group > label,
  .theme-form .form-group > label {
    margin: 0 0 10px !important;
  }
}

/* Filter css start */
.filter-button-group {
  display: flex;
  align-items: center;
  position: absolute;
  left: 222px;
  z-index: 1;
  top: 71px;
}

.filter-search-icon
  .data-table-extensions
  > .data-table-extensions-filter
  > input.filter-text {
  max-width: 167px;
  padding: 8px;
}

.filter-search-icon.card-table-custom .rdt_TableCol {
  padding: 0 20px;
}

.filter-buttons {
  background: transparent;
  border: 1px solid #fff;
  padding: 5px 5px;
  border-radius: 2px;
}

.filter-buttons:hover {
  background: #06172a;
  color: #fff;
  border: 1px solid #06172a;
}

.filter-eff button.filter-buttons:hover {
  background: inherit;
  color: inherit;
  border: 1px solid #fff;
}

.filter-button-group > div + div {
  margin-left: 8px;
}

.dropdown-comman .dropdown-menu {
  min-width: 210px;
  padding-top: 0;
  padding-bottom: 0;
}

.dropdown-comman .dropdown-menu li {
  padding: 0px 8px;
  font-size: 12px;
}

.dropdown-comman .dropdown-menu li:hover {
  background: #eff0f2;
}

.dropdown-comman .dropdown-menu li:hover input[type="checkbox"] + label:before {
  background: #d1d1d1;
}

.dropdown-comman .dropdown-menu li label {
  cursor: pointer;
  padding: 8px 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  width: 100%;
}

.dropdown-comman .dropdown-menu li + li label {
  border-top: 1px solid #eff0f2;
}

.dropdown-comman .btn {
  border-radius: 2px;
  /* font-size: 12px; */
  /* padding: 4px 6px; */
  /* background: #fff; */
  font-size: 14px;
  font-weight: 600;
  border-color: #e0edff;
  /* color: #06172A;*/
  /* box-shadow: none !important; */
}

.dropdown-comman .btn:hover {
  /* color: #000; */
  background: var(--active-color);
  border-color: var(--active-color);
}

.dropdown-comman .dropdown.show .btn {
  background: #06172a;
  color: #fff;
}

.button-reset .btn {
  background: #467fcf;
  color: #fff;
}

.item-text-blue {
  color: #1899d2;
}

.item-text-green {
  color: #00a654;
}

.item-text-yellow {
  color: #ffab2d;
}

.item-text-purple {
  color: #c41c2f;
}

.dropdown-comman .dropdown-toggle::after {
  display: none;
}

.dropdown-comman .dropdown-menu li label i {
  margin-right: 8px;
}

.dropdown-comman .dropdown.multiselect input[type="checkbox"] {
  display: none;
}

.dropdown-comman .dropdown.multiselect input[type="checkbox"] + label:before {
  content: "\f00c";
  width: 14px;
  height: 14px;
  display: inline-block;
  background: #e8e8e8;
  margin-right: 10px;
  border-radius: 2px;
  font-size: 0px;
  text-align: center;
  color: #fff;
  font-family: "Font Awesome 5 Pro";
  min-width: 14px;
}

.dropdown-comman
  .dropdown.multiselect
  input[type="checkbox"]:checked
  + label:before {
  content: "\f00c";
  background: #467fcf;
  font-size: 10px;
}

/* assessment types css start */
.as-red-type {
  color: #c41c2f;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.as-blue-type {
  color: #1899d2;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.as-orange-type {
  color: #ffab2d;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.as-green-type {
  color: #00a654;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.as-red-type i {
  margin-right: 10px;
}

.as-blue-type i {
  margin-right: 10px;
}

.as-orange-type i {
  margin-right: 10px;
}

.as-green-type i {
  margin-right: 10px;
}

.card-assessments-table.card-table-custom
  .rdt_TableRow
  .rdt_TableCell:nth-child(2),
.card-assessments-table.card-table-custom .rdt_TableCol:nth-child(2) {
  color: #1899d2;
}

.rdt_TableHeadRow {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.card-table-custom .rdt_TableRow:hover {
  background: #e6ecf2;
}

.card-table-custom .rdt_TableRow {
  border-left: 4px solid transparent !important;
  /* min-width: 1250px; */
}

.card-table-custom .rdt_TableRow:hover {
  border-color: #06172a !important;
}

.card.card-assessments-table.card-table-custom .as-red {
  color: #ff0000;
  background: #ffaaaa;
  padding: 3px 5px;
  border-radius: 2px;
  min-height: 23px;
  align-items: center;
}

.card.card-assessments-table.card-table-custom .as-blue {
  color: #1899d2;
  background: #e0edff;
  padding: 3px 5px;
  border-radius: 2px;
  min-height: 23px;
  align-items: center;
}

.card.card-assessments-table.card-table-custom .as-orange {
  color: #ffab2d;
  background: #ffeabf;
  padding: 3px 5px;
  border-radius: 2px;
  min-height: 23px;
  align-items: center;
}

.card.card-assessments-table.card-table-custom .as-green {
  color: #00c915;
  background: #d5ffd9;
  padding: 3px 5px;
  border-radius: 2px;
  min-height: 23px;
  align-items: center;
}

.card.card-assessments-table.card-table-custom .as-yellow {
  color: #ffab2d;
  background: #ffeabf;
  padding: 3px 5px;
  border-radius: 2px;
  min-height: 23px;
  align-items: center;
}

.card-table-custom .rdt_TableCol div span {
  /* font-size: 0;
  position: absolute;
  right: 0;
  padding: 2px;
  display: inline-block;
  margin-left: 10px;
  opacity: 1;
  transform: inherit !important; */
  font-size: 0px;
  position: absolute;
  right: 12px;
  width: 20px;
  min-height: 20px;
  padding: 2px;
  text-align: center;
  display: inline-block;
  margin-left: 0px;
  opacity: 1;
  -webkit-transform: inherit !important;
  transform: inherit !important;
}

.card-table-custom .rdt_TableCol_Sortable:hover div {
  background: #e9ecfb;
  color: #06172a !important;
}

.card-table-custom .rdt_TableCol_Sortable:hover span:before {
  opacity: 1;
  visibility: inherit;
}

.card-table-custom .rdt_TableCol_Sortable:hover.cqTtPc > div {
  background: #000;
}

.card-table-custom .rdt_TableCol_Sortable:hover span:before {
  color: #06172a;
}

.card-table-custom .as-red i {
  margin-right: 5px;
}

.card-table-custom .as-blue i {
  margin-right: 5px;
}

.card-table-custom .as-orange i {
  margin-right: 5x;
}

.card-table-custom .as-green i {
  margin-right: 5px;
}

.sc-giImIA button {
  border: 1px solid;
  border-radius: 0px;
  border-color: #eff0f2;
  width: 23px;
  height: 23px;
  position: relative;
}

.sc-giImIA button::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0px;
  right: 0;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Font Awesome 5 Pro";
  content: "";
}

.sc-giImIA button#pagination-first-page::before {
  content: "\f323";
}

.sc-giImIA button#pagination-last-page::before {
  content: "\f324";
}

.sc-giImIA button#pagination-previous-page::before {
  content: "\f053";
}

.sc-giImIA button#pagination-next-page::before {
  content: "\f054";
}

.sc-giImIA button svg {
  opacity: 0;
  display: none;
}

.sc-giImIA button + button {
  margin-left: 5px;
}

.rdt_Pagination select {
  border: 1px solid #eff0f2;
  color: #06172a;
  -webkit-appearance: none !important;
  appearance: none !important;
  /* background-image: url(/static/media/arrow-down.607a4deb.svg) !important; */
  background-repeat: no-repeat;
  background-position: center right 7px;
}

.rdt_Pagination > span {
  color: #06172a;
}

.rdt_Pagination > span.sc-ezredP.sc-kLgnNl {
  order: 3;
  margin: 0px 10px 0px 47px;
}

.sc-cxFLGX {
  order: 4;
}

.rdt_Pagination .sc-ezredP.sc-bYEvvW {
  display: none;
}

.rdt_Pagination select ~ svg {
  display: none;
}

.assessment_buttons .btn-warning.assessment_btn_yellow {
  display: flex;
  align-items: center;
}

.rdt_TableCol_Sortable {
  position: relative;
}

.rdt_TableCol_Sortable > div {
  background: #fff;
  color: #fff;
  border-radius: 2px;
  padding: 5px 18px 5px 8px;
  position: relative;
  left: -8px;
}

/* .card-table-custom .rdt_TableCol div span.dvajJS:before,
.card-table-custom .rdt_TableCol div span.KAizm:before,
.card-table-custom .rdt_TableCol div span.ljuikM:before{ content: "\f175";} */

.rdt_TableCol_Sortable.XTlfc>div,
/* .rdt_TableCol_Sortable.kAEIhD>div,  */
.rdt_TableCol_Sortable.cqTtPc>div,
.rdt_TableCol_Sortable.dmKtTB>div {
  background: #000;
  padding: 5px 20px 5px 5px;
  color: #fff;
}

.card-table-custom .rdt_TableCol_Sortable.XTlfc div ~ span:before,
.card-table-custom .rdt_TableCol_Sortable.kAEIhD div ~ span:before,
.card-table-custom .rdt_TableCol_Sortable.cqTtPc div ~ span:before,
.card-table-custom .rdt_TableCol_Sortable.kcxzBB div ~ span:before,
.card-table-custom .rdt_TableCol_Sortable.dmKtTB div ~ span:before {
  content: "\f175";
  opacity: 1;
  visibility: visible;
  right: 0;
}

.card-table-custom .rdt_TableCol_Sortable.XTlfc > div,
.card-table-custom .rdt_TableCol_Sortable.kAEIhD > div,
.card-table-custom .rdt_TableCol_Sortable.dmKtTB > div,
.card-table-custom .rdt_TableCol_Sortable.cqTtPc > div {
  color: #fff !important;
}

.rdt_TableCol_Sortable.XTlfc > span:before,
.rdt_TableCol_Sortable.kAEIhD > span:before,
.rdt_TableCol_Sortable.dmKtTB > span:before,
.rdt_TableCol_Sortable.cqTtPc > span:before {
  color: #fff;
}

/* .rdt_TableCol_Sortable.XTlfc> span, 
.rdt_TableCol_Sortable.kAEIhD> span,
.rdt_TableCol_Sortable.cqTtPc> span {  right: 5px !important;} */

.card-table-custom .eliEPG,
.card-table-custom .rdt_TableHeadRow {
  justify-content: space-between;
  border-top: 0px;
  border-left: 4px solid transparent;
}

/* Added by me */

@media only screen and (max-width: 1430px) {
  .rdt_Table {
    min-width: inherit;
  }
  .editor-text-overflow-ellipsis {
    max-width: 715px !important;
  }
}

@media only screen and (max-width: 767px) {
  .filter-button-group {
    position: static;
    margin-bottom: 15px;
  }

  .rdt_Table {
    min-width: inherit;
  }
  .editor-text-overflow-ellipsis {
    max-width: 250px !important;
  }
}

/* assessment types css end */

/* HOME ICON hover Changes */
.nav-ul > li > a > i {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.nav-ul > li > a:hover i {
  -webkit-transform: scale(1.3) translateZ(0);
  transform: scale(1.3) translateZ(0);
}

.side-bar-nav > li > a .nav-icon i,
.home-bx-card .icon img,
.home-bx-card .icon i,
.home-bx-card .icon svg {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.side-bar-nav > li:hover .nav-icon i {
  -webkit-transform: scale(1.3) translateZ(0);
  transform: scale(1.3) translateZ(0);
}

.home-bx-card:hover .icon img {
  -webkit-transform: scale(1.1) translateZ(0);
  transform: scale(1.1) translateZ(0);
}

.sec-search-notfication .form-control {
  width: 110px;
  border: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  height: 30px;
  padding: 6px 0px;
  box-shadow: none;
  font-size: 13px;
  font-weight: 300;
}

.sec-search-notfication {
  position: relative;
}

.fa-ic-search {
  position: absolute;
  left: -24px;
  top: 10px;
  color: var(--topbar-color);
}

.sec-search-notfication .form-control::-webkit-input-placeholder {
  color: #959595;
}

.sec-search-notfication .form-control:-ms-input-placeholder {
  color: #959595;
}

.sec-search-notfication .form-control::placeholder {
  color: #959595;
}

@media screen and (max-width: 991px) {
  .sec-search-notfication {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 25px;
    margin-top: 10px;
  }

  .sec-search-notfication .form-control {
    width: 100%;
  }

  .fa-ic-search {
    left: 0;
  }
}

/* Ticket page css START */
.sec-gap {
  padding: 40px;
}

.block-header {
  margin-bottom: 20px;
}

.block-sec-title h4 {
  font-size: 22px;
  font-weight: bold;
  color: #06172a;
}

.block-sec-title h4 span {
  color: #467fcf;
}

.ticket-row {
  display: flex;
}

.ticket-sidebar {
  flex: 0 0 400px;
  max-width: 400px;
}

.ticket-sec-right {
  flex: 1;
  padding-left: 38px;
}

.auth-info,
.assessments-message,
.ticket-footer-sec {
  padding: 0px 25px;
}

.ticket-card {
  border: 1px solid #d7dee8;
  padding: 25px 0px;
  min-height: 953px;
  height: 100%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}

.ticket-footer-sec {
  margin-top: auto;
}

.ticket-item-list {
  display: flex;
  justify-content: space-between;
}

.ticket-item h4 {
  color: #06172a;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
  width: 111px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ticket-item-list .ticket-item {
  position: relative;
}

.ticket-item-list .ticket-item {
  margin-bottom: 15px;
}

.ticket-item p {
  margin: 0px;
  color: #5b5b5b;
}

.author-status {
  font-size: 14px;
  font-weight: bold;
  border-radius: 2px;
  padding: 4px 7px;
  margin-left: 15px;
  position: absolute;
  right: 12px;
  display: inline-block;
  top: 0;
}

.g-green {
  background: #d5ffd9;
  color: #00c915;
}

.figure {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-right: 5px;
  font-size: 9px;
  flex: 0 0 23px;
}

.attachments-sec-row {
  display: flex;
  margin-bottom: 5px;
}

.attachments-thumb {
  flex: 0 0 27px;
  max-width: 27px;
  margin-right: 8px;
}

.attachments-thumb span {
  display: flex;
  width: 100%;
  height: 27px;
  background: #467fcf;
  border-radius: 2px;
  color: #fff;
  align-items: center;
  justify-content: center;
}

.attachments-links p {
  line-height: 1;
}

.attachments-links a {
  color: #467fcf;
}

.attachments-sec-row:hover .attachments-links a {
  color: #06172a;
}

.attachments-links small {
  font-size: 10px;
}

.assessments-message .ticket-item p + p {
  margin-top: 20px;
}

.notifi-meta p {
  display: flex;
  align-items: center;
}

.as-text-purple {
  color: #bf1e2e !important;
}

.ticket-item p i {
  font-size: 10px;
  margin-right: 5px;
}

.ticket-chat-block .comments-card.card {
  border: 1px solid #d7dee8;
  border-radius: 2px;
  height: 100%;
}

.ticket-chat-block .card-header {
  border: 0px;
  font-size: 18px;
  padding: 22px;
  font-weight: 600;
  color: #06172a;
  background: #fff;
  border-bottom: 1px solid #d7dee8;
  border-radius: 0px;
}

.ticket-chat-block .card-body {
  border: 0px;
  padding: 0px 27px;
  height: 100vh;
  max-height: 100%;
}

.ticket-chat-block .comment-conversation-list > li {
  margin: 28px 0;
}

.ticket-chat-block small.date {
  color: #5b5b5b;
  font-size: 14px;
  position: static;
}

.ticket-chat-block .comment-form-control {
  padding: 15px 22px;
  height: 68px !important;
  font-size: 14px;
  color: #5b5b5b;
}

.ticket-chat-block .comment-form-control::-webkit-input-placeholder {
  color: #5b5b5b;
  font-style: italic;
}

.ticket-chat-block .comment-form-control:-ms-input-placeholder {
  color: #5b5b5b;
  font-style: italic;
}

.ticket-chat-block .comment-form-control::placeholder {
  color: #5b5b5b;
  font-style: italic;
}

.ticket-chat-block {
  height: 100%;
}

.ticket-chat-block .comments-card-footer {
  border-radius: 0px;
  border: 0px;
  border-top: 1px solid #d7dee8;
  margin-top: auto;
}

.ticket-chat-block .comment-user-detail {
  background: transparent;
}

.border-radius-2 {
  border-radius: 2px;
  padding: 10px 20px;
}

.border-radius-2 div + div {
  margin-top: 15px;
}

.ticket-chat-block .posted-comment {
  border-radius: 0px;
  background: transparent;
  color: #fff;
  font-size: 14px;
  padding: 0px;
}

.ticket-chat-block .Attachment-area {
  padding: 0px;
}

.ticket-chat-block .Attachment-area,
.ticket-chat-block .Attachment-area a {
  color: #fff;
}

.ticket-chat-block .comment-user-other .border-radius-2 {
  background: #467fcf;
}

.ticket-chat-block .comment-user-current .border-radius-2 {
  background: #5b5b5b;
}

.ticket-chat-block .comment-user-current .border-radius-2 a {
  color: #fff;
}

.ticket-chat-block .comment-user-img {
  width: 53px;
  height: 53px;
}

.ticket-chat-block .comment-user-current small.date {
  text-align: left;
}

@media screen and (max-width: 1199px) {
  .sec-gap {
    padding: 20px;
  }

  .auth-info,
  .assessments-message,
  .ticket-footer-sec {
    padding: 0px 10px;
  }

  .ticket-card {
    padding: 10px 0px;
  }

  .ticket-sec-right {
    padding-left: 20px;
  }

  .author-status {
    position: static;
    margin-left: 0px;
    margin-top: 5px;
  }

  .ticket-sidebar {
    flex: 0 0 300px;
    max-width: 300px;
  }
}

@media only screen and (min-width: 992px) {
  .ticket-sidebar .close-btn-sidebar {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .editor-text-overflow-ellipsis {
    max-width: 450px !important;
  }
  .ticket-sidebar .close-btn-sidebar {
    display: block;
    right: -40px;
  }

  .ticket-sidebar {
    max-width: 290px;
    left: -245px;
    top: 49px;
    position: absolute;
    z-index: 1;
    max-height: calc(100vh - 50px);
  }

  .block-sec-title {
    padding-left: 15px;
  }

  .block-sec-title h4 {
    font-size: 16px;
  }

  .ticket-card {
    display: block;
    padding-bottom: 100px;
    min-height: inherit;
  }

  body.hide-subject-sidebar .ticket-sidebar {
    left: 68px;
    overflow: auto;
  }

  body.hide-subject-sidebar .ticket-sidebar .close-btn-sidebar {
    top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .ticket-sidebar {
    left: -300px;
  }

  body.hide-subject-sidebar .ticket-sidebar {
    left: 0px;
  }

  .ticket-chat-block .card-header {
    padding: 10px;
  }
}

/* Ticket page css START END */

.card-assessments-table.card-table-custom .rdt_TableCol,
.card-assessments-table.card-table-custom .rdt_TableRow .rdt_TableCell {
  padding: 0 10px;
  color: #06172a;
  font-size: 13px;
}

/* Datatable css start */
.item-text-red {
  color: #ff2f2f;
}

.your-assessments-main {
  padding: 30px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.assessment-table-card {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 30px;
  border-radius: 0.25rem;
}

.assessment-table-header {
  font-weight: 400;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 15px;
}

.dataTables_wrapper .dataTables_filter {
  float: left;
  margin-bottom: 10px;
  top: -20px;
  position: relative;
}

.dataTables_wrapper .dataTables_filter label {
  margin: 0px;
  position: relative;
}

.dataTables_wrapper .dataTables_filter label::before {
  position: absolute;
  content: "";
  left: 0;
  top: 9px;
  width: 20px;
  height: 20px;
  background-image: url(../images/data-table-search.svg);

  background-repeat: no-repeat;
}

.dataTables_wrapper .dataTables_filter input {
  border: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
  outline: none;
  margin-left: 25px;
  padding: 6px 0px;
  font-size: 13px;
  font-weight: 300;
  min-width: 178px;
  font-family: "Segoe UI";
  color: #959595;
}

.dataTables_wrapper .dataTables_filter input::-webkit-input-placeholder {
  color: #959595;
}

.dataTables_wrapper .dataTables_filter input:-ms-input-placeholder {
  color: #959595;
}

.dataTables_wrapper .dataTables_filter input::placeholder {
  color: #959595;
}

.dataTables_wrapper .dataTables_length {
  float: right;
  margin-left: 50px;
}

.dataTables_wrapper .dataTables_length label {
  margin-bottom: 0px;
}

.table.assessment-table-main thead th {
  background: #fff !important;
  color: #06172a !important;
  font-size: 14px !important;
  border-top: 0px;
  font-weight: 600 !important;
  border-bottom: 1px solid #f3f6f9 !important;
  padding: 20px 10px 30px 10px;
}

.table-striped.assessment-table-main tbody tr:nth-of-type(odd) {
  background-color: #eff0f2;
}

.assessment-table-main.no-footer {
  border-bottom: none !important;
  margin-bottom: 20px;
}

.assessment-table-main,
th,
.assessment-table-main td {
  border: none;
}

.table.assessment-table-main tbody td {
  font-size: 13px;
  color: #06172a;
}

table.assessment-table-main.dataTable thead th,
table.assessment-table-main.dataTable tbody td {
  white-space: nowrap;
}

table.assessment-table-main tbody tr td:first-child {
  border-left: 4px solid transparent;
}

table.assessment-table-main tbody tr:hover td:first-child {
  border-color: #06172a;
}

table.assessment-table-main tbody tr:hover {
  background-color: #e9ecfb !important;
}

table.table.assessment-table-main thead th div {
  display: inline-block;
  padding: 5px 0px;
  border-radius: 2px;
  position: relative;
}

table.table.assessment-table-main thead th div:before {
  position: absolute;
  right: 5px;
  font-size: 15px;
  top: 5px;
  font-weight: 300;
  opacity: 0;
  visibility: hidden;
  font-family: "Font Awesome 5 Pro";
}

table.table.assessment-table-main thead th.sorting.sorting_asc div,
table.table.assessment-table-main thead th.sorting.sorting_desc div {
  background: #06172a;
  color: #fff !important;
  padding: 5px 20px 5px 5px;
}

table.table.assessment-table-main
  thead
  th.sorting.sorting_asc
  div:hover::before {
  content: "\f176";
  font-weight: 300;
  font-family: "Font Awesome 5 Pro";
}

table.table.assessment-table-main
  thead
  th.sorting.sorting_desc
  div:hover::before {
  content: "\f175";
  font-weight: 300;
  font-family: "Font Awesome 5 Pro";
}

/*table.table.assessment-table-main thead th.sorting.sorting_asc div:hover,
table.table.assessment-table-main thead th.sorting.sorting_desc div:hover{ background: #e9ecfb; color: #394759 !important; }
*/
table.table.assessment-table-main thead th.sorting.sorting_asc div,
table.table.assessment-table-main thead th.sorting.sorting_desc div {
  color: #fff !important;
  padding: 5px 20px 5px 5px;
}

/* table.table.assessment-table-main thead th.sorting.sorting_desc div{ background: #e9ecfb;  color: #394759 !important;}
*/
table.table.assessment-table-main thead th.sorting.sorting_asc div:before {
  content: "\f176";
  opacity: 1;
  visibility: visible;
}

table.table.assessment-table-main thead th.sorting.sorting_desc div:before {
  content: "\f175";
  opacity: 1;
  visibility: visible;
}

.table.assessment-table-main tbody td p {
  margin: 0px;
}

.as-buttons {
  text-align: right;
}

.as-buttons .btn ~ .btn {
  margin-left: 5px;
  /* display: none; */
}

.assessment-01 span {
  font-weight: 600;
}

.assessment-01 i {
  margin-right: 5px;
  min-width: 15px;
}

.assessment-01 {
  max-width: 130px;
  white-space: normal;
}

.assessment-01 p {
  display: flex;
  align-items: center;
}

.as-widget {
  padding: 3px 5px;
  border-radius: 2px;
  /*min-height: 23px;*/
  display: inline-flex;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.as-widget-date {
  padding: 3px 5px;
  border-radius: 2px;
  /*min-height: 23px;*/
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
}

.oneline-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-all;
}

.as-widget i {
  margin-right: 5px;
  min-width: 10px;
}

.as-widget .fa-circle {
  font-size: 7px;
  vertical-align: middle;
}

.info-text {
  color: #1899d2;
}

.info-text a {
  color: #1899d2;
  text-decoration: underline !important;
}

.info-text a:hover {
  /*color: #007bff;*/
  color: #1899d2;
}
.as-regblue-bg{
  color: #fff;
  background: #3c78d7;
}
.as-cwred-bg{
  color: #fff;
  background: #d1313a;
}
.as-cwrej-bg{
  color: #fff;
  background: #f68042;
}
.as-repostyel-bg{
  color: #fff;
  background: #eba400;
}
.as-skpengreen-bg{
  color: #fff;
  background: #70a246;
}
.as-completegreen-bg{
  color: #fff;
  background: #298e5f;
}
.as-prelimrred-bg{
  color: #fff;
  background: #e45149;
}
.as-acadrejred-bg{
  color: #fff;
  background: #ed6151;
}
.as-accrejred-bg{
  color: #fff;
  background: #f68042;
}
.as-calledyel-bg{
  color: #fff;
  background: #f68e2c;
}
.as-ipyellow-bg{
  color: #fff;
  background: #eba400;
}
.as-quoteyel-bg{
  color: #fff;
  background: #d6a40d;
}
.as-appformgren-bg{
  color: #fff;
  background: #c4a417;
}
.as-cpgreen-bg{
  color: #fff;
  background: #afa423;
}
.as-cwgreen-bg{
  color: #fff;
  background: #9aa430;
}
.as-reregrejgren-bg{
  color: #fff;
  background: #8aa438;
}
.as-reportgreen-bg{
  color: #fff;
  background: #70a246;
}
.as-reregskpgreen-bg{
  color: #fff;
  background: #55a054;
}
.as-rcogreen-bg{
  color: #fff;
  background: #469b58;
}
.as-previewgreen-bg{
  color: #fff;
  background: #37955d;
}
.as-red-bg {
  background: #ff0000;
  color: #fff;
}
.as-grey-bg {
  background: #707070;
  color: #fff;
}

.as-blue-bg {
  background: #1899d2;
  color: #fff;
}

.as-orange-bg {
  background: #fba900;
  color: #fff;
}

.as-purpleshade-bg {
  background: #F3F0F0;
  color: #fff;
}

.as-redshade-bg {
  background: #F3F0F0;
  color: #fff;
}

.as-green-bg {
  background: #00c915;
  color: #fff;
}

.as-yellow-bg {
  /* background: #ffab2d; */
  background: #c5892f;
  color: #fff;
}

.as-ext-reg-blue-bg {
  background: #3334dd;
  color: #fff;
}
.as-ext-reg-red-bg {
  background: #dd3333;
  color: #fff;
}

.as-skyblue-bg {
  background: #40e0d0;
  color: #fff;
}

.as-yellowshade-bg {
  background: #abb072;
  color: #fff;
}

.as-lavendershade-bg {
  background: #8060C3;
  color: #fff;
}
.as-orangeshade-bg {
  background: #F66537;
  color: #fff;
}
.as-limeshade-bg {
  background: #3CC913;
  color: #fff;
}
.as-brown-bg {
  background: #875642;
  color: #fff;
}

.as-purple-bg {
  background: #9f1f63;
  color: #fff;
}

.as-text-blue {
  color: #1899d2;
}

.as-text-red {
  color: #c41c2f;
}

.as-text-orange {
  color: #ffab2d;
}

.as-text-green {
  color: #00a654;
}

.as-text-skyblue {
  color: #40e0d0;
}

.assessment-07 .as-widget {
  background: transparent;
  padding: 0;
  min-height: inherit;
}

.dataTables_paginate .paginate_button {
  border: 1px solid #eff0f2 !important;
  border-radius: 2px !important;
  border-color: #eff0f2;
  width: 23px;
  height: 23px;
  overflow: hidden;
  position: relative;
  font-size: 0;
}

.dataTables_paginate .paginate_button::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0px;
  right: 0;
  width: 23px;
  height: 23px;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  font-family: "Font Awesome 5 Pro";
  content: "";
}

.dataTables_paginate span {
  display: none;
}

.paginate_button.first::before {
  content: "\f323";
}

.paginate_button.previous::before {
  content: "\f053";
}

.paginate_button.next::before {
  content: "\f054";
}

.paginate_button.last::before {
  content: "\f324";
}

.dataTables_wrapper .dataTables_length select {
  border: 1px solid #eff0f2;
  color: #06172a;
  -webkit-appearance: none !important;
  appearance: none !important;
  /* background-image: url(../images/arrow-down.607a4deb.svg) !important; */
  background-repeat: no-repeat;
  padding-right: 20px;
  margin-left: 24px;
  outline: none;
  background-position: center right 7px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  opacity: 0.5;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #467fcf;
  border-color: #467fcf !important;
}

.dataTables_wrapper .dataTables_info {
  display: none;
}

.your-assessments-main .table-responsive {
  overflow-y: hidden;
}

.table-responsive-div {
  min-width: 1060px;
}

@media screen and (max-width: 768px) {
  div.dataTables_wrapper div.dataTables_filter {
    width: 100%;
    float: none;
    text-align: center;
  }

  .dataTables_wrapper .dataTables_length {
    float: none;
    margin-left: 0;
  }

  .table-responsive-div {
    min-width: inherit;
  }
}

.table-striped.assessment-table-main tbody tr:nth-of-type(even) {
  background-color: #f3f6f9;
}

.table-striped.assessment-table-main tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

/**********new css************/
/* .as-buttons .btn{box-shadow: none !important;} */

.assessment-03 p {
  font-size: 10px;
  text-transform: uppercase;
}

.info-text a:hover {
  text-decoration: underline !important;
}

.info-text a {
  text-decoration: none !important;
}

.dataTables_wrapper .dataTables_filter input[type="search"] {
  font-weight: 500;
}

table.table.assessment-table-main thead th div {
  display: inline-block;
  padding: 5px 20px 5px 0px;
}

.table.assessment-table-main thead th div:hover {
  background: #e9ecfb;
  color: #394759 !important;
  padding: 5px 20px 5px 5px;
}

/* global CSS */
.colorRed {
  color: red;
}

.table-striped.assessment-table-main tbody tr:nth-of-type(even) {
  background-color: #f3f6f9;
}

.colorGreen {
  color: green;
}

.table-striped.assessment-table-main tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

/* .edit-profile-icon-active i:before {content: "\f00d";} */
.edit-profile-icon-active .edit-icon .btn-danger i:before {
  content: "\f00d" !important;
}

/* done by above line  SP-209  */
.dataTables_wrapper .dataTables_filter input[type="search"] {
  font-weight: 500;
}

.edit-profile-icon-active .edit-profile-icon {
  background: #eef1f5;
  color: #252423;
}

table.table.assessment-table-main thead th.sorting div:hover:before {
  opacity: 1;
  visibility: visible;
  content: "\f0dc";
  font-weight: bold;
}

.subject-info-list > li {
  width: 50%;
  line-height: 1.2;
  padding: 0 5px;
  word-break: normal !important;
}

table.table.assessment-table-main thead th.sorting.sorting_asc div:hover,
table.table.assessment-table-main thead th.sorting.sorting_desc div:hover {
  background: #06172a;
  color: #fff !important;
}

.rdt_TableCol_Sortable.jvguKP > div,
.rdt_TableCol_Sortable.bqEkvt > div,
.rdt_TableCol_Sortable.dvsxvu > div {
  background: #000 !important;
  padding: 5px 20px 5px 5px !important;
  color: #fff !important;
}

.card-table-custom .rdt_TableHeader {
  order: -5;
  padding: 0;
  margin-bottom: 0px;
  min-height: inherit;
}

.card-table-custom .rdt_TableCol_Sortable.jvguKP div ~ span:before,
.card-table-custom .rdt_TableCol_Sortable.bqEkvt div ~ span:before,
.card-table-custom .rdt_TableCol_Sortable.dvsxvu div ~ span:before {
  content: "\f175" !important;
  opacity: 1 !important;
  visibility: visible !important;
  color: #fff;
  right: 5px;
}

.card-table-custom .rdt_TableCol_Sortable.jvguKP div ~ span.fmMaBA:before,
.card-table-custom .rdt_TableCol_Sortable.dvsxvu div ~ span.kcxzBB:before,
.card-table-custom .rdt_TableCol_Sortable.dvsxvu div ~ span.gqmYNu:before {
  content: "\f176" !important;
}

/* -----08-dec-21------ */
.login-box-w100 {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-position: center;
  position: relative;
}

.login-box-w100 .login-left {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 690px;
  padding: 40px 0 20px;
  position: relative;
}

.login-bg-mobile:before {
  position: absolute;
  content: "";
  background: var(--login-header-color);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  opacity: 1;
}

.login-box-w100 .login-right {
  width: calc(100% - 690px);
  position: fixed;
  right: 0;
  background: var(--active-color);
  top: 0;
}

.login-box-w100 .login-right .login-right-img {
  width: 100%;
  height: 100vh;
}

.login-box-w100 .login-right .login-right-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.login-left .card-body-inr.text-center {
  min-width: 442px;
  padding: 0 30px;
}

.login-left .portal-login-form .custom-control-label {
  color: #fff;
}

.login-left .portal-login-form small {
  color: #fff;
  margin-top: 5px;
  display: inline-block;
  font-size: 10px;
}

.login-left .portal-login-form small a {
  color: #fff;
}

/* .login-left .portal-login-form .input-group .form-control {background-color: #EEF1F5;font-size: 14px;line-height: 46px;height: 46px;font-weight: 400;border: none;padding: 12px 15px;min-height: 46px;border-radius: 1px;color: #000;} */
/* .login-left .portal-login-form .input-group .form-control::placeholder{color: #fff;} */
.login-left .portal-login-form .input-group i {
  color: #fff;
}

.login-left .portal-login-form .btn-white-bordered {
  background: var(--topbar-color);
  color: #fff;
  border: 1px solid var(--topbar-color);
}

.login-left .portal-login-form .btn-white-bordered:hover {
  background: var(--hover-color);
  border: 1px solid var(--hover-color);
}

.login-left .portal-login-info h3 {
  color: var(--topbar-color);
  font-weight: 400;
  font-size: 40px;
  margin: 5px 0px 30px 0px;
}

.login-left .portal-login-info p {
  color: #fff;
  letter-spacing: inherit;
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0 30px;
}

.login-left .portal-login-form .custom-control.custom-checkbox {
  margin: 30px 0px 28px;
  margin-left: 0px;
  text-align: center !important;
}

/* .login-left .portal-login-form .input-group .input-group-prepend .input-group-text {width: 35px;font-size: 24px;} */
.forgot-pass-bx {
  padding: 30px 0px;
}

.forgot-pass-bx a {
  font-size: 20px;
  color: var(--topbar-color);
  font-weight: 400;
}

/* .login-left button.btn.btn-white-bordered.btn-submit-info {padding: 0px 10px;text-transform: uppercase;font-size: 17px;font-weight: 400;height: 32px;line-height: 32px;display: inline-flex;align-items: center;} */
.login-box-w100 .portal-login-card .card-body-inr {
  max-width: 442px;
}

.login-box-w100 .smsMessage {
  color: #dc3545;
  padding-top: 3px;
}

.login-box-w100 .portal-login-form .custom-control-label {
  padding-left: 0px;
}

.login-box-w100 .custom-checkbox .custom-control-label::before,
.custom-checkbox .custom-control-label::after {
  top: 0px;
  width: 20px !important;
  height: 20px !important;
}

.card.home-bx-card:hover .icon {
  transform: scale(1.1) translateZ(0);
}

.card.home-bx-card .icon {
  transition: 0.3s ease-in-out;
}

.login-left .portal-login-form .input-group .form-control::placeholder {
  color: #666;
}

/* .login-left .portal-login-form .input-group {margin-bottom: 33px;} */
.login-left .portal-login-form .forgot-pass-bx {
  padding: 24px 0 0;
}

.login-bg-mobile {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  overflow: hidden;
  z-index: -1;
}

.login-bg-mobile img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.overflow-inherit {
  overflow: inherit;
}

.login-box-main.portal-login {
  height: 100vh;
  min-height: 100vh;
}

.login-left .portal-login-card .card-footer {
  background: transparent;
}

/* .notfication-body .card .card-body {
  max-height: 450px;
  overflow: auto;
} */
.notfication-body .card .card-body {
  max-height: 500px;
  overflow: auto;
}

.data-table-extensions > .data-table-extensions-filter > .icon {
  background: none;
  position: relative;
}

.data-table-extensions > .data-table-extensions-filter > .icon:before {
  position: absolute;
  content: "\f002";
  font-family: "Font Awesome 5 Pro";
  color: var(--topbar-color);
  top: 5px;
  left: 3px;
}

.notfication-body .card .card-body {
  max-height: 500px;
  overflow: auto;
}

.filter-search-icon
  .data-table-extensions
  > .data-table-extensions-filter
  > .icon {
  background: none;
  position: relative;
}

.filter-search-icon
  .data-table-extensions
  > .data-table-extensions-filter
  > .icon:before {
  content: "\f002";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-size: 15px;
  top: 10px;
  left: 3px;
  /*color: #467fcf;*/
}

/* .my-tickets-heading-blk h4.landing-heading {margin: 0 !important;} */
.my-tickets-heading-blk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-tickets-heading-blk .text-md-right p {
  margin: 0;
}

.filter-search-bar-blk {
  display: flex;
  justify-content: space-between;
  position: absolute;
  /*width: -webkit-fill-available;*/
  right: 30px;
  left: 0;
  /* margin-left: 242px; */
  margin-left: 165px;
  top: 42px;
  z-index: 1;
}

.filter-search-bar-blk .filter-button-group {
  position: relative;
  top: 0;
  left: -8px;
}

.my-tickets-heading-blk .filter-search-icon {
  position: relative;
}

.cat {
  font-size: 13px;
  display: flex;
  align-items: center;
}

.cat i {
  font-size: 8px;
  vertical-align: middle;
  position: relative;
  top: 1px;
}
.istyle i{
  font-size: 13px;
}
.istyle {
  margin-right: 10px;
}

.cat-yellow {
  color: #f7941d;
}

.cat-blue {
  color: #1899d2;
}

.cat-red {
  color: #bf1e2e;
}
.cat-light-pink {
  color: #e3a2bb;
}

.cat-dark-red {
  color: #9f1f63;
}

.cat-skyblue {
  color: #40e0d0;
}
.cat-extra-blue {
  color: #0c2ea0;
}

.cat-green {
  color: #00a654;
}
.cat-purple {
  color: #9847ff;
}
.cat-light-green {
  color: #95c621;
}

.cat-orange {
  color: #ffab2d;
}

.cat-lightgreen {
  color: #93faaf;
}

.cat-lightblue {
  color: #7da8b3;
}
.cat-dark-blue {
  color: #2c8bf7;
}

.cat-pink {
  color: #faafef;
}

.cat-blueshade {
  color: #585c75;
}

.cat-yellowshade {
  color: #abb072;
}

.cat-greenshade {
  color: #919c13;
}

.cat-brown {
  color: #875642;
}

.cat-pinkshade {
  color: #f7c1ab;
}

.cat-darkblue {
  color: #27698f;
}

.cat-darkyellow {
  color: #aba733;
}

.cat-greenhex {
  color: #00a651;
}

.my-tickets-info-list .as-text-blue a {
  color: #1899d2;
}

.my-tickets-info-list .as-text-blue a:hover {
  text-decoration: underline !important;
}

.ticket-id p {
  padding-left: 14px;
  position: relative;
}

.ticket-id p i {
  margin: 0;
  margin-left: 0;
  width: 16px;
  font-size: 15px;
  position: absolute;
  left: -5px;
  top: 2px;
  font-weight: 500;
}

.assigned-title-info {
  position: absolute;
  background: #fff;
  border: 1px solid #e0edff;
  z-index: 2;
  left: -270px;
  top: 5px;
  padding: 20px;
  min-width: 280px;
  border-radius: 2px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  visibility: hidden;
  opacity: 1;
  transition: all 0.5s ease 0s;
}

.transform-none { transform: none !important; }
.assigned-title-itm span.name-icon {
  width: 29px;
  height: 29px;
  min-width: 29px;
  background: #9f1f63;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  line-height: 29px;
  text-align: center;
  border-radius: 50%;
  font-family: system-ui;
  margin-right: 6px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.assigned-title-itm {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #06172a;
}

.assigned-title-blk:hover .assigned-title-info {
  opacity: 1;
  visibility: visible;
  transition: all 0s ease 1s;
}

.assigned-title-info:before {
  position: absolute;
  top: 0;
  height: 40px;
  background: #e0edff;
  width: 100%;
  left: 0;
  content: "";
}

.assigned-title-info article {
  position: relative;
  display: flex;
}

.assigned-title-info article figure {
  width: 29px;
  height: 29px;
  background: #9f1f63;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  line-height: 29px;
  text-align: center;
  border-radius: 50%;
  font-family: system-ui;
  margin-right: 10px;
  /* border: 1px solid #fff; */
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.assigned-title-info article figcaption {
  flex: 1;
}

.assigned-title-info article figcaption h4 {
  color: #06172a;
  font-size: 14px;
  font-weight: 400;
}

.assigned-title-info article figcaption p {
  font-size: 12px;
  color: #5b5b5b;
  margin-bottom: 5px !important;
}

.assigned-title-info article figcaption h6 {
  font-size: 12px;
  color: #06172a;
  font-weight: 700;
  margin: 0;
}

/* user hover right*/

.assigned-title-blk .assigned-title-info.popup-right {
  right: auto;
  left: 30px;
}

.assigned-title-info article figcaption a:active {
  font-weight: 400;
  color: #1899d2;
}

.assigned-title-info article figcaption a {
  /* display: inline-block; */
  display: block;
  color: #000;
}

.files-export-group {
  display: flex;
}

.files-export-group .btn-files {
  text-align: center;
  padding: 0px 0px;
  font-size: 20px;
}

.files-export-group .btn-files i {
  margin: 0;
}

/* user hover right*/

.wrap-box-right {
  padding: 0px;
  /* max-width: 1446px; */
  margin: 0 auto;
  margin-left: initial;
}

.my-tickets-detail-block .Tickets-detail .card-body.col-11 {
  min-width: 100%;
  padding: 32px;
}

.new-ticket-form.row .col-md-8 {
  display: flex;
  flex-direction: column;
}

.new-ticket-form.row .form-group.col-md-8 textarea {
  height: calc(100% - 42px);
}

.elHTkS,
.fpYUKa {
  min-height: 285px !important;
  min-height: calc(100vh - 550px) !important;
}

.rdt_TableBody .rdt_TableRow:last-child .assigned-title-info {
  top: -70px;
}

.rdt_TableBody .rdt_TableRow:nth-last-child(0n + 2) .assigned-title-info {
  top: -70px;
}

.rdt_TableBody .rdt_TableRow:nth-last-child(0n + 3) .assigned-title-info {
  top: -70px;
}

.Tickets-head-rgt-new h3 {
  font-size: 18px;
  color: #06172a;
  font-weight: 700;
}

.Tickets-head-rgt-new span {
  color: #5b5b5b;
}

.Tickets-head-rgt-new {
  margin-left: 20px;
  padding-left: 15px;
  border-left: 1px solid #d7dee8;
}

/* .comment-details,
.ticket-details {
  height: calc(100vh - 128px);
} */

.comment-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.comments-card-header {
  background-color: #fff;
  font-size: 16px;
  font-weight: 600;
  color: #06172a;
}

.comments-card-sub-header {
  background-color: #fff;
  color: #06172a;
}

.ticket-details {
  flex: 0 0 450px;
  max-width: 450px;
}

.ticket-details .ac-white-card {
  flex-direction: column;
  display: flex;
}

.comment-details {
  flex: 0 0 calc(100% - 450px);
  max-width: calc(100% - 450px);
  position: sticky;
  top: 40px;
  display: flex;
}

/* .ticket-details .description {
  height: 100%;
  overflow: auto;
} */

.assigned-title-right .assigned-title-info {
  right: -100%;
  left: auto;
}

.as-text-blue a {
  color: #1899d2;
}

.as-text-blue a:hover {
  text-decoration: underline !important;
}

.icon-w-box {
  width: 28px;
  height: 28px;
  background-color: #467fcf;
  color: #fff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doc-list-info {
  width: 100%;
  padding-left: 10px;
}

.doc-list-in .icon-w-box {
  min-width: 28px;
}

.doc-list .doc-list-info {
  margin-bottom: 10px;
}

.lb-black {
  color: #06172a;
}

.description {
  border-top: 1px solid #ccc;
}

.ticket-details {
  display: flex;
}

.ticket-details .ac-card {
}

.filter-buttons {
  pointer-events: none;
}

.bgRed {
  background: #bf1e2e !important;
}

.bgDarkRed {
  background: #9f1f63 !important;
}

.bgYellow {
  background: #f7941d !important;
}

.bgBlue {
  background: #1899d2 !important;
}

.bgSkyblue {
  background: #40e0d0 !important;
}

.bgGreen {
  background: #00a654 !important;
}

.bgOrange {
  background: #ffab2d !important;
}

.bgLightGreen {
  background: #93faaf !important;
}

.bgLightBlue {
  background: #7da8b3 !important;
}

.bgPink {
  background: #faafef !important;
}

.bgBlueShade {
  background: #585c75 !important;
}

.bgYellowShade {
  background: #abb072 !important;
}

.bgGreenShade {
  background: #919c13 !important;
}

.bgBrown {
  background: #875642 !important;
}

.bgPinkShade {
  background: #f7c1ab !important;
}

.bgDarkBlue {
  background: #27698f !important;
}

.bgDarkYellow {
  background: #aba733 !important;
}

.bgGreenHex {
  background: #00a651 !important;
}

/* global CSS END*/

/*.........New CSS ticket 21 Dec...........*/

.test-series-main-heading.icon-add {
  display: flex;
  align-items: center;
}

.test-series-main-heading h4 {
  font-size: 20px;
  color: #06172a;
  font-family: "Segoe UI";
  font-weight: 600;
  margin: 0;
}

.test-series-main-heading.icon-add span.test-series-main-left-heading-act {
  margin-left: 10px;
  line-height: normal;
}

.test-series-main-left-heading-act i {
  font-size: 14px;
  color: #cbced0;
  margin: 0 5px;
}

.test-series-main-left-heading-act i.active {
  color: #06172a;
}

.custom-breadcrumb ul {
  margin: 0;
  border: 1px solid #467fcf;
  display: inline-block;
  border-radius: 5px;
  padding: 12px 10px;
}

.custom-breadcrumb ul li.custom-breadcrumb-item {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  margin: 0 15px;
  position: relative;
  color: #06172a;
}

.custom-breadcrumb ul li.custom-breadcrumb-item:first-child {
  margin-left: 0;
}

.custom-breadcrumb ul li + li:before {
  content: "";
  position: absolute;
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  left: -20px;
  top: 2px;
  color: #06172a;
}

.custom-breadcrumb ul li.custom-breadcrumb-item a {
  color: #06172a;
}

.custom-breadcrumb ul li.custom-breadcrumb-item.active a {
  color: #467fcf;
  font-size: 15px;
  font-weight: 600;
}

.custom-breadcrumb ul li.custom-breadcrumb-item.active.done a {
  font-weight: 400;
}

.textseries-content-blk.white-brd-blk {
  background: #fff;
  border: 1px solid #cbced0;
  padding: 40px;
}

.textseries-info-details ul {
  padding: 0;
  list-style: none;
}

.textseries-info-details ul li {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.textseries-info-details ul li span.heading {
  font-size: 15px;
  font-weight: 600;
  min-width: 110px;
}

.ts-info-act i {
  font-size: 16px;
  color: #cbced0;
  margin: 0 5px;
}

.ts-info-act i.active {
  color: #06172a;
}

.ts-info-act {
  display: flex;
  align-items: center;
}

.ts-info-act button {
  padding: 0;
  margin-left: 5px;
  color: #467fcf !important;
  background: transparent !important;
  border: none;
  font-size: 17px;
}

.ts-info-act button i {
  color: inherit;
}

.innerblue-textseries-content.blue-bg-blk {
  background: #467fcf;
  color: #fff;
  padding: 30px 40px;
  border-radius: 10px;
}

.check-permission {
  display: flex;
}

.check-permission label {
  margin-left: 5px;
}

.textseries-info-details ul li span.content .as-text-red {
  color: #ff0c0f;
}

.count-list {
  padding-left: 15px;
  list-style: auto;
}

.btn-action-block button.btn-blue-bg {
  background: #467fcf;
  border-radius: 1px;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  padding: 5px 26px;
  border: 1px solid #467fcf;
  height: 44px;
}

/* .btn-action-block button.btn-blue-bg i {margin: 0 10px;} */
.btn.disabled,
.btn-action-block .btn:disabled {
  background: #cbced0;
  border-color: #cbced0;
  opacity: 1;
  cursor: auto;
}

.test-series-main-block {
  display: flex;
  justify-content: space-between;
}

.test-series-main-block .test-series-main-left-blk {
  width: calc(100% - 372px);
}

.test-series-main-block .test-series-main-right-blk {
  background: #fff;
  width: 306px;
  position: absolute;
  right: 0px;
  top: 113px;
  height: calc(100% - 113px);
  bottom: 0;
  padding: 20px;
}

.btn-action-block button.btn-darkblue-bg {
  font-size: 17px;
  background: #06172a;
  color: #fff;
  border-radius: 1px;
  font-weight: 600;
  height: 44px;
  padding: 5px 6px;
}

.test-series-act-info ul {
  list-style: none;
  padding: 0;
  text-align: end;
}

.test-series-act-info ul li {
  display: inline-block;
  font-size: 33px;
  color: #000000;
  margin: 0 5px;
  position: relative;
  width: 40px;
  height: 40px;
  text-align: center;
}

.test-series-act-info ul li.active:before {
  content: "";
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  border: 2px solid #f3f6f9;
  position: absolute;
  background: #467fcf;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  right: -5px;
  top: -5px;
  font-size: 8px;
  padding: 4px;
  color: #fff;
}

.test-series-steps ul {
  padding: 0;
  list-style: none;
  margin: 0 0px 0 -47px;
}

.test-series-steps ul li.test-series-steps-item.active a {
  color: #06172a;
}

.test-series-steps ul li.test-series-steps-item a {
  font-size: 20px;
  color: #cbced0;
  display: flex;
  align-items: center;
}

.test-series-steps ul li.test-series-steps-item a span {
  width: 56px;
  height: 56px;
  border: 2px solid #cbced0;
  color: #cbced0;
  font-size: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff;
  margin-right: 15px;
  position: relative;
}

.test-series-steps ul li {
  padding: 20px 0;
}

.test-series-steps ul li.test-series-steps-item.active a span {
  background: #467fcf;
  border-color: #467fcf;
  color: #fff;
}

.test-series-steps ul li.test-series-steps-item.active.done a span:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  width: 56px;
  height: 56px;
  position: absolute;
  background: #06172a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
}

.test-series-steps ul li:first-child {
  padding-top: 0;
}

.tooltip-inner {
  border: 1px solid #000;
  background-color: #fff;
  color: #575757;
  border-radius: 0;
}

.tooltip .arrow {
  display: none;
}

.custom-act-check input[type="checkbox"] {
  position: absolute;
  left: -20px;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  z-index: 2;
}

.custom-act-check input[type="checkbox"] + label {
  margin-left: 0;
}

.custom-act-check {
  position: relative;
}

.custom-act-check input[type="checkbox"] + label:before {
  content: "";
  width: 14px;
  height: 14px;
  background: #fff;
  position: absolute;
  left: -20px;
  border-radius: 1px;
  top: 2px;
}

.custom-act-check input[type="checkbox"]:checked + label:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  left: -18px;
  top: 3px;
  position: absolute;
  color: #467fcf;
  font-size: 10px;
}

.percent-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.percent-block h4 {
  font-size: 17px;
  font-weight: 400;
  color: #fff;
}

.percent {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 1000;
}

.percent .number {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.percent .number h2 {
  color: #fff;
  font-weight: 400;
  font-size: 17px;
  transition: 0.5s;
  margin: 0;
}

.percent .number h2 span {
  font-size: 24px;
  color: #777;
}

.percent svg {
  position: relative;
  width: 80px;
  height: 80px;
  z-index: 1000;
}

.percent svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: rgb(255 255 255 / 30%);
  stroke-width: 6px;
  stroke-linecap: round;
  transform: translate(5px, 5px);
}

.percent svg circle:nth-child(2) {
  stroke-dasharray: 218;
  stroke-dashoffset: 218;
  stroke: #ffffff;
}

.assessment-result-content h4 {
  font-size: 25px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 30px;
}

.ass-status {
  background: #fff;
  display: inline-block;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 5px;
  height: fit-content;
  margin-right: 10px;
}

.status-green {
  color: #58ba62;
}

.status-red {
  color: #ff0000;
}

.status-dark-red {
  color: #ba5858;
}

.ass-status i {
  margin-right: 5px;
}

.assessment-result-status {
  display: inline-flex;
  align-items: center;
}

.assessment-result-blk .row {
  align-items: center;
}

.assessment-result-release-blk h1 {
  font-size: 12px;
  text-align: center;
}

.result-countdown ul li {
  display: inline-block;
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 2px;
}

.result-countdown ul li span {
  display: block;
  background: rgb(255 255 255 / 46%);
  font-size: 35px;
  color: #ffffff;
  font-weight: 600;
  width: 74px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
}

.result-countdown ul {
  margin: 0;
  padding: 0;
  text-align: center;
}

.result-countdown ul li:nth-child(1) span {
  border-radius: 8px 0px 0px 8px;
}

.result-countdown ul li:nth-child(3) span {
  border-radius: 0 8px 8px 0;
}

.system-check-items ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.system-check-items ul li {
  flex: 33.33%;
  max-width: calc(33.33% - 10px);
  border: 1px solid #d7dee8;
  border-radius: 2px;
  background: #fff;
  text-align: center;
  padding: 50px 20px;
}

.system-check-items ul li p {
  font-size: 21px;
  color: #06172a;
  font-weight: 600;
  line-height: 22px;
}

.system-check-items ul li i {
  font-size: 87px;
  color: #000;
  margin-bottom: 8px;
  position: relative;
}

.system-check-items ul li.active i:after {
  content: "\f00c";
  position: absolute;
  background: #467fcf;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #fff;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  top: -15px;
  right: -25px;
}

.system-check-items ul li.non-active i:after {
  content: "\f00d";
  position: absolute;
  background: #ff0000;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #fff;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  top: -15px;
  right: -25px;
}

.std-identify-blk figure {
  margin: 0;
  height: 537px;
  position: relative;
  overflow: hidden;
}

.std-identify-blk figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.std-identify-blk figure i {
  position: absolute;
  font-size: 180px;
  color: #467fcf;
  right: auto;
  left: 50%;
  margin: 0 auto;
  top: 50%;
  bottom: auto;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.std-identify-blk p {
  font-size: 15px;
  color: #06172a;
}

.std-identify-blk .react-html5-camera-photo {
  position: initial;
}

.profile-test-blk figure {
  width: 325px;
  height: 215px;
  border: 5px solid #467fcf;
  margin: 0;
}

.profile-test-blk figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.innerblue-textseries-content.profile-test-blk {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.chart-canvas {
  position: relative;
}

.chart-canvas .chart-count {
  color: #fff;
  font-weight: 400;
  font-size: 17px;
  transition: 0.5s;
  position: absolute;
  right: auto;
  left: 50%;
  margin: 0 auto;
  top: 50%;
  bottom: auto;
  transform: translate(-50%, -50%);
}

.profile-doc-upload {
  position: relative;
  padding-bottom: 60px;
}

.profile-doc-upload input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.profile-doc-upload lable i {
  font-size: 55px;
  color: #467fcf;
  margin-bottom: 20px;
}

.profile-doc-upload lable h4 {
  color: #06172a;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
}

.profile-doc-upload lable h4 span {
  color: #467fcf;
}

.profile-doc-upload lable p {
  color: #484644;
  font-size: 15px;
  margin: 0;
}

.profile-doc-upload lable {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23467FCFFF' stroke-width='2' stroke-dasharray='5 10 ' stroke-dashoffset='5' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 8px;
  display: block;
  padding: 51px 20px;
  text-align: center;
  width: 100%;
  height: 100%;
}

.assessment-warning-blk {
  border: 1px solid #467fcf;
  border-radius: 6px;
  overflow: hidden;
  background: #fff;
}

.assessment-warning-blk h2 {
  font-size: 20px;
  color: #ffffff;
  background: #467fcf;
  padding: 7px 25px;
}

.assessment-warning-blk ul {
  margin: 0;
  padding: 20px;
  list-style: none;
}

.assessment-warning-blk article {
  display: flex;
  align-items: center;
}

.assessment-warning-blk article figure {
  width: 24px;
  height: 24px;
  background: #467fcf;
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0;
  margin-right: 10px;
}

.assessment-warning-blk article figcaption p {
  font-size: 12px;
  color: #707070;
  margin-bottom: 0px;
}

.assessment-warning-blk article figcaption h4 {
  font-size: 14px;
  color: #06172a;
  margin: 0;
}

.assessment-warning-blk article figcaption h4 span {
  color: #467fcf;
  font-weight: 600;
}

.assessment-warning-blk ul li + li {
  margin-top: 15px;
}

/*.........New CSS ticket 21 Dec...........*/
.rdt_TableRow .rdt_TableCell {
  padding-left: 20px;
  padding-right: 20px;
}

.filter-search-icon.card-table-custom .rdt_TableCol.dczDXf {
  padding-left: 12px;
}

.ticket-status-blk p {
  margin: 0;
}

.rdt_TableRow .kophWq {
  padding-left: 20px;
  padding-right: 20px;
}

.curser {
  cursor: pointer;
}

.side-bar-nav > li > a .nav-icon i,
.home-bx-card .icon img,
.home-bx-card .icon svg path {
  fill: var(--topbar-color);
}

.heading_color_dynamic span {
  color: var(--topbar-color) !important;
}

.card-table-custom .rdt_TableCol div span:before {
  position: absolute;
  content: "\f883";
  /* right: 6px; */
  right: 0;
  font-size: 15px;
  top: 2px;
  font-weight: 400;
  opacity: 0;
  visibility: hidden;
  font-family: "Font Awesome 5 Pro";
}

.card-table-custom .rdt_TableCol div span.sc-kDTinF.KAizm:before,
.card-table-custom .rdt_TableCol div span.sc-kDTinF.fmMaBA:before,
.card-table-custom .rdt_TableCol div span.sc-kDvujY.kcxzBB:before,
.card-table-custom .rdt_TableCol div span.sc-kDvujY.gqmYNu:before,
.card-table-custom .rdt_TableCol div span.sc-kDvujY.hBoifs:before,
.card-table-custom .rdt_TableCol div span.sc-kDvujY.fyIqoz:before,
.card-table-custom .rdt_TableCol div span.sc-kDTinF.EaODY:before {
  opacity: 1;
  visibility: visible;
  /* right: -2px; */
  right: 0px;
  content: "\f885" !important;
  color: #fff;
}

.card-table-custom .rdt_TableCol div span.sc-kDTinF.jwDKoz:before,
.card-table-custom .rdt_TableCol div span.sc-kDTinF.itMblB:before,
.card-table-custom .rdt_TableCol div span.sc-kDvujY.hBoifs:before,
.card-table-custom .rdt_TableCol div span.sc-kDvujY.fyIqoz:before,
.card-table-custom .rdt_TableCol div span.sc-kDTinF.ccHTVR:before {
  opacity: 1;
  visibility: visible;
  /* right: 11px; */
  right: -1px;
  content: "\f160" !important;
  color: #fff;
}

.card-table-custom .rdt_TableCol div span.sc-kDTinF.dJAGNK:before {
  right: 12px;
}

.card-table-custom .rdt_TableCol_Sortable.jvguKP:hover div,
.card-table-custom .rdt_TableCol_Sortable.bqEkvt:hover div,
.card-table-custom .rdt_TableCol_Sortable.dvsxvu:hover div {
  color: #fff !important;
}

.card-table-custom .rdt_TableCol div span.sc-kDTinF.iqQqvD,
.card-table-custom .rdt_TableCol div span.sc-kDTinF.gNpdTd {
  right: 7px;
}

.card-table-custom .rdt_TableCol div span.sc-kDTinF.KAizm {
  right: 13px;
  /* right: 20px; */
}

.overflow-ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
  white-space: nowrap;
  display: inline-block;
}

.relative-class {
  position: relative;
}

.attch-text-font {
  top: 15px;
  position: absolute;
  font-size: 10px;
}

.std-identify-blk figure video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid #eee;
}

.assessment-08 {
  display: inherit;
}

.assessment-08 button.btn.btn-warning.assessment_btn_yellow {
  font-weight: 600;
  font-size: 14px;
  height: 34px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 2px;
  line-height: 34px;
  box-shadow: 0px 1.5px 2px 0px rgb(0 0 0 / 30%) !important;
  border: none;
}

.assessment-08 .as-buttons + .as-buttons {
  margin-left: 5px;
}

.rdt_TableRow .hVElVJ,
.rdt_TableRow .bDfpUj {
  justify-content: right;
}

/*  two line elipse  */
.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

/*  exam condtions  */
.dropzone-transparent {
  border-width: 0px !important;
}

.card-table-custom .rdt_TableRow .rdt_TableCell:nth-child(9),
.card-table-custom .rdt_TableHead .rdt_TableCol:nth-child(9) {
  padding-left: 0 !important;
  min-width: 150px;
}

/*  drop zone submit btn  */
.btn.btn-upload {
  display: block;
  text-align: right;
  padding: 10px 0px;
}

.btn.btn-warning {
  font-weight: 600;
  font-size: 14px;
  height: 34px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 2px;
  line-height: 34px;
  box-shadow: 0px 1.5px 2px 0px rgb(0 0 0 / 30%) !important;
  border: none;
}

.bold-text {
  font-weight: 600;
}

/* text line ellipsis ticketlist id column position*/

.filter-search-icon.card-table-custom .rdt_TableCol:first-child {
  /* justify-content: center; */
  justify-content: inherit;
  /* left: 17px; */
}

.filter-search-icon.card-table-custom
  .rdt_TableCol:first-child
  .rdt_TableCol_Sortable {
  /* justify-content: left; */
}

/* .filter-search-icon.card-table-custom .rdt_TableCol:first-child .rdt_TableCol_Sortable>div{left: 0px;} */

/* text line ellipsis ticketlist id column position */
.card-table-custom .rdt_TableCol > div span.jwDKoz {
  transform: rotate(180deg) !important;
  top: 13px;
  position: absolute;
}

/*Dev Css 05/01/22 Verify ticket*/
.student-card-main-block {
  background: var(--topbar-color);
  height: 100vh;
  display: flex;
  align-items: center;
}

.student-card-main-block .container {
  max-width: 1067px;
}

.student-card-detail-head {
  text-align: center;
  padding: 20px 20px;
  position: relative;
}

.student-card-detail-head:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--topbar-color);
  right: 0;
  top: 0;
  opacity: 0.5;
}

.student-card-detail-content {
  padding: 30px 40px;
}

.student-card-detail-blk {
  display: flex;
  flex-direction: column;
  box-shadow: 2px 5px 20px rgb(0 0 0 / 58%);
  background: var(--sidebar-color);
}

.student-card-detail-head h4 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 15px;
  position: relative;
}

.student-card-detail-head p {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
  position: relative;
}

.student-card-detail-infos {
  display: flex;
  align-items: center;
  max-width: 850px;
  margin: 0 auto;
}

.student-card-image-blk {
  width: 205px;
  min-width: 205px;
  height: 205px;
  background: #254b7f;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 9px rgb(255 255 255 / 20%);
  margin-right: 92px;
  overflow: hidden;
}

.student-card-image-blk img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.student-card-infos h6 {
  color: #fff;
  font-size: 13px;
  margin-bottom: 0px;
}

.student-card-infos {
  width: 100%;
}

.student-card-info-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
}

.student-card-info-list ul {
  padding: 0;
  display: inline-block;
  list-style: none;
  padding-right: 25px;
  max-width: 135px;
}

.student-card-info-list ul:last-child {
  padding-right: 0;
}

.student-card-info-list ul li {
  padding: 20px 0px 0;
  display: block;
}

.student-card-info-list table.mobile-view {
  display: none;
}

/* .student-card-info-list table{display: block;}
.student-card-info-list tr{display: block;} */
.student-card-info-list table {
  border: none;
}

.student-card-info-list tr td {
  padding-right: 30px;
  vertical-align: top;
  padding-bottom: 10px;
  border: none;
}

.student-card-info-list tr td p {
  max-width: 135px;
}

.student-card-info-list tr td h4 {
  max-width: 135px;
}

.student-card-info-list h4 {
  font-size: 13px;
  color: #fff;
  font-weight: 700;
}

.student-card-info-list p {
  font-size: 13px;
  margin-bottom: 0;
  color: #fff;
}

.student-card-header img {
  max-width: 622px;
  width: 100%;
}

.student-card-header {
  text-align: center;
}

.student-card-image-blk-mobile {
  display: none;
}

.student-card-detail-head span.std-status {
  width: 40px;
  display: inline-block;
  line-height: 30px;
  position: relative;
  top: -5px;
}

.student-card-detail-head span.std-status img {
  width: 100%;
}

.std-card-body {
  overflow: hidden;
  overflow-y: auto;
  background: var(--topbar-color);
}

.student-card-info-block {
  margin-bottom: 40px;
}

.profile-doc-upload .dzu-dropzone lable {
  background-image: none;
}

.profile-doc-upload .dzu-dropzone {
  border: transparent !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23467FCFFF' stroke-width='2' stroke-dasharray='5 10 ' stroke-dashoffset='5' stroke-linecap='round'/%3e%3c/svg%3e");
}

.system-check-items li.system-check-opacity i,
.system-check-items li.system-check-opacity p {
  color: #eee;
}

.textseries-info-details ul li .content p {
  margin: 0;
}

.row.camhidden {
  align-items: end;
}

.row.camhidden ul {
  margin: 0;
}

.row.camhidden .col-md-4:last-child div {
  justify-content: end;
}

.btn-action-block.examfile-upload-btn {
  position: absolute;
  bottom: 0;
  margin-bottom: 15px;
}

.profile-doc-upload .btn.btn-upload {
  position: absolute;
  right: 0;
}

.step-sidebar-space {
  min-height: 190px;
}

@media only screen and (max-width: 991px) {
  .student-card-image-blk {
    margin-right: 50px;
    width: 180px;
    min-width: 180px;
    height: 180px;
  }

  .student-card-detail-head h4 {
    font-size: 32px;
  }

  .student-card-detail-head span.std-status {
    width: 32px;
    display: inline-block;
    line-height: 22px;
    top: -3px;
  }

  .student-card-detail-head span.std-status img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .student-card-main-block {
    padding: 40px 0;
  }

  .student-card-detail-head h4 {
    font-size: 20px;
  }

  .student-card-detail-head p {
    font-size: 14px;
  }

  .student-card-detail-infos {
    flex-direction: column;
  }

  .student-card-image-blk {
    width: 120px;
    min-width: 120px;
    height: 120px;
    display: none;
    box-shadow: 0px 0px 0px 6px rgb(255 255 255 / 20%);
  }

  .student-card-info-list ul li {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .student-card-infos h6 {
    display: none;
  }

  .student-card-image-blk-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;
  }

  .student-card-image-blk-mobile .student-card-image-blk {
    display: block;
    margin-right: 30px;
  }

  .student-card-image-blk-mobile h6 {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0px;
  }

  .student-card-detail-head span.std-status {
    width: 20px;
    line-height: 16px;
    top: -2px;
  }

  .student-card-info-list table.mobile-view {
    display: block;
  }

  .student-card-info-list table.desktop-view {
    display: none;
  }
}

@media only screen and (max-width: 475px) {
  .innerblue-textseries-content.blue-bg-blk .custom-control.custom-checkbox {
    margin-left: -25px;
  }

  .student-card-main-block {
    align-items: center;
  }

  .student-card-detail-head h4 {
    font-size: 15px;
  }

  .student-card-detail-head p {
    font-size: 10px;
  }

  .student-card-detail-head {
    padding: 15px 15px;
  }

  .student-card-image-blk-mobile .student-card-image-blk {
    width: 72px;
    height: 72px;
    min-width: 72px;
    box-shadow: 0px 0px 0px 3px rgb(255 255 255 / 20%);
  }

  .student-card-image-blk-mobile h6 {
    font-size: 10px;
  }

  .student-card-info-list h4 {
    font-size: 10px;
  }

  .student-card-info-list p {
    font-size: 10px;
  }

  .student-card-image-blk-mobile {
    margin-bottom: 0;
  }

  .student-card-detail-content {
    padding: 25px;
  }
}

@media only screen and (max-width: 375px) {
  .student-card-detail-head h4 {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .student-card-detail-head span.std-status {
    width: 15px;
  }
}

/*Dev Css 05/01/22  Verify ticket*/

/*Dev Css 13/01/22  Profile Card */

.avtar-bottom.avtar-card-block {
  justify-content: space-between;
}

.avtar-card-block .avtar-card-left {
  display: flex;
}

.avtar-card-right .avtar-card-item {
  position: relative;
  margin-bottom: 10px;
}

.avtar-card-right .avtar-card-item > i {
  font-size: 150px;
  color: #fff;
  line-height: 129px;
  border-radius: 35px;
  transition: 0.2s ease-in-out;
}

.avtar-card-item .avtar-card-actions {
  position: absolute;
  right: auto;
  left: 50%;
  margin: 0 auto;
  top: 50%;
  bottom: auto;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.2s ease-in-out;
}

.avtar-card-right {
  position: absolute;
  bottom: 21px;
  text-align: center;
  right: 30px;
}

.avtar-card-right h4 {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}

.avtar-card-right:hover .avtar-card-item > i {
  background: #fffc;
}

.avtar-card-actions a {
  background: #467fcf;
  color: #fff !important;
  font-size: 16px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
}

.avtar-card-right:hover .avtar-card-actions {
  opacity: 1;
}

/*Dev Css 13/01/22  Profile Card */
.closed-not-submitted {
  color: #ff0000;
}

.closed-marked {
  color: #1899d2;
}

.closed-submitted {
  color: #ffab2d;
}

.submitted {
  color: #00c915;
}

.pending {
  color: #ffab2d;
}

.draft {
  color: #00c915;
}

.open {
  color: #1899d2;
}

/*  Admin portal css 24 Jan 2022  */

.admin-login-main {
  height: 100vh;
  background: #10284b;
}

.admin-login-box {
  max-width: 850px;
  margin: auto;
  height: 100%;
  align-items: center;
  display: flex;
  padding: 15px;
  overflow: auto;
}

.admin-login-content {
  width: 100%;
}

.admin-login-form .admin-login-form-head {
  background: #527bbe;
  text-align: center;
  padding: 76px 20px;
}

.admin-login-form .admin-login-form-info {
  background: #fff;
}

.admin-login-form-info .admin-login-form-content {
  max-width: 400px;
  margin: 0 auto;
  padding: 30px 20px;
}

.admin-login-form-heading {
  text-align: center;
  margin-bottom: 30px;
}

.admin-login-form-heading h3 {
  font-size: 32px;
  font-weight: 700;
  color: #4a7dbf;
}

.admin-login-form-heading p {
  font-size: 16px;
  color: #6a617a;
  font-weight: 400;
  max-width: 220px;
  margin: 0 auto;
}

.admin-login-form.portal-login-form form {
  text-align: center;
}

.admin-login-form.portal-login-form p {
  text-align: center;
  margin: 20px 0 0;
}

.admin-login-form-info {
  margin-bottom: 30px;
}

.admin-login-content .forgotpass {
  text-align: center;
}

.admin-login-content .forgotpass a {
  font-size: 16px;
  color: #467fcf;
  font-weight: 400;
}

.system-administration-table.table-responsive {
  margin-top: 20px;
  overflow: inherit;
}

.system-administration-table .dataTables_wrapper .dataTables_filter input {
  /* min-width: 167px; */
  min-width: 100px !important;
  width: 100px;
}

.assigned-title-blk {
  position: relative;
}

.table-action-dropdown {
  display: inline-block;
}

.table-action-dropdown .dropdown-toggle::after {
  display: none;
}

.table-action-dropdown .dropdown-menu {
  min-width: auto;
  padding: 5px 15px;
  transform: inherit !important;
  left: -70px !important;
  right: auto !important;
}

.table-action-dropdown .list-unstyled {
  margin: 0;
}

.table-action-dropdown .list-unstyled li {
  margin: 10px 0;
}

.table-action-dropdown .dropdown-menu:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  /* background-image: url("../images/arrow.png"); */
  right: -21px;
  transform: rotate(180deg);
  top: 0;
}

/* .sub-menu-content-block {
  display: flex;
} */

.sub-menu-content-block .sub-menu-right-block {
  /* width: calc(100% - 80px); */
  padding: 0px 0px;
  margin-left: auto;
  transition: 0.3s ease-in-out;
}

.sub-menu-content-block .sub-menu-left-block {
  width: 235px;
  position: fixed;
  height: calc(100% - 48px);
  background: #fff;
  left: 80px;
  transition: 0.1s ease-in-out;
  /* border-top: 1px solid #fff; */
  z-index: 2;
}

.sub-menu.accordion .card-header {
  background: #fff;
  border-bottom: 1px solid #eff2f5;
  padding: 0;
  margin-bottom: 0;
}

.sub-menu.accordion .card {
  border: none;
  /* border-bottom: 1px solid #fff; */
  border-radius: 0;
}

.sub-menu.accordion .card-header a.btn-link.btn-block.collapsed {
  color: #06172a;
  font-size: 13px;
  text-align: inherit;
  padding: 14px 17px;
  padding-right: 15px;
  border: none;
  border-radius: 0;
  /* border-right: 4px solid #d3dee9; */
}

.sub-menu.accordion .card-header a.btn-link.btn-block {
  border: none;
  color: #06172a;
  font-size: 13px;
  text-align: inherit;
  padding: 14px 17px;
  padding-right: 30px;
  border: none;
  border-radius: 0;
  /* border-right: 4px solid #06172a; */
}

.sub-menu.accordion .card-header a.btn-link:before {
  position: absolute;
  content: "\f078";
  font-family: "Font Awesome 5 Pro";
  right: 0;
  height: 51px;
  top: 0;
  line-height: 51px;
  width: 40px;
  text-align: center;
  transition: 0.3s ease-in-out;
  transform: rotate(180deg);
}

.sub-menu.accordion .card-header a.btn-link.collapsed:before {
  transform: rotate(0deg);
}

.sub-menu.accordion .card-body {
  padding: 0;
}

.sub-menu-blk ul {
  margin: 0;
  padding: 0;
}

.sub-menu-blk li a {
  font-size: 14px;
  color: #06172a;
  display: block;
  padding: 15px 10px 15px 40px;
}

.sub-menu-blk ul li + li {
  border-top: 1px solid #f2f5f7;
}

.hide-subject-sidebar .sub-menu-left-block {
  left: -212px;
}

.hide-subject-sidebar .sub-menu-left-block button.close-btn-sidebar {
  right: -39px;
}

.sub-menu.accordion {
  height: 100%;
  overflow: auto;
}

.sub-menu-left-block button.close-btn-sidebar {
  right: -26px;
  display: none;
}

.hide-subject-sidebar .sub-menu-right-block {
  width: calc(100% - 55px);
}

/*  Admin portal css 24 Jan 2022  */

/*Template CSS */

section.template-sec {
  background: #fff;
  height: 100%;
  /* position: fixed; */
  width: 100%;
  padding: 100px 0;
}

.form-icon-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  width: 100%;
  /* align-items: center; */
}

.form-icon-group .form-icon {
  background: none;
  border: none;
  color: #4a7dbf;
  font-size: 18px;
  padding: 8px 0 0;
  width: 32px;
  text-align: initial;
}

.form-icon-group .form-control {
  background-color: #eef1f5;
  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  border: 1px solid #eef1f5;
  padding: 6px 15px;
  min-height: 42px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.template-block h2 {
  font-size: 36px;
  font-weight: 600;
  color: #000;
  margin-bottom: 30px;
}

.template-block h4 {
  font-size: 22px;
  font-weight: 700;
  color: #333;
  margin-bottom: 30px;
}

.form-group-blk label {
  color: #484644;
  font-size: 14px;
  /* margin-bottom: 10px; */
}

.form-group-blk .form-control {
  background-color: #eef1f5;
  font-size: 14px;
  line-height: 24px;
  height: inherit;
  border: 1px solid #eef1f5;
  padding: 6px 15px;
  min-height: 42px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.form-grp {
  display: flex;
  justify-content: space-between;
}

.form-grp .form-control {
  max-width: calc(33.33% - 6px);
  flex: 0 0 33.33%;
}

.custom-check.custom-control {
  line-height: 20px;
  min-height: auto;
}

.custom-check.custom-control .custom-control-label {
  line-height: 20px;
}

.tabs-wrap .nav.nav-pills {
  white-space: nowrap !important;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  border-bottom: 1px solid #e6ebf1 !important;
  margin-bottom: 30px !important;
}

.tabs-wrap .nav-pills .nav-item .nav-link {
  padding: 0px 0 10px;
  background: none !important;
  color: #484644;
  position: relative;
}

.tabs-wrap .nav-pills .nav-item .nav-link.active {
  color: var(--sidebar-color);
  font-weight: bold;
}

.tabs-wrap .nav-pills .nav-item .nav-link:after {
  background: #06172a;
  content: "";
  height: 4px;
  background: transparent;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.tabs-wrap .nav-pills .nav-item .nav-link.active:after,
.tabs-wrap .nav-pills .nav-item .nav-link:hover:after {
  background: #06172a;
}

.tabs-wrap .nav-pills .nav-item + .nav-item {
  margin-left: 20px;
}

.tabs-wrap .nav-pills .nav-link i {
  margin-right: 5px;
  font-weight: 300;
  font-family: "Font Awesome 5 Pro";
}

/*Template CSS */

/* dev css*/
.text-info-initials {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #133038 !important;
  background-color: #cfdadf;
  border-radius: 50%;
  font-weight: 600;
}

.user-profile .user-drop-down-img .text-infos {
  justify-content: center;
  display: flex;
  align-items: center;
  color: #133038 !important;
  background-color: #cfdadf;
  border-radius: 50%;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  height: 100%;
  width: 100%;
}

.profile-img-child .text-infos {
  justify-content: center;
  display: flex;
  align-items: center;
  color: #133038 !important;
  border-radius: 50%;
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #cfdadf;
  line-height: normal;
}

/* dev css */

.assigned-title-itm span.name-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.assigned-title-info article figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.sub-menu.accordion .card-header a.btn-link.btn-block {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.sub-menu.accordion .card-header a.btn-link.btn-block.no-sub:before {
  display: none;
}

.sub-menu.accordion .card-header.active a.btn-link.sub-sub-tab {
  background: var(--active-color) !important;
  font-weight: 500;
}

.sub-menu.accordion .card-header.active a.btn-link.btn-block {
  background: var(--active-color);
  border-right: 4px solid var(--sidebar-color);
  font-weight: 200;
  color: var(--active-text-color);
}

.active-white-text {
  color: white !important;
}

.sub-menu.accordion .card-header a.btn-link.btn-block:hover {
  background: var(--hover-color);
  border-right: 4px solid var(--sidebar-color);
}

.sub-menu.accordion .card-header.active a.btn-link.btn-block:hover {
  background: var(--hover-color);
  border-right: 4px solid var(--sidebar-color);
}

.sub-menu.accordion .card-header.sub-menu-box a.btn-link.btn-block {
  border-left: 4px solid transparent;
  border-bottom: 1px solid #eff2f5;
  border-left: 4px solid var(--sidebar-color) !important;
}

.sub-menu.accordion .card-header.sub-menu-box a.btn-link.btn-block:hover {
  border-right: 0;
  border-left: 4px solid var(--sidebar-color);
}

.sub-menu.accordion .card-header.sub-menu-box.active a.btn-link.btn-block {
  border-right: 0;
  border-left: 4px solid var(--sidebar-color);
  color: #000;
}

.sub-menu.accordion .card-header.sub-menu-box {
  padding-left: 15px;
  border: none;
}

/* Profile Image */

.avtar-bot-lft .profile-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

/* select */

select.form-control.is-invalid {
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.rdt_TableBody .rdt_TableRow:last-child .assigned-title-info {
  top: -100px;
}

.rdt_TableBody .rdt_TableRow:first-child .assigned-title-info {
  top: -63px;
}

.assigned-title-block-new .assigned-title-info article figcaption {
  text-align: initial;
}

.assigned-title-block-new .assigned-title-info article figcaption span {
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
}

/* changes feb 14 */
.files-export-group .btn-files {
  border-radius: 50%;
  width: 20px;
  height: 34px;
}

.files-export-group .btn-files .icon:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
  background: #e6ecf2;
}

.files-export-group .btn-files .icon:active,
.files-export-group .btn-files .icon:focus-visible {
  background: var(--active-color);
}

/* changes feb 18 */

/* changes feb 14 */
.filter-search-icon.card-table-custom .rdt_TableRow .rdt_TableCell:nth-child(3),
.filter-search-icon.card-table-custom .rdt_TableCol:nth-child(3) {
  min-width: 167px;
}

.custom-table-div .rdt_Table {
  width: 100%;
  display: table;
  border-collapse: collapse;
}

.custom-table-div .rdt_TableRow {
  display: table-row;
  height: 48px;
}

.custom-table-div .rdt_TableCell {
  display: table-cell;
  padding: 5px 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  white-space: nowrap;
  min-width: 60px !important;
}

.custom-table-div .rdt_TableBody {
  width: 100%;
  display: table-row-group;
}

.custom-table-div .rdt_TableCol {
  display: table-cell;
  padding: 5px 8px !important;
  line-height: 1.42857143;
  vertical-align: middle;
  /* white-space: nowrap; */
  min-width: 10px !important;
}

.custom-table-div .rdt_TableHeadRow {
  display: table-row;
  height: 56px;
}

.custom-table-div .rdt_TableHead {
  width: 100%;
  display: table-row-group;
  border-spacing: 0;
}

.filter-search-icon.card-table-custom
  .rdt_TableCol:last-child
  .rdt_TableCol_Sortable
  > div {
  padding-right: 8px;
}

/* .custom-table-div .rdt_TableRow div:last-child {
  width: 100%;
}
/* changes feb 16 */
.form-icon-group .form-grp {
  width: 100%;
}

.new-right-container {
  max-width: 1540px;
  margin: 0 auto;
}

.profile-box-2 article {
  display: flex;
}

.profile-box-2 article figure {
  width: 80px;
  height: 80px;
  min-width: 80px;
  border-radius: 50%;
  position: relative;
  margin: 0 15px 0px 0;
  background: #1899d2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #fff;
}

.profile-box-2 article figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.profile-box-2 article figure .profile-box-2-status {
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 16px;
  color: #000;
}

.profile-box-2 figcaption h4 {
  font-size: 16px;
  margin: 0 0 6px;
  font-weight: 600;
  margin-bottom: 15px;
}

.status-select-form {
  display: flex;
  align-items: center;
}

.status-select-form lable {
  margin-right: 10px;
}

.status-select-form .form-control {
  min-height: 42px !important;
}

.profile-box-2 article figure .profile-box-2-status.Online {
  color: #00dd60;
}

.profile-box-2 article figure .profile-box-2-status.Offline {
  color: #ccc;
}

.profile-box-2 article figure .profile-box-2-status.Donotdisturb {
  color: #ff0102;
}

.card.card-profile-info-card .form-grp .form-control {
  min-height: 42px;
}

.header-profile-status-blk {
  display: flex;
  align-items: center;
  padding: 8px 20px;
}

.header-profile-status-blk .profile-status-icon {
  display: block;
  margin-right: 10px;
  font-size: 16px;
  color: #000;
}

.header-profile-status-blk .form-control {
  background-color: #eef1f5;
  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  border: none;
  min-height: 42px;
  line-height: 42px;
  padding: 0 10px;
  width: auto;
}

.header-profile-status-blk .profile-status-icon.online.Online {
  color: #00dd60;
}

.header-profile-status-blk .profile-status-icon.online.Offline {
  color: #ccc;
}

.header-profile-status-blk .profile-status-icon.online.Donotdisturb {
  color: #ff0102;
}

.ml-new {
  margin-left: 28px;
}

/* 17 Feb*/
.profile-box-2 article figure .upload-drop-down-wrap {
  opacity: 1;
  right: -15px;
}

.profile-box-2 article figure .upload-drop-down-wrap .btn.dropdown-toggle {
  box-shadow: 0 2px 2.97px 0.03px hsla(0, 0%, 49%, 0.13) !important;
}

.profile-box-2 article figure .upload-drop-down-wrap .dropdown-menu {
  left: auto !important;
  right: auto !important;
  transform: translate3d(11px, 7px, 0px) !important;
}

/* feb 18 */

/* feb 18 */
.remove-list-style ul {
  list-style: none !important;
  margin: 0px !important;
  padding: 0px !important;
}

/* feb 19 */
.btn-selected {
  background: #06172a;
  border-color: #06172a;
  color: white;
}

/* 22 Feb */

.card.card-profile-info-card .form-control-status {
  min-width: 155px;
}

.card.card-profile-info-card .form-icon-group .form-control {
  min-height: 42px;
}

.header-profile-status-blk .form-control {
  padding-right: 25px;
}

/* 26 Feb */

.form-icon-group .custom-select-box .custom-select-box {
  padding: 0 !important;
}
.form-control.custom-select-box.time-box {
  min-width: 110px !important;
}

.form-icon-group .custom-select-box .css-yk16xz-control,
.form-icon-group .custom-select-box .css-1fhf3k1-control {
  min-height: 42px !important;
  border: none !important;
  background: transparent !important;
}

.custom-select-box .css-1pahdxg-control,
.custom-select-box .css-1fhf3k1-control {
  min-height: 42px !important;
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
}

.custom-select-box .css-1wa3eu0-placeholder {
  color: #495057;
}

.custom-select-box .css-tlfecz-indicatorContainer .css-6q0nyr-Svg {
  fill: #495057;
}

.custom-select-box .css-g1d714-ValueContainer {
  padding: 0 12px;
}

.form-icon-group select.form-control {
  padding: 0 10px !important;
}

.form-control:disabled,
.form-control[readonly] {
  color: #6c757d;
}

.permission-mgt-sec .wrap-box-right {
  max-width: 100%;
}

.custom-select-box .css-1pahdxg-control .css-1wa3eu0-placeholder {
  padding-left: 20px;
}

.custom-select-box .css-1pahdxg-control .css-1wa3eu0-placeholder:before {
  content: "\f002";
  font-family: "Font Awesome 5 Pro";
  top: 2px;
  position: absolute;
  left: 0;
  color: #4a7dbf;
}

.custom-select-box .css-26l3qy-menu {
  z-index: 2;
}

.as-buttons button + button {
  display: block;
}

.profile-box-2 .custom-select-box .css-yk16xz-control {
  height: 42px !important;
  border: none !important;
  background: transparent !important;
}

.card.card-profile-info-card .profile-box-2 .custom-select-box {
  padding: 0;
}

.remove-list-style ul {
  max-height: 375px;
  overflow: auto;
}

.reports-profile-view .assigned-title-info.popup-right {
  left: -270px;
  right: auto;
}

.permission-mgt-sec .wrap-box-right {
  max-width: 100%;
}

/* .permission-mgt-sec .landing-heading {margin-bottom: 5px !important;} */

.permission-mgt-sec .wrap-box-right {
  max-width: 100%;
}

.permission-mgt-sec .add-ticket-blk button.btn.btn-primary {
  margin: 0 3px;
}
.headingPermission{
  color: var(--topbar-color)
}

.assigned-title-itm span.name-icon .profile-box-2-status {
  position: absolute;
  bottom: -6px;
  right: -2px;
  font-size: 8px;
}

.assigned-title-itm span.name-icon .profile-box-2-status.Online .fas.fa-circle {
  color: #00dd60;
  font-size: 8px;
}

.assigned-title-itm
  span.name-icon
  .profile-box-2-status.Offline
  .fas.fa-circle {
  color: #ccc;
  font-size: 8px;
}

.assigned-title-itm
  span.name-icon
  .profile-box-2-status.Donotdisturb
  .fas.fa-circle {
  color: #ff0102;
  font-size: 8px;
}

.portal-status-icon.Online .fas.fa-circle {
  color: #00dd60;
  font-size: 8px;
}

.portal-status-icon.Offline .fas.fa-circle {
  color: #ccc;
  font-size: 8px;
}

.portal-status-icon.Donotdisturb .fas.fa-circle {
  color: #ff0102;
  font-size: 8px;
}

.custom-profile-page {
  white-space: nowrap;
  min-width: 195px;
}

.profile-box-2.ml-new.mb-4.mt-2 article figcaption {
  padding-left: 5px;
}

*/ .audit-trial-json pre {
  text-align: initial;
  white-space: break-spaces;
}

/* permission management related css */

.new-sec-permission-mgt .elHTkS,
.fpYUKa {
  min-height: auto !important;
}

.permission-mgt-sec ul#tabs {
  margin-bottom: 20px;
}

.filter-search-icon.card-table-custom.roles-table .rdt_TableCol:first-child {
  justify-content: left;
  left: 0;
}

.filter-search-icon.card-table-custom.roles-table
  .rdt_TableCol:first-child
  div {
  padding-left: 0px;
}

.filter-search-icon.card-table-custom.roles-table .rdt_TableCell:last-child {
  justify-content: right;
  margin-right: 10px;
}

div#cell-akn7l-L7NNL-undefined {
  justify-content: right;
  margin-right: 10px;
}

.filter-search-icon.card-table-custom .rdt_TableCol:first-child {
  justify-content: left;
  left: 0;
}

.filter-search-icon.card-table-custom.roles-table .rdt_TableCol:last-child {
  justify-content: right;
}

.cursor-default {
  cursor: default;
}

.new-sec-permission-mgt .sub-menu-checkBox {
  padding-left: 10px;
}

.permission-mgt-sec .add-ticket-blk.dropdown-comman {
  margin-top: 30px;
}

.new-sec-permission-mgt .elHTkS,
.fpYUKa {
  min-height: auto !important;
}

.permission-mgt-sec .add-ticket-blk.dropdown-comman button.btn {
  border-color: #ff0102;
}

.permission-mgt-sec .add-ticket-blk.dropdown-comman button.btn:hover {
  color: #fff;
  background: #c90103;
  border-color: #c90103;
}

/* end of permission management related css */

.landing-subheading {
  line-height: 1.2;
  font-size: 14px;
  color: #252423;
  font-weight: 700;
}

.upload-img-size {
  width: auto;
  height: 100px;
  margin: inherit;
  display: inline-block;
}

.upload-img-size img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.my-tickets-heading-blk .text-md-right.action2-blk {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #d7dee8;
}

.custom-3coltable-div.custom-table-div .rdt_TableCell:last-child,
.custom-3coltable-div.custom-table-div .rdt_TableCol:last-child {
  text-align: -moz-right;
}

.form-control.custom-select-box.is-invalid {
  background-position: right calc(3em + 0.1875rem) center;
}

/*     Course Module     */

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.breadcrumb-blk .breadcrumb {
  box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%);
  border: 1px solid #d7dee8;
  background: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 20px 20px;
}

.breadcrumb-blk .breadcrumb .breadcrumb-item,
.breadcrumb-blk .breadcrumb .breadcrumb-item a {
  font-size: 14px;
  color: #d1d4d6;
}

.breadcrumb-blk .breadcrumb .breadcrumb-item.active {
  font-weight: 600;
  color: #467fcf;
}

.breadcrumb-blk .breadcrumb-item + .breadcrumb-item::before {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  color: #d1d4d6;
  padding-right: 10px;
}

.breadcrumb-blk .breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 14px;
}

.breadcrumb-blk {
  margin-bottom: 15px;
}

.basic-info-box-list ul {
  list-style: none;
}

.basic-info-box-list ul li {
  font-size: 14px;
  color: #484644;
  display: flex;
  align-items: center;
}

.basic-info-box-list ul li span.title {
  font-weight: 600;
  min-width: 140px;
  max-width: 140px;
  display: inline-block;
}

.basic-info-box-list ul li + li {
  display: inline-block;
}

.tooltip-custom .tooltip-custom-box {
  position: absolute;
  left: 0;
  top: 23px;
  right: auto;
  width: 100%;
  color: #fff;
  word-break: break-all;
  display: none;
  white-space: break-spaces;
  padding: 10px;
  background: #1b1c1d;
  font-size: 12px;
  border-radius: 0.28571429rem;
  z-index: 9;
}

.tooltip-custom:hover .tooltip-custom-box {
  display: block;
}

.basic-info-box-list ul li + li {
  margin-top: 15px;
}

.graphs-info-items {
  border: 1px solid #e6ebf1;
  border-radius: 2px;
  padding: 20px;
  height: 100%;
}

.graphs-info-items img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.form-group-blk .custom-check.mt-2 {
  margin-top: 12px !important;
}

.edit-icon.new-card-header .card-header {
  padding: inherit;
}

.card-new.card.card-profile-info-card .card-header {
  padding: initial;
  margin-bottom: 15px;
}

.form-grp-dates .form-control.col {
  margin: 0 5px;
}

.row.form-grp-dates {
  margin: auto -5px;
}

.form-grp-dates .form-control.col:nth-child(3),
.form-grp-dates .form-control.col:nth-child(4) {
  max-width: max-content;
  min-width: 60px;
}

.form-group-check {
  display: flex;
}

.form-group-check .form-control {
  margin-right: 10px;
}

.custom-check.custom-control input {
  z-index: 1;
}

.custom-check-group .custom-check {
  display: inline-block;
}

.custom-check-group .custom-check + .custom-check {
  margin-left: 40px;
}

/*     Course Module     */

.audit-trial-json pre {
  text-align: initial;
  white-space: break-spaces;
}

.permission-mgt-sec ul#tabs {
  margin-bottom: 20px;
}

.filter-search-icon.card-table-custom.roles-table
  .rdt_TableCol:first-child
  div {
  padding-left: 0px;
}

.filter-search-icon.card-table-custom.roles-table .rdt_TableCell:last-child {
  justify-content: right;
  margin-right: 10px;
}

div#cell-akn7l-L7NNL-undefined {
  justify-content: right;
  margin-right: 10px;
}

.filter-search-icon.card-table-custom.roles-table .rdt_TableCol:last-child {
  justify-content: right;
}

.filter-search-icon.card-table-custom.grade-mix-table .rdt_TableCol:last-child {
  display: flex;
  justify-content: right;
  max-width: 100% !important;
}

.filter-search-icon.card-table-custom.grade-mix-table .rdt_TableCell:last-child {
  justify-content: right;
  display: flex;
  margin-right: 10px;
}

.cursor-default {
  cursor: default;
}

.new-sec-permission-mgt .sub-menu-checkBox {
  padding-left: 10px;
}

.permission-mgt-sec .add-ticket-blk.dropdown-comman {
  margin-top: 30px;
}

.new-sec-permission-mgt .elHTkS,
.fpYUKa {
  min-height: auto !important;
}

.permission-mgt-sec .add-ticket-blk.dropdown-comman button.btn {
  border-color: #ff0102;
}

.permission-mgt-sec .add-ticket-blk.dropdown-comman button.btn:hover {
  color: #fff;
  background: #c90103;
  border-color: #c90103;
}

/* end of permission management related css */

.new-datatable-blk .filter-search-bar-blk {
  top: inherit;
  margin-left: 212px;
  right: 0;
}

.new-datatable-blk .system-administration-table.table-responsive {
  margin-top: 26px;
}

/* .new-datatable-blk nav.sc-llYSUQ.dKvXPZ.rdt_Pagination {
  display: none;
} */

.new-datatable-blk .sc-hGPBjI.elHTkS {
  border: 1px solid #d7dee8;

  border-radius: 2px;

  padding: 0 20px 20px;

  box-shadow: 0px 2px 3px rgb(0 0 49 / 9%);
}

.topic-add-modal-content h4 {
  font-size: 30px;

  font-weight: 600;

  color: #595959;

  text-align: center;

  margin-bottom: 20px;
}

.topic-add-modal .modal-body {
  padding: 30px;
}

.modal .modal-content {
  border-radius: 2px;
  border: none;
}

.topic-add-modal-content .form-group-blk {
  margin-bottom: 20px;
}

.topic-add-modal-content button.btn-primary {
  display: block;

  margin: 0 auto;
}

.new-standard-box .card.card-profile-info-card .card-header {
  font-weight: 600;

  color: #06172a;
}

.new-standard-box .dataTables_wrapper .dataTables_filter input {
  font-weight: 500;

  border-bottom: 1px solid #f5f5f5;
}

.new-standard-box .dataTables_wrapper .dataTables_filter input::placeholder {
  font-weight: 500;
}

.new-datatable-blk .dataTables_wrapper .dataTables_filter {
  margin-bottom: 0;
}

.topic-icon {
  font-size: 22px;
}

.btn-dropdown-item button.btn:after {
  display: none;
}

.btn-dropdown-item .dropdown-menu {
  min-width: auto;
  padding: 10px;
  /* display: flex; */
}

.btn-dropdown-grp .as-buttons {
  display: flex;
}

.btn-dropdown-grp .as-buttons .btn-dropdown-item {
  margin-left: 5px;
}

/* Course Details */

.card.course-module-details-tab {
  /* border: none;
  border-radius: 0;
  background: none; */
}

.course-sec-gap {
  margin-top: 20px;
}

Select.form-control {
  background-image: url(/static/media/arrow-down.607a4deb.svg) !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 10px) center !important;
  background-size: 10px !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  border: none;
  padding: 8px;
  min-height: 34px;
  max-height: 34px;
}

.course-details-tab-sec .form-icon-group {
  display: block;
}

.course-table-filter-sec .filter-search-bar-blk {
  top: -5px;
  margin-left: 135px;
}

/* .course-table-filter-sec.custom-table-div .rdt_TableCell {
  min-width: auto !important;
} */

/* End of Course Details */

/*     Course Module     */

.btn-dropdown-item button.btn:after {
  display: none;
}

.btn-dropdown-item .dropdown-menu {
  min-width: auto;
  padding: 10px;
}

.btn-dropdown-grp .as-buttons {
  display: flex;
}

.btn-dropdown-grp .as-buttons .btn-dropdown-item {
  margin-left: 5px;
}

.form-group-blk .custom-select-box .css-yk16xz-control {
  min-height: 42px !important;
  border: none !important;
  background: transparent !important;
}

.form-group-blk .custom-select-box,
.form-icon-group .custom-select-box {
  padding: 0 !important;
  border: none;
}

.form-grp-btn {
  display: flex;
  align-content: center;
}

.form-grp-btn .btn {
  margin-left: 10px;
  height: 38px;
}

/* Draft.js css */
/* Student Hub related css */

.new-datatable-blk1 .filter-search-bar-blk {
  top: inherit;
  margin-left: 212px;
  right: 0;
}

.DraftEditor-root {
  padding: 10px 10px;
  background-color: #eef1f5;
  max-height: 350px;
  overflow-y: auto;
}

.draftJsMentionPlugin__mention__29BEd,
.draftJsMentionPlugin__mention__29BEd:visited {
  background-color: rgb(0, 82, 204);
  color: white;
}

.draft-editor {
  background: #06172a;
  padding: 5px 10px;
  border-radius: 0 0 2px 2px;
}

.draft-editor-btn {
  border: none;
  color: #fff;
  background: no-repeat;
  margin: 0 2px;
}

/* .public-DraftStyleDefault-ltr { // commented because it gives long space between two line in html editor
  min-height: 200px;
} */

/* End Draft.js css */
/* resource pool */

.custom-table-expandable table.table {
  border: none;
  margin: auto;
}

.custom-table-expandable table.table thead th,
.custom-table-expandable table.table tbody td {
  background: none !important;
  border: none;
  border-color: #dee2e6 !important;
  color: #212529 !important;
  padding: 8px 15px;
  white-space: nowrap;
}

.custom-table-expandable table.table thead th {
  border-bottom: 1px solid #dee2e6;
  font-size: 14px !important;
  padding: 15px 15px;
}

.custom-table-expandable {
  background: #ffff;
  padding: 10px 20px 20px 20px;
  position: relative;
  border-radius: 2px;
  box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%);
  border: 1px solid #d7dee8;
  overflow: auto;
}

button.btn.expand-btn {
  padding: 0;
}

.custom-table-expandable table.table tbody tr:nth-child(even) {
  background: #f3f6f9;
}

.custom-table-expandable table.table tbody tr:hover {
  background: #e6ecf2;
}

.hideTr .card-table-custom {
  padding: 0;
}

.custom-table-expandable table.table tbody tr.hideTr td {
  padding: 0 0 0 35px;
}

.custom-table-expandable table.table tbody tr {
  border-left: 4px solid transparent !important;
}

.custom-table-expandable table.table tbody tr:hover {
  border-left: 4px solid #06172a !important;
}

.custom-table-expandable table.table tbody tr.hideTr {
  background: none;
  border: none !important;
}

.hideTr .custom-table-div .rdt_TableCell {
  min-width: auto !important;
  display: inline-flex;
  padding: 15px;
}

.hideTr .custom-table-div .rdt_TableRow {
  display: flex;
  align-items: center;
}

/* .custom-table-expand-blk .dataTables_wrapper .dataTables_filter {
  top: -4px;
} */

.dKvXPZ {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  min-height: 56px;
  background-color: #ffffff;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: rgba(0, 0, 0, 0.12);
}

.kkNaNQ {
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gENuGq {
  position: relative;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: inherit;
  color: inherit;
  margin-top: 1px;
}

.rdt_Pagination {
  min-height: inherit !important;
  padding-top: 10px;
}

.rdt_Pagination select {
  border: 1px solid #eff0f2;
  color: #06172a;
  -webkit-appearance: none !important;
  appearance: none !important;
  background-image: url(/static/media/arrow-down.607a4deb.svg) !important;
  background-repeat: no-repeat;
  background-position: center right 7px;
}

.rdt_Pagination > span {
  color: #06172a;
}

.bqKZDG {
  margin: 0 24px;
}

.gKsGGb {
  cursor: pointer;
  height: 24px;
  min-width: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 8px;
  padding-right: 12px;
  box-sizing: content-box;
  font-size: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  direction: ltr;
}

.hGeawu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  white-space: nowrap;
}

.iNniLt:disabled {
  cursor: unset;
  color: rgba(0, 0, 0, 0.18);
  fill: rgba(0, 0, 0, 0.18);
}

.iNniLt {
  position: relative;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 8px;
  margin: px;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: rgba(0, 0, 0, 0.54);
  fill: rgba(0, 0, 0, 0.54);
  background-color: transparent;
}

.iNniLt:hover:not(:disabled) {
  background-color: rgba(0, 0, 0, 0.12);
}

.exREar {
  margin: 0 4px;
}

.tag-list {
  list-style: none;
  padding: inherit;
  margin: inherit;
  padding: 5px 0 0;
}

.tag-list li {
  display: inline-flex;
  align-items: center;
  background: #ddd;
  margin: 2px 2px;
  padding: 3px 5px;
  font-size: 12px;
  color: #333;
  border-radius: 2px;
}

.tag-list li span {
  margin-left: 5px;
  line-height: initial;
  cursor: pointer;
  border-left: 1px solid #ccc;
  padding-left: 5px;
}

.uploded-files-item-txt {
  display: flex;
  align-items: center;
}

.uploded-files-item-txt figure {
  font-size: 30px;
  margin: 0;
  margin-right: 20px;
  color: #000;
}

.uploded-files-item-txt figcaption h4 {
  font-size: 14px;
  color: #06172a;
  font-weight: 600;
  margin-bottom: 0;
}

.uploded-files-item-txt figcaption p {
  font-size: 14px;
  color: #06172a;
  margin: 0;
}

.uploded-files-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #d7dee8;
  padding: 5px 5px;
  box-shadow: 0 5px 24px rgba(6, 23, 42, 0.0784313725490196);
}

.uploded-files-item button.btn.file-remove-btn {
  font-size: 20px;
  color: #48464a;
  background: transparent;
  border: none;
  box-shadow: none !important;
  padding: 0;
  margin-left: 10px;
}

.expand-btn1 {
  margin-left: auto;
  min-width: 245px;
}

/* end of Student Hub related css */

.align-center {
  display: flex;
  align-content: center;
}

.align-center i {
  line-height: inherit;
}

.my-tickets-heading-blk .text-md-right.action2-blk {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #d7dee8;
}

.new-custom-login .container {
  max-width: 550px;
  position: relative;
}

.new-custom-login .card-footer {
  background-color: transparent;
  padding: 0 74px 28px;
}

.new-custom-login .portal-login-info p {
  color: #fff;
  font: normal normal normal 23px/26px Segoe UI;
  letter-spacing: 0.76px;
  margin: 0 auto 28px;
  /* font-size: 18px;   */
}

.new-custom-login .portal-login-form small {
  font: normal normal normal 16px/18px Segoe UI;
  letter-spacing: -0.37px;
  color: #fff;
  margin-top: 28px;
  display: block;
}

.new-custom-login .portal-login-card .card-body-inr {
  max-width: initial;
}

.new-custom-login .portal-login-card .card-body {
  padding: 28px 44px 28px 44px;
  background: transparent;
}

.new-custom-login .portal-login-info h3 {
  font-size: 24px;
  margin: 0 0 20px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #fff;
  text-transform: capitalize;
}

.new-custom-login .card-header-img.card-header-text {
  margin: auto;
  text-align: center;
  /* padding: 26px 20px 0px; */
}

.card-header-img.card-header-text .header-text {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.card-header-text .header-text h4 {
  font: italic normal 900 102px/136px Segoe UI;
  letter-spacing: -6.73px;
  color: #fff;
  line-height: inherit;
}

.card-header-text .header-text p {
  font: italic normal bold 19px/21px Segoe UI;
  letter-spacing: 0.63px;
  color: #fff;
  border-left: 2px solid #fff;
  margin: 0;
  margin-left: 40px;
  padding: 10px 0px 10px 20px;
  max-width: 210px;
  text-align: initial;
  height: 100%;
  display: flex;
}

.new-custom-login .portal-login-card .card-footer a {
  color: #fff;
  font: normal normal 600 21px/24px Segoe UI;
  letter-spacing: 0.06px;
}

.custom-3coltable-div.custom-table-div .rdt_TableCell:last-child,
.custom-3coltable-div.custom-table-div .rdt_TableCol:last-child {
  text-align: -moz-right;
}

.Tickets-panel {
  width: 100%;
}

.tooltip-custom {
  position: relative;
}

.tooltip-custom .tooltip-custom-box {
  position: absolute;
  left: 0;
  top: 23px;
  right: auto;
  width: 100%;
  color: #fff;
  word-break: break-all;
  display: none;
  white-space: break-spaces;
  padding: 10px;
  background: #1b1c1d;
  font-size: 12px;
  border-radius: 0.28571429rem;
  z-index: 9;
}

.tooltip-custom:hover .tooltip-custom-box {
  display: block;
}

.card-table-custom .rdt_TableRow:last-child .tooltip-custom-box {
  bottom: 20px;
  top: auto;
  max-height: 78px;
  overflow: auto;
}

.tooltip-custom p:last-child {
  display: inline-block !important;
}

/* Live chat css */

.my-tickets-info-list.Tickets-main-wrap.live-chat-tab iframe#myId {
  width: 100%;
  border: unset;
  height: 500px;
}

/* End of Live chat css */
.btn.rounded-circle.btn-status-1 {
  min-width: auto;
  width: 30px;
  border-radius: 20px !important;
  height: 12px;
  font-size: 8px;
  line-height: inherit;
}

.btn.rounded-circle.btn-status-1 i {
  margin-right: 3px;
  border-right: 1px solid #fff;
  padding-right: 3px;
}

.card-table-custom .rdt_TableCol_Sortable.dmKtTB div ~ span.ccHTVR::before {
  opacity: 1;
  visibility: visible;
  right: 7px;
  content: "\f160" !important;
  color: #fff;
}

.card-table-custom .rdt_TableCol > div span.ccHTVR {
  transform: rotate(180deg) !important;
  /* top: 13px; */
  position: absolute;
}

.card-table-custom .rdt_TableCol_Sortable:hover.dmKtTB > div {
  background: #000;
  color: #fff !important;
}

.card-table-custom .rdt_TableCol > div span.EaODY {
  transform: rotate(0deg) !important;
}

.card-table-custom .rdt_TableCol_Sortable.dmKtTB div ~ span.EaODY::before {
  content: "\f160" !important;
}

.card-table-custom .rdt_TableCol_Sortable.dmKtTB:hover span:before {
  color: #fff !important;
}

.card-table-custom .rdt_TableCol_Sortable:hover span:before {
  color: #000;
}

.new-custom-login .portal-login-card {
  border: 1px solid #ffffff;
  border-radius: 2px;
  background: #ffffff33;
}

.new-custom-login .portal-login-card .card-header {
  background: #fff;
}

.new-custom-login
  .portal-login-form
  .input-group
  .input-group-prepend
  .input-group-text {
  color: #fff;
}

.new-custom-login .portal-login-form .custom-control-label {
  color: #fff;
}

.new-custom-login .portal-login-form .custom-control-label::before {
  background: transparent;
  border-color: #fff;
}

.new-custom-login .portal-login-form small a {
  color: inherit;
}

.new-custom-login button.btn.btn-white-bordered.btn-submit-info {
  background: #ef8700;
  border: none;
  color: #fff;
}

.new-custom-login.portal-login > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.new-custom-login.portal-login {
  position: relative;
}

.new-custom-login .card-header-img.card-header-text img {
  height: 170px;
  padding: 10px 10px 10px 10px; 
}

.new-custom-login .portal-login-info > h4 {
  font-size: 18px;
  margin: 0 0 28px;
  font-weight: 400;
  color: #fff;
}

.custom-3coltable-div.custom-table-div .rdt_TableCell:last-child,
.custom-3coltable-div.custom-table-div .rdt_TableCol:last-child {
  text-align: -moz-right;
}

@media only screen and (min-width: 991px) {
  .ticket-details {
    height: auto !important;
  }
}

.box-style-new2 a.dashboard_hover {
  cursor: pointer;
}

.box-style-new2 a {
  cursor: default;
  display: flex;
  align-items: center;
  background: #ddd;
  padding: 15px 10px;
  color: #fff;
  height: inherit;
  border-radius: 2px;
}

.box-style-new2 a i {
  font-size: 36px;
  margin-right: 10px;
}

.box-style-new2 a h4 {
  font-size: 16px;
  margin: inherit;
  font-weight: 500;
  color: #fff;
  text-align: inherit;
}

.speed-test-button {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.box-class-analytics button,
.box-class-analytics a {
  margin-bottom: 10px;
  border: none;
  flex-direction: column;
  display: flex;
  color: #fff;
  align-items: center;
  padding: 5px 18px;
  justify-content: center;
  border-radius: 2px;
  text-align: center;
}

.box-class-analytics button i,
.box-class-analytics a i {
  font-size: 23px;
}

.box-class-analytics button h4,
.box-class-analytics a h4 {
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  color: #fff;
  text-align: inherit;
}
.toggle-text p {
  font-size: 11px;
  margin: 0;
  margin-bottom: 5px;
  font-weight: bold;
}
.box-style-new2 {
  height: auto;
}

.box-style-new2.box-2 a {
  flex-direction: column;
}

.box-style-new2.box-2 a h4 {
  font-size: 14px;
}

.box-style-new2.box-2 a i {
  margin: inherit;
  /* margin-bottom: 10px; */
}

.box-class-analytics.bg-red button {
  background: #ff0000;
}
.box-class-analytics.bg-red button:hover {
  background: #ad2500;
}
.box-class-analytics button:disabled {
  background: #ddd !important;
}

.box-class-analytics.bg-gr button {
  background: #00c1a8;
}

.box-class-analytics.bg-gr button:hover {
  background: #1a9aab;
}

.box-class-analytics.bg-yl button {
  background: #ff9e01;
}

.box-class-analytics.bg-yl button:hover {
  background: #ea9203;
}

.row.row-pd .col-md-4,
.row.row-pd .col-md-3,
.row.row-pd .col-md-6 {
  padding: 0 5px;
}

.row.row-pd {
  margin: 0 -5px;
}

.box-style-new3.active {
  background: #006cfd;
}

.box-style-new3 {
  position: relative;
  padding: 10px;
  background: #ddd;
  color: #fff;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 2px;
}

.box-style-new3 h4 {
  font-size: 14px;
  margin-bottom: 5px;
  color: #fff;
}

.box-style-new3 input.form-control {
  padding: 2px 10px !important;
  border-radius: 2px;
  height: auto !important;
  font-size: 12px;
}

.class-analytics a {
  font-size: 20px;
  color: #fff;
}

.input-box-style-2 {
  display: flex;
}

.input-box-style-2 button.btn-submit {
  border: 2px solid #fff;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: inherit;
  width: 42px;
  /* height: 38px; */
}

.input-box-style-2 button.btn-submit i {
  margin: auto;
  font-weight: 600;
}

.input-box-style-2 button.btn-submit:hover:enabled {
  background-color: #004cfd;
}

.input-checkbox-block {
  display: flex;
  align-items: center;
}

.form-control.custom-select-box.is-invalid {
  background-position: right calc(3em + 0.1875rem) center;
}

.form-grp-dates.dates-select .custom-select-box {
  width: auto;
  flex: 1;
  margin: 0 5px;
}

.form-grp-dates.time-select .custom-select-box {
  width: auto;
  flex: 1;
  margin: 0 5px;
}

/* Smart Assesment realted css */

/* End of Smart Assesment realted css */
/* student hub feedback css added */

.exclamation-tooltip {
  position: absolute;
  right: 10px;
  left: auto;
  /* top: 13px; */
}

.exclamation-tooltip-content {
  display: none;
  position: absolute;
  right: 0;
  background: #fff;
  border: 1px solid #e0edff;
  z-index: 2;
  padding: 15px;
  min-width: 260px;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  font-size: 14px;
}

.exclamation-tooltip i {
  color: #495057;
  font-size: 14px;
  font-weight: 400;
}

.exclamation-tooltip:hover .exclamation-tooltip-content {
  display: block;
}

.tag-list-blk span + span {
  margin-left: 5px;
}

.card .form-icon-group .custom-select-box > div {
  height: auto !important;
}

.l-o-c-t .system-administration-table.table-responsive {
  margin-top: 39px;
}

.l-o-c-t.card-table-custom .rdt_TableHeader {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker-popper {
  z-index: 9 !important;
}

.btn-dropdown-item .dropdown-menu.show {
  display: flex !important;
}

/* Launch button Csss*/

.meeting-detailinfo-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.meeting-detail-list-box ul {
  list-style: none;
  padding: inherit;
  margin: inherit;
}

.meeting-detail-list-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.meeting-detail-info {
  width: 100%;
  border: 1px solid var(--topbar-color);
  border-radius: 20px;
  padding: 20px;
}

.meeting-detail-item {
  display: flex;
  align-items: center;
  color: #000;
}

.meeting-detail-item i {
  margin-right: 10px;
}

.meeting-detail-item-info h4 {
  font-size: 14px;
  margin: auto;
  font-weight: 600;
}

.meeting-detail-item-info p {
  margin: inherit;
  font-size: 12px;
}

.meeting-detail-list-box ul li + li {
  margin-top: 10px;
}

.meeting-detail-files {
  margin-left: 20px;
}

.meeting-detail-item.meeting-users i {
  color: #c90103;
}

.box-style-new2.bg-grn a {
  background: var(--green);
}

.box-style-new2.bg-sidebar a {
  background: #00a9ff;
}

.box-style-new2.bg-pur a {
  background: #8e00a7;
}

.topic-add-modal .modal-dialog.modal-dialog-centered.modal-xl {
  max-width: 950px;
}

.box-style-box-22.link-box-style {
  padding-right: 20px;
  position: relative;
}

.box-style-box-22.link-box-style:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 52.75%;
  background: #9b9a9b;
  right: 0;
  left: auto;
  margin: 0 auto;
  top: 50%;
  bottom: auto;
  transform: translate(-50%, -50%);
}

.box-style-box-22.link-box-style:after {
  position: absolute;
  content: "\f0c1";
  font-family: "Font Awesome 5 Pro";
  background: #fff;
  color: #9b9a9b;
  padding: 5px;
  right: 0;
  left: auto;
  margin: 0 auto;
  top: 50%;
  bottom: auto;
  transform: translate(50%, -50%);
}

.link-box-style:hover .dashboard_hover {
  background-color: #ef8700 !important;
}

.box-style-box-22.link-box-style .box-style-new2 {
  position: relative;
}

.box-style-box-22.link-box-style .box-style-new2:before {
  content: "";
  position: absolute;
  width: 19.5px;
  height: 1px;
  background: #9b9a9b;
  right: 0;
  left: auto;
  margin: 0 auto;
  top: 50%;
  bottom: auto;
  transform: translate(100%, -50%);
}

/* .box-style-box-22 .box-style-new2 a {
  padding: 20px 20px;
} */

.topic-add-modal .modal-header h4 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin: inherit;
}

.topic-add-modal .modal-header button.btn-close {
  border: none;
  background: transparent;
  font-size: 16px;
}

body .rdw-image-modal-upload-option {
  justify-content: left;
  position: relative;
  overflow: hidden;
}

body .rdw-image-modal-upload-option-label {
  cursor: pointer;
  width: 95%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: unset;
}

.box-style-new2.mb-4.bg-grey a {
  background: #ddd;
}

.sub-menu.accordion .card-header a.btn-link {
  position: relative;
}

/* brajesh*/
.monitor-color button {
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.monitor-color button:hover {
  background-color: #0066cc !important;
  border-color: #0066cc !important;
}

.action-color button {
  background-color: #919c13 !important;
  border-color: #919c13 !important;
}

.action-color button:hover {
  background-color: #0066cc !important;
  border-color: #0066cc !important;
}

.recordBtnView-color {
  background-color: #428bca !important;
  border-color: #428bca !important;
}

.recordBtnDown-color {
  background-color: #47a447 !important;
  border-color: #47a447 !important;
}

.greenBtn-color {
  background-color: #428bca !important;
  border-color: #428bca !important;
}

.greenBtn-color {
  background-color: #35aa47 !important;
  border-color: #35aa47 !important;
}

.blueBtn-color {
  background-color: #4d90fe !important;
  border-color: #4d90fe !important;
}

.yelloBtn-color {
  background-color: #f9b849 !important;
  border-color: #f9b849 !important;
}

.redBtn-color {
  background-color: #d84a38 !important;
  border-color: #d84a38 !important;
}

.online-class-sec .card-body-inr.card-body-info .react-datepicker-wrapper {
  display: block;
}

/* brajesh*/

.box-style-new2.mb-4.bg-grey a {
  background: #ddd;
}

/* Course Details New Css Section */

.course-details-tab-sec .new-card-header {
  padding-right: 0px;
}

.intake-year-z-i {
  z-index: 9 !important;
}

.course-dashborad .basic-info-box-list ul li span {
  word-break: break-word;
  display: inline-block;
}

.course-module-audit-trails p {
  word-break: break-all !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* End of Course Details New Css Section */

.custon_rd_new {
  display: flex;
  align-items: center;
  height: 44px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background: var(--topbar-color);
  border-color: var(--topbar-color);
}

.custom-control-label {
  line-height: 20px;
}

.tooltip-custom p:last-child {
  display: inline-block !important;
}

.top-0 {
  top: 0;
}

.dateTime {
  display: flex;
  flex-wrap: wrap;
}

.dateTime p {
  white-space: nowrap;
}

.right-space {
  margin-right: 5px !important;
}

.new-card-header {
  padding-right: 0px;
}

.custom-checkbox.status-blk {
  background: #eef1f5;
  padding: 20px 10px 10px 40px;
  border-radius: 2px;
}

.custom-checkbox.status-blk p {
  margin: auto;
}

.file-req.is-invalid {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
}

.as-buttons a {
  margin-left: 5px;
}

.assigned-title-info article figcaption i {
  width: 15px;
  text-align: center;
}

.info-blockright-toleft .assigned-title-info.popup-right {
  right: 40px;
  left: auto;
}

.online-class-table .basic-info-box-list ul li span.title {
  min-width: unset;
  margin-right: 8px;
}

.custom-3coltable-div.custom-table-div .rdt_TableCell:last-child,
.custom-3coltable-div.custom-table-div .rdt_TableCol:last-child {
  text-align: -moz-right;
}

.filter-scroll .assessment-table-filter {
  display: inline-block;
  margin-left: 8px;
}

.d-flex.new-heading-css {
  margin-bottom: 20px;
}

.new-heading-css .landing-heading {
  margin: 5px 0 !important;
}

.none_top_scroll {
  display: none;
}

.none_top_scroll p {
  width: 800px;
  min-width: 800px;
  display: inline-block;
}

@media only screen and (min-width: 991px) {
  .ticket-details {
    height: auto !important;
  }
}

.filter-scroll .assessment-table-filter {
  display: inline-block;
  margin-left: 8px;
}

@media only screen and (max-width: 1700px) {
  .filter-scroll {
    min-width: 500px;
    position: relative;
  }

  .filter-scroll-inner {
    max-width: 500px;
    overflow-x: auto;
    overflow-y: visible;
    display: flex;
    position: static;
    left: 0;
    top: -17px;
    min-height: 470px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .filter-scroll-inner::-webkit-scrollbar {
    display: none;
  }

  .none_top_scroll {
    z-index: 11;
    position: absolute;
    max-width: 500px;
    min-width: 500px;
    overflow-x: auto;
    height: 15px;
    top: -32px;
    display: block;
    overflow-y: clip;
  }

  .filter-scroll {
    top: -17px;
  }

  .filter-scroll-inner-sd .assessment-table-filter {
    display: inline-block;
    margin: 4px 4px 4px 0px;
  }

  .top_search_sd .filter-scroll {
    top: 0;
  }

  .filter-scroll .btn-arrow {
    display: block;
  }

  .filter-scroll-inner {
    padding: 0px 30px 0 20px;
  }
}

@media only screen and (max-width: 1500px) {
  .filter-scroll {
    min-width: 300px;
    position: relative;
  }

  .filter-scroll-inner {
    max-width: 300px;
    overflow-x: auto;
    overflow-y: visible;
    display: flex;
    position: static;
    left: 0;
    top: 0;
    min-height: 470px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .filter-scroll-inner::-webkit-scrollbar {
    display: none;
  }

  .none_top_scroll {
    max-width: 400px;
    min-width: 400px;
  }
}

@media only screen and (max-width: 1300px) {
  .filter-scroll {
    min-width: 340px;
    position: relative;
  }

  .filter-scroll-inner {
    max-width: 340px;
    overflow-x: auto;
    overflow-y: visible;
    display: flex;
    position: static;
    left: 0;
    top: 0;
    min-height: 470px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .filter-scroll-inner::-webkit-scrollbar {
    display: none;
  }

  .none_top_scroll {
    max-width: 340px;
    min-width: 340px;
  }
}

/* Class Management */

.class-mgt-online-class.card-profile-info-card .btn.rounded-circle {
  margin: 0 0px;
}

.class-mgt-online-class.card-profile-info-card .as-buttons .btn ~ .btn {
  margin-left: 5px;
}

.swal2-popup.swal2-modal.custom_server_class h2#swal2-title {
  display: block !important;
  text-align: left;
  font-size: 20px;
  margin-top: 18px;
}

.swal2-popup.swal2-modal.custom_server_class .swal2-actions {
  margin-top: unset;
}

.server-modal pre {
  text-align: left;
}

.add-campus-modal .modal-footer {
  border-top: unset;
}

/* End of Class Management */

.intake-year-z-i {
  z-index: 9 !important;
}

.DraftEditor-root {
  min-height: 200px;
  resize: vertical;
}

.filter-table-bg .filter-search-bar-blk,
.filter-table-bg .filter-button-group {
  z-index: inherit;
}

.appeals-tables .system-administration-table.table-responsive {
  margin-top: 35px;
}

.appeals-tables.card-table-custom .rdt_TableHeader {
  display: none;
}

.course-details-tab-sec .custom-control.custom-checkbox.text-left {
  padding-top: 26px;
}

/* End of Live Chat related Css */

.DraftEditor-editorContainer,
.public-DraftEditor-content {
  height: auto !important;
}

.public-DraftStyleDefault-block,
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  margin: auto !important;
}

.filter-table-bg .filter-search-bar-blk,
.filter-table-bg .filter-button-group {
  z-index: inherit;
}

/* posts and feed css */

.assigned-title-blk {
  cursor: pointer;
}

.portal-status-icon .fa-check-circle {
  color: #92c353;
}

.portal-status-icon .fa-circle {
  color: #c4314b;
}

.portal-status-icon .fa-minus-circle {
  color: #c4314b;
}

.portal-status-icon .fa-clock {
  color: rgb(253, 185, 19);
}

.portal-status-icon .fad.fa-check-circle:before {
  color: #fff;
  opacity: 1;
}

.portal-status-icon .fad.fa-check-circle:after {
  color: #92c353;
  opacity: 1;
}

.upload-img-size {
  width: auto;
  height: 100px;
  margin: inherit;
  display: inline-block;
}

.portal-status-icon .fad.fa-check-circle:before {
  color: #fff;
  opacity: 1;
}

.portal-status-icon .fad.fa-check-circle:after {
  color: #92c353;
  opacity: 1;
}

.upload-img-size {
  width: auto;
  height: 100px;
  margin: inherit;
  display: inline-block;
}

.post-area-img .portal-status-icon {
  font-size: 10px;
  position: absolute;
  bottom: 5px;
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.online-u {
  background-color: #5cb85c;
}

.assigned-title-info:before {
  position: absolute;
  top: 0;
  height: 40px;
  background: #e0edff;
  width: 100%;
  left: 0;
  content: "";
}

.assigned-title-info article {
  position: relative;
  display: flex;
}

.assigned-title-info article figure {
  width: 32px;
  height: 32px;
  background: #9f1f63;
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  font-family: system-ui;
  margin-right: 10px;
  /* border: 1px solid #fff; */
  position: relative;
}

.assigned-title-info article figcaption h4 {
  color: #06172a;
  font-size: 14px;
  font-weight: 400;
}

.assigned-title-info article figcaption p {
  font-size: 12px;
  color: #5b5b5b;
  margin-bottom: 5px !important;
}

.assigned-title-info article figcaption h6 {
  font-size: 12px;
  color: #06172a;
  font-weight: 700;
  margin: 0;
}

.assigned-title-info article figcaption i {
  width: 15px;
  text-align: center;
}

.assigned-title-info article figcaption span {
  color: #5b5b5b;
}

.assigned-title-info article figcaption span:hover {
  color: #1899d2;
  text-decoration: underline !important;
}

.assigned-title-info article figure span.portal-status-icon {
  position: absolute;
  right: 0;
  bottom: -2px;
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.rdt_TableBody .rdt_TableRow:last-child .assigned-title-info {
  top: -70px;
}

.rdt_TableBody .rdt_TableRow:nth-last-child(0n + 2) .assigned-title-info {
  top: -20px;
}

.assigned-title-right .assigned-title-info {
  right: -100%;
  left: auto;
}

.ticket-profile-box.assigned-title-right .assigned-title-info {
  right: 35px;
}

.assigned-title-info span.portal-status-icon i {
  color: #92c353;
  background: #fff;
  border-radius: 50%;
}

.assigned-title-info span.portal-status-icon i.offline {
  color: #f8a503;
}

.assigned-title-info .portal-status-icon .fad.fa-check-circle:after {
  color: inherit;
}

.comment-user-other .assigned-title-info {
  left: 43px;
}

.assigned-title-info.ticket-table-tiltle {
  left: -242px;
  min-width: 250px;
}

.bgBlue {
  background: #1899d2 !important;
}

.bgBlueShade {
  background: #585c75 !important;
}

.hover-color-blue:hover {
  color: #1899d2;
}

/* posts and feed chat css */

/* chat css */
.ps-fd-card img {
  max-width: 100%;
}

.post-user-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #eee;
}

.post-area-img {
  position: relative;
}

.active-status {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #f8fafc;
  border-radius: 50%;
  padding: 3px;
  font-size: 7px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-area-left {
  max-width: 60px;
}

/* from here 22-07-2022 */

.post-area-right {
  max-width: calc(100% - 60px);
  margin-left: 30px;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.post-area {
  display: flex;
  align-items: self-start;
}

.post-area-right .card {
  display: flex;
  padding: 25px;
  border-radius: 2px;
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #d7dee8;
}

.active-status .fa-check {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #5cb85c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textarea-btn-submit {
  width: 100%;
  position: relative;
  display: flex;
}

.textarea-btn-submit textarea.form-control {
  border: 1px solid #d7dee8;
  resize: none;
  font-size: 16px;
  padding: 10px;
  height: 35px;
  line-height: 1.2;
  border-radius: 2px;
  min-height: 41px;
  overflow: hidden;
}

.textarea-btn-submit textarea.form-control:focus {
  box-shadow: 0 0 0 1.5px rgba(52, 144, 220, 0.25);
}

.textarea-btn-submit textarea.form-control::-webkit-input-placeholder {
  color: #cbced0;
}

.textarea-btn-submit textarea.form-control::-moz-placeholder {
  color: #cbced0;
}

.textarea-btn-submit textarea.form-control:-ms-input-placeholder {
  color: #cbced0;
}

.textarea-btn-submit textarea.form-control:-moz-placeholder {
  color: #cbced0;
}

.textarea-btn-submit .btn-submit-arrow {
  /* position: absolute; */
  /* right: 10px; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  /* border: 1px solid #484644; */
  /* background: #fff; */
  /* padding: 1px; */
  /* line-height: 1; */
  /* width: 15px; */
  /* height: 15px; */
  /* border-radius: 2px; */
  /* color: #484644; */
  /* font-size: 10px; */
  background: none !important;
  padding: 0px;
  color: #484644 !important;
  font-size: 24px;
  width: 50px;
  min-height: 100%;
  text-align: center;
  line-height: 1;
}

.textarea-btn-submit .btn-submit-arrow:disabled {
  opacity: 0.3;
}

.textarea-btn-submit .btn-submit-arrow:hover {
  background: #484644 !important;
  color: #fff !important;
}

.textarea-btn-submit .btn-submit-arrow:focus,
.textarea-btn-submit .btn-submit-arrow:active {
  background: #232222 !important;
  color: #fff !important;
  box-shadow: none;
}

.post-by-left {
  max-width: 60px;
  position: relative;
}

.post-by-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e6eddd;
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
}

.post-by-status {
  position: absolute;
  right: 0;
  bottom: -2px;
  background: #fff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: 2px solid #fff;
}

.post-by-status .fa-check {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #5cb85c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-by-right {
  max-width: calc(100% - 48px);
  margin-left: 15px;
  width: 100%;
}

.post-by {
  display: flex;
  align-items: self-start;
}

.ps-fd-card-body {
  padding: 30px 0;
}

.post-by + .post-by {
  margin-top: 30px;
}

.ps-by-card {
  border-radius: 3px 3px 3px 3px;
  border: 1px solid #d7dee8;
  background: #fff;
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  background-color: white;
}

.ps-by-card-header {
  padding: 15px 20px;
  /*border-left: 4px solid #06172a;*/
  /*border-radius: 3px 0px  0 0;*/
  line-height: 1.2;
  /*    border-bottom: 1px solid #e2e7ee;*/
  overflow: hidden;
}

.ps-by-card-header.mypost {
  border-left: 4px solid #06172a;
}

.ps-by-card-body {
  border-bottom: 0.5px solid #e2e7ee;
  border-top: 0.5px solid #e2e7ee;
  background: #f8fafc;
}

.ps-by-card-footer {
  padding: 10px 15px;
  /*    border-top: 1px solid #d7dee8;*/
}

.ps-by-card-footer .card-footer {
  background: none;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
}

.ps-by-card-header h5 {
  font-size: 14px;
  margin: 0 0 5px 0;
}

.ps-by-card-header h5 a {
  color: #252423;
  font-weight: bold;
  margin-right: 5px;
}

.ps-by-card-header h5 span {
  font-size: 12px;
  font-weight: normal;
  color: #484644;
}

.ps-by-card-header p {
  font-size: 16px;
  color: #484644;
  margin: 0;
}

.card-left {
  max-width: 60px;
  position: relative;
}

.card-footer {
  display: flex;
  align-items: self-start;
  border: 1px solid #d7dee8
}

.btn-dropdown-grp .as-buttons .btn-dropdown-item {
  margin-left: 5px;
}

/* Course Details */

.card-right {
  max-width: calc(100% - 60px);
  margin-left: 20px;
  width: 100%;
}

.card-left-by-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  /* overflow: hidden; */
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e6eddd;
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
}

.card-left-by-img img {
  border-radius: 50%;
}

.card-left-status {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  border-radius: 50%;
  padding: 3px;
  font-size: 7px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-left-status .fa-check {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #5cb85c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-reply {
  padding: 15px 20px;
  display: flex;
  align-items: self-start;
}

/* .other-reply {
  padding: 3px 20px 0;
  color: #467fcf;
  cursor: pointer;
  line-height: 1.2;
}

.other-reply i {
  margin-right: 10px;
} */

/* .card-reply.mycomment {
  border-left: 4px solid #06172a;
} */

.card-reply .card-right h5 {
  font-size: 14px;
  margin: 0 0 5px 0;
}

.card-reply .card-right h5 a {
  color: #252423;
  font-weight: bold;
  margin-right: 5px;
}

.card-reply .card-right h5 span {
  font-size: 14px;
  font-weight: normal;
  color: #484644;
}

/* Draft.js css */
/* Student Hub related css */

.card-reply .card-right p {
  font-size: 16px;
  color: #484644;
  margin: 0;
}

.card-reply .card-right p a {
  color: #467fcf;
  font-weight: bold;
}

@media only screen and (max-width: 991px) {
  /* posts and feed css */
  /* posts and feed css */

  .assigned-title-blk {
    cursor: pointer;
  }

  .portal-status-icon .fa-check-circle {
    color: #92c353;
  }

  .portal-status-icon .fa-circle {
    color: #c4314b;
  }

  .portal-status-icon .fa-minus-circle {
    color: #c4314b;
  }

  .portal-status-icon .fa-clock {
    color: rgb(253, 185, 19);
  }

  .portal-status-icon .fad.fa-check-circle:before {
    color: #fff;
    opacity: 1;
  }

  .portal-status-icon .fad.fa-check-circle:after {
    color: #92c353;
    opacity: 1;
  }

  .post-area-img .portal-status-icon {
    font-size: 10px;
    position: absolute;
    bottom: 5px;
    right: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #fff;
  }

  .online-u {
    background-color: #5cb85c;
  }

  /* .assigned-title-info {display: none;position: absolute;background: #fff;border: 1px solid #E0EDFF;z-index: 2;left: -280px;top:5px;padding: 20px;min-width: 280px;border-radius: 2px;box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);} */

  .assigned-title-blk:hover .assigned-title-info {
    display: block;
  }

  .assigned-title-info:before {
    position: absolute;
    top: 0;
    height: 40px;
    background: #e0edff;
    width: 100%;
    left: 0;
    content: "";
  }

  .assigned-title-info article {
    position: relative;
    display: flex;
  }

  .assigned-title-info article figure {
    width: 32px;
    height: 32px;
    background: #9f1f63;
    display: block;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    font-family: system-ui;
    margin-right: 10px;
    /* border: 1px solid #fff; */
    position: relative;
  }

  .assigned-title-info article figcaption h4 {
    color: #06172a;
    font-size: 14px;
    font-weight: 400;
  }

  .assigned-title-info article figcaption p {
    font-size: 12px;
    color: #5b5b5b;
    margin-bottom: 5px !important;
  }

  .assigned-title-info article figcaption h6 {
    font-size: 12px;
    color: #06172a;
    font-weight: 700;
    margin: 0;
  }

  .assigned-title-info article figcaption i {
    width: 15px;
    text-align: center;
  }

  .assigned-title-info article figcaption a {
    color: #5b5b5b;
  }

  .assigned-title-info article figcaption a:hover {
    color: #1899d2;
    text-decoration: underline !important;
  }

  .assigned-title-info article figure span.portal-status-icon {
    position: absolute;
    right: 0;
    bottom: -2px;
    display: flex;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #fff;
  }

  .rdt_TableBody .rdt_TableRow:last-child .assigned-title-info {
    top: -70px;
  }

  .rdt_TableBody .rdt_TableRow:nth-last-child(0n + 2) .assigned-title-info {
    top: -20px;
  }

  .assigned-title-right .assigned-title-info {
    right: -100%;
    left: auto;
  }

  .ticket-profile-box.assigned-title-right .assigned-title-info {
    right: 35px;
  }

  .assigned-title-info span.portal-status-icon i {
    color: #92c353;
    background: #fff;
    border-radius: 50%;
  }

  .assigned-title-info span.portal-status-icon i.offline {
    color: #f8a503;
  }

  .assigned-title-info .portal-status-icon .fad.fa-check-circle:after {
    color: inherit;
  }

  .comment-user-other .assigned-title-info {
    left: 43px;
  }

  .assigned-title-info.ticket-table-tiltle {
    left: -242px;
    min-width: 250px;
  }

  .bgBlue {
    background: #1899d2 !important;
  }

  .bgBlueShade {
    background: #585c75 !important;
  }

  .hover-color-blue:hover {
    color: #1899d2;
  }

  /* posts and feed chat css */

  /* chat css */
  .ps-fd-card img {
    max-width: 100%;
  }

  .post-user-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #eee;
  }

  .post-area-img {
    position: relative;
  }

  .active-status {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #f8fafc;
    border-radius: 50%;
    padding: 3px;
    font-size: 7px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .post-area-left {
    max-width: 60px;
  }

  .post-area-right {
    max-width: calc(100% - 60px);
    margin-left: 30px;
    width: 100%;
  }

  .post-area {
    display: flex;
    align-items: self-start;
  }

  .post-area-right .card {
    display: flex;
    padding: 25px;
    border-radius: 2px;
    box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
    border: 1px solid #d7dee8;
  }

  .active-status .fa-check {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #5cb85c;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textarea-btn-submit {
    width: 100%;
    position: relative;
    display: flex;
  }

  .textarea-btn-submit textarea.form-control {
    border: 1px solid #d7dee8;
    resize: none;
    font-size: 16px;
    padding: 10px;
    height: 35px;
    line-height: 1.2;
    border-radius: 2px;
    min-height: 41px;
    overflow: hidden;
  }

  .textarea-btn-submit textarea.form-control:focus {
    box-shadow: 0 0 0 1.5px rgba(52, 144, 220, 0.25);
  }

  .textarea-btn-submit textarea.form-control::-webkit-input-placeholder {
    color: #cbced0;
  }

  .textarea-btn-submit textarea.form-control::-moz-placeholder {
    color: #cbced0;
  }

  .textarea-btn-submit textarea.form-control:-ms-input-placeholder {
    color: #cbced0;
  }

  .textarea-btn-submit textarea.form-control:-moz-placeholder {
    color: #cbced0;
  }

  .textarea-btn-submit .btn-submit-arrow {
    /* position: absolute; */
    /* right: 10px; */
    /* top: 50%; */
    /* transform: translateY(-50%); */
    /* border: 1px solid #484644; */
    /* background: #fff; */
    /* padding: 1px; */
    /* line-height: 1; */
    /* width: 15px; */
    /* height: 15px; */
    /* border-radius: 2px; */
    /* color: #484644; */
    /* font-size: 10px; */
    background: none !important;
    padding: 0px;
    color: #484644 !important;
    font-size: 24px;
    width: 50px;
    min-height: 100%;
    text-align: center;
    line-height: 1;
  }

  .textarea-btn-submit .btn-submit-arrow:disabled {
    opacity: 0.3;
  }

  .textarea-btn-submit .btn-submit-arrow:hover {
    background: #484644 !important;
    color: #fff !important;
  }

  .textarea-btn-submit .btn-submit-arrow:focus,
  .textarea-btn-submit .btn-submit-arrow:active {
    background: #232222 !important;
    color: #fff !important;
    box-shadow: none;
  }

  .post-by-left {
    max-width: 60px;
    position: relative;
  }

  .post-by-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e6eddd;
    font-size: 26px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .post-by-status {
    position: absolute;
    right: 0;
    bottom: -2px;
    background: #fff;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    border: 2px solid #fff;
  }

  .post-by-status .fa-check {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #5cb85c;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .post-by {
    display: flex;
    align-items: self-start;
  }

  .ps-fd-card-body {
    padding: 30px 0;
  }

  .post-by + .post-by {
    margin-top: 30px;
  }

  .ps-by-card {
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #d7dee8;
    background: #fff;
    box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
    background-color: white;
  }

  .ps-by-card-header {
    padding: 15px 20px;
    /*border-left: 4px solid #06172a;*/
    /*border-radius: 3px 0px  0 0;*/
    line-height: 1.2;
    /*    border-bottom: 1px solid #e2e7ee;*/
    overflow: hidden;
  }

  .ps-by-card-header.mypost {
    border-left: 4px solid #06172a;
  }

  .ps-by-card-body {
    border-bottom: 0.5px solid #e2e7ee;
    border-top: 0.5px solid #e2e7ee;
    background: #f8fafc;
  }

  .ps-by-card-footer {
    padding: 10px 15px;
    /*    border-top: 1px solid #d7dee8;*/
  }

  .ps-by-card-footer .card-footer {
    background: none;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
  }

  .ps-by-card-header h5 {
    font-size: 14px;
    margin: 0 0 5px 0;
  }

  .ps-by-card-header h5 a {
    color: #252423;
    font-weight: bold;
    margin-right: 5px;
  }

  .ps-by-card-header h5 span {
    font-size: 12px;
    font-weight: normal;
    color: #484644;
  }

  .ps-by-card-header p {
    font-size: 16px;
    color: #484644;
    margin: 0;
  }

  .scroll-tabs.filter-scroll .filter-scroll-inner .nav.nav-pills {
    overflow: visible;
  }

  .scroll-tabs.filter-scroll
    .filter-scroll-inner
    .nav-pills
    .nav-item
    .nav-link::after {
    height: 3px;
  }

  .scroll-tabs.filter-scroll .btn-arrow {
    background-color: #f3f6f9;
  }
}

.uploded-files-item:hover {
  background: #e6ecf2;
}

.admin-home-acc.accordion > .card > .card-header h2 button.collapsed > i {
  transform: rotate(0deg);
}

.admin-home-acc.accordion > .card > .card-header h2 button > i {
  color: var(--topbar-color);
  font-size: 26px;
  transform: rotate(180deg);
  transition: 0.3s ease-in-out;
}

.admin-home-acc.accordion > .card > .card-header {
  padding: 15px;
  background-color: #fff;
}

.admin-home-acc.accordion > .card {
  background: #fff;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 2px 2.97px 0.03px hsl(0deg 0% 49% / 13%);
  border: 1px solid #d7dee8;
}

.admin-home-acc.accordion > .card > .card-header h2 button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
}

.admin-home-acc.accordion > .card + .card {
  margin-top: 20px;
}

.admin-home-acc.accordion > .card > .card-header h2 button span i {
  font-size: 32px;
  width: 50px;
  text-align: center;
  margin-right: 10px;
  color: var(--topbar-color);
}

.admin-home-acc.accordion > .card > .card-header h2 button span {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #252423;
  font-weight: 700;
}

.custom-main-table.custom-table-div .rdt_TableCol {
  font-size: 14px;
  font-weight: 600;
  color: #06172a;
}

.custom-main-table.custom-table-div .rdt_TableHead {
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
}

.custom-main-table.custom-table-div {
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
}

.custom-main-table.custom-table-div .rdt_TableRow {
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffffff;
  min-height: 48px;
}

.table-overflow-show .sc-hHLeRK.kaLCam {
  overflow: inherit;
}

.btn.rtimg-icon-btn {
  display: flex;
  align-items: center;
}

.btn.rtimg-icon-btn img {
  margin-right: 3px;
  width: 20px;
}

.card-subheading {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 16px;
  color: #252423;
}

.program-table .system-administration-table.table-responsive {
  margin-top: 35px;
}

.program-table.card-table-custom .rdt_TableHeader {
  display: none;
}

.form-group-blk .custom-select-box .css-1pahdxg-control,
.form-group-blk .custom-select-box .css-yk16xz-control,
.form-group-blk .custom-select-box .css-1fhf3k1-control {
  height: auto !important;
}

.form-check-grps div {
  display: inline-block;
}

.form-check-grps .send-via-blk {
  display: inline-flex;
  align-items: center;
}

.form-check-grps .send-via-blk > label {
  white-space: nowrap;
  margin-bottom: 3px;
  margin-right: 30px;
  font-weight: 600;
}

.form-check-grps {
  align-items: center;
  display: flex;
  padding-top: 38px;
}

.add-c-f .form-group.d-inline-flex {
  margin-top: 1.8rem;
}

.public-DraftStyleDefault-block,
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  margin: auto;
}

.DraftEditor-editorContainer {
  height: initial;
}

.hide-comment-cnt {
  display: none;
}

.hide-reply {
  display: none;
}

.show-reply {
  display: block;
}

.row.support-team-skeleton {
  margin-left: auto;
}

.row.support-team-skeleton li {
  list-style: none;
  margin: 0 10px;
}

.post-image-ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 -5px;
}

.post-image-ul > li {
  margin: 5px;
  width: calc(100% / 5);
}

.post-image-ul > li img {
  max-width: 100%;
  height: auto !important;
  width: 100%;
}

.comment-image-ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 -5px;
}

.comment-image-ul > li {
  margin: 5px;
}

.files-container-ul {
  padding: 0;
  margin: 0 -5px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.files-container-ul .files-container-li {
  margin: 5px;
}

ul.files-container-ul li .file-link {
  display: flex;
  align-items: center;
  color: #373635;
  text-decoration: none;
  padding: 5px 10px;
  background: #f3f2f1;
  border-radius: 3px;
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #e7e6e5;
}

ul.files-container-ul li a img {
  max-width: 30px;
  margin-right: 5px;
}

ul.files-container-ul li .file-link:hover {
  background: #e6ecf2;
}

ul.files-container-ul li .file-link:focus {
  background: #d3dee9;
  box-shadow: none;
  outline: none;
}
/* ---16-nav-22--- */
.drag_card_btn {
  position: relative;
  z-index: 1;
}
.drag_card_btn .filepond--root {
  margin: 0px 0 !important;
}
.drag_card_btn .filepond--root .filepond--drop-label {
  min-height: 42px;
  font-size: 14px;
  background-color: #eef1f5 !important;
  border-radius: 2px !important;
}
.drag_card_btn .filepond--root.filepond--hopper {
  min-height: 42px !important;
  line-height: 42px !important;
}
.drag_card_btn .filepond--root {
  /* border-radius: 2px !important; */
  border: 1px solid #eef1f5 !important;
}
.drag_card_btn .filepond--root {
  overflow: inherit;
}
.drag_card_btn .filepond--credits {
  display: none;
}
/* ---16-nav-22--- */

.filepond--root {
  margin: 10px 0 !important;
  border: 1px solid;
  display: block;
  max-height: calc(100% - 1px) !important;
  overflow: hidden;
  background: #f3f2f1;
  border-radius: 3px;
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #e7e6e5;
}

.filepond--root > div {
  background: none !important;
  opacity: 1 !important;
  border-radius: 0 !important;
}

.filepond--image-preview-overlay-success {
  color: #1a6dbd !important;
}

.filepond--image-preview-overlay-failure {
  color: #d80e21 !important;
}

.filepond--image-preview-wrapper {
  border-radius: 0 !important;
}

.filepond--item {
  border: 1px solid #d7dee8 !important;
  border-radius: 5px !important;
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13) !important;
  overflow: hidden !important;
  max-height: 120px;
}

/****File Uploader Css End****/

i.fas.fa-check.offline {
  background: orange;
}

/* chat css */
.textarea-btn-submit > div:nth-child(1) {
  width: 100%;
}

.textarea-btn-submit > div:nth-child(1) textarea {
  border: 1px solid #d7dee8 !important;
  resize: none !important;
  font-size: 16px !important;
  padding: 10px !important;
  height: -webkit-fill-available;
  line-height: 1.2 !important;
  border-radius: 2px !important;
  min-height: 41px !important;
  overflow: hidden;
  font-family: "Segoe UI" !important;
}

.textarea-btn-submit > div:nth-child(1) textarea::-webkit-input-placeholder {
  color: #cbced0;
}

.textarea-btn-submit > div:nth-child(1) textarea::-moz-placeholder {
  color: #cbced0;
}

.textarea-btn-submit > div:nth-child(1) textarea:-ms-input-placeholder {
  color: #cbced0;
}

.textarea-btn-submit > div:nth-child(1) textarea:-moz-placeholder {
  color: #cbced0;
}

.textarea-btn-submit > div:nth-child(1) textarea:focus {
  box-shadow: 0 0 0 1.5px rgba(52, 144, 220, 0.25);
  outline: none;
}

.textarea-btn-submit > div:nth-child(1) > div {
  min-height: 41px !important;
}

.ps-by-card-header p a {
  color: #467fcf;
}

.post-textarea__control {
  margin-top: 10px;
  margin-bottom: 20px;
}

.post-textarea__input {
  background-color: #fff;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
  padding: 9px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  border: 1px solid #dedede;
}

.post-textarea__highlighter {
  padding: 0px !important;
}

.post-textarea__suggestions__list {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 14px;
  max-height: 200px;
  overflow: auto;
}

.post-textarea__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.post-textarea__suggestions__item — focused {
  background-color: #daf4fa;
}

.chat-side-bar {
  overflow: hidden;
  border-radius: 50%;
  background: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tribute-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(215, 222, 232);
  background-color: #ffffff;
}

.tribute-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.tribute-container::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgb(215, 222, 232);
  background-color: #3499e3;
}

.tribute-container {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 999999;
  min-width: 220px;
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #d7dee8;
  border-radius: 2px;
  transform: translateY(15px);
}

.tribute-container ul {
  margin: 0 !important;
  margin-top: 2px !important;
  padding: 0 !important;
  list-style: none !important;
  background: #ffffff !important;
}

.tribute-container li {
  padding: 5px 5px !important;
  cursor: pointer !important;
}

.tribute-container li.highlight {
  background: #06172a !important;
  color: #fff;
}

.tribute-container li span {
  font-weight: bold !important;
}

.tribute-container li.no-match {
  cursor: default !important;
}

.tribute-container .menu-highlighted {
  font-weight: bold !important;
}

.v-tribute {
  width: 100% !important;
  height: auto !important;
  overflow-y: auto !important;
}

@media only screen and (max-width: 767px) {
  div#app main.py-4.px-4 {
    padding: 10px !important;
  }

  .post-area-left {
    max-width: 45px;
  }

  .post-user-img {
    width: 45px;
    height: 45px;
  }

  .post-area-right {
    max-width: calc(100% - 45px);
    margin-left: 10px;
  }

  .post-area-right .card {
    padding: 10px;
  }

  .textarea-btn-submit textarea.form-control {
    font-size: 14px;

    height: 38px;
    line-height: 1.2;
  }

  .ps-by-card-header h5 {
    font-size: 14px;
  }

  .ps-by-card-header p {
    font-size: 13px;
  }

  .ps-fd-card-body {
    padding: 15px 0;
  }

  .post-by-left {
    max-width: 45px;
  }

  .post-by-right {
    max-width: calc(100% - 45px);
    margin-left: 10px;
  }

  .ps-by-card-header {
    padding: 10px;
  }

  .card-reply {
    padding: 10px;
  }

  .card-reply .card-right p {
    font-size: 13px;
  }

  .card-left {
    width: 32px;
  }
}

@media only screen and (max-width: 991px) {
  .post-user-img {
    width: 50px;
    height: 50px;
  }

  .post-area-right {
    max-width: calc(100% - 50px);
    margin-left: 14px;
  }

  .post-area-right .card {
    padding: 15px;
  }

  .textarea-btn-submit textarea.form-control {
    font-size: 16px;
    /* line-height: 1.2; */
    height: 42px;
  }

  .post-by-left {
    max-width: 50px;
  }

  .post-by-right {
    max-width: calc(100% - 50px);
    margin-left: 14px;
  }

  .ps-by-card-header {
    padding: 10px 15px;
  }

  .ps-by-card-header h5 {
    font-size: 16px;
  }

  .ps-by-card-header p {
    font-size: 14px;
  }

  .card-left {
    width: 50px;
  }

  .card-left-by-img {
    width: 50px;
    height: 50px;
    font-size: 18px;
  }

  .card-left-status {
    width: 20px;
    height: 20px;
    font-size: 8px;
  }

  .card-left-status .fa-check {
    width: 15px;
    height: 15px;
  }

  .card-right {
    max-width: calc(100% - 50px);
    margin-left: 15px;
  }

  .card-reply {
    padding: 10px 15px;
  }

  .assigned-title-blk {
    cursor: pointer;
  }

  .portal-status-icon .fa-check-circle {
    color: #92c353;
  }

  .portal-status-icon .fa-circle {
    color: #c4314b;
  }

  .portal-status-icon .fa-minus-circle {
    color: #c4314b;
  }

  .portal-status-icon .fa-clock {
    color: rgb(253, 185, 19);
  }

  .portal-status-icon .fad.fa-check-circle:before {
    color: #fff;
    opacity: 1;
  }

  .portal-status-icon .fad.fa-check-circle:after {
    color: #92c353;
    opacity: 1;
  }

  .post-area-img .portal-status-icon {
    font-size: 10px;
    position: absolute;
    bottom: 5px;
    right: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #fff;
  }

  .online-u {
    background-color: #5cb85c;
  }

  /* .assigned-title-info {display: none;position: absolute;background: #fff;border: 1px solid #E0EDFF;z-index: 2;left: -280px;top:5px;padding: 20px;min-width: 280px;border-radius: 2px;box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);} */

  .assigned-title-info:before {
    position: absolute;
    top: 0;
    height: 40px;
    background: #e0edff;
    width: 100%;
    left: 0;
    content: "";
  }

  .assigned-title-info article {
    position: relative;
    display: flex;
  }

  .assigned-title-info article figure {
    width: 32px;
    height: 32px;
    background: #9f1f63;
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    font-family: system-ui;
    margin-right: 10px;
    /* border: 1px solid #fff; */
    position: relative;
  }

  .assigned-title-info article figcaption h4 {
    color: #06172a;
    font-size: 14px;
    font-weight: 400;
  }

  .assigned-title-info article figcaption p {
    font-size: 12px;
    color: #5b5b5b;
    margin-bottom: 5px !important;
  }

  .assigned-title-info article figcaption h6 {
    font-size: 12px;
    color: #06172a;
    font-weight: 700;
    margin: 0;
  }

  .assigned-title-info article figcaption i {
    width: 15px;
    text-align: center;
  }

  .assigned-title-info article figcaption a {
    color: #5b5b5b;
  }

  .assigned-title-info article figcaption a:hover {
    color: #1899d2;
    text-decoration: underline !important;
  }

  .assigned-title-info article figure span.portal-status-icon {
    position: absolute;
    right: 0;
    bottom: -2px;
    display: flex;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #fff;
  }

  .rdt_TableBody .rdt_TableRow:last-child .assigned-title-info {
    top: -70px;
  }

  .rdt_TableBody .rdt_TableRow:nth-last-child(0n + 2) .assigned-title-info {
    top: -20px;
  }

  .assigned-title-right .assigned-title-info {
    right: -100%;
    left: auto;
  }

  .ticket-profile-box.assigned-title-right .assigned-title-info {
    right: 35px;
  }

  .assigned-title-info span.portal-status-icon i {
    color: #92c353;
    background: #fff;
    border-radius: 50%;
  }

  .assigned-title-info span.portal-status-icon i.offline {
    color: #f8a503;
  }

  .assigned-title-info .portal-status-icon .fad.fa-check-circle:after {
    color: inherit;
  }

  .comment-user-other .assigned-title-info {
    left: 43px;
  }

  .assigned-title-info.ticket-table-tiltle {
    left: -242px;
    min-width: 250px;
  }

  .bgBlue {
    background: #1899d2 !important;
  }

  .bgBlueShade {
    background: #585c75 !important;
  }

  .hover-color-blue:hover {
    color: #1899d2;
  }

  /* posts and feed chat css */

  /* chat css */
  .ps-fd-card img {
    max-width: 100%;
  }

  /* till 22-07-2022 */

  .post-user-img {
    width: 50px;
    height: 50px;
  }

  .post-area-right {
    max-width: calc(100% - 50px);
    margin-left: 14px;
  }

  .post-area-right .card {
    padding: 15px;
  }

  .textarea-btn-submit textarea.form-control {
    font-size: 16px;
    /* line-height: 1.2; */
    height: 42px;
  }

  .post-by-left {
    max-width: 50px;
  }

  .post-by-right {
    max-width: calc(100% - 50px);
    margin-left: 14px;
  }

  .ps-by-card-header {
    padding: 10px 15px;
  }

  .ps-by-card-header h5 {
    font-size: 16px;
  }

  .ps-by-card-header p {
    font-size: 14px;
  }

  .card-left {
    width: 50px;
  }

  .card-left-by-img {
    width: 50px;
    height: 50px;
    font-size: 18px;
  }

  .card-left-status {
    width: 20px;
    height: 20px;
    font-size: 8px;
  }

  .card-left-status .fa-check {
    width: 15px;
    height: 15px;
  }

  .card-right {
    max-width: calc(100% - 50px);
    margin-left: 15px;
  }

  .card-reply {
    padding: 10px 15px;
  }

  .card-reply .card-right h5 {
    /* font-size: 16px; */
  }

  .card-reply .card-right p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  div#app main.py-4.px-4 {
    padding: 10px !important;
  }

  .post-area-left {
    max-width: 45px;
  }

  .post-user-img {
    width: 45px;
    height: 45px;
  }

  .post-area-right {
    max-width: calc(100% - 45px);
    margin-left: 10px;
  }

  .post-area-right .card {
    padding: 10px;
  }

  .textarea-btn-submit textarea.form-control {
    font-size: 14px;

    height: 38px;
    line-height: 1.2;
  }

  .ps-by-card-header h5 {
    font-size: 14px;
  }

  .ps-by-card-header p {
    font-size: 13px;
  }

  .ps-fd-card-body {
    padding: 15px 0;
  }

  .post-by-left {
    max-width: 45px;
  }

  .post-by-img {
    width: 45px;
    height: 45px;
  }

  .post-by-right {
    max-width: calc(100% - 45px);
    margin-left: 10px;
  }

  .ps-by-card-header {
    padding: 10px;
  }

  .card-reply {
    padding: 10px;
  }

  .card-reply .card-right h5 {
    /* font-size: 14px; */
  }

  .card-reply .card-right p {
    font-size: 13px;
  }

  .card-left {
    width: 32px;
  }

  .card-left-by-img {
    width: 45px;
    height: 45px;
  }

  .card-right {
    max-width: calc(100% - 45px);
    margin-left: 10px;
  }

  .card-reply .card-right h5 span {
    font-size: 12px;
  }

  .ps-by-card-footer {
    padding: 10px;
  }
}

@media only screen and (max-width: 420px) {
  .textarea-btn-submit textarea.form-control {
    height: inherit;
    font-size: 13px;
  }
}

/* vue tribute css */

.tribute-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(215, 222, 232);
  background-color: #ffffff;
}

.tribute-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.tribute-container::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgb(215, 222, 232);
  background-color: #3499e3;
}

.tribute-container {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 999999;
  min-width: 220px;
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #d7dee8;
  border-radius: 2px;
  transform: translateY(15px);
}

.tribute-container ul {
  margin: 0 !important;
  margin-top: 2px !important;
  padding: 0 !important;
  list-style: none !important;
  background: #ffffff !important;
}

.tribute-container li {
  padding: 5px 5px !important;
  cursor: pointer !important;
}

.tribute-container li.highlight {
  background: #06172a !important;
  color: #fff;
}

.tribute-container li span {
  font-weight: bold !important;
}

.tribute-container li.no-match {
  cursor: default !important;
}

.tribute-container .menu-highlighted {
  font-weight: bold !important;
}

.v-tribute {
  width: 100% !important;
  height: auto !important;
  overflow-y: auto !important;
}

/***Post Images Css Start***/
.post-image-ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 -5px;
}

.post-image-ul > li {
  margin: 5px;
  width: calc(100% / 5);
}

.post-image-ul > li img {
  max-width: 100%;
  height: auto !important;
  width: 100%;
}

.comment-image-ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 -5px;
}

.comment-image-ul > li {
  margin: 5px;
}

/***Post Images Css End***/

/***Documnet css Start***/
.files-container-ul {
  padding: 0;
  margin: 0 -5px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.files-container-ul .files-container-li {
  margin: 5px;
}

ul.files-container-ul li .file-link {
  display: flex;
  align-items: center;
  color: #373635;
  text-decoration: none;
  padding: 5px 10px;
  background: #f3f2f1;
  border-radius: 3px;
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #e7e6e5;
}

ul.files-container-ul li a img {
  max-width: 30px;
  margin-right: 5px;
}

ul.files-container-ul li .file-link:hover {
  background: #e6ecf2;
}

ul.files-container-ul li .file-link:focus {
  background: #d3dee9;
  box-shadow: none;
  outline: none;
}

/***Documnet css End***/

/****File Uploader Css Start****/
.filepond--root {
  margin: 10px 0 !important;
  border: 1px solid;
  display: block;
  max-height: calc(100% - 1px) !important;
  overflow: hidden;
  background: #f3f2f1;
  border-radius: 3px;
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #e7e6e5;
}

.filepond--root > div {
  background: none !important;
  opacity: 1 !important;
  border-radius: 0 !important;
}

.filepond--image-preview-overlay-success {
  color: #1a6dbd !important;
}

.filepond--image-preview-overlay-failure {
  color: #d80e21 !important;
}

.filepond--image-preview-wrapper {
  border-radius: 0 !important;
}

.filepond--item {
  border: 1px solid #d7dee8 !important;
  border-radius: 5px !important;
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13) !important;
  overflow: hidden !important;
}

/****File Uploader Css End****/

i.fas.fa-check.offline {
  background: orange;
}

/* chat css */
.textarea-btn-submit > div:nth-child(1) {
  width: 100%;
}

.textarea-btn-submit > div:nth-child(1) textarea {
  border: 1px solid #d7dee8 !important;
  resize: none !important;
  font-size: 16px !important;
  padding: 10px !important;
  height: -webkit-fill-available;
  line-height: 1.2 !important;
  border-radius: 2px !important;
  min-height: 41px !important;
  overflow: hidden;
  font-family: "Segoe UI" !important;
}

.textarea-btn-submit > div:nth-child(1) textarea::-webkit-input-placeholder {
  color: #cbced0;
}

.textarea-btn-submit > div:nth-child(1) textarea::-moz-placeholder {
  color: #cbced0;
}

.textarea-btn-submit > div:nth-child(1) textarea:-ms-input-placeholder {
  color: #cbced0;
}

.textarea-btn-submit > div:nth-child(1) textarea:-moz-placeholder {
  color: #cbced0;
}

.textarea-btn-submit > div:nth-child(1) textarea:focus {
  box-shadow: 0 0 0 1.5px rgba(52, 144, 220, 0.25);
  outline: none;
}

.textarea-btn-submit > div:nth-child(1) > div {
  min-height: 41px !important;
}

.ps-by-card-header p a {
  color: #467fcf;
}

.post-textarea__control {
  margin-top: 10px;
  margin-bottom: 20px;
}

.post-textarea__input {
  background-color: #fff;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
  padding: 9px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  border: 1px solid #dedede;
  /* &:focus,
  &:active {
    outline: none;
    border: 1px solid #3fb9de;
  }
  &::placeholder {
    color: #a4b0be;
    font-size: 14px;
  } */
}

.post-textarea__highlighter {
  padding: 0px !important;
}

.post-textarea__suggestions__list {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 14px;
  max-height: 200px;
  overflow: auto;
}

.post-textarea__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.post-textarea__suggestions__item — focused {
  background-color: #daf4fa;
}

.assigned-title-info article figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.chat-side-bar {
  overflow: hidden;
  border-radius: 50%;
  background: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row.support-team-skeleton {
  margin-left: auto;
}

.row.support-team-skeleton li {
  list-style: none;
  margin: 0 10px;
}

/* comment css for post and feeds */
.hide-reply {
  display: none;
}

.show-reply {
  display: block;
}

.hide-comment-cnt {
  display: none;
}

/* course module online class css 13-06-2022 */

.add-c-f .form-group.d-inline-flex {
  margin-top: 1.8rem;
}

.form-check-grps div {
  display: inline-block;
}

.form-check-grps .send-via-blk {
  display: inline-flex;
  align-items: center;
}

.form-check-grps .send-via-blk > label {
  white-space: nowrap;
  margin-bottom: 3px;
  margin-right: 30px;
  font-weight: 600;
}

.form-check-grps {
  align-items: center;
  display: flex;
  padding-top: 38px;
}

.form-group-blk .custom-select-box .css-1pahdxg-control,
.form-group-blk .custom-select-box .css-yk16xz-control,
.form-group-blk .custom-select-box .css-1fhf3k1-control {
  height: auto !important;
}

/* End of course module online class css 13-06-2022 */
/* Live Chat related Css */

.custom-table-div.filter-search-icon.card.card-table-custom .live-chat-box {
  height: 500px;
}

.live-chat-box p {
  margin-top: auto;
  text-align: center;
  vertical-align: middle;
}

/* End of Live Chat related Css */

.rdw-link-modal.custom-picker .block-picker + div {
  display: none;
}

.rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child {
  max-width: 52px;
}

.as-buttons a:first-child {
  margin-left: 0;
}

.btn.rtimg-icon-btn {
  display: flex;
  align-items: center;
}

.btn.rtimg-icon-btn img {
  margin-right: 3px;
  width: 20px;
}

.filter-scroll .btn-arrow {
  position: absolute;
  /* border: 1px solid #e0edff; */
  border-radius: 2px;
  padding: 6px 6px;
  background: #fff;
  font-size: 14px;
  top: 0;
  z-index: 2;
  display: none;
}

.filter-scroll .btn-arrow.btn-scroll-left {
  left: 0;
  right: auto;
}

.filter-scroll .btn-arrow.btn-scroll-right {
  right: 0;
  left: auto;
}

.filter-scroll .btn-arrow i {
  margin: inherit;
}

.filter-scroll-inner {
  padding: initial;
}

.filter-scroll {
  min-width: 600px;
  position: relative;
}

.filter-scroll-inner {
  max-width: 600px;
  overflow-x: auto;
  overflow-y: visible;
  display: flex;
  position: static;
  left: 0;
  top: 0;
  min-height: 470px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.filter-scroll-inner::-webkit-scrollbar {
  display: none;
}

.none_top_scroll {
  z-index: 11;
  position: absolute;
  max-width: 500px;
  min-width: 500px;
  overflow-x: auto;
  height: 15px;
  top: -17px;
  display: block;
  overflow-y: clip;
}

.filter-scroll {
  top: -17px;
}

.filter-scroll .btn-arrow {
  display: block;
}

.filter-scroll-inner {
  padding: 0px 30px 0 30px;
}

.table-overflow-show .sc-hHLeRK.kaLCam {
  overflow: inherit;
}

.custom-table-div.filter-search-icon.card.card-table-custom .live-chat-box {
  height: 500px;
}

.live-chat-box p {
  margin-top: auto;
  text-align: center;
  vertical-align: middle;
}

/* End of Live Chat related Css */

.rdw-link-modal.custom-picker .block-picker + div {
  display: none;
}

.rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child {
  max-width: 52px;
}

.as-buttons a:first-child {
  margin-left: 0;
}

.btn.rtimg-icon-btn {
  display: flex;
  align-items: center;
}

.btn.rtimg-icon-btn img {
  margin-right: 3px;
  width: 20px;
}

.filter-scroll .btn-arrow {
  position: absolute;
  /* border: 1px solid #e0edff; */
  border-radius: 2px;
  padding: 6px 6px;
  background: #fff;
  font-size: 14px;
  top: 0;
  z-index: 2;
  display: none;
}

.filter-scroll .btn-arrow.btn-scroll-left {
  left: 0;
  right: auto;
}

.filter-scroll .btn-arrow.btn-scroll-right {
  right: 0;
  left: auto;
}

.filter-scroll .btn-arrow i {
  margin: inherit;
}

.filter-scroll-inner {
  padding: initial;
}

.filter-scroll {
  min-width: 600px;
  position: relative;
}

.filter-scroll-inner {
  max-width: 600px;
  overflow-x: auto;
  overflow-y: visible;
  display: flex;
  position: static;
  left: 0;
  top: 0;
  min-height: 470px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.filter-scroll-inner::-webkit-scrollbar {
  display: none;
}

.none_top_scroll {
  z-index: 11;
  position: absolute;
  max-width: 500px;
  min-width: 500px;
  overflow-x: auto;
  height: 15px;
  top: -17px;
  display: block;
  overflow-y: clip;
}

.filter-scroll {
  top: -17px;
}

.filter-scroll .btn-arrow {
  display: block;
}

.filter-scroll-inner {
  padding: 0px 30px 0 30px;
}

.table-overflow-show .sc-hHLeRK.kaLCam {
  overflow: inherit;
}

/* Programes css */

.program-table .system-administration-table.table-responsive {
  margin-top: 35px;
}

.program-table.card-table-custom .rdt_TableHeader {
  display: none;
}

/* End program css */

/* custom main css */
.custom-main-table.custom-table-div .rdt_TableCol {
  font-size: 14px;
  font-weight: 600;
  color: #06172a;
}

.custom-main-table.custom-table-div .rdt_TableHead {
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
}

.custom-main-table.custom-table-div {
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
}

.custom-main-table.custom-table-div .rdt_TableRow {
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffffff;
  min-height: 48px;
}

/* custom main css */

.box-style-new2.bg-pur a {
  background: #8e00a7;
}

.topic-add-modal .modal-dialog.modal-dialog-centered.modal-xl {
  max-width: 950px;
}

/* Dashboard dropdown collapse css  start */
.admin-home-acc.accordion > .card > .card-header {
  padding: 15px;
  background-color: #fff;
}

.admin-home-acc.accordion > .card {
  background: #fff;
  position: relative;
}

.active-status {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #f8fafc;
  border-radius: 50%;
  padding: 3px;
  font-size: 7px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-area-left {
  max-width: 60px;
}

.post-area-right {
  max-width: calc(100% - 60px);
  margin-left: 30px;
  width: 100%;
}

.post-area {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #252423;
  font-weight: 700;
}

.admin-home-acc.accordion > .card > .card-header h2 button > i {
  color: var(--topbar-color);
  font-size: 26px;
  transform: rotate(180deg);
  transition: 0.3s ease-in-out;
}

.admin-home-acc.accordion > .card > .card-header h2 button.collapsed > i {
  transform: rotate(0deg);
}

/*Dashboard dropdown collapse css start*/

.filter-scroll-inner {
  pointer-events: initial;
}

.btn-type-filter.dropdown-comman {
  pointer-events: initial;
}

/* Related to custom delete btn */

.files-list-item.frm-group {
  display: inline-block;
  width: min-content;
}

.files-list-item.frm-group .attached-file-ul li {
  padding-right: 40px;
}

.table-file-list .attached-file-ul {
  margin: 0;
  margin-top: 5px;
}

.table-file-list.frm-group {
  margin-top: 15px;
}

.scroll-tabs.filter-scroll {
  top: initial;
  display: flex;
  margin-bottom: 60px;
}

.scroll-tabs.filter-scroll .filter-scroll-inner {
  max-width: 100%;
  pointer-events: auto;
}

.scroll-tabs.filter-scroll .filter-scroll-inner .nav.nav-pills {
  overflow: visible;
}

.scroll-tabs.filter-scroll
  .filter-scroll-inner
  .nav-pills
  .nav-item
  .nav-link::after {
  height: 3px;
}

.scroll-tabs.filter-scroll .btn-arrow {
  background-color: #f3f6f9;
}

/* End of related to custom delete btn */

/* Reregistration > Course and Programme related css */

.custom-table-div.filter-search-icon.card.card-table-custom.c-a-p-c-t {
  position: relative;
}

.custom-table-div.filter-search-icon.card.card-table-custom.c-a-p-c-t .btn-sec {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.p-a-c .card-body-inr.card-body-info {
  position: relative;
}

.p-a-c .btn-sec.t-b-s {
  position: absolute;
  bottom: -7px;
}

.p-a-c
  .topic-add-modal
  .modal-dialog.modal-dialog-centered.modal-xl
  .modal-body {
  padding: 30px;
}

/* End of Reregistration > Course and Programme related css */

.img-preview-modal button.close {
  position: absolute;
  background-color: hsla(0, 0%, 100%, 0.6588235294117647);
  border-radius: 0;
  right: 20px;
  height: 25px;
  width: 25px;
  top: 20px;
  color: #000000;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  opacity: 1;
}

.img-preview-modal .modal-header {
  padding: 0;
  border: none;
}

.img-preview-modal .modal-body {
  padding: 0;
}

.editor-static-blk {
  border-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #f1f1f1;
  justify-content: flex-start;
  background: #eef1f500;
  font-size: 15px;
  margin-bottom: 5px;
  padding: 10px 10px;
  background-color: #eef1f5;
  max-height: 100% !important;
  overflow-y: auto;
}

.editor-static-blk-info p {
  margin: initial;
}

.editor-static-blk h4 {
  line-height: 1.2;
  font-size: 18px;
  color: #252423;
  font-weight: 700;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.dKvXPZ {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  min-height: 56px;
  background-color: #ffffff;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: rgba(0, 0, 0, 0.12);
}

.kkNaNQ {
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gENuGq {
  position: relative;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: inherit;
  color: inherit;
  margin-top: 1px;
}

.bqKZDG {
  margin: 0 24px;
}

.gKsGGb {
  cursor: pointer;
  height: 24px;
  min-width: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 8px;
  padding-right: 12px;
  box-sizing: content-box;
  font-size: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  direction: ltr;
}

.hGeawu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  white-space: nowrap;
}

.iNniLt:disabled {
  cursor: unset;
  color: rgba(0, 0, 0, 0.18);
  fill: rgba(0, 0, 0, 0.18);
}

.iNniLt {
  position: relative;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 8px;
  margin: px;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: rgba(0, 0, 0, 0.54);
  fill: rgba(0, 0, 0, 0.54);
  background-color: transparent;
}

.iNniLt:hover:not(:disabled) {
  background-color: rgba(0, 0, 0, 0.12);
}

.exREar {
  margin: 0 4px;
}

.rdw-link-modal.custom-picker .block-picker div {
  height: 30px !important;
  line-height: 1.8;
  font-size: 15px !important;
}

.rdw-link-modal.custom-picker .block-picker div span div {
  height: 22px !important;
}

.rdw-link-modal.custom-picker {
  width: 195px;
  height: 155px;
}

.rdw-link-modal.custom-picker .block-picker {
  box-shadow: unset !important;
}

/* End of Live Chat related Css */

.filter-button-group .add-ticket-blk {
  margin-left: auto;
}

.add-ticket-blk .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .new-sec-permission-mgt .form-group-save-cancel{display: flex; align-items: center;} */
.form-group-save-cancel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.form-group-save-cancel .invalid-feedback {
  width: 100%;
}

/* Live Chat related Css */

.custom-table-div.filter-search-icon.card.card-table-custom .live-chat-box {
  height: 500px;
}

.live-chat-box p {
  margin-top: auto;
  text-align: center;
  vertical-align: middle;
}

/* End of Live Chat related Css */

.popup-item-vertical-center {
  display: flex;
  align-items: center;
}

.popup-item-border-right {
  border: aliceblue;
  border-width: 0px;
  border-right-width: 2px;
  border-style: solid;
}

.range-of-recurrence .custom-control-label {
  min-width: 100px;
}

.prefix-sufix-box {
  display: flex;
  align-items: center;
}

.prefix-sufix-box .form-control {
  min-width: 135px;
}

.recurrencePattern .basic-info-box-list ul li {
  align-items: center;
}

.recurrencePattern .basic-info-box-list ul li p {
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 14px;
}

.recurrencePattern .form-control {
  min-width: 100px;
}

@media only screen and (max-width: 992px) {
  .modal-dialog {
    max-width: 90%;
  }

  .prefix-sufix-box {
    flex-wrap: wrap;
  }

  .prefix-sufix-box .form-control {
    margin-bottom: 10px;
  }

  .dailyWeekdayHead {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .dailyWeekdayHead .custom-control {
    margin: 0 10px 0 0 !important;
  }

  .recurrencePattern .basic-info-box-list ul {
    padding: 0;
  }

  .prefix-sufix-box .custom-radio {
    margin-bottom: 10px;
  }

  .range-of-recurrence .popup-item-vertical-center {
    flex-wrap: wrap;
  }

  .range-of-recurrence .react-datepicker-wrapper {
    width: 100%;
  }

  .topic-add-modal .modal-body {
    padding: 30px;
  }

  .topic-add-modal .form-group-save-cancel button {
    margin-bottom: 10px;
  }

  .recurrencePattern .col-md-10,
  .recurrencePattern .col-md-2 {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.filter-scrolable .filter-search-bar-blk .filter-button-group {
  width: 100%;
}

.marLeft135 .filter-search-bar-blk {
  margin-left: 135px;
}

.top-5 .filter-search-bar-blk {
  top: -5px;
}

.checkbox-container input[type="checkbox"]:checked {
  background-color: #00a651 !important;
}

.custom-check input[type="checkbox"] {
  cursor: pointer;
}

.custom-check input[type="checkbox"]:after {
  content: " ";
  background-color: #ef8700;
  display: inline-block;
  visibility: visible;
}

.custom-check input[type="checkbox"]:checked:after {
  content: "\2714";
}

.scroll-tabs.filter-scroll.c-o-c-a ul#tabs {
  border-bottom: unset;
  padding-left: 4px;
}

.custom-table-div.filter-search-icon.card.card-table-custom.new-datatable-blk1
  .filter-search-bar-blk {
  margin-left: 135px;
  top: -8px;
}

@media only screen and (max-width: 1700px) {
  .filter-scroll {
    min-width: 500px;
    position: relative;
  }

  .filter-scroll-inner {
    max-width: 500px;
    overflow-x: auto;
    overflow-y: visible;
    display: flex;
    position: static;
    left: 0;
    top: 0;
    min-height: 470px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .filter-scroll-inner::-webkit-scrollbar {
    display: none;
  }

  .none_top_scroll {
    z-index: 11;
    position: absolute;
    max-width: 500px;
    min-width: 500px;
    overflow-x: auto;
    height: 15px;
    top: -17px;
    display: block;
    overflow-y: clip;
  }

  .filter-scroll {
    top: -17px;
  }

  .filter-scroll .btn-arrow {
    display: block;
  }

  .filter-scroll-inner {
    padding: 0px 30px 0 20px;
  }
}

@media only screen and (max-width: 1500px) {
  .filter-scroll {
    min-width: 300px;
    position: relative;
  }

  .filter-scroll-inner {
    max-width: 300px;
    overflow-x: auto;
    overflow-y: visible;
    display: flex;
    position: static;
    left: 0;
    top: 0;
    min-height: 470px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .filter-scroll-inner::-webkit-scrollbar {
    display: none;
  }

  .none_top_scroll {
    max-width: 400px;
    min-width: 400px;
  }
}

@media only screen and (max-width: 1300px) {
  .filter-scroll {
    min-width: 335px;
    position: relative;
  }

  .filter-scroll-inner {
    max-width: 340px;
    overflow-x: auto;
    overflow-y: visible;
    display: flex;
    position: static;
    left: 0;
    top: 0;
    min-height: 470px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .filter-scroll-inner::-webkit-scrollbar {
    display: none;
  }

  .none_top_scroll {
    max-width: 340px;
    min-width: 340px;
  }
}

.filter-button-group .add-ticket-blk {
  margin-left: auto;
}

.add-ticket-blk .btn {
  display: flex;
  align-items: center;
}

.filter-scrolable .filter-search-bar-blk .filter-button-group {
  width: 100%;
}

.block-picker div:last-child {
  position: unset !important;
}

.marLeft135 .filter-search-bar-blk {
  margin-left: 135px;
}

.top-5 .filter-search-bar-blk {
  top: -5px;
}

/* added by yash for html editor start */
.editor-static-blk {
  border-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #f1f1f1;
  justify-content: flex-start;
  background: #eef1f500;
  font-size: 15px;
  margin-bottom: 5px;
  padding: 10px 10px;
  background-color: #eef1f5;
  max-height: 100% !important;
  overflow-y: auto;
}

.editor-static-blk-info p {
  margin: initial;
}

.editor-static-blk h4 {
  line-height: 1.2;
  font-size: 18px;
  color: #252423;
  font-weight: 700;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

/* added by yash for html editor end */

.admin-home-acc.accordion > .card > .card-header h3 div span .firstIcon {
  font-size: 32px;
  width: 50px;
  text-align: center;
  margin-right: 10px;
  color: var(--topbar-color);
}

.admin-home-acc.accordion > .card > .card-header h3 div span button i {
  font-size: 16px;
  /* width: 50px; */
  text-align: center;
  color: "#fff" !important;
}

.admin-home-acc.accordion > .card > .card-header h3 div span {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-home-acc.accordion > .card > .card-header h3 div span div {
  display: flex;
  align-items: center;
}

.admin-home-acc.accordion > .card > .card-header h3 div span button {
  display: flex;
  align-items: center;
  float: right;
}

.quik-link-list .add-ticket-blk {
  display: flex;
  flex-wrap: wrap;
}

.quik-link-list .add-ticket-blk .btn a {
  color: #fff;
}

.sub-menu-right-block.pmc-m-topic
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol:last-child {
  max-width: 52px;
  text-align: right;
  padding-right: 25px !important;
}

.sub-menu-right-block.pmc-m-topic .assessment-08.module-topic {
  float: right;
  margin-right: 42px;
}

.topic-add-modal-content .center-label {
  display: flex;
  align-items: flex-start;
}

.border-remove {
  /* border: 1px 0px 0px 0px !important; */
  border-bottom: 0px;
  border-radius: 0%;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 0px;
  border-right: 0px;
}

.last-column-table {
  min-width: 120px !important;
}

.learning-quiz-wrap .table-custom-info td,
.learning-quiz-wrap .table-custom-info th {
  width: 50%;
}

.icon-w-text .icon-w {
  width: 20px;
  display: inline-block;
  text-align: center;
}

.icon-area {
  display: flex;
}

.icon-area-row {
  margin-right: 10px;
}

.icon-area .icon-area-row:first-child {
  width: 95px;
  border: #dde3e9;
  border-width: 0px;
  border-right-width: 2px;
  border-style: solid;
}

.accordion.ac_over_none > .card {
  overflow: inherit;
}

.fa-refresh:before {
  content: "\f021";
}

.fa-refresh:before {
  content: "\f021";
}

.scroll-height .Tickets-body {
  height: 35vh;
}

.border-remove {
  /* border: 1px 0px 0px 0px !important; */
  border-bottom: 0px;
  border-radius: 0%;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 0px;
  border-right: 0px;
}

.last-column-table {
  min-width: 120px !important;
}

.learning-quiz-wrap .table-custom-info td,
.learning-quiz-wrap .table-custom-info th {
  width: 50%;
}

.icon-w-text .icon-w {
  width: 20px;
  display: inline-block;
  text-align: center;
}

.icon-area {
  display: flex;
}

.icon-area-row {
  margin-right: 10px;
}

.icon-area .icon-area-row:first-child {
  width: 95px;
  border: #dde3e9;
  border-width: 0px;
  border-right-width: 2px;
  border-style: solid;
}

.accordion.ac_over_none > .card {
  overflow: inherit;
}

.fa-refresh:before {
  content: "\f021";
}

.fa-refresh:before {
  content: "\f021";
}

/* .scroll-height .Tickets-body{height: 35vh;} */

/* 
.selectBox {
  min-width: 151px;
  width: 151px;
}
.selectBox .css-1okebmr-indicatorSeparator {
  display: none;
}
.selectBox .css-yk16xz-control {
  border-color: #e0edff;
  min-height: 35px;
}
.selectBox .css-tlfecz-indicatorContainer {
  padding: 4px;
}
.selectBox .css-yk16xz-control:hover {
  border-color: #e0edff !important;
  background: var(--active-color);
  border-color: var(--active-color);
}
.selectBox .css-1wa3eu0-placeholder {
  color: #212529;
  font-size: 14px;
  font-weight: 600;
}
.selectBox .css-lpahdxg-control {
  box-shadow: none !important;
  border-width: 0px !important;
}
.css-tlfecz-indicatorContainer {
  color: #212529 !important;
  max-width: 22px !important;
}

.css-26l3qy-menu {
  width: 300px;
  min-width: 300px;
}
.css-1pahdxg-control {
  color: #fff !important;
  background: #000 !important;
  box-shadow: none !important;
  border-color: #000 !important;
  border-radius: 0px !important;
}
.css-1pahdxg-control .css-1wa3eu0-placeholder {
  color: #fff !important;
}
.css-g1tu0rj-indicatorContainer {
  color: #fff !important;
  max-width: 22px !important;
} */

.reg-st-tbl div#assessment-table-main_filter.pt-5 {
  padding-top: unset !important;
}

.form-icon-group i {
  position: absolute;
  right: 15px;
  top: 15px;
}

.online-recording {
  pointer-events: none;
  opacity: 0.3;
}

.assesment-dropdown .dropdown-filter-pagination {
  min-height: 350px;
  min-width: 300px;
}

.assesment-dropdown .css-1rhbuit-multiValue {
  max-width: 90px;
}

.assesment-dropdown .css-yt9ioa-option {
  font-size: 13px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  overflow: hidden;
}

.assesment-dropdown .css-1n7v3ny-option {
  font-size: 13px !important;
  background: #eff0f2 !important;
  border-bottom: transparent !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  overflow: hidden;
}

.assesment-dropdown .dropdown.multiselect .dropdown-menu.remove-list-style ul {
  max-height: initial;
  overflow: inherit;
  border: 0px;
  background-color: transparent !important;
}

.assesment-dropdown .dropdown-menu.remove-list-style {
  border: 0px !important;
  background-color: transparent !important;
}

.assesment-dropdown .css-lpahdxg-control {
  box-shadow: none !important;
  border-width: 0px !important;
}

.assesment-dropdown .css-1pahdxg-control {
  box-shadow: none !important;
  border-color: hsl(0, 0%, 80%) !important;
}

.assesment-dropdown .css-1pahdxg-control:hover {
  border-color: hsl(0, 0%, 80%) !important;
}

.assesment-dropdown .css-tlfecz-indicatorContainer {
  display: none !important;
}

.assesment-dropdown .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.assesment-dropdown .css-xb97g8:hover {
  background-color: #ef8700fc !important;
  color: #ffffff !important;
}

.assesment-dropdown .css-1wa3eu0-placeholder {
  font-size: 13px !important;
}

.custom-table-div.filter-search-icon.card.card-table-custom
  .assigned-title-info {
  top: auto;
  bottom: -19px;
}

/*Two line text css lakhan*/
.new-table-box .overflow-ellipsis2 a,
.new-table-box .rdt_TableRow .textLimit2,
.textLimit75,
.textLimit100,
.textLimit50,
.textLimit150 {
  overflow: hidden;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.new-table-box .overflow-ellipsis2 a,
.new-table-box .rdt_TableRow .titleTextLimit2,
.titleTextLimit75,
.titleTextLimit100,
.titleTextLimit50,
.titleTextLimit30,
.titleTextLimit150 {
  overflow: hidden;
}

.new-table-box.custom-table-div .rdt_TableCol {
  white-space: normal;
}

.learningMethodText {
  max-width: 111px;
  white-space: normal;
  word-break: normal;
}

.new-table-box .rdt_TableRow .textLimit2 {
  max-width: 100px;
}

.new-table-box .rdt_TableRow .textLimit100 {
  max-width: 100px;
}

.new-table-box .rdt_TableRow .textLimit75 {
  max-width: 75px;
}

.new-table-box .rdt_TableRow .textLimit50 {
  max-width: 60px;
  word-wrap: break-word;
}

.new-table-box .rdt_TableRow .textLimit150 {
  max-width: 150px;
}

.new-table-box .rdt_TableRow .titleTextLimit100 {
  max-width: 100px;
}

.new-table-box .rdt_TableRow .titleTextLimit75 {
  max-width: 75px;
}

.new-table-box .rdt_TableRow .titleTextLimit50 {
  max-width: 50px;
}

.new-table-box .rdt_TableRow .titleTextLimit30 {
  max-width: 30px;
}

.new-table-box .rdt_TableRow .titleTextLimit150 {
  max-width: 150px;
}

.overflow-ellipsis2.fst_line2 .titleTextLimit50 {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.overflow-ellipsis2.fst_line2 .titleTextLimit30 {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.overflow-ellipsis2.fst_line2 a {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.assigned-title-itm.fst_line2 .textLimit2 {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.overflow-ellipsis2.fst_line2 a.textLimit100 {
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.icon-success {
  margin-left: 2px;
}

.icon-danger {
  margin-left: 2px;
}

.icon-success i.fal.fa-check {
  color: #00c915;
}

.icon-danger i.fal.fa-times {
  color: #891822;
}

.sc-dJjYzT.hgWSFM.rdt_TableBody
  .sc-jrQzAO.OTlLy.rdt_TableRow:first-child
  .assigned-title-info {
  top: 0;
  bottom: auto;
}

.days-overdue-cell-width .sc-jrQzAO.OTlLy.rdt_TableRow .rdt_TableCell {
  min-width: 72px !important;
}

.days-overdue-cell-width
  .sc-jrQzAO.OTlLy.rdt_TableRow
  .rdt_TableCell
  .textLimit100 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  white-space: normal;
  -webkit-box-orient: vertical;
}

/* Second lines number of lines to show */
.new-table-box .overflow-ellipsis21 a,
.new-table-box .rdt_TableRow .textLimit21,
.textLimit76,
.textLimit101,
.textLimit51,
.textLimit130,
.textLimit151,
.textLimit250 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.textLimit3Line76 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 2;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.overflow-ellipsis21.textLimit101 {
  width: 100px;
}

.overflow-ellipsis21.textLimit51 {
  width: 51px;
}

.textLimit51 {
  width: 51px;
}

.textLimit76 {
  width: 76px;
}

.textLimit103 {
  width: 103px;
}

.textLimit130 {
  width: 130px;
}

.textLimit250 {
  width: 376px;
}

.rdt_Table .rdt_TableRow .rdt_TableCell:first-child {
  min-width: 155px !important;
}

.rdt_Table .rdt_TableRow .rdt_TableCell:first-child span,
.rdt_Table .rdt_TableRow .rdt_TableCell:first-child span a {
  overflow: inherit;
  text-overflow: inherit;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

/***/

.poup-item-box .icon-area .icon-area-row:first-child {
  width: initial;
  padding-right: 10px;
}

.matched-red {
  color: #f00;
}

/* submission dropdown input css */
.input_wt01 input {
  width: 80px;
}

.resultstatus_flex {
  display: flex;
  align-items: center;
}

.resultstatus_flex span {
  margin: 0px 8px 0 10px;
}

.flex_wt_loder {
  display: flex;
  align-items: center;
}

.form-control.custom-select-box.wdtcontrl {
  max-width: 100px;
}

.form-control.custom-select-box.wdtcontrlrobot {
  max-width: 123px;
}

.import_capture_inputs {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.import_capture_inputs .form-control {
  margin-bottom: 0px;
}

.import_capture_inputs .btn {
  height: 37px;
  margin-left: 5px;
}

.import_capture_inputs form {
  width: 100%;
}

.input_wt01 input {
  width: 80px;
}

.application-status p {
  width: 100px;
  white-space: break-spaces;
  word-break: break-all;
}

.days-overdue-cell-width .rdt_TableCell {
  padding: 5px 5px;
  min-width: 70px !important;
}

.custom-table-div.filter-search-icon.card.card-table-custom
  .rdt_TableRow:first-child
  .rdt_TableCell
  .assigned-title-info {
  top: -56px;
  bottom: auto;
}

.new-table-box .overflow-ellipsis21 a,
.new-table-box .rdt_TableRow .textLimit21,
.textLimit76,
.textLimit101,
.textLimit51,
.textLimit151 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 2; 
  line-clamp: 2; */
  white-space: normal;
  -webkit-box-orient: vertical;
}

.overflow-ellipsis21.textLimit101 {
  width: 100px;
}

.overflow-ellipsis21.textLimit51 {
  width: 51px;
}

.textLimit51 {
  width: 51px;
}

.textLimit76 {
  width: 76px;
}

.textLimit103 {
  width: 103px;
}

.rdt_Table .rdt_TableRow .rdt_TableCell:first-child {
  min-width: 155px !important;
}

.rdt_Table .rdt_TableRow .rdt_TableCell:first-child span,
.rdt_Table .rdt_TableRow .rdt_TableCell:first-child span a {
  overflow: inherit;
  text-overflow: inherit;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.sidebar-left .sub-menu-content-block .sub-menu-left-block {
  top: 48px;
  box-shadow: 0px 0px 8px #c5bebe;
  border-right: solid 1px #d7dee8;
}

.sidebar-left ul li:hover .sub-menu-content-block {
  /* animation-name: sidemenuhover;
  animation-iteration-count: 1;
  animation-duration: 0.9s;
  animation-delay: 2s;
  animation-fill-mode: forwards; */
  visibility: inherit;
  transition-duration: 0.4s;
}

@keyframes sidemenuhover {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.mentored-date-icon {
  width: 15px;
  height: auto !important;
}

span.mentored-subject-status {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dashboard-view-button {
  border: none;
  background: no-repeat;
  color: #000;
}

.dashboard-disable-button {
  border: none;
  background: no-repeat;
}

.form-group-blk .custom-select-box .css-yk16xz-control {
  height: 42px !important;
}

.form-group-blk > .is-multi .css-yk16xz-control {
  height: auto !important;
}

.Netsuite-Link-Width {
  width: 300px;
}

.custom-control {
  z-index: inherit;
}

/*hide arrow  Chrome, Safari, Edge, Opera */
.arrowhide.input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* hide arrow Firefox */
.arrowhide.input[type="number"] {
  -moz-appearance: textfield;
}

/*show arrow  Chrome, Safari, Edge, Opera */
.arrowshow.input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance:unset !important;  
}

/* show arrow Firefox */
.arrowshow.input[type="number"] {
  -moz-appearance:unset !important;
}

.sidebar-left .sub-menu-content-block .sub-menu-left-block {
  top: 48px;
  box-shadow: 0px 0px 8px #c5bebe;
  border: 1px solid #d7dee8;
  border-right: solid 1px #d7dee8;
  z-index: -1;
  position: static;
}

.sidebar-left .sub-menu-content-block {
  visibility: hidden;
  transition-delay: 0.3s;
  transition-duration: 0.3s;
  position: absolute;
  z-index: -1;
  top: 0;
  left: -250px;
  height: 100%;
}

.sidebar-left ul li:hover .sub-menu-content-block {
  left: 79px;
  visibility: visible;
  /* display: block; */
}

.side-bar-nav li.active-li .sub-menu-content-block {
  left: 79px !important;
}

/* .sidebar-left .sub-menu-left-block{
  transform: translateX(-155%);
  transition: 0.3s ease-in-out;
} */

/* .sidebar-left ul li:hover .sub-menu-left-block{
  transform: translateX(0%);
} */

/* .sidebar-left ul li:hover .sub-menu-content-block{display: block;} */
/* .sub-sub-tab {padding: 18px !important;} */
/* chat room start */
.ps-fd-card {
  display: flex;
  flex-direction: column-reverse;
  height: calc(100vh - 221px);
  position: relative;
}

.ps-fd-card svg {
  fill: var(--active-color);
  position: static;
  z-index: 2;
  align-self: center;
}

.ps-fd-card-body .assigned-title-info {
  left: 100%;
  /* right: auto; */
}

.ps-fd-card-body {
  overflow: auto;
  margin: 0 0 15px;
  padding-right: 20px;
  padding-bottom: 0;
}

.ps-fd-card-body .post-by + .post-by {
  margin: 0;
  margin-bottom: 15px;
}

.ps-fd-card-body {
  overflow: hidden;
}

.modal-update .flexible-modal {
  position: fixed !important;
}

.flexible-modal .body {
  height: 100%;
  width: 100%;
  overflow-y: inherit;
}

.flexible-modal .body iframe {
  height: 100%;
  width: 100%;
}

.iframe_modal_full .flexible-modal .body {
  height: 100%;
  width: 100%;
  overflow-y: inherit !important;
}

.iframe_modal_full h3 {
  display: none;
}

.iframe_modal_full .body {
  padding: 0px !important;
}

.post-by-img {
  width: 32px !important;
  height: 32px !important;
  font-size: 14px !important;
}

.like-list .post-by-img {
  overflow: inherit;
  margin-right: 10px;
}

.like-list .post-by-img img {
  border-radius: 50%;
}

.media-icon.rounded-circle .online-status {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #f8fafc;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  padding: 3px;
  font-size: 7px;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.media-icon.rounded-circle .online-status i {
  width: 15px;
  height: 15px;
  font-size: 7px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
}

.media-icon.rounded-circle .online-status i.fa-check {
  background: #94c456;
}

.online-status {
  position: relative;
}

.online-status .portal-status-icon {
  font-size: 10px;
  position: absolute;
  bottom: -2px;
  right: 0px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.user-profile .user-drop-down-img .portal-status-icon {
  font-size: 10px;
  position: absolute;
  bottom: -2px;
  right: 0px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
  line-height: 0;
}

.status-container .portal-status-icon {
  font-size: 12px;
  width: 15px;
  min-width: 15px;
  display: inline-block;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-right: 5px;
}

.portal-status-icon .fa-check-circle {
  color: #92c353;
}

.portal-status-icon .fa-circle {
  color: #c4314b;
}

.portal-status-icon .fa-minus-circle {
  color: #c4314b;
}

.portal-status-icon .fa-clock {
  color: rgb(253, 185, 19);
}

.Chat-card .portal-status-icon {
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.Chat-head-lft .icon .portal-status-icon {
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 0px;
  font-size: 14px;
}

.portal-status-icon .fad.fa-check-circle:before {
  color: #fff;
  opacity: 1;
}

.portal-status-icon .fad.fa-check-circle:after {
  color: #92c353;
  opacity: 1;
}

.assigned-title-info article figure span.portal-status-icon {
  position: absolute;
  right: 0;
  bottom: -2px;
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.assigned-title-info span.portal-status-icon i {
  color: #92c353;
  background: #fff;
  border-radius: 50%;
}

.assigned-title-info span.portal-status-icon i.offline {
  color: #f8a503;
}

.assigned-title-info .portal-status-icon .fad.fa-check-circle:after {
  color: inherit;
}

.post-area-img .portal-status-icon {
  font-size: 10px;
  position: absolute;
  bottom: -2px;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.online-status .portal-status-icon {
  font-size: 10px;
  position: absolute;
  bottom: -2px;
  right: 0px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.online-u {
  background-color: #5cb85c;
}

.offline-u {
  background-color: #f7941d !important;
}

.donotdisturb-u {
  background-color: #ff0102 !important;
}

.or-line-post-ps {
  margin-bottom: 15px;
}

.or-line-post {
  width: 100%;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 12px;
}

.or-line-post::after {
  content: "";
  position: absolute;
  /* background: #06172A; */
  background: #e6ebf1;
  top: 8px;
  left: 0;
  height: 1px;
  width: 100%;
}

.or-line-post .post-by-time {
  position: absolute;
  z-index: 1;
  background: #f3f6f9;
  padding: 0px 8px;
}

.or-line-post-ps {
  margin-bottom: 15px;
}

.ps-fd-card-body .post-by + .post-by {
  margin: 0;
  margin-bottom: 15px;
}

.post-by {
  margin-bottom: 15px;
  padding: 0px;
  margin: 0px 72px 15px 0px !important;
}

.post-area.post-by {
  margin-bottom: 0;
}

.assigned-title-blk:hover .assigned-title-info {
  opacity: 1;
  visibility: visible;
  transition: all 0.1s 0.4s ease;
}

.assigned-title-blk:hover .assigned-title-info:before {
  height: 40px;
}

.user-profile.assigned-title-blk .assigned-title-info {
  right: 45px;
  left: auto;
}

.assigned-title-blk {
  cursor: pointer;
}

.comment-user-current .assigned-title-blk .assigned-title-info {
  left: auto;
  right: 42px;
}

.nav-ul > li.user-profile.assigned-title-blk:hover {
  background: transparent;
}

.post-by-status {
  position: absolute;
  right: 0;
  bottom: -2px;
  /* background: #fff; */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: 2px solid #fff;
}

.assigned-title-info {
  position: absolute;
  background: #fff;
  border: 1px solid #e0edff;
  z-index: 2;
  top: 5px;
  /* padding: 20px; */
  padding: 15px 20px 10px;
  min-width: 280px;
  border-radius: 2px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s 0s ease;
}

.assigned-title-blk:hover .assigned-title-info {
  opacity: 1;
  visibility: visible;
  transition: all 0.1s 0.4s ease;
}

.assigned-title-blk:hover .assigned-title-info:before {
  height: 40px;
}

.user-profile.assigned-title-blk .assigned-title-info {
  right: 45px;
  left: auto;
}

.ps-fd-card-body .assigned-title-info {
  left: 100%;
  /* right: auto; */
}

.ps-fd-card-head .post-area .assigned-title-info {
  left: 100%;
  /* right: auto; */
  bottom: 0px;
  top: auto;
}

.assigned-title-info:before {
  position: absolute;
  top: 0;
  height: auto;
  background: #e0edff;
  width: 100%;
  left: 0;
  content: "";
}

.assigned-title-info article {
  position: relative;
  display: flex;
}

.assigned-title-info article figure {
  width: 32px;
  height: 32px;
  background: #9f1f63;
  display: flex;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-family: system-ui;
  margin-right: 10px;
  /* border: 1px solid #fff; */
  position: relative;
  overflow: inherit;
}

.assigned-title-info article figcaption h4 {
  color: #06172a;
  font-size: 14px;
  font-weight: 400;
}

.assigned-title-info article figcaption p {
  font-size: 12px;
  color: #5b5b5b;
  margin-bottom: 5px !important;
  display: -webkit-box;
}

.assigned-title-info article figcaption h6 {
  font-size: 12px;
  color: #06172a;
  font-weight: 700;
  margin: 0;
}

.assigned-title-info article figcaption i {
  width: 15px;
  text-align: center;
}

.assigned-title-info article figcaption a {
  color: #5b5b5b;
  /* display: block; */
}

.assigned-title-info article figcaption a:hover {
  color: #1899d2;
  text-decoration: underline !important;
}

.assigned-title-info article figure span.portal-status-icon {
  position: absolute;
  right: 0;
  bottom: -2px;
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.bgBlue {
  background: #1899d2 !important;
}

.hover-color-blue:hover {
  color: #1899d2;
}

.cat-greenhex {
  color: #00a651;
}

.attach-tag-rt {
  position: relative;
}

.ps-by-card {
  border-radius: 2px;
}

.post-reaction-rel01 {
  position: relative;
}

.post-reaction-rel01 .post-reaction-blk .like-list {
  z-index: 2;
}

.post-reaction-rel01 {
  position: relative;
}
.post-reaction-rel01 .post-reaction-blk .like-list {
  z-index: 2;
}
.important-tag {
  border-left: 4px solid #ff0000 !important;
  position: relative;
}

.important-tag {
  border-radius: 2px 0px 0px 0px;
}

.ps-by-card-header.mypost {
  border-left: 4px solid transparent;
}

.ps-by-card-header {
  overflow: visible;
}

.desc_box.ps-by-card-header {
  padding: 15px 15px;
}

.ps-by-card-header:hover .post-reaction-item-info {
  opacity: 1;
  visibility: visible;
  transition: all 0s 0.5s ease;
}

.desc_box.ps-by-card-header {
  padding: 15px 15px;
}

.ps-by-card-header.mypost {
  border-left: 4px solid transparent;
}

.post-reaction-item-info {
  position: absolute;
  background: #fff;
  z-index: 2;
  top: 10px;
  right: 20px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #cbced0;
  border-radius: 2px;
  visibility: hidden;
  opacity: 0;
  transition: all 0s 0.5s ease;
}

.show-reply .card-reply:hover .post-reaction-item-info {
  opacity: 1;
  visibility: visible;
  /* transition: all 0.0s 1s ease; */
}

.post-reaction-item-info > a {
  display: inline-block;
  font-size: 14px;
  color: #06172a;
  margin-right: 10px;
}

.post-reaction-item-info .dropdown a.btn-secondary {
  padding: inherit;
  color: #06172a;
  background: transparent;
  border: none;
  box-shadow: none !important;
}

.post-reaction-item-info .dropdown a::after {
  display: none;
}

.post-reaction-item-info .dropdown a i {
  margin: auto;
}

.post-reaction-item-info .dropdown a.dropdown-item {
  font-size: 14px;
  padding: 4px 12px;
}

.post-reaction-item-info .dropdown a.dropdown-item i {
  margin-right: 5px;
}

.post-reaction-item-info > a i {
  margin: auto;
}

.post-reaction-item-info {
  top: -24px;
}

.dropdown-comman .dropdown.multiselect input[type="checkbox"] {
  display: none;
}

.dropdown-comman .dropdown.multiselect input[type="checkbox"] + label:before {
  content: "\f00c";
  width: 14px;
  height: 14px;
  display: inline-block;
  background: #e8e8e8;
  margin-right: 10px;
  border-radius: 2px;
  font-size: 0px;
  text-align: center;
  color: #fff;
  font-family: "Font Awesome 5 Pro";
}

.dropdown-comman
  .dropdown.multiselect
  input[type="checkbox"]:checked
  + label:before {
  content: "\f00c";
  background: #467fcf;
  font-size: 10px;
}

.user-profile .dropdown-menu {
  padding: 10px 0px;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
  top: 100% !important;
  right: 0 !important;
  left: auto !important;
  border: 1px solid #d7dee8;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  min-width: 360px;
}

.user-profile .dropdown-menu li a,
.user-profile .dropdown-menu li button {
  padding: 8px 20px;
  display: block;
  font-size: 14px;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  color: #484644;
  line-height: 1.2;
  cursor: pointer;
}

.user-profile .dropdown-menu li > a > i,
.user-profile .dropdown-menu li > button > i {
  font-size: 14px;
  min-width: 20px;
  display: inline-block;
}

.user-profile .dropdown-menu li a:hover,
.user-profile .dropdown-menu li button:hover {
  background: #e6ecf2;
}

.user-profile .dropdown-menu li a:focus,
.user-profile .dropdown-menu li button:focus {
  background: #d3dee9;
}

.user-profile .dropdown-menu .li-has-menu > a > .toogle-menu-icon,
.user-profile .dropdown-menu .li-has-menu > button > .toogle-menu-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  top: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.dropdown-dots .dropdown-menu {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
  right: 10px !important;
  left: auto !important;
  top: 100% !important;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 1.98px 0.02px rgba(125, 125, 125, 0.13);
  -moz-box-shadow: 0px 2px 1.98px 0.02px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 1.98px 0.02px rgba(125, 125, 125, 0.13);
  border: 1px solid rgb(215, 222, 232);
}

.upload-drop-down-wrap .dropdown-menu {
  padding: 10px 0px;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
  top: 100% !important;
  right: 0 !important;
  left: auto !important;
  border: 1px solid #d7dee8;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%);
  font-size: 14px;
  color: #484644;
  line-height: 1.2;
  min-width: 250px;
  text-align: left;
}

.upload-drop-down-wrap .dropdown-menu ul {
  margin: 0;
}

.upload-drop-down-wrap .dropdown-menu ul li i {
  font-size: 14px;
  min-width: 20px;
  display: inline-block;
}

.upload-drop-down-wrap .dropdown-menu ul li button {
  border: none;
  background: none;
  padding: 8px 20px;
  display: block;
  width: 100%;
  text-align: left;
}

.upload-image-option:hover,
.upload-drop-down-wrap .dropdown-menu ul li button:hover {
  background: #e6ecf2;
}

.upload-image-option:focus,
.upload-drop-down-wrap .dropdown-menu ul li button:focus {
  background: #d3dee9;
}

.avtar-bot-lft .upload-drop-down-wrap .dropdown-menu {
  left: 0 !important;
  right: auto !important;
}

.dropdown-comman .dropdown-menu {
  min-width: 210px;
  padding-top: 0;
  padding-bottom: 0;
}

.dropdown-comman .dropdown-menu li {
  padding: 0px 8px;
  font-size: 12px;
}

.dropdown-comman .dropdown-menu li:hover {
  background: #eff0f2;
}

.dropdown-comman .dropdown-menu li:hover input[type="checkbox"] + label:before {
  background: #d1d1d1;
}

.dropdown-comman .dropdown-menu li label {
  cursor: pointer;
  padding: 8px 0px;
  margin: 0px;
  display: flex;
  align-items: center;
}

.dropdown-comman .dropdown-menu li + li label {
  border-top: 1px solid #eff0f2;
}

.dropdown-comman .dropdown-menu li label i {
  margin-right: 5px;
}

.comment-con {
  display: flex !important;
}

/* .other-reply .comment-con{align-items: center;} */
.new-comment {
  color: var(--topbar-color);
  margin-left: 4px;
}

.post-reaction-blk a {
  display: inline-flex;
  align-items: center;
  color: #06172a;
  padding: 0 5px;
  line-height: normal;
  /* font-size: 16px; */
}

.post-reaction-blk a span {
  margin-left: 5px;
}

.post-reaction-blk a i {
  margin: inherit;
}

.likeUnlikeBox .post-reaction-blk {
  position: absolute;
  right: 15px;
  top: 13px;
}

.post-reaction-blk a:hover .like-list {
  display: block;
}

.post-reaction-rel01 .post-reaction-blk {
  position: absolute;
  top: 15px;
  right: 20px;
}

.post-reaction-rel01 .post-reaction-blk .like-list {
  /* .likeUnlikeBox .post-reaction-blk{
 position: absolute; 
 right: 15px; 
 top:13px;
} */
}
.post-reaction-blk a:hover .like-list {
  display: block;
}
/* .post-reaction-rel01 .post-reaction-blk {
 position: absolute;
 top: 15px;
 right: 20px;
} */
.post-reaction-rel01 .post-reaction-blk .like-list {
  z-index: 2;
}

.like-list {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  z-index: 1;
  background-color: white;
  left: auto;
  top: auto;
  max-height: 200px;
  overflow: inherit;
  display: none;
  bottom: 20px;
  right: 30px;
}

/* .like-list:hover {
  display: flex;
} */
.like-list .post-by-img {
  overflow: inherit;
  margin-right: 10px;
}

.like-list .post-by-img img {
  border-radius: 50%;
}

.like-list ul {
  padding: 0;
  min-width: 220px;
  margin-bottom: 0px;
}

.like-list ul li {
  display: flex;
  padding: 7px 15px;
  align-items: center;
}

.like-list ul li figcaption {
  margin-top: -3px;
}

.like-list li i {
  margin-right: 10px;
}

.ps-by-card-body {
  background: #f5f5f5;
  border-top: none;
}

/* .ps-by-card-body>.other-reply {
  border-left: 4px solid #06172a !important;
} */
.spacing_lt-rt .card-reply.card-reply-box {
  padding: 15px 10px;
}

.spacing_lt-rt .card-right {
  max-width: calc(100% - 58px);
}

.reply-static {
  background: #fff;
  color: black;
  font-size: 12px;
  padding: 4px 10px;
  font-weight: 300;
  cursor: pointer;
}

.reply-static:hover {
  color: var(--topbar-color);
  background: #fff;
  font-size: 12px;
  padding: 4px 10px;
  font-weight: 300;
  cursor: pointer;
}

.reply-static i {
  margin-right: 5px;
}

.reply-static {
  border-radius: 0px 0px 2px 2px;
}

.user-post-main > .media-body.card > .card-footer {
  background: #fff;
}

.user-post-main > .media-body.card > .card-footer {
  border: none;
  padding: 15px 35px 15px 15px;
  line-height: 1;
}

.portal-login-card .card-footer {
  padding: 30px;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  justify-content: center;
}

.portal-login-card .card-footer a {
  color: var(--topbar-color);
  font-size: 16px;
}

.portal-login-card .card-footer a:hover {
  color: var(--hover-color);
}

.login-left .portal-login-card .card-footer {
  background: transparent;
}

.ps-by-card-footer .card-footer .card-right {
  max-width: calc(100% - 52px);
}

.ps-by-card-footer .card-footer {
  align-items: flex-start;
}

.card-footer {
  padding-left: 10px;
}

.notify-card .card-left {
  max-width: -webkit-calc(100% - 90px) !important;
  max-width: -moz-calc(100% - 90px) !important;
  max-width: calc(100% - 90px) !important;
  line-height: 1.2;
  width: 100%;
}

.notify-card .card-left h4,
.notify-card .card-left p {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.notify-card .card-left h4 {
  font-size: 14px;
  margin: 0 0 5px 0;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.notify-card .card-left p {
  margin: 0;
  font-size: 11px;
}

.notify-card .card-left h4,
.notify-card .card-left p,
.notify-card .card-right .date-time {
  font-weight: 600;
}

.notify-card .card-left p,
.notify-card .card-right .date-time {
  color: var(--topbar-color);
}

.card.notify-card.notify-read .card-left h4,
.card.notify-card.notify-read .card-left p,
.card.notify-card.notify-read .card-right .date-time {
  font-weight: normal;
}

.card.notify-card.notify-read .card-left p,
.card.notify-card.notify-read .card-right .date-time {
  color: #484644;
}

.card.notify-bulk .card-left h4,
.card.notify-bulk .card-left p,
.card.notify-bulk .card-right .date-time {
  color: #ff0202 !important;
}

.Chat-card .card-left {
  max-width: -webkit-calc(100% - 90px) !important;
  max-width: -moz-calc(100% - 90px) !important;
  max-width: calc(100% - 90px) !important;
  line-height: 1.2;
  width: 100%;
  display: flex;
}

.Chat-card .card-left h4,
.Chat-card .card-left p {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.Chat-card .card-left h4 {
  font-size: 14px;
  margin: 0 0 5px 0;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.Chat-card .card-left p {
  margin: 0;
  font-size: 11px;
}

.Chat-card .card-left h4,
.Chat-card .card-left p,
.Chat-card .card-right .date-time {
  font-weight: 600;
}

.Chat-card .card-left p,
.Chat-card .card-right .date-time {
  color: #467fcf;
}

.card.Chat-card.Chat-read .card-left h4,
.card.Chat-card.Chat-read .card-left p,
.card.Chat-card.Chat-read .card-right .date-time {
  font-weight: normal;
}

.card.Chat-card.Chat-read .card-left p,
.card.Chat-card.Chat-read .card-right .date-time {
  color: #484644;
}

.card.Chat-bulk .card-left h4,
.card.Chat-bulk .card-left p,
.card.Chat-bulk .card-right .date-time,
.card.Chat-bulk .card-right .alert-icon,
.tag-important {
  color: #ff0202 !important;
}

.Tickets-card .card-left {
  max-width: -webkit-calc(100% - 115px) !important;
  max-width: -moz-calc(100% - 115px) !important;
  max-width: calc(100% - 115px) !important;
  line-height: 1.2;
  width: 100%;
}

.Tickets-card .card-left h4,
.Tickets-card .card-left p {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.Tickets-card .card-left h4 {
  font-size: 14px;
  margin: 0 0 5px 0;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-weight: 600 !important;
}

.Tickets-card .card-left p {
  margin: 0;
  font-size: 11px;
}

.Tickets-card .card-left h4,
.Tickets-card .card-left p,
.Tickets-card .card-right .date-time {
  font-weight: 600;
}

.Tickets-card .card-left p,
.Tickets-card .card-right .date-time {
  color: var(--topbar-color);
}

.card.Tickets-card.Tickets-read .card-left h4,
.card.Tickets-card.Tickets-read .card-left p,
.card.Tickets-card.Tickets-read .card-right .date-time {
  font-weight: normal;
}

.card.Tickets-card.Tickets-read .card-left p,
.card.Tickets-card.Tickets-read .card-right .date-time {
  color: #484644;
}

.card.Tickets-bulk .card-left h4,
.card.Tickets-bulk .card-left p,
.card.Tickets-bulk .card-right .date-time {
  color: #ff0202 !important;
}

.Tickets-card .card-left h4 {
  max-width: calc(100% - 35px);
}

.Tickets-card-v-2 .card-left {
  max-width: -webkit-calc(100% - 70px) !important;
  max-width: -moz-calc(100% - 70px) !important;
  max-width: calc(100% - 70px) !important;
}

.Tickets-card-v-2 .card-left h4 {
  max-width: 100%;
  margin: 0 0 10px 0;
}

.Tickets-card-v-2 .card-left > p {
  margin-bottom: 10px !important;
  min-width: calc(100% + 75px);
  font-size: 12px;
}

.Tickets-card-v-2 .card-left .last-update p {
  line-height: 1.4;
  margin-bottom: 10px !important;
}

.assigned-title-blk:hover .assigned-title-info {
  opacity: 1;
  visibility: visible;
  transition: all 0.1s 0.4s ease;
}

.assigned-title-blk:hover .assigned-title-info:before {
  height: 40px;
}

.assigned-title-blk {
  cursor: pointer;
}

.comment-user-current .assigned-title-blk .assigned-title-info {
  left: auto;
  right: 42px;
}

.nav-ul > li.user-profile.assigned-title-blk:hover {
  background: transparent;
}

.card-left-by-img {
  width: 32px;
  height: 32px;
  font-size: 14px;
}

.Chat-card .portal-status-icon {
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.Chat-head-lft .icon .portal-status-icon {
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 0px;
  font-size: 14px;
}

.portal-status-icon .fad.fa-check-circle:before {
  color: #fff;
  opacity: 1;
}

.portal-status-icon .fad.fa-check-circle:after {
  color: #92c353;
  opacity: 1;
}

.assigned-title-info span.portal-status-icon i {
  color: #92c353;
  background: #fff;
  border-radius: 50%;
}

.assigned-title-info span.portal-status-icon i.offline {
  color: #f8a503;
}

.assigned-title-info .portal-status-icon .fad.fa-check-circle:after {
  color: inherit;
}

.post-area-img .portal-status-icon {
  font-size: 10px;
  position: absolute;
  bottom: -2px;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.notify-card .card-right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 90px;
  font-size: 11px;
  padding-left: 5px;
}

.notify-card .card-right .notify-include-items li + li {
  margin-left: 5px;
}

.Chat-card .card-right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 90px;
  font-size: 11px;
  padding-left: 5px;
}

.Chat-card .card-right .Chat-include-items li + li {
  margin-left: 5px;
}

.Tickets-card .card-right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 90px;
  font-size: 11px;
  padding-left: 5px;
}

.Tickets-card .card-right .Tickets-include-items li + li {
  margin-left: 5px;
}

.Tickets-card .card-right .date-time {
  min-width: 105px;
  margin-left: -35px;
}

.Tickets-card-v-2 .card-right {
  font-size: 14px;
  max-width: 70px;
  padding: 0;
  display: block;
  text-align: right;
}

.Tickets-card-v-2 .card-right li.ticket-number {
  width: 100%;
  text-align: right;
}

.Tickets-card-v-2 .card-right li.ticket-number .TCAI-bx {
  justify-content: flex-end;
}

.hideFilePond {
  display: none;
}

.showFilePond {
  display: block;
}

/* .other-reply {
  padding: 3px 20px !important;
  color: #06172A;
  cursor: pointer;
  line-height: 1.2;
  border-left: 4px solid #467FCF !important;
  position: relative;
} */
.hide-comment-cnt {
  display: none;
}

.comment-conversation-list > li {
  margin: 30px 0;
}

.ticket-chat-block .comment-conversation-list > li {
  margin: 28px 0;
}

.comment-con {
  display: flex !important;
}

/* .other-reply .comment-con{align-items: center;} */
p.new-cmt-txt {
  background: #d9d9d9;
  font-weight: 600;
  padding: 1px 8px;
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: 0;
}

.row_icons_fas div,
.row_icons_fas i {
  position: static;
}

.row_icons_fas {
  position: absolute;
  display: flex;
  left: 101%;
  top: 0;
  min-width: auto;
}

.post-reaction-icon,
.row_icons_fas i {
  margin-right: 10px;
}

.row_icons_fas .important-icon,
.row_icons_fas .post-reaction-icon,
.row_icons_fas .attachment-icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  font-size: 12px;
}

.row_icons_fas.ls_box {
  left: 100%;
  top: 4px;
  margin-left: -10px;
}

.ls_box .post-reaction-icon,
.ls_box .important-icon {
  margin-right: 5px !important;
}

.event-calendar-left .fal.fa-eye {
  opacity: 0;
}

.important-icon {
  position: absolute;
  right: -15px;
  top: 0;
  background: #ff0000;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.post-reaction-icon {
  position: absolute;
  right: -15px;
  top: 10px;
  width: 30px;
  height: 30px;
  background: #06172a;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.post-reaction-item .post-reaction-icon i {
  margin: inherit;
}

.post-reaction-icon i {
  margin: auto;
}

.post-reaction-icon > i {
  margin: 0;
}

.attachment-icon {
  position: absolute;
  right: -15px;
  top: 0;
  background: #82858a;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.hide-reply {
  display: none;
}

.show-reply {
  display: block;
}

.hide-comment-cnt {
  display: none;
}

.likeUnlikeBox {
  position: relative;
}

.card-reply.mycomment {
  border-left: 4px solid transparent;
}

.card-reply {
  border-left: 4px solid transparent;
  position: relative;
}

.show-reply .card-reply {
  padding: 15px 20px;
  cursor: auto;
}

.card-reply.card-reply-box {
  padding: 15px 15px;
}

.card-reply.card-reply-box {
  padding: 15px 15px;
}

.ps-by-card-header:hover .post-reaction-item-info {
  opacity: 1;
  visibility: visible;
  transition: all 0s 0.5s ease;
}

.user-profile .dropdown-toggle:after {
  display: none;
}

.upload-drop-down-wrap .dropdown-toggle:after {
  display: none;
}

.upload-drop-down-wrap .dropdown-toggle {
  width: 30px;
  height: 30px;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%);
  -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%);
  cursor: pointer;
  overflow: hidden;
  font-size: 22px;
  padding: 0 !important;
}

.upload-drop-down-wrap .dropdown-toggle:hover {
  background: #e6ecf2;
}

.upload-drop-down-wrap .dropdown-toggle:focus {
  background: #d3dee9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.upload-drop-down-wrap .dropdown-toggle i {
  margin: 0;
}

.dropdown-comman .dropdown-toggle::after {
  display: none;
}

.form-group.atttach-file.m-0.col-12.d-flex {
  flex-wrap: wrap;
}

.form-group.atttach-file.m-0.col-12.d-flex .m-2:first-child {
  margin-left: 0 !important;
}

.d-flex.ticket-box {
  flex-wrap: wrap;
}

.modal-thumb {
  max-width: 400px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  margin: 10px 0px;
}

.modal-thumb img {
  width: 100%;
}

.modal-thumb .modal-thumb-play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-thumb .modal-thumb-play span {
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: #467fcf;
  border-radius: 4px;
  cursor: pointer;
}

.modal-thumb .modal-thumb-play span i {
  margin: 0;
}

.modal-thumb {
  max-width: 320px;
  min-height: 210px;
  overflow: hidden;
  height: 210px;
}

.modal-thumb img {
  transition: 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.modal-thumb:hover img {
  transform: scale(1.1);
}

.modal-thumb .modal-thumb-play span {
  z-index: 1;
}

.modal-thumb figure {
  overflow: hidden;
  height: 180px;
  width: 320px;
  border-radius: 4px;
}

.modal-thumb figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-position: center;
}

.modal-thumb {
  border-radius: 0;
}

.modal-thumb:hover .modal-thumb-play span {
  background-color: #fff;
  color: #467fcf;
}

.post-input-content {
  border-radius: 2px;
  box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%);
  border: 1px solid #d7dee8;
  background: #fff;
  position: relative;
}

.post-input-content .rdw-editor-wrapper {
  display: flex;
  flex-direction: column-reverse;
}

.post-input-content .rdw-editor-wrapper .rdw-editor-toolbar {
  display: flex;
  padding: 0;
  padding: 0px 5px 0;
  margin-bottom: 0;
  background: var(--sidebar-color);
  border: none;
  height: auto;
  transition: all 0.1s 0s ease;
}

.post-input-content .rdw-editor-wrapper .rdw-editor-toolbar > div {
  margin: 5px 0;
}

.post-input-content .rdw-editor-wrapper .rdw-editor-main {
  padding: 9px 15px;
  overflow: inherit;
  /* inherit was above. auto used to resolve scroll issue fix */
}

.post-input-content .rdw-option-wrapper {
  border: none;
  background: transparent;
  color: #fff;
  box-shadow: none;
  position: relative;
  padding: 0;
  margin: 0;
  min-width: 20px;
  font-size: 11px;
  line-height: 20px;
}

.post-input-content .rdw-option-wrapper.i-bold-icon:after {
  content: "\f032";
}

.post-input-content .rdw-option-wrapper:after {
  font-family: "Font Awesome 5 Pro";
  position: absolute;
}

.post-input-content .rdw-option-wrapper img {
  display: none;
}

.post-input-content .rdw-option-wrapper.i-italic-icon:after {
  content: "\f033";
}

.post-input-content .rdw-option-wrapper.i-underline-icon:after {
  content: "\f0cd";
}

.post-input-content .rdw-option-wrapper.i-left-icon:after {
  content: "\f036";
}

.post-input-content .rdw-option-wrapper.i-center-icon:after {
  content: "\f037";
}

.post-input-content .rdw-option-wrapper.i-right-icon:after {
  content: "\f038";
}

.post-input-content .rdw-option-wrapper.i-justify-icon:after {
  content: "\f039";
}

.post-input-content .rdw-option-wrapper.i-unordered-icon:after {
  content: "\f0ca";
}

.post-input-content .rdw-option-wrapper.i-ordered-icon:after {
  content: "\f0cb";
}

.post-input-content .rdw-option-wrapper.i-indent-icon:after {
  content: "\f03c";
}

.post-input-content .rdw-option-wrapper.i-outdent-icon:after {
  content: "\f03b";
}

.post-input-content .rdw-option-wrapper.i-embedded-icon:after {
  content: "\f121";
}

.post-input-content .rdw-editor-wrapper .rdw-suggestion-dropdown {
  min-width: 240px;
  max-width: 250px;
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.post-input-content
  .rdw-editor-wrapper
  .rdw-suggestion-dropdown
  .rdw-suggestion-option {
  padding: 10px;
}

.post-input-content
  .rdw-editor-wrapper
  .rdw-suggestion-dropdown
  .rdw-suggestion-option:hover {
  background: #eff0f2;
}

.post-input-content.readonly .rdw-editor-toolbar {
  display: none;
}

.post-input-content.readonly {
  box-shadow: none;
  border: none;
  background: transparent;
}

.post-input-content.readonly .rdw-editor-wrapper .rdw-editor-main {
  padding: initial;
  overflow: inherit;
  max-height: inherit;
}

.post-input-content.readonly .public-DraftStyleDefault-block {
  margin: 0;
}

.post-input-content .rdw-editor-wrapper .rdw-editor-main a.rdw-mention-link {
  font-size: 16px;
  letter-spacing: 0px;
  color: #467fcf;
  background: transparent;
}

.post-input-content
  .rdw-editor-wrapper
  .rdw-editor-main
  .public-DraftStyleDefault-block {
  margin: inherit;
}

.post-input-content.other-tag
  .rdw-editor-wrapper
  .rdw-editor-main
  a.rdw-mention-link {
  color: #06172a;
  font-weight: bold;
}

.post-input-content .rdw-option-wrapper.rdw-option-active {
  background: var(--topbar-color);
}

.post-input-content .rdw-editor-wrapper .rdw-editor-toolbar > div > div + div {
  margin-left: 3px;
}

.post-input-content .rdw-editor-wrapper .rdw-editor-toolbar > div + div {
  margin-left: 3px;
}

.post-input-content .rdw-editor-wrapper .rdw-embedded-modal {
  position: absolute;
  z-index: 9999;
  bottom: 0;
  top: auto;
  left: 20px;
}

.post-input-content .rdw-option-wrapper:hover,
.action-input-content button.btn-input-action:hover {
  background: var(--hover-color);
}

.post-input-content .rdw-editor-wrapper .rdw-suggestion-dropdown {
  bottom: 28px;
}

.show-more-div {
  height: 44px;
  overflow: hidden;
}

.discuss-text a {
  font-weight: bold;
}

.discuss-text p {
  font-size: 16px !important;
  color: #484644 !important;
  margin: 0;
}

.rdw-editor-position {
  position: relative;
}

.action-input-content {
  position: absolute;
  right: 5px;
  bottom: 0;
  height: 30px;
  display: flex;
  align-items: center;
}

.action-input-content button.btn-input-action {
  color: #fff;
  padding: 0 2px;
  height: 20px;
  border: none;
  box-shadow: none !important;
  width: 20px;
  font-size: 14px;
  border-radius: 2px;
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.action-input-content button + button {
  margin-left: 3px;
}

.action-input-content button.btn-input-action.active {
  background: var(--topbar-color);
}

.action-input-content button.btn-input-action i {
  margin: auto;
}

.show-more-txt {
  color: var(--topbar-color);
  padding-top: 7px;
  cursor: pointer;
  font-size: 11px;
}

.show-more-txt:hover {
  text-decoration: underline;
}

.attachment-con {
  height: 30px;
  overflow: hidden;
}

.list_post_imgview .post-image {
  width: 100px;
  min-width: 100px;
  height: 90px;
  overflow: hidden;
  position: relative;
}

.list_post_imgview .post-image img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.list_post_imgview .post-image button.btn.file-remove-btn.img-del {
  position: absolute;
  right: 5px;
  top: 5px;
  background: #00000054;
  color: #fff;
  padding: 0px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin: 0px;
}

.list_post_imgview .post-image button.btn.file-remove-btn.img-del i {
  margin: 0px;
}

.uploded-files-item button.btn.file-remove-btn {
  font-size: 20px;
  color: #48464a;
  background: transparent;
  border: none;
  box-shadow: none !important;
  padding: 0;
  margin-left: 10px;
}

.files-list-blk.files-container {
  margin-top: 15px;
}

.files-list-blk.files-container .files-container-ul .files-container-li {
  margin: inherit;
  display: block;
  max-width: 29.33%;
  min-width: 220px;
  /* min-height: 75px; */
}

.files-list-blk.files-container ul.files-container-ul {
  display: block;
  margin: inherit;
}

.files-list-blk.files-container .files-container-ul .files-container-li + li {
  margin-top: 15px;
}

.image-popup-block {
  position: fixed;
  z-index: 99;
  background: rgb(11 11 11 / 80%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px;
  display: none;
}

.image-popup-block.active {
  display: flex;
}

.image-popup {
  position: relative;
  display: block;
  max-width: 720px;
  box-shadow: 0px 0px 1px #eee;
  border-radius: 2px;
  display: block !important;
  left: auto !important;
  width: auto;
  max-height: calc(100% - 41px) !important;
}

.image-popup-img {
  max-height: 100%;
  height: 100%;
}

.image-popup-img img {
  max-height: 600px;
  max-width: 600px;
  width: auto;
  height: auto;
}

.image-popup button.btn-primary {
  position: absolute;
  right: -15px;
  top: -15px;
  padding: 0;
  min-width: auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.image-popup button.btn-primary i {
  margin: 0;
}

.flexible-modal {
  height: auto !important;
  z-index: 99 !important;
}

.flexible-modal .close-icon {
  background-color: #ffffff;
  border-radius: 0;
  right: 10px;
  top: 10px;
  color: #06172a;
}

.flexible-modal .react-player {
  height: 100% !important;
  background: #fff !important;
}

.flexible-modal-mask {
  z-index: 2 !important;
}

.flexible-modal {
  z-index: 99;
}

.flexible-modal .close-icon {
  background-color: #ffffffa8;
  border-radius: 0;
  right: 10px;
  top: 10px;
  color: #06172a;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-update .flexible-modal {
  position: fixed !important;
}

.flexible-modal .close-icon i {
  margin: inherit;
}

.flexible-modal .body {
  height: 100%;
  width: 100%;
  overflow-y: inherit;
}

.flexible-modal .body iframe {
  height: 100%;
  width: 100%;
}

.iframe_modal_full .flexible-modal .body {
  height: 100%;
  width: 100%;
  overflow-y: inherit !important;
}

.flexible-modal div:empty {
  padding: 0 !important;
}

.post_content09 .rdw-embedded-modal {
  width: 240px;
  height: auto;
}

.post_content09 .rdw-embedded-modal .rdw-embedded-modal-size-input {
  width: 88%;
  height: 35px;
  margin: 5px 0;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 14px;
  padding: 0 5px;
}

.post_content09 .rdw-embedded-modal .rdw-embedded-modal-link-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post_content09 .rdw-embedded-modal .rdw-embedded-modal-header {
  font-size: 15px;
  display: flex;
  margin-bottom: 10px;
}

.portal-login-form .input-group .form-control.is-invalid {
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
}

.form-group select.form-control.is-invalid ~ .exclamation {
  display: block;
}

.form-group select.form-control.is-invalid ~ .exclamation i {
  font-weight: 400;
}

select.form-control.is-invalid {
  background-position: calc(100% - 30px) 50% !important;
}

.post-static-input input.form-control {
  border: none;
  box-shadow: none;
  padding: 10px 16px 0;
  height: auto;
  font-weight: 600;
  color: #000;
}

.post-static-input input.form-control::placeholder {
  color: #000;
}

.post-edit-input {
  padding: 0 !important;
  background: none !important;
  margin-bottom: 5px;
}

.font12 {
  font-size: 12px !important;
}

.feed-pad {
  padding: 5px !important;
}

.uploded-files-item.feed-pad figure {
  margin-right: 10px;
}

.uploded-files-item.feed-pad figcaption {
  margin-right: 5px;
}

.uploded-files-item.feed-pad a.btn {
  padding: 0;
}

.uploded-files-item.feed-pad a.btn i {
  margin: 0;
}

.post-reaction-icon > i {
  margin: 0 !important;
}

small.notify-count1 {
  position: absolute;
  width: 18px;
  height: 18px;
  background: #ff0202;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Segoe UI";
  font-weight: 600;
  z-index: 1;
  border-radius: 50%;
  color: #fff;
  top: 1px;
  left: -8px;
}

.post-root .post-area-left .post-by-img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

/* chat room end */

/* 24-Sept Chat Related Css */

.chat-msg-container.ps-fd-card-body {
  padding-bottom: unset;
  margin-bottom: unset;
}

/* Enf of 24-Sept Chat Related Css */

.post-root .no-bg-editor .public-DraftStyleDefault-ltr {
  min-height: auto;
}

.post-root .no-bg-editor .DraftEditor-root {
  min-height: auto;
  max-height: inherit;
  background-color: #fff;
  padding: 0;
  overflow: inherit;
}

.post-root .no-bg-editor.bg-trans .DraftEditor-root {
  background: transparent;
}

.post-root .post-by-status.online-u {
  background-color: #5cb85c;
}

.post-root .DraftEditor-root .public-DraftEditorPlaceholder-root {
  width: auto;
}

body .post-root .DraftEditor-root {
  max-height: inherit;
  background: #fff;
  padding: 0;
}

body .post-root .public-DraftStyleDefault-ltr {
  min-height: inherit;
}

body .post-root .DraftEditor-root {
  min-height: inherit;
  overflow: inherit;
}

.post-root .important-tag.ps-by-card-header {
  overflow: inherit;
}

.scroll-tabs.filter-scroll .filter-scroll-inner {
  max-width: 100%;
  pointer-events: auto;
  min-height: 100%;
}

.skelaton-custom {
  margin-bottom: 15px;
  margin-right: 72px;
}

.skelaton-custom .media-icon.rounded-circle .user-profile-pic {
  width: 33px;
  height: 33px;
}

.skelaton-custom .media-icon.rounded-circle .user-profile-pic span {
  width: 100%;
}

.post-subject-bold {
  font-weight: 600;
  color: #000;
  font-size: 16px;
  margin-bottom: 5px;
}

.other-reply {
  padding: 10px 20px 3px;
  color: #06172a;
  cursor: pointer;
  line-height: 1.2;
  border-left: 4px solid #06172a;
}

.other-reply {
  border-left: 4px solid #467fcf !important;
}

/* .ps-by-card-body>.other-reply {
  border-left: 4px solid #06172a !important;
} */
.other-reply {
  position: relative;
}

.other-reply .comment-con {
  align-items: center;
}

/* 27-sept chat related css */
.post-reat-new {
  background: #f2f5f8;
  position: absolute;
  right: -45px;
  top: 45px;
  border: 1px solid #e0edff;
  z-index: 2;
  padding: 10px;
  /* max-width: 245px; */
  border-radius: 2px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease 0s;
}

.post-reat-head {
  display: flex;
  color: #000;
}

.post-reat-head a {
  color: #000;
  padding: 0px 5px;
  display: inline-flex;
  align-items: center;
}

.post-reat-icon {
  /* padding-right: 5px; */
  border-right: 1px solid #d2d9e3;
  margin-right: 5px;
}

.post-reat-action i {
  margin-right: 5px;
}

.post-reat-list {
  margin-top: 10px;
}

.post-reat-list ul {
  padding: 0;
  margin: 0;
}

.post-reat-list ul li {
  display: flex;
  list-style: none;
  margin-bottom: 10px;
}

.post-reat-list ul li:last-child {
  margin-bottom: 0;
}

.post-reat-list .user-drop-down-img {
  min-width: 32px;
}

.post-reat-list ul .info-drop {
  display: flex;
  width: 100%;
  margin-left: 10px;
  align-items: center;
  position: relative;
}

.post-reat-list ul .info-drop:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: #f3f3f3;
  bottom: -5px;
}

.post-reat-list ul li:last-child .info-drop:after {
  display: none;
}

.post-reat-list ul .info-drop span {
  font-size: 14px;
  width: 145px;
  position: relative;
  top: -3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-reat-list ul .info-drop a {
  color: #000;
  font-size: 16px;
}

.post-reat-list ul .info-drop a {
  margin-left: auto;
}

.post-reat-list h4 {
  font-size: 14px;
}

.post-reat-list ul li:hover .assigned-title-info:before {
  height: 40px;
}

.ps-by-card-header:hover .post-reat-new {
  visibility: visible;
  opacity: 1;
  transition: all 0.1s 0.4s ease;
}

.important-tag {
  border-left: 4px solid #ff0000 !important;
  position: relative;
}

.important-tag {
  border-radius: 2px 0px 0px 0px;
}

.ps-by-card-header.mypost {
  border-left: 4px solid transparent;
}

.ps-by-card-header {
  overflow: visible;
}

.desc_box.ps-by-card-header {
  padding: 15px 15px;
}

.ps-by-card-header:hover .post-reaction-item-info {
  opacity: 1;
  visibility: visible;
  transition: all 0s 0.5s ease;
}

.desc_box.ps-by-card-header {
  padding: 15px 15px;
}

.comment-con {
  display: flex !important;
}

/* .other-reply .comment-con{align-items: center;} */
.or-line-post .post-by-time {
  position: absolute;
  z-index: 1;
  background: #f3f6f9;
  padding: 0px 8px;
}

.new-comment {
  color: var(--topbar-color);
  margin-left: 4px;
}

.important-txt {
  font-size: 12px !important;
  color: #ff0000 !important;
  font-weight: 700;
  margin-bottom: 5px !important;
}

.right-align {
  text-align: -webkit-right;
  text-align: -moz-right;
}
.modal-header-custom {
  background: var(--topbar-color);
  border-radius: 2px 2px 0px 0px;
  padding: 0.6rem 1rem;
  color: #fff;
}
.modal-header-custom h5 {
  font-weight: 600;
}
.modal-header-custom .close {
  color: #fff;
  opacity: 1;
  cursor: pointer;
}
.assessmentDisbale {
  pointer-events: none;
}

.new-table-box .overflow-ellipsis2 a,
.new-table-box .rdt_TableRow .textLimit2,
.textLimit75,
.textLimit100,
.textLimit50,
.textLimit150 {
  overflow: hidden;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.new-table-box .overflow-ellipsis2 a,
.new-table-box .rdt_TableRow .titleTextLimit2,
.titleTextLimit75,
.titleTextLimit100,
.titleTextLimit50,
.titleTextLimit30,
.titleTextLimit150 {
  overflow: hidden;
}

.new-table-box.custom-table-div .rdt_TableCol {
  white-space: normal;
}

.learningMethodText {
  max-width: 111px;
  white-space: normal;
  word-break: normal;
}

.new-table-box .rdt_TableRow .textLimit2 {
  max-width: 100px;
}

.new-table-box .rdt_TableRow .textLimit100 {
  max-width: 100px;
}

.new-table-box .rdt_TableRow .textLimit75 {
  max-width: 75px;
}

.new-table-box .rdt_TableRow .textLimit50 {
  max-width: 60px;
  word-wrap: break-word;
}

.new-table-box .rdt_TableRow .textLimit150 {
  max-width: 150px;
}

.new-table-box .rdt_TableRow .titleTextLimit100 {
  max-width: 100px;
}

.new-table-box .rdt_TableRow .titleTextLimit75 {
  max-width: 75px;
}

.new-table-box .rdt_TableRow .titleTextLimit50 {
  max-width: 50px;
}

.new-table-box .rdt_TableRow .titleTextLimit150 {
  max-width: 150px;
}

.overflow-ellipsis2.fst_line2 .titleTextLimit50 {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.overflow-ellipsis2.fst_line2 a {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.assigned-title-itm.fst_line2 .textLimit2 {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.overflow-ellipsis2.fst_line2 a.textLimit100 {
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.icon-success {
  margin-left: 2px;
}

.icon-danger {
  margin-left: 2px;
}

.icon-success i.fal.fa-check {
  color: #00c915;
}

.icon-danger i.fal.fa-times {
  color: #891822;
}

.sc-dJjYzT.hgWSFM.rdt_TableBody
  .sc-jrQzAO.OTlLy.rdt_TableRow:first-child
  .assigned-title-info {
  top: 0;
  bottom: auto;
}

.days-overdue-cell-width .sc-jrQzAO.OTlLy.rdt_TableRow .rdt_TableCell {
  min-width: 72px !important;
}

.days-overdue-cell-width
  .sc-jrQzAO.OTlLy.rdt_TableRow
  .rdt_TableCell
  .textLimit100 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  white-space: normal;
  -webkit-box-orient: vertical;
}

/* Second lines number of lines to show */
.new-table-box .overflow-ellipsis21 a,
.new-table-box .rdt_TableRow .textLimit21,
.textLimit76,
.textLimit3Line76,
.textLimit101,
.textLimit51,
.textLimit130,
.textLimit151,
.textLimit400 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  white-space: normal;
  -webkit-box-orient: vertical;
}
.textLimit3Line76 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 2;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.overflow-ellipsis21.textLimit101 {
  width: 100px;
}

.overflow-ellipsis21.textLimit51 {
  width: 51px;
}

.textLimit51 {
  width: 51px;
}

.textLimit76 {
  width: 76px;
}

.textLimit3Line76 {
  width: 76px;
}

.textLimit103 {
  width: 103px;
}

.textLimit130 {
  width: 130px;
}

.textLimit400 {
  width: 400px;
}

.rdt_Table .rdt_TableRow .rdt_TableCell:first-child {
  min-width: 155px !important;
}

.rdt_Table .rdt_TableRow .rdt_TableCell:first-child span,
.rdt_Table .rdt_TableRow .rdt_TableCell:first-child span a {
  overflow: hidden;
  text-overflow: inherit;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.custom-main-table.custom-table-div.select_tp_year01 {
  overflow-x: initial;
  overflow-y: inherit;
}

.select-table .select_tp_year01 .css-26l3qy-menu {
  top: auto;
  z-index: 11 !important;
  bottom: 100%;
}

.show-reply.likeUnlikeBox .card-reply {
  overflow: inherit;
}

.show-reply.likeUnlikeBox .card-reply .post-reat-new {
  top: -39px;
  bottom: auto;
  right: 20px;
  margin: auto;
  left: auto;
}

.post-root .card-footer .card-left-by-img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.other-reply {
  border-left: 4px solid #467fcf !important;
}

.my-tag-reply {
  border-left: 4px solid #06182a !important;
}

.ps-fd-card-head a.hover-color-blue {
  font-weight: normal !important;
}

.ps-fd-card-head .post-area .assigned-title-info {
  left: 100%;
  /* right: auto; */
  bottom: 0px;
  top: auto;
}

.ps-by-card-header
  .post-reat-new
  .post-reat-list
  .assigned-title-blk
  .assigned-title-info {
  right: 100%;
  left: auto;
  top: -8px;
}

.post-reaction-blk a .like-list .assigned-title-info article i {
  margin-right: 0;
}

a.show_more_btn {
  font-size: 14px;
  text-decoration: underline !important;
  color: #ef8700;
  font-weight: 600;
}

.title_bitton_lb label {
  display: block;
}

.title_bitton_lb {
  padding-bottom: 15px;
}

.col-md-3 > .upload-file-icon {
  display: flex;
  justify-content: space-between;
}

.able_modal01 .course-table-filter-sec .filter-search-bar-blk {
  top: 41px;
  margin-left: 175px;
}

.able_modal01 .modal-body {
  /* padding: 20px 50px 40px 50px; */
  padding: 30px;
}

.able_modal01 .card-table-custom {
  padding: 20px;
}

.sub-menu.accordion .card-header a.btn-link.btn-block[aria-expanded="true"] {
  background: var(--active-color);
  font-weight: 600;
}

.pmc-available-modal {
  max-width: none;
  width: 70%;
}

/****12-10-2022****/
.card-body1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-main button.btn-link {
  border: 0;
  background: transparent;
  font-size: 16px;
  padding: 0;
  color: #000;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.title-border-box {
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  border-left: 3px solid var(--sidebar-color) !important;
  box-shadow: 0 2px 2.97px 0.03px hsl(0deg 0% 49% / 13%);
  border: 1px solid #d7dee8;
  flex: 1;
  font-weight: 600;
  border-radius: 2px;
}

.header-status .status-gol {
  width: 47px;
  height: 47px;
  box-shadow: 0px 1.5px 2px 0px rgb(0 0 0 / 30%) !important;
}

.header-status .status-gol:hover {
  background: var(--hover-color) !important;
  border-color: var(--hover-color) !important;
}

/* .card-body {  
  border: 1px solid #eef1f5 !important;
} */

.new-reply-font {
  font-weight: 600;
}

.summary-sidebar-hide {
  border-left: 4px solid transparent !important;
}
.summary-sidebar-hide:hover {
  color: var(--topbar-color);
}
.comment-con.new-reply-font:hover {
  color: var(--topbar-color);
}
/* .other-reply.summary-main:hover {
  color: var(--topbar-color);
}:hover {
  color: var(--topbar-color);
} */
.summary-sidebar-show {
  border-left: 4px solid #06172a !important;
}

.sidebar-left li.active-li .sub-menu-content-block {
  display: block;
  visibility: visible;
}

.description-content {
  max-height: 50px;
  overflow: auto;
}

.tooltip {
  display: inline;
  position: relative;
}

.tooltip:hover:after {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  background: #444;
  border-radius: 8px;
  color: #fff;
  content: attr(title);
  margin: -82px auto 0;
  font-size: 16px;
  padding: 13px;
  width: 220px;
}

.tooltip:hover:before {
  border: solid;
  border-color: #444 transparent;
  border-width: 12px 6px 0 6px;
  content: "";
  left: 45%;
  bottom: 30px;
  position: absolute;
}

.card-main {
  margin-bottom: 15px;
}

.auditTrail-text-overflow-ellipsis p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 350px;
}

.as-text-blue * {
  color: #1899d2 !important;
}

.card-blue-1:hover {
  background: var(--hover-color) !important;
  border-color: var(--hover-color) !important;
}
.custom-table-div .cat img {
  height: 40px !important;
}

.editor-block-invalid {
  border: 3px #f6ccd0 solid !important;
}

/* .title-border-box:hover{
  background: var(--tab-hover-color) !important;
} */
.title-border-box:hover,
.btn-link[aria-expanded="true"] .title-border-box {
  background: var(--form-tab-hover-color) !important;
}
.card-main {
  margin-bottom: 15px;
}
.btn-link[aria-expanded="true"] .header-status i {
  transform: rotate(180deg);
}

.card.card-profile-info-card .inner-tab-card {
  border-radius: 5px !important;
}

/**Deon CSS*****************************************************************/

body {
  --grey-grey: #c6c6c6;
  --greensoft: #00c62e;
  --red-red: #ff0000;
}
.phonesoft {
  background-color: white;
  height: 100vh;
  width: 350px;
}
.softphone {
  background-color: var(--topbar-color);
  height: 60px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-top: 12px;
  width: 350px;
}
.softstatusback {
  background-color: white;
  border-radius: 18px;
  height: 36px;
  display: flex;
  flex-direction: row;
  padding: 1px;
  width: 324px;
}
.btnphoneclose {
  background-color: var(--topbar-color);
  border-radius: 15px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  font-size: 16px;
  height: 30px;
  margin-left: -35px;
  margin-top: 2px;
  padding-top: 7px;
  justify-content: center;
  width: 30px;
}
.softstatusdot {
  background-color: var(--greensoft);
  border-radius: 50%;
  display: flex;
  height: 34px;
  margin-left: 5px;
  width: 34px;
}
.softext {
  color: var(--grey-grey);
  display: flex;
  font-size: 16px;
  font-weight: 700;
  padding-top: 8px;
  padding-left: 5px;
  left: 40px;
}
.softtimecall {
  color: var(--greensoft);
  display: flex;
  font-size: 16px;
  font-weight: bold;
  padding-left: 25px;
  margin: 0 auto;
  padding-top: 8px;
}
.softtimecall i {
  padding-top: 2px;
  padding-right: 5px;
}
.softactivity {
  color: var(--greensoft);
  display: flex;
  font-size: 16px;
  font-weight: bold;
  padding-top: 8px;
  margin-left: auto;
  margin-right: 12px;
  right: 12px;
}
.phonecontent {
  background-color: white;
  padding: 18px;
}
.plt4 {
  padding-left: 35px;
}
.phonecontent input {
  padding-bottom: 20px !important;
}
.phonekeypad {
  padding-top: 20px;
  padding-bottom: 10px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.keypadsoft {
  background-color: var(--topbar-color);
  border-radius: 0.3rem;
  color: white;
  font-size: 20px;
  height: 42px;
  padding-top: 8px;
  margin-bottom: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 83px;
}
.keypadsoft i {
  font-size: 16px;
}
.bottombuttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 20px;
}
.bottombuttonss {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 20px;
  padding-top: 20px;
}
.bottombut {
  background-color: var(--topbar-color);
  border-radius: 50%;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  height: 49px;
  width: 49px;
}
.bottombut:hover {
  background-color: var(--sidebar-color);
  border-radius: 50%;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  height: 49px;
  width: 49px;
}
.bottombutactive {
  background-color: var(--greensoft);
  border-radius: 50%;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  height: 49px;
  width: 49px;
}
.bottombutinactive {
  background-color: var(--red-red);
  border-radius: 50%;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  height: 49px;
  width: 49px;
}
.netdate {
  font-size: 20px;
  padding-top: 20px;
  text-align: center;
}
.accordph {
  padding-top: 10px;
}
.accordianphone {
  background-color: white;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  padding: 5px 10px 5px 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}
.accordianphone i {
  color: var(--topbar-color);
  display: flex;
  font-weight: 700;
  padding-top: 2px;
  margin-left: auto;
  margin-right: 0px;
}
.accordiancoc {
  display: flex;
}
.bottombut,
.bottombutactive,
.bottombutinactive i {
  color: white;
  font-size: 17px;
  padding-top: 17px;
}
.historyheader {
  background-color: var(--topbar-color);
  color: white;
  font-size: 20px;
  font-weight: bold;
  height: 42px;
  padding-top: 7px;
  text-align: center;
}
.historyblock {
  border: 1px solid var(--grey-grey);
  margin-top: 10px;
  margin-left: 10px;
  width: 90%;
}
.historyblockheader {
  background-color: var(--grey-grey);
  font-size: 12px;
  padding-bottom: 3px;
  padding-right: 5px;
  padding-top: 3px;
  text-align: end;
  font-style: italic;
}
.historyblockcontent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 16px;
  padding: 11px;
  overflow-y: auto;
}
.historyblockig {
  color: var(--greensoft);
  display: flex;
  flex: 5%;
  padding-right: 7px;
  padding-top: 1px;
}
.historyblockib {
  color: var(--sidebar-color);
  display: flex;
  flex: 5%;
  padding-right: 7px;
  padding-top: 1px;
}
.historyblockname {
  display: flex;
  flex: 45%;
}
.historyblocktel {
  display: flex;
  flex: 47%;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 0px;
}

.auditTrail-text-overflow-ellipsis p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 350px;
}

.as-text-blue * {
  color: #1899d2 !important;
}

.card-blue-1:hover {
  background: var(--hover-color) !important;
  border-color: var(--hover-color) !important;
}
.custom-table-div .cat img {
  height: 40px !important;
}

.editor-block-invalid {
  border: 3px #f6ccd0 solid !important;
}

.title-border-box:hover,
.btn-link[aria-expanded="true"] .title-border-box {
  background: var(--form-tab-hover-color) !important;
}
.card-main {
  margin-bottom: 15px;
}
.btn-link[aria-expanded="true"] .header-status i {
  transform: rotate(180deg);
}

.card.card-profile-info-card .inner-tab-card {
  border-radius: 5px !important;
}
.settinglayout {
  display: flex;
  flex-direction: column;
}
.settingtext {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
}
.phonebuttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
}
.phonecaller {
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
}
.callerunknown {
  background-color: #c6c6c6;
  border-radius: 50%;
  padding-top: 20px;
  text-align: center;
  height: 76px;
  width: 76px;
}
.callerunknown i {
  color: var(--topbar-color);
  font-size: 35px;
  text-align: center;
}
.callertext {
  font-size: 20px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}
.callername {
  font-weight: bold;
  line-height: 23px;
}
.callstudent {
  line-height: 23px;
}
.callemail {
  line-height: 23px;
}

/* content assembly related css */
.available-assessment-modal {
  max-width: none;
  width: 90%;
}

.ass-bel-stuff .new-datatable-blk .filter-search-bar-blk {
  margin-left: 132px;
}
.ass-bel-stuff .new-datatable-blk .sc-hGPBjI.elHTkS {
  border: unset;
  border-radius: unset;
  padding: 0 0px 20px;
  box-shadow: unset;
}

/* end of content assembly related css */
.dashboard-message-count {
  display: flex;
}
.message-count {
  color: red;
}
.attach-comments {
  margin-left: 10px;
  margin-bottom: 10px;
}

/* manisha css start */
.profile-title-box {
  display: flex;
}
.profile-title-box-main {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: flex-start;
}
.mob-profile {
  display: none;
}
.likeUnlikeBox h5.comment-con {
  flex: 1;
}
.scroll-sec-feed-msg .post-reaction-blk a i {
  margin-right: 0;
}
.scroll-sec-feed-msg .post-reaction-blk a .like-list i {
  margin-right: 10px;
}
.scroll-sec-feed-msg .like-list ul li {
  font-weight: 400;
}
.scroll-sec-feed-msg .post-reaction-blk a:hover .like-list {
  display: block;
  bottom: auto;
  top: -30px;
  z-index: 9;
}
.mob-profile .post-by {
  padding-right: 10px;
}
.scroll-sec-feed-msg .lastIndex .post-reaction-blk a:hover .like-list {
  top: -61px;
}
.scroll-sec-feed-msg .lastIndex .post-reat-new {
  top: -38px;
  bottom: auto;
  right: 20px;
  margin: auto;
  left: 0;
}
.mob-profile-dotted {
  display: none;
}
/* manisha css end */
/* Oct29 start */
.mob-profile-right .post-reaction-blk {
  display: flex;
  align-items: center;
}

.mob-profile-right .post-reaction-blk a {
  margin-right: 0 !important;
}

/* Oct 29 end */

.post-by.reply-profile-hide .assigned-title-info {
  right: auto;
  left: 0;
  bottom: 33px;
  top: auto;
}

.post-reat-new {
  right: 0;
}

.mob-profile-right .post-reaction-blk a {
  font-weight: 400;
}

.show-reply.likeUnlikeBox .card-reply .post-reat-new {
  top: auto;
  bottom: 0;
  right: 100px;
  margin: auto;
  left: auto;
}

.show-reply.likeUnlikeBox .card-reply .post-reat-new,
.scroll-sec-feed-msg .lastIndex .post-reat-new {
  top: -38px;
  bottom: auto;
  right: 20px;
  margin: auto;
  left: auto;
}

.post-reat-new {
  right: 20px;
  top: -38px;
}

.assigned-title-info article figcaption p {
  display: flex;
  align-items: center;
}
.scroll-sec-feed-msg .assigned-title-info article figcaption i.fal.fa-envelope {
  padding-right: 4px;
  margin-right: 0 !important;
}
.scroll-sec-feed-msg .assigned-title-info article figcaption a {
  font-weight: 400;
  color: #5b5b5b;
  font-size: 12px;
}
.filter-search-bar-blk .filter-button-group .top-margin {
  position: relative;
  top: 41px;
  left: -8px;
}
#feature-popup {
  background: rgba(0, 0, 0, 0.5);
}

.feature-name {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 50px;
}
.color-drop .css-26l3qy-menu > div {
  padding: 8px 0;
  display: flex;
  flex-flow: row wrap;
}
.color-drop .css-26l3qy-menu div {
  background: none;
  display: inline-flex;
  border-bottom: 1px solid #eff0f2;
}
.color-drop .css-26l3qy-menu div:last-child {
  border-bottom: 0px;
}
.color-drop .css-26l3qy-menu div span {
  padding: 3px 5px;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.2;
}
.color-drop .css-26l3qy-menu > div span.red {
  background-color: #ffaaaa;
  color: #ff0000 !important;
}
.color-drop .css-26l3qy-menu > div span.skyblue {
  background-color: #e0ffff !important;
  color: #40e0d0 !important;
}
.color-drop .css-26l3qy-menu > div span.green {
  background-color: #d5ffd9 !important;
  color: #00c915 !important;
}
.color-drop .css-26l3qy-menu > div span.blue {
  background-color: #e0edff !important;
  color: #1899d2 !important;
}
.color-drop .css-26l3qy-menu > div span.yellowshade {
  background: #e7e8d7 !important;
  color: #abb072 !important;
}
.color-drop .css-26l3qy-menu > div > div:hover {
  background: #f3f6f9;
}

/* .erp-d-transform{ transform: translate3d(0px, 35px, 0px) !important;} */
.z-0 {
  z-index: 0;
}
/* .erp-d-transform{ transform: translate3d(0px, 35px, 0px) !important;} */
.z-0 {
  z-index: 0;
}

.z-none .filter-button-group {
  z-index: initial;
}

.filter-search-bar-blk.z-none {
  z-index: initial;
}
.popup-top {
  top: -89px !important;
  left: -31px !important;
}

.scroll-sec-feed-msg .assigned-title-info article figcaption i {
  padding-right: 0px;
  margin-right: 0px;
  margin-bottom: 0;
  padding-top: 2px;
  font-size: 12px;
}

@media only screen and (max-width: 768px) {
  .sidebar-left .sub-menu-content-block {
    position: fixed;
    top: 47px;
    height: calc(100% - 65px);
  }
  .sidebar-left li.active-li .sub-menu-content-block {
    left: 0 !important;
  }
  .side-bar-nav {
    height: 100%;
  }
}
.dashboard-message-count {
  display: flex;
  /* flex-direction: column-reverse;
  height: calc(100vh - 221px);
  position: relative; */
}
.message-count {
  color: red;
}
.nav-fa-comments {
  position: relative !important;
  top: 50%;
  transform: translateY(21%);
}
.notify-count {
  position: absolute !important;
  /* right: 29px !important; */
  left: -15px !important;
  top: -12px !important;
  width: 21px !important;
  height: 21px !important;
  background: #ff0202 !important;
  border-radius: 100% !important;
  font-size: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: "Segoe UI" !important;
  font-weight: 700 !important;
  color: #fff !important;
  z-index: 1 !important;
  border: 1px solid #fff !important;
}
/* .nav-tpFixed-comments {
  position: absolute !important;
  top: 19px !important;
  right: 53px !important;
} */

.nav-tpFixed-comments {
  position: absolute !important;
  top: 12px !important;
  right: 100px !important;
}

.unread-message {
  font-size: 10px !important;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  /* position: relative;
  top:-1px; */
}
.comments {
  font-size: 23px !important;
}
.scroll-tabs.filter-scroll {
  height: 1px;
}
.programme-name {
  justify-content: center;
}
.programme-font {
  font-size: x-large !important;
}
main.header-padding {
  padding-top: 0 !important;
}
.header-padding .content-wrapper {
  height: 100vh !important;
  max-height: 100vh !important;
}

.subjectcheck-title {
  font-weight: bold;
}
.zindexadd {
  background-color: rgba(0, 0, 0, 0.5);
}
.available-top {
  margin-top: -86px;
}
.modal-body .dataTables_wrapper .dataTables_filter {
  float: left;
  margin-bottom: 10px;
  top: -20px;
  position: relative;
}
#add-outcome {
  background: rgba(0, 0, 0, 0.5);
}

.table-item-width {
  max-width: 400px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.btn-close-position {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  background: none;
  border: none;
  font-size: 16px;
}

.modal-inner-std .system-administration-table.table-responsive {
  margin-top: -20px;
}
.modal-inner-std .filter-search-bar-blk {
  position: static;
}
.modal-inner-std .card-table-custom {
  padding: 0;
}
.modal-inner-std .rdt_TableHeader {
  display: none;
}

.export-heading {
  text-transform: none !important;
}

#add-topic {
  background: rgba(0, 0, 0, 0.5);
}

.editor-text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 1000px;
}

.custom-table-div.export-border .rdt_Table {
  width: 100%;
  display: table;
  border-collapse: inherit !important;
}
.line-break {
  word-break: break-all;
  white-space: normal;
}
/* css added by yash for resource management dashboard */
.dash-box.course-dashborad .basic-info-box-list ul li span {
  word-break: normal;
  min-width: unset;
}

.dash-box.course-dashborad .basic-info-box-list ul li span .title {
  min-width: 0;
}

.dash-box-2 ul {
  padding-left: 0;
}
.export-heading-width {
  width: 100%;
}
.export-heading-border {
  background-color: var(--topbar-color);
  border: 1px solid #000 !important;
  display: flex;
  color: #ffffff;
  flex-flow: row wrap;
  align-items: center;
  font-size: large;
  padding: 3px 10px 7px 10px;
  /* margin-bottom: -2px; */
}
.edit-icon.new-card-header {
  min-height: auto;
}

.border-bottom-0 {
  border-bottom: none !important;
}
.table-header-border {
  background: none !important;
  border-color: #06172a !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

table.export-table-border-header {
  width: 100%;
}
table.export-table-border-header,
table.export-table-border-header td,
table.export-table-border-header th {
  border: 0.1px solid #333 !important;
  padding: 3px;
  /* font-size: larger; */
}
table.export-table-border-header {
  display: table;
  /* border-collapse: separate; */
  box-sizing: border-box;
  text-indent: initial;
  /* border-spacing: 5px; */
}
.export-vertical-align {
  vertical-align: top;
}
.export-table-border-header td p {
  margin-bottom: 0px !important;
}
.edit-icon-margin {
  margin-bottom: 0px !important;
}
.module-margin p {
  margin-bottom: 0px !important;
}
/* .export-html-top{
  margin-top: 8px !important;
} */
.likeUnlikeBox:hover {
  background: var(--lightGray);
}
.export-module-box-border {
  border: 1px solid !important;
  padding: 10px 10px;
  margin-bottom: 15px;
}
.topic-margin-bottom {
  margin-bottom: 5px;
}
.my-tickets-info-list.Tickets-main-wrap.export-outcome {
  margin-top: 10px;
}

.c-a-a-r .modal-body .dataTables_wrapper .dataTables_filter {
  top: -20px;
}
.export-font-change,
.export-font-change label {
  font-size: 12px;
}
.export-table-th {
  font-size: 14px;
  background-color: var(--sidebar-color);
  color: #fff;
}
.table-end-btn {
  margin-top: -70px;
  position: relative;
  margin-left: 30px;
}
.module-module-heading {
  background-color: var(--sidebar-color);
  border: 1px solid #000 !important;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 10px;
  color: #fff;
}

.module-module-heading label {
  margin-bottom: 0 !important;
}
.module-title .module-module-heading {
  border-bottom: none !important;
}

.card-sub-list .export-module-box-border {
  margin-bottom: -2px;
}

/* dragging dummy css by yash but need to remove after dragging done  */

.draggable {
  /* display: flex; */
  align-items: center;
  gap: 1rem;
  margin: 1rem;
}
/* .draggable2 {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
} */

.draggable-category {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin: 1rem;
}

h2 {
  margin: 0;
}

.drag-handle {
  border: 2px solid blue;
  background: cornflowerblue;
  border-radius: 4px;
}

.category-container {
  width: 90%;
}

.item {
  border: 2px solid gray;
  border-radius: 4px;
  width: 100%;
  background: lightgray;
}
.c-a-a-r .modal-body .dataTables_wrapper .dataTables_filter {
  top: -20px;
}

/* Assembly tree style start */

.tree-wrapper {
  padding: 0px;
}

.tree-header {
  padding: 0px;
  padding-right: 55px;
  display: flex;
  justify-content: end;
}

.tree-header .card-header {
  padding: 0 !important;
}

button.drag-option {
  border: none;
  margin-right: 10px;
}

.drag-option-container:last-child {
  margin-right: 0;
}

.tree-wrapper .rstcustom__rowWrapper {
  margin: 0px 0;
  padding: 0;
  margin-right: 10px;
}
.tree-wrapper .tree-header.title-border-box {
  margin-bottom: 10px;
}

.tree-wrapper .rstcustom__highlight {
  background: none;
}
.tree-wrapper .rstcustom__nodeContent {
  width: 100%;
  position: static;
  padding-right: 45px !important;
}

.tree-wrapper .rstcustom__node {
  height: auto !important;
  margin-top: 15px;
}

p.assembly-list-title {
  padding:5px;
  margin: 0;
  align-items: center;
  display: flex;
}
p.assembly-list-title i {
  color: #467fcf;
}

.rstcustom__row {
  padding-left: 0px !important;
}

.main-category-node .rstcustom__rowContents {
  border-left: 3px solid rgb(70, 127, 207) !important;
}
.sub-category-node .rstcustom__rowContents {
  border-left: 3px solid #ef8700 !important;
}

.rstcustom__rowContents {
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 8px 15px 8px 20px;
  box-shadow: none;
  border: 1px solid #d7dee8;
  flex: 1 1;
  font-weight: 600;
  border-radius: 2px;
}
.rstcustom__rowContents:hover,
.btn-link[aria-expanded="true"] .title-border-box {
  background: var(--form-tab-hover-color) !important;
}

button.rstcustom__expandButton,
button.rstcustom__collapseButton {
  padding: 0;
  z-index: 9;
  right: 0px;
  top: 0px;
  color: #fff;
  border-radius: 2px;
  transition: ease-in-out;
  font-size: 20px;
  font-weight: 300;
  font-family: "Font Awesome 5 Pro";
  transform: none;
  height: 100%;
}

button.rstcustom__expandButton {
  background: var(--topbar-color);
  width: 45px;
  height: 55px;
}

button.rstcustom__expandButton:hover {
  background-color: var(--hover-color);
}

button.rstcustom__collapseButton {
  right: 15px !important;
}
button.rstcustom__collapseButton:hover ~ .rstcustom__lineChildren {
  background-color: var(--hover-color);
}

.rstcustom__collapseButton:before {
  content: "\f077";
}

.rstcustom__expandButton:before {
  content: "\f078";
}

.rstcustom__lineChildren {
  height: 100%;
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 0px;
  border: none;
  background: var(--topbar-color);
  border-radius: 2px;
}

.drag-placeholder {
  padding: 20px;
}

.rstcustom__rowTitle {
  font-weight: normal;
}

.rstcustom__collapseButton:before {
  content: "\f077";
}

.rstcustom__expandButton:before {
  content: "\f078";
}

.content-assembly .modal-content {
  border: none;
}

/* Assembly tree style end */
.qus-bank-table .rdt_Table .rdt_TableRow .rdt_TableCell:first-child {
  white-space: pre-wrap !important;
  max-width: 1000px !important;
}

.ques-modal {
  max-width: max-content;
}
.col-width .basic-info-box-list ul li {
  font-size: 14px;
  color: #484644;
  display: block;
}

.custom-table-div.filter-search-icon.card.card-table-custom.course-table-filter-sec.mt-4.assinged-quiz-tab
  .filter-search-bar-blk {
  right: 0;
}

.multiline-text {
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  white-space: normal;
}
/* Assembly tree style end */

.filepond--file-action-button.filepond--file-action-button svg {
  /* width: 100%;
  height: 100%; */
  vertical-align: inherit;
}

.basic-info-box-list.space-box ul li {
  display: block !important;
}
.dashboard_hover:hover {
  background-color: #ef8700 !important;
}
/***/
.filter-search-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.filter-search-head-left .filter-search-bar-blk {
  position: static;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 15px;
}
.filter-search-head-left .filter-search-bar-blk .filter-button-group {
  position: static;
  top: 0;
  left: 0;
}
.filter-search-head-left .dataTables_filter {
  display: flex;
}
.filter-search-head-left .system-administration-table.table-responsive {
  margin-top: 0;
  overflow: inherit;
  display: flex;
  align-items: center;
}
.filter-search-head-left .table-responsive-div {
  min-width: auto;
}
.filter-search-head-left .filter-button-group {
  position: static;
}
.filter-search-head-left .dataTables_wrapper .dataTables_filter {
  float: none;
  margin-bottom: 0;
  top: 0;
  position: static;
}
.filter-search-head-left .files-export-group {
  margin-left: 10px;
}

/**/

.search-filter-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  flex-wrap: wrap;
}
.search-filter-div-left .filter-scroll-inner {
  flex-wrap: wrap;
  min-height: auto;
  position: static;
  padding: 0px 10px 0 10px;
  max-width: 604px;
  overflow-x: visible;
  max-width: initial;
}
.search-filter-div-left .assessment-table-filter {
  margin: 0px 4px 4px 0px;
}
.search-filter-div-right .filter-search-bar-blk {
  position: static;
  margin-left: 0;
}
.search-filter-div-left .filter-button-group {
  position: static;
  align-items: flex-start;
  top: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0 0px;
}
.search-filter-div-left .system-administration-table.table-responsive {
  margin-top: 0;
  display: flex;
  align-items: flex-start;
}
.search-filter-div-left .filter-scroll .btn-arrow {
  display: none;
}
.search-filter-div-left .filter-scroll {
  top: 0;
  min-width: auto;
}
.search-filter-div-left .dataTables_wrapper .dataTables_filter {
  float: none;
  margin-top: 6px;
  top: 0;
  position: relative;
  display: flex;
  align-items: center;
}
.search-filter-div-left .table-responsive-div {
  min-width: auto;
}
.search-filter-div-left .filter-button-group > div + div {
  margin-left: 0;
}
.search-filter-div-left .button-reset.dropdown-comman {
  margin-right: 10px;
}
.search-filter-div-left .reset-btn-group {
  display: flex;
  align-items: center;
}
.search-filter-div-left .button-reset {
  margin-top: 0;
}
.search-filter-div-left {
  flex: 1;
}
.search-filter-div-right {
  margin-left: 15px;
}
.search-filter-div-left .filter-scroll-inner {
  padding: 0;
}

.search-filter-div-left .dataTables_wrapper .dataTables_filter input {
  padding: 9px 0px;
}

/* Rearrange css start */
.re-arrange-header {
  padding: 10px 12px;
  background: #f3f6f9;
  width: 20%;
  margin-right: 20px;
  border-radius: 2px;
  height: 40px;
  display: flex;
  align-items: center;
  color: rgb(72 70 68 / 59%);
}
.re-arrange-items {
  border: 1px dashed var(--grey-grey);
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

.re-arrange-items i {
  padding-right: 5px;
}

.first-rearrange {
  color: var(--topbar-color);
  font-weight: 600;
}

.re-arrange-items.dragging {
  border-color: #484644;
}

.resource-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  margin-right: 20px;
  min-width: 390px;
}
/* Rearrange css end */

/* STL Student hub section css start */

.resource-icon {
  padding: 15px;
  background: var(--sidebar-color);
  border-radius: 2px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1.5px 2px 0px rgb(0 0 0 / 30%) !important;
}

.resource-icon.not-published {
  background: #c5c5c5;
}

.resource-icon i {
  color: #fff;
}

.resource-title h6,
.resource-title p {
  margin-bottom: 0;
}

.resource-action {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.student-section-head {
  display: flex;
  align-items: center;
}

.stl-action-list {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
}

.stl-action-list li {
  list-style: none;
  margin: 0 3px;
}

.stl-action-btn {
  min-width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
}
.stl-action-btn i {
  margin-right: 0;
}

.student-section-head h3 {
  margin-bottom: 0;
}

.student-section-head h3 {
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}

.resource-container {
  border-bottom: 1px solid #eef1f5;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.section-top {
  margin-bottom: 25px;
  align-items: center;
}

.stl-tab-card .card-body {
  padding: 25px !important;
}

.section-top h5 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}

.resource-title {
  padding-left: 10px;
  cursor: default;
}
.resource-title h6 {
  font-size: 14px;
  font-weight: 600;
}

.resource-title p {
  font-size: 12px;
  padding-top: 1px;
}

.resource-action {
  margin-left: 30px;
}

.resource-item-left {
  display: flex;
}

.student-section-body {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: auto;
}

.stl-hub-dragging {
  border: 1px dashed #484644;
}

/* STL Student hub section css end */
.assest-custom .attached-file-ul li {
  padding: 0;
}

/**/

.assest-custom .attached-file-ul li .btn-danger {
  transform: none;
  position: static;
}

.assest-custom .attached-file-ul {
  margin-top: 5px !important;
}

.or-box label {
  margin-bottom: 0;
  margin-top: 8px;
}

.or-box {
  margin: 0 15px;
}
.custom-table-div.filter-search-icon.card.card-table-custom.course-table-filter-sec.mt-4.assinged-quiz-tab
  .filter-search-bar-blk {
  right: 0;
}
.map-image-height {
  padding: inherit !important;
}

/****/

.sidebar-right {
  padding: 30px !important;
}

.home-bx-card .icon i {
  color: var(--topbar-color);
}
.mb-30px {
  margin-bottom: 30px !important;
}
.landing-heading {
  margin-bottom: 0px !important;
}
.my-tickets-heading-blk {
  margin-bottom: 30px !important;
}

/***/

.filter-scroll-inner {
  position: static;
}

.scroll-tabs.filter-scroll {
  height: auto;
  padding-bottom: 0px;
  position: relative;
  margin-bottom: 0;
}

ul.home-ul-list {
  margin-top: 30px;
}

.search-filter-div-right {
  flex: 0 0 auto;
}

.search-filter-div-left .dataTables_wrapper .dataTables_filter {
  margin: 0;
}

.search-filter-div-left .filter-scroll {
  /* margin-right: 10px; */
}

.search-filter-div-left .table-responsive-div {
  padding-right: 10px;
}
.filter-scroll .btn-arrow.btn-scroll-left,
.filter-scroll .btn-arrow.btn-scroll-right {
  top: 0;
  padding: 0;
}

button.btn.btn-arrow.btn-scroll-left {
  padding-right: 5px;
}

button.btn.btn-arrow.btn-scroll-right {
  padding-left: 5px;
}

.filter-scroll-inner {
  padding: 0 20px;
}
/* .tabs-wrap .card { padding: 30px !important;} */
.form-group {
  margin-bottom: 0px;
}
.tabs-wrap .card {
  padding: 30px;
}
.sub-menu-left-block .card {
  padding: 0px;
}
.card-table-custom .rdt_TableHeader {
  margin-bottom: 0px !important;
}

.edit-icon.new-card-header .card-header {
  border: none;
  background: none;
  font-weight: bold;
  padding: 0px 0;
  text-transform: capitalize;
  font-size: 16px;
  color: #252423;
}

.custom-inside-form {
  padding: 0 !important;
  border: 0;
}

.course-details-tab-sec .custom-control.custom-checkbox.text-left {
  padding-top: 0 !important;
}

.course-sec-gap.course-setting-sec {
  margin-top: 0;
}
.custom-new-select .css-1okebmr-indicatorSeparator {
  min-width: 1px;
}
.que-custom-space {
  margin-top: 140px;
}
.custom-des-space {
  margin-top: 130px;
}

.word-break-all {
  word-break: break-all;
}
.tabs-wrap .card-body {
  padding: 0px !important;
}
.modal-body .card-table-custom {
  padding: 0;
}

.resource-image-box {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  max-height: 230px;
  padding: 0 !important;
  box-shadow: 2px 4px 8px #dfdfdf !important;
  border: 1px solid #f6f3f3;
}
.resource-image-box img {
  height: 180px;
}

.attached-file-ul li a {
  margin-right: 30px;
}

.customPadding {
  padding: 15px 30px !important;
}
.export-word-break {
  word-break: break-all;
  font-weight: 400;
  font-size: 12px;
}

.export-white-space {
  white-space: nowrap;
}
.rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child .as-text-blue.curser {
  font-weight: bold;
}
.rdt_TableBody .rdt_TableRow .rdt_TableCell .as-text-blue.curser:hover,
.launch-detail-info .as-text-blue.curser:hover {
  text-decoration: underline !important;
  color: #0056b3 !important;
}
.rdt_TableBody .rdt_TableRow .rdt_TableCell .as-text-blue.curser:hover span {
  color: #0056b3 !important;
}
.react-datepicker {
  display: flex !important;
}

.admin-home-acc .card-body {
  margin-right: 57px;
  margin-top: 0px;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.title-border-box {
  box-shadow: none;
  background: #fff;
}

.admin-home-acc .card-body1 {
  margin-top: 0;
}

.admin-home-acc .cke_chrome {
  border-top: 0;
}

.header-status i {
  margin-right: 0;
}

.title-border-box i {
  margin-right: 10px !important;
}

.title-border-box i {
  color: var(--topbar-color);
}
.react-datepicker-wrapper
  .react-datepicker__input-container
  .form-control
  .w-100 {
  width: 100%;
}
.header-time .dropdown .dropdown-toggle::after {
  display: none;
}
.header-time .dropdown .dropdown-toggle {
  background-color: var(--active-text-color);
  color: var(--black);
  border: none;
}
.header-time > p {
  color: var(--active-text-color);
}
.header-time .dropdown ul {
  width: 200px;
  border-bottom: 4px solid var(--topbar-color);
}
.header-time .dropdown .dropdown-item {
  padding: 0.25rem 0.75rem;
  display: flex;
  height: 32px;
  font-size: 14px;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-all;
}
.dorpdown-icon-head {
  margin-left: auto;
  visibility: hidden;
}
.dorpdown-icon-head .btn {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  font-size: 13px;
}
.dorpdown-icon-head .btn:last-child {
  margin-right: 0;
}
.hover-li {
  position: relative;
}
.hover-li:hover:after {
  content: "";
  width: 3px;
  height: 100%;
  background-color: var(--sidebar-color);
  position: absolute;
  left: 0;
  top: 0;
}
.header-time .dropdown-menu {
  padding: 0;
  box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);
  border: 1px solid #d7dee8;
  border-radius: 2px;
}
.hover-li:hover .dorpdown-icon-head {
  visibility: visible;
}
.dropdown-titile .dorpdown-icon-head {
  visibility: visible;
}
.dropdown-titile {
  font-size: 16px !important;
  font-weight: 600;
}
.dropdown-menu.dropdown-menu-right .dropdown-item a {
  color: var(--black);
}
.dropdown-item:focus,
.dropdown-item:active,
.quick-links:hover,
.quick-links:active {
  background-color: var(--hover-color);
}
.dropdown-item:focus,
.dropdown-item:hover a {
  font-weight: bold;
  color: var(--black) !important;
}
.dropdown-divider {
  margin: 0px !important;
}
.next__class__wrapper .next__class__timer {
  padding: 3px 5px;
  border-radius: 2px;
  font-size: 12px;
  text-transform: none;
  background-color: var(--topbar-color);
  color: var(--active-text-color);
}
.comment-conversation-list {
  padding-right: 30px;
  padding-left: 30px;
}
span.no-class-left {
  font-weight: normal;
}
.ticket-response-section {
  text-align: center;
}
.ticket-detail-item {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 0px;
  justify-content: space-between;
  border-bottom: 1px solid #e6ebf1;
}
.ticket-detail-item:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.ticket-detail-item span.data {
  font-weight: bold;
}
.ticket-detail-item span.danger-ticket {
  font-weight: bold;
}
.ticket-detail-item.danger-ticket-item {
  color: var(--red);
}
.ticket-response-section h5 {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  margin: 0;
}
.clock-box {
  font-size: 34px;
  padding: 10px 0px;
}
.date-time-box {
  font-size: 18px;
  font-weight: 300;
}
.frm-group .mb-2.d-flex .uploaded-files {
  width: 90%;
}

.launch-modal-title h6 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
}
.class-detail-item {
  display: flex;
  padding-bottom: 5px;
}
.class-detail-item p {
  margin: 0;
}
.class-detail-item i {
  margin-right: 5px;
}
h6.launch-detail-info-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px;
}
.launch-modal-recording {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.launch-modal-recording p {
  font-weight: bold;
}
.launch-detail-info .assigned-title-block-new {
  margin-bottom: 10px;
}
.launch-detail-info:last-child {
  margin-bottom: 0;
}
.launch-server-detail h6 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.launch-detail-toggle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
}
.launch-class-status {
  background: var(--topbar-color);
  padding: 2px 5px;
  color: #fff;
  font-size: 12px;
  border-radius: 2px;
  font-weight: bold;
}
.class-attendance {
  display: flex;
  font-size: 12px;
  align-items: center;
  margin-top: 10px;
}
.dashboard-loading i {
  color: var(--black);
}

.notify-count.four-digit {
  left: -22px !important;
  top: -14px !important;
  width: 31px !important;
  height: 24px !important;
  font-size: 9px !important;
}
.notify-count.five-digit {
  left: -25px !important;
  top: -14px !important;
  width: 33px !important;
  height: 24px !important;
  font-size: 9px !important;
}
.notify-count.three-digit {
  left: -20px !important;
  width: 25px !important;
  height: 25px !important;
}
.no-text-transformation {
  text-transform: none;
}
.Away {
  color: var(--tab-hover-color);
}
.uploded-files-item .btn {
  padding: 0.375rem 0px 0.375rem 0.75rem;
}
.uploded-files-item {
  line-height: 1;
}
.uploded-files-item figure {
  line-height: 1;
}

/**/
.feature-name.fist-name-box {
  display: flex;
  justify-content: space-around;
  max-width: 100%;
  align-items: flex-end;
}

.feature-name.fist-name-box .as-text-blue.curser {
  flex: 1;
}

.feature-name.fist-name-box .icon-w-text {
  width: 20px;
}

#online_classes_list .rdt_Table .rdt_TableRow .rdt_TableCell:first-child {
  min-width: 160px !important;
}

#online_classes_list.new-table-box .rdt_TableRow .textLimit100 {
  max-width: initial;
}

.scroll-tabs.filter-scroll .filter-scroll-inner {
  padding-top: 20px;
  margin-top: -20px;
}

.scroll-tabs.filter-scroll small.notify-count1 {
  top: -8px;
  left: -13px;
}

.bizcharts-plot-title {
  font-family: inherit !important;
}

.filter-scroll.coures-scroll-arrow .btn-arrow,
.filter-scroll.std-scroll-arrow .btn-arrow {
  display: none;
}
.filter-scroll.coures-scroll-arrow .filter-scroll-inner,
.filter-scroll.std-scroll-arrow .filter-scroll-inner {
  padding-left: 0;
}

@media only screen and (max-width: 1366px) {
  .filter-scroll.coures-scroll-arrow .btn-arrow {
    display: block;
  }
  .filter-scroll.coures-scroll-arrow .filter-scroll-inner {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 1798px) {
  .filter-scroll.std-scroll-arrow .btn-arrow {
    display: block;
  }
  .filter-scroll.std-scroll-arrow .filter-scroll-inner {
    padding-left: 20px;
  }
}
@media screen and (max-width: 991px) {
  .view-ticket-head .text-md-right.action2-blk {
    padding: 0;
    margin: 5px;
    border: none;
  }
  .view-ticket-head .add-new-ticket-bx {
    margin-left: 0;
  }
}

.as-virtual-bg {
  background: #429d4c !important;
  color: #fff;
}
.as-campus-bg {
  background: #8e00a7 !important;
  color: #fff;
}
.as-hybrid-bg {
  background: orange !important;
  color: #fff;
}
.as-launched-bg {
  background: #2d81ef !important;
  color: #fff;
}
.as-not-launched-bg {
  background: #ef671b !important;
  color: #fff;
}
.as-live-bg {
  background: #5cb85c !important;
  color: #fff;
}
.as-past-bg {
  background: #8e00a7 !important;
  color: #fff;
}
.as-upcoming-bg {
  background: #f9a900 !important;
  color: #fff;
}
.as-cancel-bg,
.as-ended-bg {
  background: #d9534f !important;
  color: #fff;
}
.btn-no {
  background: #d42800 !important;
  border: none;
}
.btn-yes {
  background: #4ea04b !important;
  border: none;
}

.css-12jo7m5 .custom-filter-selected {
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 85%;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.css-1rhbuit-multiValue .cat {
  display: block;
}

.ticket-hover-student,
.ticket-hover-user {
  position: absolute;
  right: 0;
  bottom: 0;
}

.ticket-hover-user {
  right: auto;
  left: 0;
}

.comment-box-pro-item {
  position: relative;
  overflow: visible;
}

.Offline i {
  color: #ccc;
}

.Online i {
  color: #00dd60 !important;
}

.ticket-hover-student {
  position: absolute;
  right: -4px;
  bottom: 2px;
}

span.profile-box-2-status.hover-profile-icon {
  position: absolute;
  bottom: -6px;
  right: -2px;
  font-size: 8px;
}

.blank-chat-icon i.fad:before {
  color: var(--sidebar-color);
}
.blank-chat-icon i.fad:after {
  color: var(--topbar-color);
}
.blank-chat-icon i.fad.fa-comments:before {
  color: var(--topbar-color);
}
.blank-chat-icon i.fad.fa-comments:after {
  color: var(--sidebar-color);
}
.blank-chat-icon i.fad {
  font-size: 100px;
}
/* .blank-chat-icon{
  height: 150px;
} */
.chat-icon-1 {
  z-index: -2;
  left: 1px;
  top: -4px;
}
/* .chat-icon-2{
  left: -141px;
  top: 50px;
  z-index: -1;
  font-size: 110px;
} */

.chat-icon-2 {
  left: auto;
  top: auto;
  z-index: -1;
  position: absolute;
  left: 41px;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -47px;
}
.blank-chat-icon i.fad::after,
.blank-chat-icon i.fad::before {
  opacity: 0.9;
}
.blank-chat-icon i.fad.fa-comment-dots::after,
.blank-chat-icon i.fad.fa-comment-dots::before {
  opacity: 0.8;
}
.blank-chat-text p:first-child {
  font-size: 20px;
  font-weight: 600;
}
.blank-chat-text p:last-child {
  font-size: 18px;
}
.blank-chat-text .blank-chat-icon i.fad:before {
  color: var(--sidebar-color);
}
.blank-chat-text i.fad:after {
  color: var(--topbar-color);
  opacity: 0.9;
}
.blank-chat-text {
  position: inherit;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}
.blank-chat-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 1;
  justify-content: center;
  height: 68vh;
}

.rating-comment {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 80px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 6;
  white-space: normal;
  -webkit-box-orient: vertical;
}

ul.course-name-merge li {
  align-items: flex-start;
}

.blank-chat-icon {
  position: relative;
}

#launch {
  overflow-y: hidden;
}
.drag-handler {
  cursor: move;
  -webkit-box-shadow: 2px 2px 4px 0.3px rgba(125, 125, 125, 0.3) !important;
  -moz-box-shadow: 2px 2px 4px 0.3px rgba(125, 125, 125, 0.3) !important;
  box-shadow: 0px 2px 4px 0.3px rgba(125, 125, 125, 0.3) !important;
}
.drag-handler {
  cursor: move;
}

.notification-view.basic-info-box-list ul li span.title {
  font-weight: 600 !important;
  min-width: 220px !important;
  max-width: 140px !important;
  display: inline-block !important;
}
/* remove react-select overlap invalid icon */
.form-control.custom-select-box.is-invalid {
  background-image: none !important;
}
/* chat-room-system-bot */

.system-bot-inner {
  display: flex;
}
/* .system-bot-inner-L {
  max-width: 35px;
} */

/* .system-bot-inner-L figure {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #1899D2;
  color: #fff;
  position: relative;
} */

.system-bot-inner-L figure {
  width: 32px;
  height: 32px;
  background: var(--sidebar-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 5px;
}
.system-bot-inner-L {
  max-width: 35px;
}

/* .system-bot-inner-L figure {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #1899D2;
  color: #fff;
  position: relative;
} */

.system-bot-inner-R {
  border-radius: 3px 3px 3px 3px;
  border: 1px solid #d7dee8;
  background: #fff;
  box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%);
  background-color: white;
  margin-left: 15px;
  flex: 1;
}

.system-bot-inner {
  padding: 0px 90px;
  padding-left: 0px;
}

.system-bot-inner-R-content {
  padding: 0px;
}

.system-bot-inner-R-title h4 {
  font-size: 14px !important;
  font-weight: bold;
  margin-right: 5px;
  margin-bottom: 0;
}

.system-bot-inner-R-title small.tag {
  background: #f8a503;
  padding: 4px 7px;
  font-size: 9px;
  border-radius: 3px;
  color: #fff;
  text-transform: capitalize;
  margin-right: 8px;
}

.system-bot-inner-R-title small.tag-purple {
  background: #8e00a7;
  padding: 4px 7px;
  font-size: 9px;
  border-radius: 3px;
  color: #fff;
  text-transform: capitalize;
  margin-right: 8px;
}

.system-bot-inner-R-title small.tag-green {
  background: #5db85d;
  padding: 4px 7px;
  font-size: 9px;
  border-radius: 3px;
  color: #fff;
  text-transform: capitalize;
  margin-right: 8px;
}

.system-bot-inner-R-title {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.system-bot-inner-R-title small.tag i {
  padding-right: 0px;
  font-size: 10px;
  font-weight: 500;
}

.system-bot-inner-R-title small.tag i:last-child {
  padding-right: 3px;
}

.class-schedule h5 {
  font-size: 13px;
  color: #484644;
  text-transform: capitalize;
}
.class-schedule h5 p {
  font-size: 13px;
  color: #484644;
  text-transform: capitalize;
}
.class-schedule h5 p a {
  font-size: 13px;
  color: var(--topbar-color);
  text-transform: capitalize;
  cursor: pointer;
}

.ratings-class-box {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;
}

ul.rate-list {
  padding: 0;
  list-style: none;
  display: flex;
  margin-bottom: 0;
}

ul.rate-list li i {
  color: var(--topbar-color);
  font-size: 17px;
  font-weight: 300;
  margin-right: 1px;
}
ul.rate-list {
  margin-right: 8px;
}

.status-show b {
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  color: #278c19;
  margin-right: 5px;
}

.status-show-absent b {
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  color: red;
  margin-right: 5px;
}

.status-percentage {
  font-size: 12px;
  font-weight: lighter;
}

.status-show {
  margin-bottom: 12px;
  margin-top: 4px;
}

.status-show-absent {
  margin-bottom: 12px;
  margin-top: 4px;
}

ul.class-schedule-list {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  font-size: 12px !important;
}

ul.class-schedule-list li {
  /* width: 50%; */
  margin-bottom: 8px;
  margin-right: 10px;
}

.name-user p {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  padding-left: 5px;
  text-transform: capitalize;
}

.name-user i {
  padding-right: 10px;
  position: relative;
  top: 1px;
}

ul.class-schedule-list li i {
  padding-right: 5px;
  font-size: 16px;
}

.system-bot-inner-R-content {
  display: flex;
}

.system-bot-inner-R-left {
  flex: 1;
}

ul.action-info {
  display: flex;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

ul.action-info li {
  list-style: none;
}

.system-bot-inner-R-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

ul.action-info li {
  margin-left: 15px;
}

ul.action-info li i {
  font-size: 15px;
  padding-right: 2px;
}

ul.action-info li span {
  font-size: 18px;
}

i.fad:before {
  color: var(--sidebar-color);
}
i.fad:after {
  color: var(--topbar-color);
}

.rolls-tag-small {
  background-color: var(--sidebar-color);
  color: #fff;
}
.student-rolls-tag-small {
  background-color: var(--topbar-color);
  color: #fff;
}

.rate-class {
  gap: 6px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.system-bot-inner-R-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post-reaction-blk.system-actions.mb-3 {
  margin-bottom: 0 !important;
}

.system-bot-inner-R-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.class-schedule h5 {
  margin-bottom: 0;
}

.class-list-row.row {
  margin-top: 5px;
}

.class-schedule {
  flex: 1;
}

.system-bot-inner-R-head {
  margin-bottom: 8px;
}

.stars-system-bot {
  margin: 5px 0;
  color: var(--sidebar-color);
  cursor: pointer;
  font-size: 16px;
}
.questions-quiz-option .custom-control.custom-radio label.custom-control-label {
  width: 100%;
}
.questions-quiz-option .custom-control-label::before,
.questions-quiz-option .custom-control-label::after {
  top: 0.75rem;
}
.as-red-bg-status {
  background: #d9534f;
  color: #fff;
}

.as-red-bg-status-cancelled {
  background: #f54041;
  color: #fff;
  /* text-decoration: line-through; */
}

.as-green-bg-status {
  background: #31a82b;
  color: #fff;
}

.as-orange-bg-status {
  background: #f8a503;
  color: #fff;
}

.as-widget-class-status {
  padding: 3px 5px;
  border-radius: 2px;
  /*min-height: 23px;*/
  display: inline-flex;
  align-items: center;
  font-size: 10px;
  /* text-transform: uppercase; */
}
.system-actions-r i.fad:after {
  opacity: 0.9;
}
.btn-group-box .btn.join-virtual-classes-list-view {
  margin-bottom: 4px;
}
.system-actions-r {
  justify-content: flex-end;
}

.system-actions-r .post-reaction-blk.system-actions.mb-3 {
  flex-wrap: wrap;
}

.system-actions-r .post-reaction-blk.system-actions.mb-3 {
  justify-content: end;
}

.cancelled-class-title {
  text-decoration: line-through;
  color: red;
}

.canceled-date-time-box {
  text-decoration: line-through;
  color: red;
}
.disabled-link {
  pointer-events: none;
  color: var(--lightGray2) !important;
}

.system-bot-inner-R-body .btn-group-box {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.system-bot-inner-R-body ul.meeting-btns-list li {
  min-width: auto;
}

.post-reaction-rel01 .post-reat-new,
.post-reat-new {
  max-width: 280px;
  min-width: auto;
}

.post-reat-icon a:hover {
  color: var(--topbar-color);
}

.post-reat-icon a:hover i {
  font-weight: 600;
  color: #000;
}

.post-reat-action a:hover i {
  font-weight: 600;
  color: #000;
}
.post-reat-action a:hover {
  color: var(--topbar-color);
}

.post-reaction-blk a:hover i {
  --fa-primary-color: black;
  --fa-secondary-color: var(--topbar-color);
}
.post-reat-list ul .info-drop a:hover {
  color: #000;
}
.likeUnlikeBox:hover {
  background: var(--lightGray);
}
.info-drop a:hover i:before,
.info-drop a:hover i:after {
  color: #000;
}

.post-reaction-blk a:hover i:before,
.post-reaction-blk a:hover i:after {
  color: #000;
}

.system-actions a:hover i:before,
.system-actions a:hover i:after {
  color: #000;
}

.info-drop i.fad:after {
  opacity: 0.9;
}

.post-reat-icon i.fad:after {
  opacity: 0.9;
}

.post-reaction-blk i.fad:after {
  opacity: 0.9;
}

.status-color-select{
  padding: 3px 5px;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  }

  .as-widget-resource {
    padding: 3px 5px;
    border-radius: 4px;
    /*min-height: 23px;*/
    display: inline-flex;
    align-items: center;
    font-size: 10px;
    text-transform: uppercase;
    margin: 0 2px 2px;
  }


  .ultimate-guide-main.card {
    display: flex;
    flex-direction: row;
}
  figure.ultimate-guide-L {
    width: 20%;
    margin:0;
}

figcaption.ultimate-guide-R{ flex: 1;  padding-left: 30px;}

.ultimate-guide-top {
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.as-widget-resource-bg {
  
  color: #Fff;
  padding: 4px 7px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
}

.as-widget-resource-bg.bg-blue{background: #4884cf;}

span.as-widget-resource-bg-blue i {
  padding-right: 2px;
}

span.as-widget-resource-bg.bg-red{
  background: red;
}

h3.ultimate-guide-body-title {
  margin-bottom: 0;
  font-size: 24px;
  display: flex;
  align-items: center;
  font-weight: 700;
}

h4.ultimate-guide-body--sub-title {
  font-size: 20px;
  margin-bottom: 0;
}

ul.ultimate-guide-info {
  padding: 0;
  margin: 0;
}

ul.ultimate-guide-info li {
  list-style: none;
  margin-top: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
}


ul.ultimate-guide-info li i {
  margin-right: 5px;
  font-size: 15px;
}

.ultimate-guide-des h4 {
  font-size: 20px;

  font-weight: 600;
  margin-bottom: 10px;
}

.ultimate-guide-des p{ font-size: 14px; }

.ultimate-guide-img {
  /* height: 100%; */
  border: 1px solid rgba(0,0,0,.125);
}

.ultimate-guide-img img {
  width: 100%;
}

h3.ultimate-guide-body-title .as-widget {
  margin-left: 0.25rem;
}

.advanced_serach-img-box-2 {
  border: 1px solid rgba(0,0,0,.125);
  filter: drop-shadow(0px 3px 1.5px rgba(0,0,0,0.16 ));
  /* background: var(--white); */
  height: 317px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.serch-icon-bg i {
  font-size: 120px;
  font-weight: 300;
  text-align: left;
  color: var(--red3);
}

.serch-icon-upper i {
  font-size: 90px;
  font-weight: 300;
  text-align: left;
  color: var(--blue);
}

.serch-icon-upper i.fad:after {
opacity: 0.9;
color: var(--sidebar-color)
}

.serch-icon-upper i.fad:before {
  opacity: 0.9;
  color: var(--topbar-color)
  }

  span.serch-icon-upper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.filter-button-group.student-zindex {
  z-index: 0;
}
.file-pond-req.is-invalid {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
  border-radius: 2px;
}


.custom-table-div .rdt_TableRow .css-26l3qy-menu{max-height: 108px; overflow: auto;}
.custom-table-div .rdt_TableRow:last-child .css-26l3qy-menu{bottom:100%; top: auto;}

.custom-table-div .rdt_TableRow:nth-last-child(2) .css-26l3qy-menu{bottom:100%; top: auto;}




/* .submisson-table-bg .assigned-title-block-new .assigned-title-itm .assigned-title-blk{overflow: hidden !important;} */

.submisson-table-item1.custom-table-div .rdt_TableRow:last-child .css-26l3qy-menu{bottom: -10px; top:auto;}


/* @media only screen and (max-width: 1300px) {
  .submisson-table-bg .iqVbfb{overflow-x:auto;}
} */

.appeal-submission-list {margin-bottom: 0px;}
.topic-add-modal-content .appeal-submission-list .form-group-blk {margin-bottom: 0px;}
.no-left-margin {margin-right: 0px;}

.course-table-filter-sec .form-icon-group i{position: relative; top:0; right:0;}

.react-datepicker__close-icon::after {
    cursor: pointer !important;
    background-color: #eef1f5 !important;
    color: #495057!important;
    border-radius: 0 !important;
    height: 16px;
    width: 16px;
    padding: 0px !important;
    font-size: 22px !important;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "\00d7";
    font-weight: 600 !important;
}
/* ticket settings -> departments */
.ticket__table__departments .rdt_TableRow .rdt_TableCell:first-child,.ticket__table__departments .rdt_TableRow .rdt_TableCell:first-child { width: 250px; }
.ticket__table__departments .rdt_TableRow .rdt_TableCell:last-child { text-align: right; } 
.ticket__table__departments .rdt_TableRow .rdt_TableCell:last-child .assessment-08 { display: inline-block; }
.ticket__table__departments .rdt_TableHeadRow .rdt_TableCol:last-child { text-align: right; }
/* ticket settings -> category */
.ticket__table__category .rdt_TableRow .rdt_TableCell:first-child,.ticket__table__category .rdt_TableRow .rdt_TableCell:first-child { width: 250px; }
.ticket__table__category .rdt_TableRow .rdt_TableCell:nth-child(2),.ticket__table__category .rdt_TableRow .rdt_TableCell:nth-child(2) { width: 250px; }
.ticket__table__category .rdt_TableRow .rdt_TableCell:last-child { text-align: right; } 
.ticket__table__category .rdt_TableRow .rdt_TableCell:last-child .assessment-08 { display: inline-block; }
.ticket__table__category .rdt_TableHeadRow .rdt_TableCol:last-child { text-align: right; }

div#viewAllCourses {
    background: rgb(0 0 0 / 24%);
  }
/* .new-reply-box{display: none;} */
.user-profile .user-drop-down-img figure img {
  border-radius: 50%;
  object-fit: unset !important;
  height: 60%;
  min-width: 0px !important;
  object-position: center;
  width: 60%;
  position: static;
  /* background-color: #fff; */
}
.as-blue-bg-status {
  background: #467fcf;
  color: #fff;
}
.as-blue-bg-not-launch-status {
  background: var(--topbar-color);
  color: #fff;
}
span.hover-color-blue{white-space: nowrap;}
textarea.form-control {
  height: 70px;
}

/*table-column-right*/

.right-align-action .custom-table-div .rdt_TableCell:last-child{display: flex; justify-content: flex-end; margin-left: auto;}
.right-align-action .custom-table-div .rdt_TableCol:last-child{text-align: right;}
body .right-align-action .as-buttons{min-width:59px; text-align: left;}



.ticket-message::-webkit-scrollbar {
  width: 13px;
  border-radius: 2px !important;
}

.css-109onse-indicatorSeparator {
min-width: 0 !important;
}

.ticket-message::-webkit-scrollbar-thumb {
  border-radius: 2px;
}
.ticket-message::-webkit-scrollbar-track {
  border-radius: 0px;
}
.assigned-title-blk.name-icon img {
  border-radius: 50% !important;
}
.user-image-name {
    position: absolute;
    z-index: 0;
}
.user-image, .profile-box-2-status {
  /* z-index: 1; */
}
.popup-zindex .custom-control-input {
  z-index: 3;
}

.popup-button-margin {
  margin: 30px !important;
}
.fm-image-container {
  max-width: 240px;
  overflow: hidden;
  max-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fm-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  border-radius: 2px;
  width: 250px;
  background-color: #f3f6f9;
}
.fm-files {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.fm-image {
  background-size: contain;
}
.fm-file-details{
  min-height: 16px;
}
.fm-container {
  padding: 30px;
}
.fm-card:focus {
  background-color: red;
}
.fm-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}
.fm-heading {
  padding: 30px 30px 0px 30px !important;
  margin-bottom: 0px !important;
}
.fm-search input{
  background: #fff;
}
.fm-li {
  display: flex;
  gap: 10px;
  background-color: antiquewhite;
  padding: 5px;
  margin: 5px;
}
.fm-ul-files .title-border-box{
  margin-bottom: 15px;
}
.selected-div {
  background-color: var(--form-tab-hover-color);
}
.fm-file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  display: block;
  direction: rtl;
  text-align: center;
  margin: 5px 0px;
}
.fm-ul-files .title-border-box i {
  color: #484644;
}
.fm-main-card {
  margin: 0px 30px;
}
.fm-search::before {
  position: absolute;
  content: "";
  left: 0;
  top: 9px;
  width: 20px;
  height: 20px;
  background-image: url(../images/data-table-search.svg);
  background-repeat: no-repeat;
}
.fm-input-search {
  min-width: 100px !important;
  width: 100px;
  border: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
  outline: none;
  margin-left: 25px;
  padding: 6px 0px;
  font-size: 13px;
  font-weight: 300;
  font-family: "Segoe UI";
  color: #959595;
}
.fm-footer {
  padding: 30px;
  display: flex;
  justify-content: space-between;
}
.fm-container .row, .fm-container .fm-ul-files {
  overflow-y: auto;
  max-height: calc(100vh - 220px);
  padding: 0px 5px 0px 0px;
}
.fm-rename-field {
  border: none;
  outline: none;
  background-color: var(--form-tab-hover-color);
  width: 100%;
}

.input_wt02 input {
  width: 15% !important;
}

.form-icon-group-input .form-control {
  background-color: #fff;
  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  border: 1px solid #eef1f5 !important;
  padding: 6px 15px;
  min-height: 42px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.grade-icon {
  position: unset !important;
}
.add-template-table .rdt_TableRow .rdt_TableCell:nth-child(1),.add-template-table .rdt_TableRow .rdt_TableCell:nth-child(2){
  width:140px;
}
.add-template-table .rdt_TableRow .rdt_TableCell:last-child {
  max-width: 150px;
  width: 150px;
}
.add-template-table .rdt_TableRow .rdt_TableCell .form-control { width: 52px; }

.exclude-check-box-template {
  margin-top: 2rem !important;
}

.input_wt03 input {
  width: 6% !important;
}
.table-book-main { border: none;width: 100%;}
.table-book-main tr td,.table-book-main tr th { padding: 10px;border: none;height: 78px;}
.table-book-main.table-book-grademix tr td,.table-book-main.table-book-grademix tr th { height: 55px !important;}
.table-book-main tr th { border-bottom: 1px solid #DFDFDF; 
  vertical-align: text-bottom; }
.table-book-main tr td { border-bottom: 1px solid #DFDFDF;}
.course-gradebook .grade-acc-in tr td { vertical-align: top;}
.table-book-main-contetn tr td { vertical-align: top;}
.table-book-main-contetn tr .course-exempted-td { vertical-align: middle !important;}
/* iframe { display: none; } */
.table-book .table-book-left { min-width: fit-content; }
/* .table-book .table-book-mid { overflow: auto; } */
.table-book .table-book-right { min-width: fit-content;width:100%; }
.table-book.row { flex-wrap: initial; }
/* .table-book .table-book-right table { border-left: 2px solid #EF8700;box-shadow: -8px 0px 7px rgba(0,0,0,0.1);} */
.table-book-main tbody tr:nth-child(even) { background: #f3f6f9; }
.grade-acc-in { display: flex; }
.table-book-main-final { width: 144px;min-width: 144px; }
.table-book-main-first { text-align: center; }
.table-book-mid { display: flex;}
.grade-acc { display: flex;width: 100%; }
.table-book-main-contetn th { width: 130px;min-width: 130px; }
.table-book-main-contetn.grademix-gradebook th { width: 250px !important;min-width: 250px !important; }
/* .table-book-main-contetn thead th:first-child { width: 220px;min-width: 220px; } */
.table-book-main-first { background-color: var(--gradebook-collapse-color);color: #fff; }
.table-book-main-first tbody tr:nth-child(even) { background-color: var(--sidebar-opacity-color); }
.table-book-main-first tbody tr:nth-child(even) td { background-color: rgba(0, 0, 0, 0.2); }
.grade-acc-in.active .table-book-main-first { background-color: var(--gradebook-expand-color);color: #fff; }
.grade-acc-in.active .table-book-main-first tbody tr:nth-child(even) { background-color: var(--topbar-opacity-color); }
.grade-acc-in.active .table-book-main-first tbody tr:nth-child(even) td { background-color: rgba(0, 0, 0, 0.2); }
.grade-acc-in.active .table-book-main-final { border-left: 2px solid #EF8700; }
.grade-acc-in.active .table-book-main-final th,.grade-acc-in.active .table-book-main-final td { background-color: #FEF3E6; }
.grade-acc-in.active .table-book-main-final tr:nth-child(even) td { background-color: #F3EBE1; }
.marks-input-text { display: flex;align-items: center;}
.marks-input-text input { width: 28px;height: 32px;background: #FFFFFF;border: 1px solid #DFDFDF;border-radius: 2px;margin-right: 5px;text-align: center; }
.marks-input-text input.input-red { background: rgba(220, 53, 69, 0.25); }
.marks-input-big input{ width: 45px; min-width: 45px; }
.grade-info-ic-box .grade-info-ic { display: inline-block;font-size: 16px;margin-right: 5px;margin-top: 5px; }
.grade-info-red { color: #d9534f; }
.grade-info-orange { color: #EF8700; }
.grade-info-purple { color: #9747FF; }
.grade-info-blue { color: #00A9FF; }
.grade-info-green { color: #429D4C; }
.table-book .as-widget { font-weight:bold; }
.select-dropdown-tb select { width: 68px;background: #FFFFFF;border: 1px solid #DFDFDF;border-radius: 2px;height: 33px; }
.grade-acc-in .table-book-main-contetn { display: none !important; } 
.grade-acc-in.active .table-book-main-contetn { display: initial !important; }
.pagination-nav .second-child { justify-content: flex-end;align-items: center;display: flex;padding: 10px;padding-bottom: 0; }
.pagination-select select { box-sizing: content-box;height: 24px;min-width: 24px;padding-left: 10px;padding-right: 10px;background-color: transparent;border: 1px solid #eff0f2;color: #06172a;appearance: none !important;background-image: url(/static/media/arrow-down.607a4deb.svg) !important;background-repeat: no-repeat;background-position: center right 7px; }
.pagination-btns { display: flex; }
.pagination-btns button { border: none;border-radius: 50%;height: 40px;width: 40px;padding: 8px;transition: 0.4s;color: rgba(0,0,0,.54);fill: rgba(0,0,0,.54);background-color: transparent; }
.pagination-btns button svg { cursor: pointer;color: rgba(0,0,0,.54);fill: rgba(0,0,0,.54);}
.pagination-btns button:disabled,.pagination-btns button:disabled svg{ cursor: unset;color: rgba(0,0,0,.18);fill: rgba(0,0,0,.18);}
.pagination-btns button:hover:not(:disabled) {background-color: rgba(0,0,0,.12);}
.tb-book-tit span { display: inline-block;margin-left: 5px; }
.table-book .as-red-bg { background-color: #D9534F; }
.table-book .as-green-bg { background-color: #5DB85D; }
.btn-grade-acc { background: none;border: none;color: #fff; }
.head-link { color: #467FCF; }
.head-link-og { color: #EF8700; }

@media only screen and (max-width: 1445px){
  .programme-gradebook .table-book-responsive { overflow: auto; }
}

@media only screen and (max-width: 1024px){
  .table-book-responsive { overflow-x: auto;overflow-y: hidden;padding-bottom: 15px; }
  .table-book { width: 100%; }
}
@media only screen and (max-width: 767px){
  .table-book-responsive { overflow-x: auto;overflow-y: hidden;padding-bottom: 15px; }
  /* .table-book { width: 1000px; } */
  .pagination-nav .pagination-select,.pagination-nav span { display: none;}
}

.fm-no-border {
  border: unset !important;
  margin: 0 !important;
  padding: 0 !important;
}
.fm-no-border .fm-heading {
  padding-top: 0 !important;
  padding-left: 0 !important;
}
.fm-no-border .fm-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.no-form-icon-group i{
  position: initial;
}
.expandable-icon {
  background-color: var(--topbar-color);
}
.gradebook-expand-table .rdt_ExpanderCell > .rdt_ExpanderIcon,
.gradebook-expand-table .rdt_ExpanderCell > .rdt_ExpanderIcon:after {
  display: none;
}

.custom-collapse-icon {
  width: 16px; /* Set the desired width */
}


/* .gradebook-expand-table .rdt_TableRow .sc-hLBbgP:nth-child(1) {
  display: none;
} */


.sub-table {
  display: flex;
}

/* .rdt_ExpanderRow {
  display: initial;
  border-collapse: initial;
} */

/* .gradebook-expand-table .rdt_TableBody .rdt_TableRow .sc-hLBbgP:first-child{display:none; min-width: 0 !important;} */

.gradebook-expand-table .rdt_TableRow .sc-hLBbgP:first-child{display: none;}
.gradebook-expand-table .rdt_ExpanderRow .rdt_TableRow .sc-hLBbgP:first-child{display:flex;}



/* .gradebook-expand-table.programme-grade-table-expand .rdt_TableRow .rdt_TableCell,.programme-grade-table-expand .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol{flex: 0 0 221px; max-width: 221px;} */
/* .gradebook-expand-table .rdt_TableRow .rdt_TableCell:last-child,.rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child{flex: 0 0 80px; padding-left: 0; max-width: 80px;} */
.gradebook-expand-table .rdt_TableRow .rdt_TableCell:last-child,.rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child{padding-left: 0; max-width: 80px; flex: 0 0 80px; min-width: 50px;}




.gradebook-expand-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .sc-hLBbgP:first-child{display:none; min-width: 0 !important;}
.rdt_ExpanderRow{display: table-row;}
/* .expand-toggle-button span{ width: 15px; height: 15px; background: #000; color:#fff; border-radius: 50px; display: flex; align-items: center; justify-content: center;font-size: 12px;} */

.gradebook-expand-table .rdt_TableBody .rdt_ExpanderRow:nth-child(even){background: #f3f6f9;}

.gradebook-expand-table .rdt_ExpanderRow .rdt_TableBody .rdt_TableRow{background: #fff;}
.gradebook-expand-table .rdt_ExpanderRow .rdt_TableBody .rdt_TableRow:nth-child(odd){background: #f3f6f9;}

.gradebook-expand-table .rdt_TableBody .rdt_TableRow { border-left: solid 5px var(--gradebook-collapse-color) !important; background: var(--gradebook-collapse-bg-color);}
.gradebook-expand-table .rdt_TableBody .rdt_TableRow.expanded-course-row { border-left: solid 5px var(--gradebook-expand-color) !important; background: var(--gradebook-expand-bg-color); border-bottom: none !important;  }

.gradebook-expand-table .rdt_TableBody .rdt_TableRow.expanded-course-row .as-text-topbar-gradebook {
  font-weight: bold;
  color: var(--gradebook-expand-color);
}

.gradebook-expand-table .rdt_TableBody .rdt_TableRow .as-text-topbar-gradebook {
  font-weight: bold;
  color: var(--gradebook-collapse-color);
}

.gradebook-expand-table .rdt_ExpanderRow .rdt_TableBody .rdt_TableRow .as-text-topbar-gradebook {
  font-weight: bold;
  color: #000 !important;
}

.gradebook-expand-table .rdt_TableBody .rdt_TableRow > div{border-bottom:2px solid #d7dee8 !important;}
.gradebook-expand-table .rdt_TableBody .rdt_ExpanderRow .rdt_TableRow { border:none !important; }
.gradebook-expand-table .rdt_TableBody .rdt_ExpanderRow .rdt_TableRow:nth-child(even) > div { background-color: #Fff;border:none !important; } 
.gradebook-expand-table .rdt_TableBody .rdt_ExpanderRow .rdt_TableRow:nth-child(odd) > div { background: #f3f6f9;border:none !important; } 
.gradebook-expand-table .rdt_TableBody .rdt_ExpanderRow .rdt_TableRow .rdt_TableCell:last-child { min-width: auto; }
.gradebook-expand-table .rdt_TableHead .rdt_TableHeadRow { border-bottom: none; }
.gradebook-expand-table .rdt_TableHead .rdt_TableHeadRow > .rdt_TableCol { border-bottom: 1px solid rgba(0,0,0,.12);}

.gradebook-expand-table .rdt_ExpanderRow .rdt_TableBody .rdt_TableRow{border-left: solid 4px transparent !important; border-bottom: none !important;}
.gradebook-expand-table .rdt_ExpanderRow > div { overflow: inherit; }

.gradebook-expand-table .rdt_ExpanderRow .rdt_TableBody .rdt_TableRow{border-left: solid 4px transparent !important; border-bottom: none !important;}


/* .modal-dialog-bottom-right {
  position: absolute;
  top: 20px;
  right: 20px;
  margin: 0;
}

.icon-above-modal {
  position: relative;
  z-index: 1;
} */


.hover-topic-gradebook{position: relative;}
.template-topic-collection{position: absolute; right:10px; bottom: 10px; background: #fff; padding:10px; box-shadow: 0px 0px 8px #ccc; display: none;}
.hover-topic-gradebook:hover .template-topic-collection{display: block; width:100%;}

.assigned-title-blk01 .assigned-title-info.none-bg:before{ display: none;}

.assigned-title-info .d-flex span {

  margin-bottom: 4px;
}

.assigned-title-info.none-bg {
  max-width: 400px !important;
  min-width: 0;
  padding: 10px 10px 7px;
}


.assigned-title-info.none-bg .d-flex span {
  margin: 2px 2px;
}

.fm-icon-img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.fm-container .title-border-box {
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.notify-count-template {
    position: absolute !important;
    /* right: 29px !important; */
    left: 10px !important;
    top: -12px !important;
    width: 21px !important;
    height: 21px !important;
    background: #ff0202 !important;
    border-radius: 100% !important;
    font-size: 10px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-family: "Segoe UI" !important;
    font-weight: 700 !important;
    color: #fff !important;
    z-index: 1 !important;
    border: 1px solid #fff !important;
}
.resultstatus_flex input {
  min-width: 75px;
  max-width: 75px;
}
.tree-group-view-header {
  padding: 0px;
  padding-bottom: 9px;
  display: flex;
  justify-content: end;
}

.group-view-ques-info {
  height: 30px;
  display: flex;
  align-items: center;
}
.matrix-table td {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 25%;
}
.matrix-table th {
  width: 25%;
}
.matrix-table tr, .matrix-table th {
  display: flex;
}
.matrix-table tr {
  margin: 20px 0px;
}
.matrix-table .export-table-border-header, table.export-table-border-header td, table.export-table-border-header th {
  border: 0px !important;
}
.fm-no-border {
  border: unset !important;
  margin: 0 !important;
  padding: 0 !important;
}
.fm-no-border .fm-heading {
  padding-top: 0 !important;
  padding-left: 0 !important;
}
.fm-no-border .fm-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.no-form-icon-group i{
  position: initial;
}
.fm-icon-img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.fm-container .title-border-box {
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.decline-reason-comment {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 155px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 6;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.select-delete {
    align-items: center;
    margin-top: 5px;
    position: absolute;
}

.select-delete a {
  margin-right: 15px;
}

.row.grade-store-box {
  align-items: center;
}

.assessment-name-div{display: table-row;}

.grade-head-input{min-height: 30px; max-height: 30px;}
.exempted-text {
  color: rgb(92, 184, 92)
}
.marks-number-info {
  min-width: 80px;
}
.mid-scroll-custom-table-select {
  min-width: 55px;
  max-width: 55px;
  width: 28px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  border-radius: 2px;
  margin-right: 5px;
  text-align: center;
}
.custom-table-msg {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.pagination-nav {     
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.document-upload button {
  margin-left: 5px;
}
.document-upload {
  margin: 5px 0px;
}
.articulation-programme-status{
  color: #000 !important;
}

.fillstyle{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.articulation-programme-status{
  color: #000 !important;
}

.group-view-ques-status {
  font-weight: 400;
  color: #A3A8AF;
  width: 186px;
  white-space: normal;
}

span.group-view-hover-icon {
  position:relative;
  margin: auto;
  color: #467FCF;
  margin-right: 10px;
}
.grade-acc-in.active a.head-link {
  display: none;
}
.exempted-td div {
  text-align: center;
  max-width: fit-content;
}
.gradebook-expand-table .btn-grade-acc {
  color: #000;
}
.min-w-40 {
  min-width: 40px;
}
.grade-info-plus {
  color: #429d4c;
}
.notify-count-group {
  position: absolute !important;
  left: 3px !important;
  top: -12px !important;
  width: 21px !important;
  height: 21px !important;
  background: #ff0202 !important;
  border-radius: 100% !important;
  font-size: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 700 !important;
  color: #fff !important;
  z-index: 1 !important;
  border: 1px solid #fff !important;
}
.overflow-unset {
  overflow: unset !important;
}
.overflow-auto {
  overflow: auto !important;
}
.width-full {
  width: 100% !important;
}

.min-width-max-content {
  min-width: max-content !important;
}
.view-matrix-table table.export-table-border-header {
  border: none !important;
}
.view-match-column li{
  border : 1px solid;
  margin-bottom:10px;
  padding:5px;
}

.view-match-column-drag li{
  border : 1px dashed grey;
  margin-bottom:10px;
  padding:5px;
}
span.group-action-buttons.as-buttons{
  display: flex;
}


.profile-doc-upload {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 !important;
}
.order-list-item{
  padding:10px;
  border:1px solid #DFDFDF;
  border-left: 3px #467FCF solid;
  margin-top:10px;
  border-radius: 2px;
}
.order-list-item p{
  margin: 0;
}
.order-list-wrapper{
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: scroll;
}
.table-book .table-book-mid {
  min-width: auto;
  width: fit-content;
  max-width: 100%;
}
.max-width-fit-content {
  max-width: fit-content;
}
.my-10px {
margin-top: 10px;
margin-bottom: 10px;
}

.pass-side-border-grade {
  border-left: 5px solid #5CB85C !important;
}

.fail-side-border-grade {
  border-left: 5px solid #D9534F !important;
}

.sideBar-side-border-grade {
  border-left: 5px solid var(--sidebar-color) !important;
}

.intake-copy-side-border-grade {
  border-left: 3px solid var(--sidebar-color) !important;
}

/* DON'T DELETE BELOW CODE
.gradebook-expand-table .rdt_TableRow .sc-hLBbgP:nth-child(2){min-width: 500px;}
.gradebook-expand-table .rdt_TableCol:nth-child(2){min-width: 500px;}
.gradebook-expand-table .rdt_ExpanderRow .rdt_TableRow .sc-hLBbgP:nth-child(1){min-width: 500px !important;}
.gradebook-expand-table .rdt_ExpanderRow .rdt_TableRow .sc-hLBbgP:nth-child(2){min-width: 100px !important;} */

.filepond-height-fixed .showFilePond{
  height: 400px;
  overflow: auto;
}
.std-percentage-view .btn {
  min-width: fit-content;
}
.std-percentage-view {
  margin-right: 57px;
  margin-bottom: 20px;
}
.table-curser input {
  cursor: pointer;
  z-index: 2;
}
.fm-no-border {
  border: unset !important;
  margin: 0 !important;
  padding: 0 !important;
}
.fm-no-border .fm-heading {
  padding-top: 0 !important;
  padding-left: 0 !important;
}
.fm-no-border .fm-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.no-form-icon-group i{
  position: initial;
}
.fm-icon-img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.fm-container .title-border-box {
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.decline-reason-comment {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 155px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 6;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.articulation-programme-status{
  color: #000 !important;
}


 .table-book-main th p {
  white-space: nowrap !important;
  color: #000 !important;
 }
.percentage-select-red {
background: rgba(220, 53, 69, 0.25) !important;
}
.text-end {
  text-align: end;
}
.border-shadow-custom-table {
  box-shadow: -8px 0px 7px rgba(0,0,0,0.1);
  position: relative;
}
.second-last-cell {
  min-width: 130px;
  width: 130px;
}
.third-last-cell {
  min-width: 215px;
  width: 215px;
}
.second-last-cell-programme {
  min-width: 100px;
  width: 165px;
}
.sticky-save-footer {
  position: fixed;
  bottom: 0;
  left: 80px;
  width: calc(100% - 80px);
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(7px);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.160784);
  padding: 20px 30px;
}
.sidebar-right.bottom-100 { padding-bottom: 100px !important; }
.fake-input {
  min-width: 75px;
  max-width: 75px;
  width: 28px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.resultstatus_flex span {
  margin: 0px;
  margin-right: 5px;
}

.form-icon-group.custom-select-box.submission-result-dropdown  .css-26l3qy-menu{max-height: max-content!important;}


.new-custom-login .portal-login-card {
  border: 1px solid hsla(0,0%,100%,0.2);
  border-radius: 2px;
  backdrop-filter: blur(15px);
  background: hsla(0,0%,100%,.2);
}
.sidebar-right.bottom-100 { padding-bottom: 100px !important; }

.programme-gradebook .table-book-right .second-last-cell-programme{width: 100%; text-align: right;}
.course-gradebook .table-book-right .second-last-cell{width: 100%; text-align: right;}
.course-gradebook .table-book-right .third-last-cell{width: 100%; text-align: right;}
.table-book-right .as-widget-outer{max-width: 135px; margin-left: auto;}
.table-book-right .as-widget-outer-submission{max-width: 128px; margin-left: auto;}
/* .programme-gradebook .table-book .table-book-right{width: auto;} */
.text-left{text-align: left;}
.sticky-save-footer {z-index: 2;}
/* Chatroom Phase 2 */
.system-bot-inner-L figure {
  background: var(--sidebar-color);
  padding: 5px;
}
.system-bot-figure-green {
  background-color: #5db85d !important;
}
.system-bot-figure-red {
  background-color: #8e00a7 !important;
}
.system-bot-figure-red-cancelled {
  background-color: #d9534f !important;
}
.system-bot-figure-orange {
  background-color: #f8a503 !important;
}
.assigned-title-itm span.name-icon img {
  object-fit: unset;
}
.border-upcoming{
  border-left: 4px solid #f8a503;
}
.border-live{
  border-left: 4px solid #5db85d;
}
.border-past{
  border-left: 4px solid #8e00a7;
}
.border-cancelled{
  border-left: 4px solid #d9534f;
}
.ch2__sts .as-red-bg-status{
  background-color: #8e00a7;
}
.ch2__sts .as-green-bg-status{
  background-color: #5db85d;
}
.ch2__sts .as-red-bg-status-cancelled{
  background-color: #d9534f;
}
.ch2__sts .as-orange-bg-status{
  background-color: #f8a503;
}
.class__schedule__venues{
  flex:1;
}
.custom-swal-button .swal2-styled.swal2-confirm, .custom-swal-button .swal2-styled.swal2-cancel {
  font-size: 1rem !important;
}
.table-book-left .table-book-main {
  border-right: 2px solid #EF8700 !important;
  border-left: 0 !important;
}
.table-book-right .table-book-main {
  /* border-left: 2px solid #EF8700 !important; */
  border-right: 0 !important;
}
.table-book-left .border-shadow-custom-table {
  box-shadow: 8px 0px 7px rgba(0,0,0,0.1) !important;
  position: relative;
  z-index: 1;
}
.right-border-grade{
  position: absolute;
  width: 2px;
  height: 102%;
  background: #EF8700;
  top: 0;
}
.second-last-cell-programme{
  position: relative;
}
.table-book-main tr td{position: relative;}
.second-last-cell {
  position: relative;
}
.third-last-cell{
  position: relative;
}
.course-gradebook .right-border-grade {
  right: 128px;
}
.programme-gradebook .right-border-grade {
  right: 158px;
}
.last-cell-border {
  height: 100% !important;
}

.title-border-box .download-SoR-icon {
  color: #fff !important;
  margin-right: 0 !important;
}
.course-gradebook input[type="number"], .programme-gradebook input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}


.filepond-height-fixed .showFilePond{
  /* height: 400px; */
  overflow: auto;
}
.border-shadow-custom-table {
  border-left: 2px solid #EF8700;
  box-shadow: -8px 0px 7px rgba(0,0,0,0.1);
}
.isSystemRobot{
  width: 32px !important;
  height: 32px !important;
  background: transparent !important;
}
.text__strike__through, .text__strike__through i {
  text-decoration: line-through!important;
  color: #d9534f;
}
.without-pagination-btns {
  padding-top: 30px !important;
}
.card-size-4 {
  width: 33%;
}
.mr-30 {
  margin-right: 30px;
}
.dates-container table {
  width: 100%;
}
.dates-container td:first-child {
  border: 1px solid rgba(0,0,0,.125);
  padding: 10px;
  border-right: 0px !important;
}
.dates-container td:last-child {
  border: 1px solid rgba(0,0,0,.125);
  padding: 10px;
  border-left: 0px !important;
  text-align: end;
}
.dates-container td:not(:first-child):not(:last-child) {
  border: 1px solid rgba(0,0,0,.125);
  padding: 10px;
  border-left: 0px !important;
  border-right: 0px !important;
}
.dates-container .icon-blue {
  color: #2c7bff;
}
.dates-container .icon-red {
  color: #dc3545;
}
.class-container table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
}
.class-container td:first-child {
  border: 1px solid rgba(0,0,0,.125);
  /* padding: 5px; */
  border-right: 0px !important;
}
.class-container td:last-child {
  border: 1px solid rgba(0,0,0,.125);
  /* padding: 5px; */
  border-left: 0px !important;
  text-align: end;
}
.class-container td:not(:first-child):not(:last-child) {
  border: 1px solid rgba(0,0,0,.125);
  /* padding: 5px; */
  border-left: 0px !important;
  border-right: 0px !important;
}
.table-expand-btn {
  font-size: 14px;
  color: #467fcf;
  cursor: pointer;
}
.as-virtual-bg i, .as-campus-bg i, .as-hybrid-bg i {
  margin-right: 0;
}
.assigned-profile-overlay{
  display: flex;
}
.assigned-profile-overlay .assigned-title-block-new:not(:first-child) { position: relative;margin-left: -20px; }
.assigned-profile-overlay-out { display: flex;align-items: center; }
.assigned-profile-tit > span { font-size: 12px;color: #000; }
.dashboard-message-count small.notify-count1 { top: 18px; left: 3px; }
.btn-orange { background-color: #EF8700 !important;border-color: #EF8700 !important; }
.btn-sidebar { background-color: #39a9ff !important;border-color: #39a9ff !important; }
.btn-orange:hover { background-color: #DC7C00 !important;border-color: #DC7C00 !important; }
.launcher-info-bar { border-top: 1px solid #E0E0E0;border-bottom: 1px solid #E0E0E0; }
.box-style-css { padding: 6px; }
.launcher-desc { border-bottom: 1px solid #E0E0E0; }
.class-container td > div, .class-container td > span { padding: 2px; }
.class-container td { padding-bottom: 5px; }
.when-scroll-sticky .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child { position: sticky;z-index: 11;background:#fff;left: -2px;
  /* box-shadow: 2px 2px 3px rgba(0,0,0,0.2); -moz-box-shadow: 2px 2px 3px rgba(0,0,0,0.2); -webkit-box-shadow: 2px 2px 3px rgba(0,0,0,0.2);  */
}
.when-scroll-sticky .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child { position: sticky;z-index: 11;right: -2px;background:#fff;
  /* box-shadow: -2px 2px 3px rgba(0,0,0,0.2); -moz-box-shadow: 2px 2px 3px rgba(0,0,0,0.2); -webkit-box-shadow: 2px 2px 3px rgba(0,0,0,0.2);  */
}
.when-scroll-sticky .rdt_TableBody .rdt_TableRow:nth-child(even) .rdt_TableCell:first-child { background-color: #f3f6f9; }
.when-scroll-sticky .rdt_TableBody .rdt_TableRow:nth-child(even) .rdt_TableCell:last-child { background-color: #f3f6f9; }
.when-scroll-sticky .rdt_TableBody .rdt_TableRow:hover .rdt_TableCell:first-child,.when-scroll-sticky .rdt_TableBody .rdt_TableRow:hover .rdt_TableCell:last-child { background-color: #e6ecf2; }

.when-scroll-sticky .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child { position: sticky;z-index: 11;background-color: #fff;left:-2px;
  /* box-shadow: 2px 2px 3px rgba(0,0,0,0.2);  */
}
.when-scroll-sticky .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child { padding-left: 15px !important; position: sticky;z-index: 11;background-color: #fff;right:-2px;
  /* box-shadow: -2px 2px 3px rgba(0,0,0,0.2);   */
}
.course-dashboard-blocks { overflow: auto; height: 190px; }
.icon-yellow {
  color: #fac10b;
}
.icon-grey {
  color: #6c757d;
}
.icon-green {
  color: #31a745;
}
.bizcharts-plot-title {padding: 0px 0px 20px 30px !important;}
.days-indication {
  position: absolute;
  top: 52px;
  left: 40px;
  width: 50%;
  /* padding: 0px 0px 0px 30px; */
  justify-content: space-between;
  display: flex;
  z-index: 2;
}
.days-indication .active {
  color: #467fcf;
}
.line-chart-dashboard {
  position: absolute !important;
  top: 105px;
  left: -20px;
}
.min-height-col {min-height: 288px;}
.css-12jo7m5 span{
  background: transparent !important;
  color: #333 !important;
}
.SoR-icon {
  color: var(--topbar-color) !important;
}

.SoR-settings {
  font-weight: 700 !important;
  font-size: 16px !important;
}

.custom-toggle-switch {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 25px;
  background-color: orange; /* Default border color */
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;
  vertical-align: middle;
}

.custom-toggle-switch input {
  display: none;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: green; /* Fill color when checked */
  border-radius: 50%;
  transition: 0.4s;
}

.sor .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: white;
  transition: 0.4s;
}

.custom-toggle-switch.checked .slider {
  background-color: var(--topbar-color) !important; /* Fill color when not checked */
}

.custom-toggle-switch.checked .icon {
  left: 5px;
}

 /* The switch - the box around the slider */
 .switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22px;
  margin-bottom: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid var(--topbar-color);
  -webkit-transition: .4s;
  transition: .4s;
  font-size: 10px;
}

.slider:before {
  position: absolute;
  content: "\f00d";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Font Awesome 5 Pro";
  background-color:var(--topbar-color);
  top: 2px;
  color: #fff;
}

input:checked + .slider {
  background-color: #5CB85C;
  border: 1px solid #5CB85C;
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
  color: #5CB85C;
  content: "\f00c";
  background-color: #fff;
  
}

/* Rounded sliders */
.slider.slider-round {
  border-radius: 34px;
}

.slider.slider-round:before {
  border-radius: 50%;
}
.modal-600{max-width: 630px;} 

.prev-value-re-reg {
  color: red;
  margin-top: -25px;
}
.dashboard-blocks {min-height: 286px;}
.dashboard-course-rating {padding-top: 15px;}
.avg-days-indication {left: 30px;}
.submission-summary .bizcharts-plot {margin-top: 10px;}
.position-relative {position: relative;}
.position-relative .notify-count1 {top: -9px !important;}
.one-line-truncate {
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.parent-imp-dates-ellipsis {
  overflow: hidden;
}
.imp-dates-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
}
.imp-dates-ellipsis-dates {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.unread-message-icon {
  position: absolute;
  left: 10px;
  top: 15px;
}
.unread-message-count {
  position: absolute !important;
  left: 0px !important;
  top: 5px !important;
}
.hidden-icon {
  visibility: hidden;
}
.when-scroll-sticky .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child:after {
  content: "";
  width: 8px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAABHNCSVQICAgIfAhkiAAAAEhJREFUSEtjZGBg+AXE36D4KxIbJIbOJ0sd46glpATraHCRlApHg2s0uMDlF0nBgKfMQynjRlMXScE6GlyjwTWaGbGGAP2LFQArHFi1ynrPpAAAAABJRU5ErkJggg==);
  position: absolute;
  right: -8px;
  height: 100%;
  top: 0;
  background-size: 100%;
  opacity: 0.1;
}
.when-scroll-sticky .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child:after {
  transform: rotate(180deg);
  content: "";
  width: 8px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAABHNCSVQICAgIfAhkiAAAAEhJREFUSEtjZGBg+AXE36D4KxIbJIbOJ0sd46glpATraHCRlApHg2s0uMDlF0nBgKfMQynjRlMXScE6GlyjwTWaGbGGAP2LFQArHFi1ynrPpAAAAABJRU5ErkJggg==);
  position: absolute;
  left: -8px;
  height: 100%;
  top: 0;
  background-size: 100%;
  opacity: 0.1;
}
.when-scroll-sticky .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child:after {
  transform: rotate(180deg);
  content: "";
  width: 8px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAABHNCSVQICAgIfAhkiAAAAEhJREFUSEtjZGBg+AXE36D4KxIbJIbOJ0sd46glpATraHCRlApHg2s0uMDlF0nBgKfMQynjRlMXScE6GlyjwTWaGbGGAP2LFQArHFi1ynrPpAAAAABJRU5ErkJggg==);
  position: absolute;
  left: -8px;
  height: 100%;
  top: 0;
  background-size: 100%;
  opacity: 0.1;
}
.when-scroll-sticky .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child:after{
  content: "";
  width: 8px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAABHNCSVQICAgIfAhkiAAAAEhJREFUSEtjZGBg+AXE36D4KxIbJIbOJ0sd46glpATraHCRlApHg2s0uMDlF0nBgKfMQynjRlMXScE6GlyjwTWaGbGGAP2LFQArHFi1ynrPpAAAAABJRU5ErkJggg==);
  position: absolute;
  right: -8px;
  height: 100%;
  top: 0;
  background-size: 100%;
  opacity: 0.1;
}
.today-class-link {
  color: #3199d2;
  font-weight: bold;
}
.width-155 {
  max-width: 155px !important;
}
.assigned-profile-tit .assigned-title-block-new {
  margin: 5px 0px;
}
.assigned-profile-tit, .assigned-profile-overlay-out {
  min-width: 155px;
  max-width: 155px;
  white-space: break-spaces;
}

.see-more-text-mentored {
  color: var(--topbar-color);
  cursor: pointer !important;
}

.expanded-mentored {
  white-space: break-spaces !important;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}



 .log-copy-table .rdt_TableCell:last-child {
  /* display: flex;
  justify-content: flex-end */
  text-align: -webkit-right !important;
}

.log-copy-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child{text-align: right;}

.log-copy-table .rdt_TableCell:last-child .assessment-08{    padding-right: 15px;}
.filter-search-icon.card-table-custom.bulk-notification-table
  .rdt_TableCol:last-child
  .rdt_TableCol_Sortable
  > div {
  padding-right: 18px;
}

.cursor-arrow {
  cursor: default !important;
}

.assessment-grade-setting-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .assessment-grade-setting-header .card-header{
  padding: 5px 0 !important;
} */
.form-group-blk .prev-value-re-reg {
  margin-top: 0px !important;
}
.custon_rd_new .prev-value-re-reg {
  margin-top: 35px !important;
  margin-left: -117px !important;
}
.search-filter-div-left {
  z-index: 98;
}

.gradeMix-sidebar-color {
  color: var(--sidebar-color) !important;
}

.grademix-gradebook .right-border-grade {
  right: 150px;
}

.grademix-subandres .right-border-grade {
  right: 151px;
}

.grademix-inside-assessment {
  color: var(--gradebook-expand-color) !important;
}

.available-attachments-modal {
  max-width: none;
  width: 50%;
}

.view-attach {
  margin-top: 2%;
  margin-left: 7%;
}

.upload-file-width {
  width: 170px;
  min-width: 170px
}
ul.submission-file li{border:none;padding: 10px 0 0;}
